import { gql } from '@apollo/client';
import { LOCK_FRAGMENTS, PARTY_FRAGMENTS } from './fragments';

export const SAVE_PARTY = gql`
  ${PARTY_FRAGMENTS}
  ${LOCK_FRAGMENTS}
  mutation CreateParty($data: PartyCreateUpdateInput!) {
    partyCreateUpdate(data: $data) {
      party {
        partyId
        partyType
        partySubType
        countryOfOriginId
        languageOfOriginId
        dateOfBeginningDay
        dateOfBeginningMonth
        dateOfBeginningYear
        dateOfEndDay
        dateOfEndMonth
        dateOfEndYear
        signed
        lock {
          ...PartyLockFields
        }
      }
      names {
        ...PartyName
      }
    }
  }
`;
