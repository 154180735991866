import { MutationHookOptions, useMutation } from '@apollo/client';
import { PARTY_UNLOCK } from 'domains/party/graphql/unlockParty';
import { PARTY_LOCK } from 'domains/party/graphql/lockParty';
import { PARTY_UPDATELOCK } from '../graphql/udaptePartyLock';

export const usePartyLock = (partyId: number|undefined) => {
  const options: MutationHookOptions = {
    variables: {
      data: {
        partyId
      }
    },
    // this property is used to avoid the rerender on the edit page, this causes errors with the focus on modals within the page, more info about this property -> https://www.apollographql.com/docs/react/api/react/hooks#ignoreresults
    ignoreResults: true
  };
  // TODO: 
  const [unlockParty] = useMutation(PARTY_UNLOCK, options);
  const [lockParty] = useMutation(PARTY_LOCK, options);
  const [updateLockParty] = useMutation(PARTY_UPDATELOCK, options);
  return { unlockParty, lockParty, updateLockParty };
};
