import React, { useCallback, useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '../Dialog';
import { EDialogType } from '../types';

type TUseConfirmation = [(args: any) => void, React.ReactNode];

interface IOptions {
  name: string;
  message: ((...args: any[]) => string) | string | React.ReactNode;
  cancelText?: string;
  actionText?: string;
  type?: EDialogType;
  onCancel?: (cb: () => void) => void;
  onClose?: (cb: () => void) => void;
}

type TActor<T> = (arg: T) => boolean | void;

export function actionCompose<T>(...funks: Array<TActor<T> | undefined>) {
  return (arg: T) => {
    funks.reduceRight((a, fn) => {
      if (!a && typeof fn === 'function') {
        return Boolean(fn(arg));
      }
      return a;
    }, false);
  };
}

export function useConfirmation(
  onAgree: ((...arg: any[]) => void) | undefined,
  condition: (...arg: any[]) => boolean | undefined,
  opt: IOptions,
): TUseConfirmation {

  const { t } = useTranslation();
  const [status, setStatus] = useState(false);
  const invocedArguments = useRef<any>();

  const closeDialog = useCallback(() => setStatus(false), [setStatus]);

  const { name, message, cancelText, actionText, type, onCancel, onClose } = useMemo(() => ({
    cancelText: t('cancel'),
    actionText: t('ok'),
    onCancel: closeDialog,
    onClose: closeDialog,
    type: EDialogType.destructive,
    ...opt,
  }), []);

  const handleAction = useCallback(() => {
    closeDialog();
    if (typeof onAgree === 'function' && typeof invocedArguments.current !== 'undefined') {
      onAgree(...invocedArguments.current);
    }
  }, []);

  const dialogMessage = useCallback(
    () => {
      if (typeof message === 'function') {
        return Array.isArray(invocedArguments.current) ? message(...invocedArguments.current) : null;
      }
      return message;
    },
    [message],
  );

  const element = useMemo(
    () => (
      <Dialog
        isOpen={status}
        name={name}
        type={type}
        onAction={handleAction}
        onCancel={() => onCancel(closeDialog)}
        onClose={() => onClose(closeDialog)}
        cancelText={cancelText}
        actionText={actionText}
      >{dialogMessage()}</Dialog>
    ),
    [status],
  );

  const onInvoke = useCallback(
    (...arg) => {
      if (condition(...arg)) {
        invocedArguments.current = arg;
        setStatus(true);
        return true;
      }
      return false;
    },
    [condition],
  );

  return [onInvoke, element];
}
