import { EPartyHistoryEntity, PartyHistoryItem } from 'domains/history';
import { tMessage } from 'modules/common/helpers/translation';
import { EField } from './types';
import { convertName, formatDateTime } from 'utils';
import { EDateTimeFormat } from 'common/consts';

export function generalAdapter<T extends PartyHistoryItem>(data: T): T {
  return {
    ...data,
    changedFrom: data.changedFrom || 'Empty',
    time: formatDateTime(data.dateTime, data?.settings?.timeFormat || EDateTimeFormat.HH_MM_A),
    date: formatDateTime(data.dateTime, data?.settings?.dateFormat?.toUpperCase() || EDateTimeFormat.MM_DD_YYYY_Slash),
    userName: convertName(data.userName),
  };
}

export function partyAdapter<T extends PartyHistoryItem>(data: T): T {
  if (data.entity === EPartyHistoryEntity.PARTY) {
    if (
      data.field === EField.partyType ||
      data.field === EField.partySubType
    ) {
      const changedTo = tMessage(data.changedTo as string);
      return {
        ...data,
        changedTo,
      };
    }
  }
  return data;
}

export function competenciesAdapter<T extends PartyHistoryItem>(data: T): T {
  if (data.entity === EPartyHistoryEntity.COMPETENCY) {
    const changedTo = data.changedTo ? tMessage(data.changedTo as string) : 'Empty';
    const changedFrom = data.changedFrom ? tMessage(data.changedFrom as string) : 'Empty';
    return {
      ...data,
      changedTo,
      changedFrom,
    };
  }
  return data;
}

export function generalFilter<T extends PartyHistoryItem>(entity: EPartyHistoryEntity, fl: (d: T) => boolean) {
  const fn = (f: T) => f.entity === entity ? fl(f) : true;
  return (list: ReadonlyArray<T>): ReadonlyArray<T> => list.filter(fn);
}

export const partyFilter = (field: EField | undefined) => generalFilter(
  EPartyHistoryEntity.PARTY,
  f => {
    if (field === EField.dateOfBeginning) {
      return f.field.startsWith(EField.dateOfBeginning);
    }
    if (field === EField.dateOfEnd) {
      return f.field.startsWith(EField.dateOfEnd);
    }
    return f.field === field;
  }
);

export function sorter<T extends PartyHistoryItem>(list: ReadonlyArray<T>) {
  return list.slice().sort((a, b) => b.dateTime - a.dateTime);
}
