import * as Yup from 'yup';
import set from 'lodash/set';
import { setIn } from 'final-form';
import { ENameType, TPartyName } from 'domains/party/types';
import { TestContext } from 'yup';
import {
  EPartyType,
  Party,
  PartyCompetency,
  PartySubType,
  partySubTypes,
  PartyType,
  partyTypes
} from 'modules/common/types/partyTypes';
import { PERFORMING_ARTIST_COMPETENCY_ID } from 'utils';


function getMaxDayForYearAndMonth (month: number, year: number) {
  let maxDay: number;
  switch (month) {
  case 2:
    maxDay = (year % 4 === 0 && year % 100) || year % 400 === 0 ? 29 : 28;
    break;
  case 9:
  case 4:
  case 6:
  case 11:
    maxDay = 30;
    break;
  default:
    maxDay = 31;
  }
  return maxDay;
}

const date = new Date();
const currentYear = date.getFullYear();
const currentMonth = date.getMonth() + 1;
const currentDate = date.getDate();

const PartySchema = Yup.object().shape({
  partyType: Yup.mixed<PartyType>()
    .oneOf(partyTypes)
    .required('Party type is required'),
  partySubType: Yup.mixed<PartySubType>().oneOf(partySubTypes).when('partyType', {
    is: (value: PartyType) => value === EPartyType.PERSON,
    then: (schema) => schema.required('Person Type is required'),
    otherwise: (schema) => schema.required('Group Type is required'),
  }),
  countryOfOriginId: Yup.mixed().test('countryOfOrigin required', 'Country of Origin is required', val => val && val !== 0),
  languageOfOriginId: Yup.mixed(),
  dateOfBeginningYear: Yup.number()
    .min(1600, `Year must be between 1600 and ${currentYear}`)
    .max(currentYear, `Year must be between 1600 and ${currentYear}`)
    .nullable()
    .when('dateOfBeginningMonth', {
      is: (val: string) => val,
      then: Yup.number().required('Must include a year with a month'),
      otherwise: Yup.number().notRequired(),
    }),
  dateOfBeginningMonth: Yup.number()
    .when('dateOfBeginningDay', {
      is: (val: string) => val,
      then: Yup.number().required('Must include a month with a day'),
      otherwise: Yup.number().notRequired(),
    })
    .test(
      'birth month + year test',
      'Cannot be later than the current month',
      function (val: any) {
        if (!val) return true;
        const dateOfBeginningYear: any = Yup.ref('dateOfBeginningYear');

        if (this.resolve(dateOfBeginningYear) === currentYear) {
          return val <= currentMonth;
        } else return true;
      }
    ),
  dateOfBeginningDay: Yup.number()
    .min(1)
    .max(31)
    .test(
      'number of days in birth month test',
      'Incorrect number of days for the month',
      function (val: any) {
        if (!val) return true;
        const dateOfBeginningYear: any = this.resolve(Yup.ref('dateOfBeginningYear'));
        const dateOfBeginningMonth: any = this.resolve(Yup.ref('dateOfBeginningMonth'));
        const dateOfBeginningDay: any = this.resolve(Yup.ref('dateOfBeginningDay'));

        const maxDay: number = getMaxDayForYearAndMonth(dateOfBeginningMonth, dateOfBeginningYear);

        return dateOfBeginningDay <= maxDay;
      }
    )
    .test(
      'birth date + month + year test',
      'Cannot be later than the current date',
      function (val: any) {
        if (!val) return true;
        const dateOfBeginningYear: any = Yup.ref('dateOfBeginningYear');
        const dateOfBeginningMonth: any = Yup.ref('dateOfBeginningMonth');
        if (
          this.resolve(dateOfBeginningYear) === currentYear &&
          this.resolve(dateOfBeginningMonth) === currentMonth
        ) {
          return val <= currentDate;
        } else return true;
      }
    ),
  dateOfEndYear: Yup.number()
    .min(1600, `Year must be between 1600 and ${currentYear}`)
    .max(currentYear, `Year must be between 1600 and ${currentYear}`)
    .nullable()
    .when('dateOfEndMonth', {
      is: (val: string) => val,
      then: Yup.number().required('Must include a year with a month'),
      otherwise: Yup.number().notRequired(),
    })
    .test(
      'birth year test',
      'Birth/Formation year cannot be greater than death/disbanded year',
      function (val: any) {
        const ref: any = Yup.ref('dateOfBeginningYear');
        if (this.resolve(ref) && val) return val >= this.resolve(ref);
        else return true;
      }
    ),
  dateOfEndMonth: Yup.number()
    .when('dateOfEndDay', {
      is: (val: string) => val,
      then: Yup.number().required('Must include a month with a day'),
      otherwise: Yup.number().notRequired(),
    })
    .test(
      'death month + year test',
      'Cannot be later than the current month',
      function (val: any) {
        if (!val) return true;
        const dateOfEndYear: any = Yup.ref('dateOfEndYear');
        if (this.resolve(dateOfEndYear) === currentYear) {
          return val <= currentMonth;
        } else return true;
      }
    )
    .test(
      'birth month test',
      'Birth/Formation month cannot be greater than death/disbanded month',
      function (val: any) {
        const ref: any = Yup.ref('dateOfBeginningMonth');
        const endYear: any = Yup.ref('dateOfEndYear');
        const begginingYear: any = Yup.ref('dateOfBeginningYear');
        if (this.resolve(ref) && val && this.resolve(endYear) == this.resolve(begginingYear)) return val >= this.resolve(ref);
        else return true;
      }
    ),
  dateOfEndDay: Yup.number()
    .min(1)
    .max(31)
    .test(
      'number of days in death month test',
      'Incorrect number of days for the month',
      function (val: any) {
        if (!val) return true;
        const dateOfEndYear: any = this.resolve(Yup.ref('dateOfEndYear'));
        const dateOfEndMonth: any = this.resolve(Yup.ref('dateOfEndMonth'));
        const dateOfEndDay: any = this.resolve(Yup.ref('dateOfEndDay'));
        const maxDay: number = getMaxDayForYearAndMonth(dateOfEndMonth, dateOfEndYear);

        return dateOfEndDay <= maxDay;
      }
    )
    .test(
      'birth day test',
      'Birth/Formation day cannot be later than death/disbanded day',
      function (val: any) {
        if (!val) return true;
        const dateOfBeginningYear: any = Yup.ref('dateOfBeginningYear');
        const dateOfEndYear: any = Yup.ref('dateOfEndYear');
        const dateOfBeginningMonth: any = Yup.ref('dateOfBeginningMonth');
        const dateOfEndMonth: any = Yup.ref('dateOfEndMonth');
        const dateOfBeginningDay: any = Yup.ref('dateOfBeginningDay');
        if (
          this.resolve(dateOfBeginningYear) === this.resolve(dateOfEndYear) &&
          this.resolve(dateOfEndMonth) === this.resolve(dateOfBeginningMonth) &&
          this.resolve(dateOfBeginningDay)
        ) {
          return val >= this.resolve(dateOfBeginningDay);
        } else return true;
      }
    )
    .test(
      'death date + month + year test',
      'Cannot be later than the current date',
      function (val: any) {
        if (!val) return true;
        const dateOfEndYear: any = Yup.ref('dateOfEndYear');
        const dateOfEndMonth: any = Yup.ref('dateOfEndMonth');
        if (
          this.resolve(dateOfEndYear) === currentYear &&
          this.resolve(dateOfEndMonth) === currentMonth
        ) {
          return val <= currentDate;
        } else return true;
      }
    ),
  names: Yup.array().of(
    Yup.object().shape({
      nameValue: Yup.string()
        .trim()
        .min(1, 'Name must consist of at least one character')
        .test('exactMatch', 'Exact match found', (value, ctx) => {
          return !ctx.parent._hasExactMatch;
        })
        .test('fuzzyMatchesNotIgnored', 'Click \'Ignore Results\' to confirm a duplicate party does not exist', (value, ctx) => {
          return !ctx.parent._isFuzzyMatchesShown;
        })
        .test('multipleNamesInParty', 'Duplicate names are not allowed in the same party. Please remove one of the duplicates or enter a different spelling', (value, ctx)=>{
          return !ctx.path.includes(ctx.parent._isRepeated);
        })
        .required('Name must be included')
      ,
      nameType: Yup.string().test('pkaName', 'Party should have one PKA name' ,(value, ctx: TestContext & {from?: any[]}) => {
        //getting name values from the form with from property: https://github.com/jquense/yup/issues/225#issuecomment-692315453
        const names = ctx?.from?.[1].value?.names as TPartyName[];
        return names.some(item => item.nameType === ENameType.PKA) || names[0].nameValue !== ctx.parent.nameValue;
      }),
      translations: Yup.array(
        Yup.object().shape({
          languageVariantId: Yup.number()
            .test('chineseValidation', ({ value }) => {
              if (value === 31) return 'When entering a translation value for Chinese (Simplified), you must also enter a value for Chinese (Traditional).';
              return 'When entering a translation value for Chinese (Traditional), you must also enter a value for Chinese (Simplified).';
            }, (value, ctx: TestContext & {from?: any[]}) => {
              //change place validation due this task: https://wmggt.atlassian.net/browse/GP-3691
              const simplified = value === 31; //Chinese simplified id option
              const traditional = value === 32; //Chinese traditional id option
              const name = ctx?.from?.[1].value as TPartyName;
              if (simplified) {
                return !!name.translations?.some(item => item.languageVariantId === 32);
              }
              if (traditional) {
                return !!name.translations?.some(item => item.languageVariantId === 31);
              }
              return true;
            }),
          name: Yup.string().trim().test(
            'language validity test',
            'Must include a language and translation',
            function (val: any, context: any) {
              const lang: any = context.parent.languageVariantId;

              return !!((!val && !this.resolve(lang)) ||
                  (val && this.resolve(lang)));

            }
          )
        })
      ),
      _namingStandardsForbidden: Yup.object().shape({
        casingStandard: Yup.boolean().test(
          'casingStandard',
          'A name should be in standard casing. However, should a name require non-standard casing (for example, all upper or all lower case), you must indicate this as an Internal Comment remark below.',
          (val) => !val,
        ),
        parenthesisStandard: Yup.boolean().test(
          'parenthesisStandard',
          'A name for a person should not have parentheses. If the use of parentheses is correct for the name, you must indicate this as an Internal Comment remark below.',
          (val) => !val,
        ),
        wordsStandard: Yup.boolean().test(
          'wordsStandard',
          'This name contains one or more prohibited words. If these words are correct for the name, you must indicate this as an Internal Comment remark below.',
          (val) => !val,
        )
      }),
      competenciesArray: Yup.array()
        .test(
          'pka_performing_artist_required',
          'Competency of Performing Artist is required for the Primarily Known As name',
          (undefinedVal, ctx) => {
            const competencies: PartyCompetency[] = ctx.parent.competencies;
            const originalValues = ctx.parent._originalValues;
            if (!ctx.parent.nameId && ctx.parent.nameValue !== '' && ctx.parent.nameType === ENameType.PKA && Array.isArray(competencies) && originalValues.nameValue !== '') {
              return !competencies.some(c => +c.competencyId === PERFORMING_ARTIST_COMPETENCY_ID);
            }
            return true;
          }
        )
        .test(
          'non_pka_legal_name_competency',
          'If the PKA name to a party is also the Legal Name, you cannot change it to an AKA or FKA name unless you remove the Competency or you deselect it as the Legal Name',
          (undefinedVal, ctx) => {
            const competencies: PartyCompetency[] = ctx.parent.competencies;
            if(ctx.parent.nameType !== ENameType.PKA && Array.isArray(competencies) && ctx.parent.isLegal){
              return competencies.some(c => (c.competencyId === 0 || c.competencyId === undefined) );
            }
            return true;
          }
        )
    }),
  ),
});

const validateFormValues = (schema: any) => async (values: Party) => {
  if (typeof schema === 'function') {
    schema = schema();
  }
  let errors: any = {};
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (err: any) {
    errors = err.inner.reduce((formError: any, innerError: any) => {
      return setIn(formError, innerError.path, innerError.message);
    }, {});
  }

  const pkaNames = values.names
    .map((n, index) => ({ nameType: n.nameType, index: index }))
    .filter(n => n.nameType === ENameType.PKA);

  if (pkaNames.length > 1) {
    pkaNames.forEach(n => {
      set(errors, ['names', n.index, 'nameType'], 'Should have only one PKA');
    });
  }

  return errors;
};

export const validate = validateFormValues(PartySchema);
