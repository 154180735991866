import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Field, useField, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import set from 'lodash/fp/set';
import { Label, Condition, LegatoSwitch } from '@grow-components/Form';
import { TSetState, TState, EOperator, IFormFilter } from './types';
import { validate, isValid, modifyValues } from './helpers';
import styles from './date-filter.module.scss';
import { useSettings } from 'domains/env';
import { LegatoDatePicker } from '@grow-components/Form/DatePicker/DatePicker';
import { FFSelect } from '@grow-components/Form/Select/Select';

interface IProps {
  handleSubmit(): void;
  min: number;
  max: number;
  state: TState;
  setState: TSetState;
}

export const DateFilterForm: FC<IProps> = ({ handleSubmit, min, setState }) => {

  const { t } = useTranslation();

  const { settings } = useSettings();

  const options = useMemo(
    () => [
      { value: EOperator.RANGE, label: t('date_range') },
      { value: EOperator.SINGL, label: t('single_date') },
    ],
    [t],
  );

  const { change, batch, getState } = useForm<IFormFilter>();
  const { values } = getState();
  
  const minSt = useMemo<string>(() => moment.unix(min).toISOString(), [min]);
  const stub = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => { e.stopPropagation(); };

  const updateRange = useCallback(
    (newState: [string, string]) => {
      if (!isValid(validate(min, () => 'err'))(values)) return;
      setState(set('dateTime')(newState));
    },
    [setState, min, values],
  );

  const { input: { value: operator } } = useField('operator');
  useEffect(() => {
    batch(() => {
      change('datecreated', false);
      change('present', false);
    });
  }, [operator]);
  
  const { input: { value: datecreated } } = useField('datecreated');
  useEffect(() => {
    if (datecreated) {
      const field = values.operator === EOperator.RANGE ? 'from' : 'date';
      batch(() => {
        change(field, minSt);
        if (values.operator === EOperator.SINGL) {
          change('present', false);
        }
      });
    }
  }, [datecreated]);
    
  const { input: { value: present } } = useField('present');
  useEffect(() => {
    if (present) {
      const field = values.operator === EOperator.RANGE ? 'to' : 'date';
      batch(() => {
        change(field, moment().toISOString());
        if (values.operator === EOperator.SINGL) {
          change('datecreated', false);
        }
      });
    }
  }, [present]);

  const { input: { value: from }, meta: fromMeta } = useField<string>('from');
  useEffect(() => {
    if (!from || !moment(from).isValid()) return;
    const correctedFrom = modifyValues({ min })([new Date(from), new Date()])[0];
    batch(() => {
      if (!moment(from).isSame(correctedFrom, 'day')) {
        change('from', correctedFrom.toISOString());
      }
      if (!moment(correctedFrom).isSame(minSt, 'day')) {
        change('datecreated', false);
      } else {
        change('datecreated', true);
      }
    });
    if (correctedFrom && values.to) {
      updateRange([correctedFrom.toISOString(), values.to]);
    }
  }, [from]);

  const { input: { value: to }, meta: toMeta } = useField<string>('to');
  useEffect(() => {
    if (!to || !moment(to).isValid()) return;
    const correctedTo = modifyValues({ min })([new Date(), new Date(to)])[1];
    batch(() => {
      if (!moment(to).isSame(correctedTo, 'day')) {
        change('to', correctedTo.toISOString());
      }
      if (!moment(correctedTo).isSame(new Date(), 'day')) {
        change('present', false);
      } else {
        change('present', true);
      }
    });
    if (values.from && correctedTo) {
      updateRange([values.from, correctedTo.toISOString()]);
    }
  }, [to]);

  const { input: { value: date } } = useField('date');
  useEffect(() => {
    if (!date || !moment(date).isValid()) return;
    if (!moment(date).isSame(minSt, 'day')) {
      change('datecreated', false);
    } else {
      change('datecreated', true);
    }
    if (!moment(date).isSame(new Date(), 'day')) {
      change('present', false);
    } else {
      change('present', true);
    }
    setState(set('dateTime')(date));
  }, [date]);



  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit}>
        <Field
          name="operator"
          component={FFSelect}
          options={options}
        />
        <Condition when="operator" is="singl">
          <Label label={t('date')} className={styles.label}>
            <div className={styles.field} onClick={stub}>
              <Field
                name="date"
                component={LegatoDatePicker}
                type="date"
                placeholder={'mm/dd/yy'}
              />
            </div>
          </Label>
        </Condition>
        <Condition when="operator" is="range">
          <Label label={t('from')} className={styles.label} error={fromMeta.error}>
            <div className={styles.field} onClick={stub}>
              <Field
                name="from"
                component={LegatoDatePicker}
                type="date"
                placeholder={settings?.dateFormat || 'mm/dd/yy'}
              />
            </div>
          </Label>
        </Condition>
        <Field
          name="datecreated"
          component={LegatoSwitch}
          size={'sm'}
          label="Date Created"
          className={styles.switch}
        />
        <Condition when="operator" is="range">
          <Label label={t('to')} className={styles.label} error={toMeta.error}>
            <div className={styles.field} onClick={stub}>
              <Field
                name="to"
                component={LegatoDatePicker}
                type="date"
                placeholder={settings?.dateFormat || 'mm/dd/yy'}
              />
            </div>
          </Label>
        </Condition>
        <Field
          name="present"
          component={LegatoSwitch}
          size={'sm'}
          label="Present"
          type="checkbox"
        />
      </form>
    </div>
  );
};
