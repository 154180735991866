import { gql } from '@apollo/client';
import { LOCK_FRAGMENTS } from './fragments';

export const PARTY_UPDATELOCK = gql`
  ${LOCK_FRAGMENTS}
  mutation PartyLockUpdate($data: PartyLockInput!) {
    partyLockUpdate(data: $data) {
      lock {
        ...PartyLockFields
      }
    }
}`;