import { useQuery } from '@apollo/client';
import { GET_PARTY_ASSETS, TGetPartyAssetsResponse, TGetPartyAssetsVars } from 'domains/party/graphql/getPartyAssets';

export const useGetPartyAssets = (uaid: string | undefined) => {
  const {
    loading,
    data
  } = useQuery<TGetPartyAssetsResponse, TGetPartyAssetsVars>(GET_PARTY_ASSETS, {
    skip: !uaid,
    fetchPolicy: 'no-cache',
    variables: {
      uaid: uaid as string
    }
  });

  const response = data?.getGCDMArtistInfo;

  return {
    loading,
    data: response,
  };
};
