import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { BasicSpinner } from '@grow-components/Spinners';
import { ESpinnerSize } from '@grow-components/Spinners/consts';
import { useQuery } from './useQuery';
import styles from './load-more.module.scss';

interface IProps {
  totalCount: number;
  loading: boolean;
}

export const LoadMore: FC<IProps> = ({ totalCount, loading }) => {
  const { href, isShow } = useQuery(totalCount);
  return loading || isShow ? (
    <div className={styles.container}>
      {
        loading ? (
          <BasicSpinner size={ESpinnerSize.SMALL} />
        ) : null
      }
      {
        !loading && isShow ? (
          <Link to={href} className={styles.link}>
            <Trans i18nKey="search:view_more" />
          </Link>
        ) : null
      }
    </div>
  ) : null;
};
