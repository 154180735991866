import { EPosition, ICalculate, IPosition, TEffect } from './types';
import styles from './popover.module.scss';

const SHIFT = 20;

export function prepare(position: IPosition): ICalculate {
  const middleX = (position.width / 2)  - (SHIFT / 2);
  return {
    top: position.y - position.height,
    left: position.x - middleX,
    bottom: position.y + position.height,
    xPosition: position.x + (position.width / 2) + SHIFT,
    yPosition: position.y + position.height + SHIFT,
    middleX,
    middleY: (position.height / 2) - (SHIFT / 2),
    yShift: position.height + (SHIFT / 2),
    ...position,
  };
}

function getPosition(position: EPosition, { middleX, width, middleY, yShift }: ICalculate): TEffect {
  switch (position) {
  case EPosition.top:
    return [position, `left: -${middleX}px; top: -${yShift}px`];
  case EPosition.bottom:
    return [position, `left: -${middleX}px; top: 30px`];
  case EPosition.right:
    return [position, `left: ${SHIFT * 1.5}px; top: -${middleY}px;`];
  case EPosition.rightTop:
    return [position, `left: -${SHIFT}px; top: -${yShift}px`];
  case EPosition.rightBottom:
    return [position, `left: -${SHIFT}px; top: 30px`];
  case EPosition.left:
    return [position, `top: -${middleY}px; left: -${width + SHIFT / 2}px`];
  }
}

export function positionCalculate(data: ICalculate): TEffect  {
  const position = (pos: EPosition) => getPosition(pos, data);
  if (data.xPosition < innerWidth && data.yPosition < innerHeight && data.left > SHIFT * 1.5) {
    if (data.top > SHIFT * 1.5) {
      return position(EPosition.top);
    } else {
      return position(EPosition.bottom);
    }
  }
  if ((data.width + data.x + SHIFT) < innerWidth) {
    if (data.bottom > (innerHeight + SHIFT)) {
      return position(EPosition.rightTop);
    }
    if (data.top < SHIFT * 1.5) {
      return position(EPosition.rightBottom);
    }
    return position(EPosition.right);
  }
  return position(EPosition.left);
}

export function setElementProperty(el: HTMLElement | null, position?: EPosition) {
  if (el !== null) {
    const { x, y, width, height } = el.getBoundingClientRect();
    const { innerWidth, innerHeight } = window;
    const calculatedData = prepare({ x, y, width, height, innerHeight, innerWidth });
    const [className, styleParams] = position ? getPosition(position, calculatedData) : positionCalculate(calculatedData);
    el.classList.add(styles[className]);
    el.setAttribute('style', styleParams);
  }
}
