import tuple from '../helpers/tuple';

export const months = tuple(
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
);

export type Month = typeof months[number];

export const convertDate = (date: string[]) => {
  if (date.length === 2) return `${date[0]}/${date[1]}`;
  if (date.length === 1 && date[0] !== '') return date[0];
  return '-';
};

export const convertDateAllNumbers = (format: string | undefined, year?: number, month?: number, day?: number): string => {
  if (!year) return '';
  if (!month) return year.toString();

  const monthNumber: number = parseInt(month.toString());
  const monthString = months[monthNumber - 1];

  if (!day) return `${monthString}/${year}`;
  
  let dayNum = String(day);
  let monthNum = String(monthNumber);

  if (dayNum.length === 1) dayNum = `0${dayNum}`;
  if (monthNum.length === 1) monthNum = `0${monthNum}`;
  
  if(format === 'mm/dd/yyyy') return `${monthNum}/${dayNum}/${year}`;
  if(format === 'yyyy-mm-dd') return `${year}-${monthNum}-${dayNum}`;

  return `${dayNum}/${monthNum}/${year}`;
};


export const convertDateToNumbers = (date: string, format: string) => {
  if (!date) return {
    year: '',
    month: '',
    day: '',
  };
  if (format === 'yyyy-mm-dd') {
    const [year, month, day] = date.split('-');
    return {
      year: year && Number(year),
      month: month && Number(month),
      day: day && Number(day)
    };
  }
  if (format === 'mm/dd/yyyy') {
    const [month, day, year] = date.split('/');
    return {
      year: year && Number(year),
      month: month && Number(month),
      day: day && Number(day)
    }; 
  }
  const [day, month, year] = date.split('/');
  return {
    year: year && Number(year),
    month: month && Number(month),
    day: day && Number(day)
  };  
};

export const convertDateMonthString = (
  year: string,
  month: number | string,
  day: number | string
) => {
  if (!year) return '';
  if (!month) return year;
  let monthNum = String(month);
  if (Number(monthNum) < 10 && monthNum.length === 1) {
    monthNum = `0${monthNum}`;
  }
  if (!day) return `${monthNum}/${year}`;
  let dayNum = String(day);
  if (dayNum.length === 1) dayNum = `0${dayNum}`;
  return `${dayNum}/${monthNum}/${year}`;
};

/**
 * Accepts a month as an argument and returns an array with all the days in that month.
 * @param month
 */
export const calcDayRange = (month: Month) => {
  let endIdx;
  switch (month) {
  case 'January':
    endIdx = 31;
    break;
  case 'February':
    endIdx = 29;
    break;
  case 'March':
    endIdx = 31;
    break;
  case 'April':
    endIdx = 30;
    break;
  case 'May':
    endIdx = 31;
    break;
  case 'June':
    endIdx = 30;
    break;
  case 'July':
    endIdx = 31;
    break;
  case 'August':
    endIdx = 31;
    break;
  case 'September':
    endIdx = 30;
    break;
  case 'October':
    endIdx = 31;
    break;
  case 'November':
    endIdx = 30;
    break;
  case 'December':
    endIdx = 31;
    break;
  default:
    endIdx = 31;
  }
  const dateRange = [];
  for (let i = 1; i <= endIdx; i++) {
    dateRange.push(i);
  }
  return dateRange;
};
export const calcDayRangeNumbers = (month: string | number) => {
  let endIdx;
  switch (Number(month)) {
  case 1:
    endIdx = 31;
    break;
  case 2:
    endIdx = 29;
    break;
  case 3:
    endIdx = 31;
    break;
  case 4:
    endIdx = 30;
    break;
  case 5:
    endIdx = 31;
    break;
  case 6:
    endIdx = 30;
    break;
  case 7:
    endIdx = 31;
    break;
  case 8:
    endIdx = 31;
    break;
  case 9:
    endIdx = 30;
    break;
  case 10:
    endIdx = 31;
    break;
  case 11:
    endIdx = 30;
    break;
  case 12:
    endIdx = 31;
    break;
  default:
    endIdx = 31;
  }
  const dateRange = [];
  for (let i = 1; i <= endIdx; i++) {
    dateRange.push(i);
  }
  return dateRange;
};
