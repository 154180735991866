import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from 'utils';
import { Tooltip } from '@grow-components/Tooltip/Tooltip';
import styles from './ViewField.module.scss';

type TViewFieldProps = {
  className?: string;
  label: string | JSX.Element;
  value?: string;
  size?: 'lg';
  labelTooltip?: string;
}

export const ViewField: FC<TViewFieldProps> = ({ labelTooltip, label, ...props }) => {
  const { t } = useTranslation();

  const content = useMemo(
    () => labelTooltip ? (<Tooltip text={labelTooltip}>{label}</Tooltip>) : label,
    [label, labelTooltip],
  );

  return (
    <div className={cn(styles.container, {
      [styles.lg]: props.size === 'lg'
    })}>
      <div className={cn(styles.label)}>{content}</div>
      <div className={cn(styles.value)}>
        {props.value ? (
          props.value
        ) : (
          <span className="no-data-available">{t('common:no_data')}</span>
        )}
      </div>
    </div>
  );
};
