import { gql } from '@apollo/client';
import { ENameType } from 'domains/party/types';
import { ENamePrivacyType, EPartyType } from 'modules/common/types/partyTypes';
import { NAMES_SEARCH } from 'domains/search/graphql';

/**
 * @deprecated use domains/search/graphql NAMES_SEARCH
 */
export const SEARCH_NAMES = NAMES_SEARCH;

export const GET_PERMISSIONS = gql`
    query Permissions {
        permissions {
            name
        }
    }
`;

export const GET_COUNTRIES_LANGUAGES_GENRES = gql`
    query getCountriesLanguagesGenres {
        lookupGetCollection {
            groupTypes
            partyTypes
            personTypes
            identifierTypes
            competencies {
                partyType
                competencies {
                    id
                    value
                }
            }
            countries {
                countryId
                name
                isoCode
            }
            languages {
                languageVariantId
                name
                displayName
                ietfCode
            }
            translationLanguages {
                languageVariantId
                name
                displayName
                ietfCode
            }
            remarkTypes
            majorGenres {
                majorGenreId
                name
                minorGenres {
                    name
                    minorGenreId
                }
            }
        }
    }
`;
export const GET_LOOKUP_SEARCH_DETAILS = gql`
    query getCountriesLanguagesGenres {
        lookupGetCollection {
            competencies {
                partyType
                competencies {
                    id
                    value
                }
            }
            majorGenres {
                majorGenreId
                name
            }
        }
    }
`;
export type TElasticName = {
  nameAdditionalIds: string
  nameId: number
  nameInternalComment: string
  nameIsLegal: boolean
  nameIsni: string
  nameType: ENameType
  nameValue: string
  partyCountryOfOriginId: number
  partyCountryOfOriginName: string
  partyId: number
  partyType: EPartyType
  privacyType: ENamePrivacyType
}
export const DUPLICATE_NAME_CHECK_EXACT = gql`
    query duplicateNameCheckExact($data: NameBulkValidateInput!) {
        nameBulkValidate(data: $data) {
            errors {
                type
                property
                message
                index
                name {
                    nameAdditionalIds
                    nameId
                    nameInternalComment
                    nameIsLegal
                    nameIsni
                    nameType
                    nameValue
                    partyCountryOfOriginId
                    partyCountryOfOriginName
                    partyId
                    partyType
                    privacyType
                }
            }
        }
    }
`;

export type TDuplicateNameFuzzyCheckVars = {
  searchTerm: string
}
export type TNameDuplicationResult = {
  nameId: number
  nameValue: string
  partyId: number
  partyType: EPartyType
  partyCountryOfOriginName: string
  nameType: ENameType
  nameIsni: string
}
export type TDuplicateNameFuzzyCheckResponse = {
  searchNames: {
    names: TNameDuplicationResult[]
  }
}
export const DUPLICATE_NAME_FUZZY_CHECK = gql`
    query duplicateNameFuzzyCheck($searchTerm:String!){
        searchNames(data: {searchType: FUZZY_DUPLICATES,searchTerm: $searchTerm, size: 5}){
            names{
                nameId
                nameValue
                partyType
                partyId
                partyCountryOfOriginName
                nameType
                nameIsni
            }
        }
    }
`;

export const SEARCH_ISNI = gql`
    query searchIsni($data: IsniSearchFilter!) {
        searchIsni(data: $data) {
            isni {
                iswcContributions {
                    value
                    title
                    role
                    bowi
                  }
                  isrcContributions {
                    durationMs
                    role
                    subtitle
                    title
                    value
                    year
                  }
                  name
                  nationality
                  countryOfOrigin
                  nameVariants {
                    language
                    fullname
                  }
                  relationships {
                    name
                    relationshipType
                    identifiers {
                      value
                      type
                    }
                  }
                  releaseContributions {
                    title
                    type
                    upc
                    year
                  }
                  type
                  isni
                  identifiers {
                    type
                    value
                  }
                  gender
                  deathDate
                  comment
                  birthDate
                  assignedNames {
                    partyId
                  }
            }
        }
    }
`;

export const SEARCH_HISTORY = gql`
    query getSerchHistory($size: Int) {
        searchHistoryGet(size: $size) {
            items {
                searchTerm,
            }
        }
    }
`;

export const GET_SPOTIFY_TOKEN = gql`
    query GetSpotifyToken {
        getSpotifyToken {
            access_token
        }
    }
`;

export const GET_NAME_BY_ID = gql`
    query GetNameById($data: nameGetByIdInput!) {
        nameGetById(data: $data) {
            name {
                privacyType
                identifiers {
                    id
                    identifierType
                    value
                }
            }
        }
    }`;

export const GET_PARTY_LOG = gql`
    query PartyGetLog($data: PartyGetLogInput!) {
        partyGetLog(data: $data) {
            log {
                action
                changedFrom
                changedTo
                dateTime
                entity
                field
                nameValue
                entityIdentifier
                userName
            }
        }
    }`;

export const GET_PARTY_LOG_FILE = gql`
    query PartyGetLogFile($data: PartyGetLogFileInput!) {
        partyGetLogFile(data: $data) {
            url
            filename
        }
    }`;

export const GET_RECENT_PARTIES = gql`
query PartyManageHistoryGet($size: Int!, $type: PARTY_MANAGE_TYPE!) {
  partyManageHistoryGet(data: { size: $size, type: $type}) {
    items {
      partyId
      managedAt
      pkaName
      spotifyId
    }
  }
}
`;
