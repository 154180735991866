import React, { FC, useMemo, useState } from 'react';
import { copyToClipboard } from 'modules/common/helpers/copyToClipboard';
import { Icon } from '@grow-components/Icon/Icon';

import styles from './identifierDrawer.module.scss';
import { Tooltip } from '@grow-components/Tooltip';
import { useTranslation } from 'react-i18next';
import { ToastService } from 'utils/ToastService';

export type TPartyFieldItem = {
  title: string
  value: JSX.Element | string | undefined
  link?: string
}

export type TIdentifierItem = {
  title: string
  value: string
  link?: string
}

type TPartyFieldsListProps = {
  items: TIdentifierItem[]
  className?: string
}


const TitleField: FC<{ title: string, link?: string }> = ({ link, title }) => ( link ? <a className={styles.idLink} href={link} target='_blank' rel="noopener noreferrer">
  {title}
</a> : <span>{title}</span>);


export const IdentifierDrawer: FC<TPartyFieldsListProps> = (props) => {
  const [show, setShow] = useState(true);
  const { t } = useTranslation();

  const filteredIdentifiers: { rest: TIdentifierItem[], mergedISNIs: TIdentifierItem[] } = useMemo(() => props.items.reduce((acc, next) => {
    if (next.title !== 'Merged ISNI') acc.rest.push(next);
    else acc.mergedISNIs.push(next);
    return acc;
  }, {
    rest: [] as TIdentifierItem[],
    mergedISNIs: [] as TIdentifierItem[]
  }), [props.items]);

  const sortValues = (a: TIdentifierItem, b: TIdentifierItem) => {
    const key: keyof TIdentifierItem = a.title !== b.title ? 'title' : 'value';
    return a[key] > b[key] ? 1 : -1;
  };

  const sortedIdentifiers = useMemo(() => filteredIdentifiers.rest.sort(sortValues), [filteredIdentifiers.rest]);
  const mergedISNIsSorted = useMemo(() => filteredIdentifiers.mergedISNIs.sort(sortValues), [filteredIdentifiers.mergedISNIs]);
  const copyValue = (value: string, label: string) => {
    copyToClipboard(value);
    ToastService.info(t('toasts:is_copied', { label }));
  };



  const additionalIdentifiers = [...sortedIdentifiers, ...mergedISNIsSorted].map( item => {
    const { title, value, link } = item;
    return <div key={`isniIdentifier__${value}`} className={styles.item}>
      {item.title !== 'Merged ISNI' ?
        <div className={styles.label}>
          <TitleField
            title={title}
            link={link}
          />
        </div> :
        <div className={styles.label}>
          <Tooltip text={t('tooltips:merged_isni_description')} place={'top'}>
            <TitleField
              title={title}
              link={link}
            />
          </Tooltip>
        </div>
      }
      <div className={styles.clip}>
        {value}&nbsp;
        <Tooltip text={t('common:click_to_copy')} className={styles.clipIcon}>
          <span onClick={() => copyValue(value, title)} className={styles.clipIcon}><Icon icon='copy' /></span>
        </Tooltip>
      </div>
    </div>;
  });

  return(
    <div className={styles.content}> 
      <div className={styles.hideButton} onClick={()=>setShow(!show)}><span>{show ? 'Hide' : 'Show'} Additional Identifiers  <Icon icon={show ? 'chevron-up' : 'chevron-down'}/></span></div>
      {show && <div className={styles.identifiersDrawer}>
        {additionalIdentifiers}
      </div>}
    </div>
  );
};