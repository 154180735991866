import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EAdvancedSearchOperator, TAdvancedSearchValues } from 'domains/search/types';

const initialState: { queryParams: TAdvancedSearchValues, isFormShown: boolean } = {
  isFormShown: false,
  queryParams: {
    partyType: undefined,
    partySubType: undefined,
    partyCountryOfOriginId: undefined,
    nameOperator: EAdvancedSearchOperator.EQUALS,
    nameValue: '',
    competency: undefined,
    createdBy: undefined,
    modifiedBy: undefined,
    createdOnOperator: undefined,
    createdOnA: undefined,
    createdOnB: undefined,
  }
};

export const advancedSearchSlice = createSlice({
  name: 'advancedSearch',
  initialState,
  reducers: {
    toggleAdvancedSearchForm: (state, action: PayloadAction<boolean>) => {
      state.isFormShown = action.payload;
    },
    updateAdvancedSearchParams: (state, action: PayloadAction<TAdvancedSearchValues>) => {
      state.queryParams = action.payload;
    },
    resetAdvancedSearchParams: (state) => {
      state.queryParams = initialState.queryParams;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  updateAdvancedSearchParams,
  resetAdvancedSearchParams,
  toggleAdvancedSearchForm
} = advancedSearchSlice.actions;

