import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { TAdvancedSearchResponse, TAdvancedSearchVars } from 'domains/search/hooks/types';
import { ADVANCED_NAMES_SEARCH } from 'domains/search/graphql';
import {
  TAdvancedSearchValues
} from 'domains/search/types';
import { useQueryMetaParams } from 'modules/common/hooks/useQueryMetaParams';
import { useAdvancedSearchOperations } from './useAdvancedSearchOperations';

export const useAdvancedNamesSearch = (vars: TAdvancedSearchValues) => {
  const { queryMetaParams } = useQueryMetaParams();
  const operations = useAdvancedSearchOperations(vars);

  const [results, setResults] = useState<TAdvancedSearchResponse['searchNamesAdvanced'] | undefined>();

  const {
    loading,
    error,
  } = useQuery<TAdvancedSearchResponse, TAdvancedSearchVars>(
    ADVANCED_NAMES_SEARCH,
    {
      fetchPolicy: 'no-cache',
      variables: {
        operations,
        ...queryMetaParams
      },
      skip: operations.length === 0,
      onCompleted(d) {
        setResults(d?.searchNamesAdvanced);
      },
    });

  return { loading, data: results, error };
};
