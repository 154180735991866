import { ISettings } from 'domains/env/types';

export enum EPartyHistoryEntity {
  PARTY = 'PARTY',
  NAME = 'NAME',
  IDENTIFIER = 'IDENTIFIER',
  TRANSLATION = 'TRANSLATION',
  REMARK = 'REMARK',
  COMPETENCY = 'COMPETENCY'
}

export enum EPartyHistoryAction {
  ADDED = 'ADDED',
  UPDATED = 'UPDATED',
  REMOVED = 'REMOVED'
}

export type PartyHistoryItem = {
  action: EPartyHistoryAction;
  changedFrom: string | null;
  changedTo: string | null;
  dateTime: number;
  entity: EPartyHistoryEntity;
  field: string;
  nameValue: string;
  entityIdentifier: string | null;
  userName: string;
  settings?: ISettings | undefined;
}
