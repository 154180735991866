import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { LegatoFFSelect } from '@grow-components/Form';
import { Button } from '@wmg-ae/legato';
import { EIsniSearchParam } from 'modules/common/types/isniTypes';
import { SearchField } from './SearchField';
import styles from './form.module.scss';

interface IProps {
  handleSubmit: () => void;
  name?: string;
}

const SERVICES = [
  'AMAZON',
  'APPLE',
  'DEEZER',
  'DISCOGS',
  'IPI',
  'IPN',
  'ISNI',
  'MBID',
  'SPOTIFY',
  'WIKIDATA'
];

export const LookupForm: FC<IProps> = ({ handleSubmit, name }) => {

  const { t } = useTranslation();
  const { submit, change, getFieldState } = useForm();

  const typeField = getFieldState('type');

  useEffect(
    () => {
      change(
        'term',
        typeField?.value === EIsniSearchParam.NAME && name ? name : '',
      );
    },
    [typeField?.value],
  );

  const isDisabled = useMemo(() => false, []);

  const serviceOptions = useCallback(
    e => ({ value: e, label: t(`enums:${e}`) }),
    [t],
  );
  const cleanLocalStorage = useCallback(() => {
    //TODO: please remove this validation for future releases
    if (window.localStorage.getItem('searchUri')) {
      window.localStorage.removeItem('searchUri');
    }
  }, []);

  const options = useMemo(
    () => [{ value: EIsniSearchParam.NAME, label: 'Name' }, ...SERVICES.map(serviceOptions)],
    [serviceOptions],
  );

  const onSearchBtnClick = () => {
    cleanLocalStorage();
    submit();
  };

  return (
    <div onSubmit={() => {
      cleanLocalStorage();
      handleSubmit();
    }} className={styles.container}>
      <Field
        label={t('search_by')}
        name="type"
        options={options}
        className={styles.field}
        component={LegatoFFSelect}
      />
      <div className={styles.searchBlock}>
        <SearchField />
        <Button disabled={isDisabled} onClick={onSearchBtnClick} className={styles.btn}>
          {t('search')}
        </Button>
      </div>
    </div>
  );
};
