import React from 'react';
import { useTranslation } from 'react-i18next';

const APP_VERSION = process.env.REACT_APP_VERSION;
const APP_BUILD_ID = process.env.REACT_APP_BUILD_ID;
const APP_COMMIT_ID = process.env.REACT_APP_COMMIT_ID;
const ENV_NAME = process.env.REACT_APP_ENV_NAME;

const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const envNameVerified = ENV_NAME?.includes('R3') ? `${ENV_NAME.substring(0, ENV_NAME.length - 2)}R5` : ENV_NAME;
  return (
    <div className="footer-wrapper">
      <footer className="navbar navbar-footer navbar-default mt-0">
        <div className="container mx-0">
          <div className="navbar-header logo-margin-left">
            <div className="brand navbar-brand ">{t('liw')}</div>
          </div>
          <div className="navbar-text">
            <p> &#169; Copyright {currentYear} Warner Music Group</p>
            {ENV_NAME !== 'Production' && (
              <p>
                <span>Environment: {envNameVerified || '-'}</span>
                {' | '}
                <span>Version: {APP_VERSION || '-'}</span>
                {' | '}
                <span>Build: {APP_BUILD_ID || '-'}</span>
                {' | '}
                <span>Commit: {APP_COMMIT_ID || '-'}</span>
              </p>
            )}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
