import React, { FC } from 'react';
import { cn } from 'utils';
import styles from '@grow-components/Form/InputsArray/InputsArray.module.scss';

type TSectionHeaderProps = {
  title: string | JSX.Element
  button?: JSX.Element
  className?: string
}

export const SectionHeader: FC<TSectionHeaderProps> = (props) => {
  return (
    <div className={cn(styles.header,'columns-4')}>
      <div className={cn('flex space-between align-center', props.className)}>
        <h4 className={styles.title}>{props.title}</h4>
        {props.button}
      </div>
    </div>
  );
};
