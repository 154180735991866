import React, { FC, useEffect, useReducer, useState } from 'react';
import { cn } from 'utils';
import { FieldRenderProps } from 'react-final-form';
import { TSelectOption } from 'common/types';
import { WarningIcon } from '@grow-components/Icon/IconWithTooltip';
import { ESpinnerSize } from '@grow-components/Spinners/consts';
import { BasicSpinner } from '@grow-components/Spinners/BasicSpinner';
import { Tooltip } from '@grow-components/Tooltip/Tooltip';
import { Icon } from '@grow-components/Icon/Icon';
import { Select as LegatoSelect } from '@wmg-ae/legato';

import styles from './Select.module.scss';
import commonStyles from '../CommonStyles.module.scss';

type TSelectProps = JSX.IntrinsicElements['select'] & {
  label?: string
  labelTooltip?: string
  disabledTooltip?: string
  placeholder?: string
  className?: string
  options?: TSelectOption[]
  error?: string
  name: string
  loading?: boolean
  customIcon?: boolean
  onChange?: (ev: any) => void
  zIndex?: string
  inputClassName?: string
  forceError?: boolean
  isFilterable?: boolean,
  testId?: boolean
}

type TLegatoList = {
  key: string | JSX.Element
  title: string
  value: string | number
  disabled?: boolean
  heading?: boolean
}

export const Select: FC<TSelectProps> = (props) => {
  const {
    label,
    required,
    placeholder,
    options = [],
    className,
    name,
    error,
    loading,
    labelTooltip,
    disabledTooltip,
    customIcon,
    testId,
    ...intrinsicProps
  } = props;

  const renderControl = () => (
    <select
      name={name}
      data-test-id={testId}
      className={cn('form-control', styles.select, {
        [styles.placeholder]: !intrinsicProps.value,
        [styles.chevronHidden]: customIcon
      })}
      {...intrinsicProps}
    >
      {placeholder && <option value="">{placeholder}</option>}
      {options.map((option) =>
        <option key={`selectOption_${option.value}`} value={option.value} disabled={option?.isDisabled}>
          {option.label}
        </option>
      )}
    </select>
  );

  return (
    <div className={cn('form-group', {
      'required': required,
      'has-error': !!error,
      [styles.wrapperIcon]: customIcon
    }, className)}>
      {label && (
        <label className={cn('control-label', commonStyles.inputLabel)}>
          {labelTooltip ? (
            <Tooltip text={labelTooltip}>{label}</Tooltip>
          ) : (
            <span>{label}</span>
          )}
          {required && <Icon icon="asterisk" className={commonStyles.iconInLabel} />}
          {loading && <BasicSpinner size={ESpinnerSize.SMALL} className={commonStyles.labelSpinner} />}
          {!loading && error && (<WarningIcon text={error} className={commonStyles.iconInLabel} />)}
        </label>
      )}
      {intrinsicProps.disabled && disabledTooltip ? (
        <Tooltip text={disabledTooltip}>
          {renderControl()}
        </Tooltip>
      ) : (renderControl())}
      {
        customIcon && <Icon icon='chevron-down' className={styles.customIcon} />
      }
    </div>
  );
};

export const FFSelect: FC<FieldRenderProps<string> & TSelectProps> = (props) => {
  const { input, meta, forceError, loading, ...rest } = props;
  return (
    <Select
      {...input}
      loading={meta.validating || loading}
      error={forceError
        ? meta.error
        : (meta.touched && meta.error)
      }
      {...rest}
    />
  );
};

export const LegatoFFSelect: FC<FieldRenderProps<string> & TSelectProps> = (props) => {
  const { input: { value, onChange }, options, placeholder, label, meta: { touched, error }, required, disabled, className, isFilterable, forceError, zIndex, name, disabledTooltip, loading, labelTooltip, onChange: customChange, inputClassName, testId, ...intrinsicProps } = props;
  const changeEventHandler = customChange || onChange;
  // Code below is temporary solution until we have other solution from Legato team
  // more details on this link https://wmggt.atlassian.net/browse/GP-3842
  const forceUpdate = useReducer(x => x + 1, 0)[1];
  const [timeoutId, setTimeoutId] = useState<number | null>(null);

  const setZIndexToElement = () => {
    const collection: HTMLCollection = document.getElementsByClassName('lgt-floaty');
    if (collection.length) {
      const element = collection[0] as HTMLElement;
      element.style.zIndex = zIndex ?  zIndex : '1';
    }
  };
  const onClick = () => {
    forceUpdate();
    const timeId: number = window.setTimeout(setZIndexToElement, 200);
    setTimeoutId(timeId);
  };

  useEffect(() => {
    () => {
      if (typeof timeoutId === 'number') {
        clearTimeout(timeoutId);
      }
    };
  }, []);
  // until this line is temporary solution
  ///////////////////////////////////////////////////////////////////////////////


  const list: TLegatoList[] = options?.map((option: TSelectOption) => ({ key: option.key || `selectOption_${option.value}`, title: option.label as string, value: option.value, disabled: option.isDisabled, heading: option.heading })) || [];

  if (placeholder) {
    list.unshift({ value: '', title: placeholder, key: 'placeHolder' });
  }

  const getInitialValue = () => {
    return value ? list.find(el => el.value === value) : undefined;
  };
  const renderControl = () => (
    <LegatoSelect
      list={list}
      name={name}
      disabled={disabled}
      onFilter={(e: TLegatoList[], term: string) => {
        if (term !== '') {
          return e.filter(item => Boolean(item.value) && !item.key.toString().includes('lastUsed') && item.title.toLowerCase().includes(term.toLowerCase()));
        }
        return e;
      }}
      isFilterable={isFilterable}
      validation={{
        message: (forceError || touched) && error,
        type: (forceError || touched) && error && 'error',
        required: required,
        pending: loading
      }}
      value={getInitialValue()}
      getElement={(el: TLegatoList) => {
        return !el.heading ? <span className='py-10'>
          {el.title}
        </span> : <span className='py-10' style={{ fontWeight: 'bold' }}>{el.title}</span>;
      }}
      onSelect={(el: TLegatoList) => {
        if(!el.disabled) {
          changeEventHandler(el.value as string);
        }
      }}
      className={cn(styles.overrideStyles, inputClassName)}
      placeholder={placeholder || ''}
      data-test-id={testId}
    />
  );



  return (
    <div onClick={onClick} className={cn('mb-10', className)}>
      {label && (
        <label className={cn('control-label', commonStyles.inputLabel)}>
          {labelTooltip ? (
            <Tooltip text={labelTooltip}>{label}</Tooltip>
          ) : (
            <span>{label}</span>
          )}
        </label>
      )}
      {intrinsicProps.disabled && disabledTooltip ? (
        <Tooltip text={disabledTooltip}>
          {renderControl()}
        </Tooltip>
      ) : (renderControl())}
    </div>
  );
};
