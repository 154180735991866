import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import compose from 'lodash/fp/compose';

/**
 * A custom hook that builds on React Router's `useLocation` to parse a query string.
 */

type TInAdapter<I, O> = (d: O) => I;
type TOutAdapter<I, O> = (d: I) => O;

/**
 * @deprecated temporary deprecated
 * @param inAdapter
 * @param outAdapter
 * @param prefix
 */
export function useQuery<I, O> (inAdapter: TInAdapter<I, O>, outAdapter: TOutAdapter<I, O>, prefix = '') {

  const { push } = useHistory();

  const location = useLocation();

  const getSearchParams = () => new URLSearchParams(window.location.search);

  const getValues = useCallback<(searchParams: URLSearchParams) => O>((searchParams) => {
    let record = {} as I;
    for (const [key, value] of searchParams.entries()) {
      record = { ...record, [key]: value };
    }
    return outAdapter(record);
  }, []);

  const values = useMemo(
    () => getValues(new URLSearchParams(location.search)),
    [location],
  );

  const getURL = useCallback<(data: Partial<O>) => string>(
    (data) => {
      const searchParams = getSearchParams();
      const record = inAdapter({ ...getValues(searchParams), ...data }) as { [s: string]: unknown; } | ArrayLike<unknown>;
      for (const [key, value] of Object.entries(record)) {
        if (typeof value !== 'undefined') {
          if (typeof value === 'string') {
            searchParams.set(key, value);
          }
        } else {
          searchParams.delete(key);
        }
      }
      return prefix + searchParams.toString();
    },
  [getValues, values],
  );

  const setURL = useCallback<(data: Partial<O>) => void>(
    (d) => {
      push(getURL(d));
    },
  [getURL, push],
  );

  return {
    getValues: compose(getValues, getSearchParams),
    values,
    getURL,
    setURL,
  };

}
