import { useCallback, useEffect, useState } from 'react';
import set from 'lodash/fp/set';
import { TNameDuplicationResult } from 'graphql/queries';
import { TPartyName } from 'domains/party/types';
import { EPartyType } from 'modules/common/types/partyTypes';
import { omitByIndex, prepareQuery } from './prepare';
import { parseResult, useCustomQuery } from './queryBuilder';
import { EQueryType } from './types';


type additionalValidateFields = {
  names: TPartyName[],
  partyType: EPartyType,
  countryOfOriginId?: number,
  id?: number,
  excludeFuzzyValidation?: boolean
}


export const useCheckNames = () => {
  // TODO: useReduce instead of useState
  const [fuzzyResults, setFuzzyResults] = useState<TNameDuplicationResult[][]>([]);
  const [exactResults, setExactResults] = useState<TNameDuplicationResult[][]>([]);

  const { validate: makeQuery, result: checkResult, loading: checkLoading } = useCustomQuery();

  const validateNames = useCallback(
    (nameIndex: number | undefined, { names, partyType, countryOfOriginId, id, excludeFuzzyValidation }: additionalValidateFields) => {
      const query = prepareQuery(names, nameIndex, id, excludeFuzzyValidation);
      if (query.length) {
        makeQuery(query, { partyType, countryOfOriginId });
      }
    },
    [],
  );

  const handleDelete = useCallback(
    (index: number, type?: EQueryType) => {
      const omit = omitByIndex(index);
      type !== EQueryType.EXACT && setFuzzyResults(omit);
      type !== EQueryType.FUZZY && setExactResults(omit);
    },
    [setFuzzyResults, setExactResults],
  );

  const handleClean = useCallback(
    (index: number, type?: EQueryType) => {
      const cleaner = set(index)(undefined);
      type !== EQueryType.EXACT && setFuzzyResults(cleaner);
      type !== EQueryType.FUZZY && setExactResults(cleaner);
    },
    [],
  );
  useEffect(
    () => {
      if (!checkLoading) {
        const { FUZZY, EXACT } = parseResult(checkResult);
        setExactResults(EXACT || []);
        setFuzzyResults(FUZZY || []);
      }
    },
    [checkResult, checkLoading],
  );

  return {
    handleClean,
    validateNames,
    fuzzyResults,
    exactResults,
    isValidating: checkLoading,
    handleDelete,
  };

};
