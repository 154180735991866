import moment from 'moment';
import { EDateTimeFormat } from 'common/consts';

export const dateShowFormat = (date: string | Date | number | undefined): string => moment(date).format(EDateTimeFormat.MM_DD_YYYY_Slash);

export const dateChangeFormat = (date: string | Date | number | undefined, format: string | undefined): string => moment(date).format(format);

export const ddMMToMMddFormat = (date: string | Date | number, format: string | undefined): string => moment(date, 'DD/MM/YYYY').format(format?.toUpperCase());

export const changeDateDashToSlash = (date: string | undefined, format: string | undefined) => {
  if(!date) return undefined;
  const spliceDate = date.split('-');
  if(spliceDate.length === 1) return date;
  if(spliceDate.length === 2) return `${spliceDate[1]}/${spliceDate[0]}`;
  if(format === 'dd/mm/yyyy') return `${spliceDate[2]}/${spliceDate[1]}/${spliceDate[0]}`;
  return `${spliceDate[1]}/${spliceDate[2]}/${spliceDate[0]}`;
};