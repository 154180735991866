import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@grow-components/Tooltip';
import { Icon } from '@grow-components/Icon/Icon';
import { copyToClipboard } from 'modules/common/helpers/copyToClipboard';
import { ToastService } from 'utils/ToastService';
import styles from './IdentifiersInfo.module.scss';

interface Props {
  link?: string | null;
  label: string | JSX.Element;
  value: string;
  labelTooltip?: string;
  extraLabel?: JSX.Element;
  extraInfo?: JSX.Element;
  clipboardMark: string;
}

export const IdentifierItem: React.FC<Props> = ({
  extraLabel,
  labelTooltip,
  link,
  label,
  value,
  extraInfo,
  clipboardMark,
}) => {
  const { t } = useTranslation();
  const handleCopy = useCallback(
    () => {
      copyToClipboard(value);
      ToastService.info(clipboardMark);
    },
    [value, clipboardMark],
  );

  const labelElement = useMemo(
    () => link ? (<a href={link} target="_blank" rel="noopener noreferrer">{label}</a>) : label,
    [link, label]
  );

  return (
    <div className={styles.identifier}>
      <label className={styles.label}>
        {
          typeof labelTooltip === 'string' ? (
            <Tooltip text={labelTooltip}>{labelElement}</Tooltip>
          ) : labelElement
        }
        {
          extraLabel
        }
      </label>
      <div className={styles.identifier__extraInfo}>{extraInfo}</div>
      {value ? (
        <div className={styles.identifier__field}>
          <div title={value} className={styles.identifier__value}>
            {value}
          </div>
          <Tooltip text={t('common:click_to_copy')} className={styles.identifier__buttonWrapper}>
            <button
              className={styles.identifier__button}
              onClick={handleCopy}
              type="button"
            >
              <Icon icon="copy" />
            </button>
          </Tooltip>
        </div>
      ) : (
        <div className="no-data-available">{t('common:no_data')}</div>
      )}
    </div>
  );
};
