import React, { useCallback, useState, useRef } from 'react';
import { NavigationLink } from 'modules/common/types/routeTypes';
import SearchBox from './SearchBox';
import { CreateBtn, usePolicy } from './CreateBtn';
import { Logo } from './Logo/Logo';
import { UserMenu } from './UserMenu';
import { UserModal } from './UserMenu/UserModal/';
import { useUser } from 'domains/env';
import { useOktaAuth } from '@okta/okta-react';
import { WorkspaceSelect } from './WorkspaceSelect/WorkspaceSelect';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { AdvancedSearch } from '@grow-components/AdvancedSearch/AdvancedSearch';
import {
  toggleAdvancedSearchForm,
} from 'redux/reducers/advancedSearchReducer';
import { EAdvancedSearchOperator, TAdvancedSearchValues } from 'domains/search/types';
import { useQueryParams } from 'modules/common/hooks/useQueryParams';

interface Props {
  current?: string;
  navigationLinks?: NavigationLink[];
  loggedIn?: boolean;
  userName?: string;
}

export type THandleAdvancedSearch = {
  handleAdvancedSearch: (values: TAdvancedSearchValues) => void
}

const Header: React.FC<Props> = ({ loggedIn }) => {
  const user = useUser();
  const searchRef = useRef<THandleAdvancedSearch>(null);
  const { oktaAuth, authState } = useOktaAuth();
  const dispatch = useDispatch();
  const { params } = useQueryParams<{ term: string }>();
  const {
    queryParams: advancedSearchQueryParams,
    isFormShown: isAdvancedSearchFormShown
  } = useSelector((state: RootState) => state.advancedSearch);

  const [searchTerm, setSearchTerm] = useState(params.term ?? '');

  const handleAdvancedSearch = (values: TAdvancedSearchValues) => {
    searchRef.current?.handleAdvancedSearch(values);
  };

  const initialAdvancedSearchParams: TAdvancedSearchValues = {
    ...advancedSearchQueryParams,
    nameOperator: advancedSearchQueryParams.nameOperator || EAdvancedSearchOperator.EQUALS,
    nameValue: advancedSearchQueryParams.nameValue || searchTerm
  };
  const handleLogout = useCallback(async () => {
    try {
      localStorage.removeItem('okta-cache-storage');
      localStorage.removeItem('okta-token-storage');
      await oktaAuth.signOut({
        postLogoutRedirectUri: window.location.origin + '/',
      });
    } catch (err) {
      console.warn(err);
    }
  }, [authState, oktaAuth]);

  const createButton = usePolicy(() => <CreateBtn />);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <nav
      className="header navbar header-nav"
      role="navigation"
    >
      <div className="nav__contents-wrapper m-0">
        <div className="navbar__left mr-50">
          <Logo />
        </div>
        {loggedIn && <SearchBox term={searchTerm} setTerm={setSearchTerm} ref={searchRef} />}
        {loggedIn && (
          <div className="navbar__right">
            <WorkspaceSelect />
            {
              createButton
            }
            <UserMenu
              onOpen={() => setIsOpen(true)} 
              onLogout={handleLogout}
              userData={user.getItem}
            />
            {isOpen && 
              <UserModal 
                onClose={() => setIsOpen(false)} 
                userData={user.getItem}
              />
            }
          </div>
        )}
      </div>
      {loggedIn && isAdvancedSearchFormShown && 
        <AdvancedSearch
          initialValues={initialAdvancedSearchParams}
          onClose={() => dispatch(toggleAdvancedSearchForm(false))}
          onSearch={handleAdvancedSearch}
        />
      }
    </nav>
  );
};

export default Header;
