import React, { FC, useMemo, useCallback } from 'react';
import { Trans } from 'react-i18next';
import get from 'lodash/get';
import compose from 'lodash/fp/compose';
import { Popover } from '@grow-components/Popover';
import { EPartyHistoryEntity, PartyHistoryItem } from 'domains/history';
import { generalAdapter, partyAdapter, generalFilter, partyFilter, competenciesAdapter, sorter } from './adapters';
import { IProps } from './types';
import styles from './history-tooltip.module.scss';

const COUNT_OF_ENTRY = 10;

export const HistoryTooltip: FC<IProps> = ({ field, gkey, data, values, settings }) => {
  
  const getSettings = (partyInfo: PartyHistoryItem): PartyHistoryItem =>{
    return { ...partyInfo, settings };
  };

  const list = useMemo<ReadonlyArray<PartyHistoryItem>>(
    () => compose(
      sorter,
      generalFilter(EPartyHistoryEntity.NAME, f => f.entityIdentifier === values?.nameType && f.field === field),
      generalFilter(EPartyHistoryEntity.IDENTIFIER, f => f.entityIdentifier === values?.identifier && f.nameValue === values?.nameValue),
      generalFilter(EPartyHistoryEntity.TRANSLATION, f => f.nameValue === values?.nameValue),
      generalFilter(EPartyHistoryEntity.COMPETENCY, f => f.nameValue === values?.nameValue),
      partyFilter(field),
    )(get(data, gkey, [])),
    [data, gkey, values, field, settings],
  );

  const prepareData = useCallback(
    (d) => compose(
      generalAdapter,
      getSettings,
      partyAdapter,
      competenciesAdapter,
    )(d),
    [data, settings],
  );

  const text = useMemo<JSX.Element>(
    () => (
      <>
        { // TODO: Should use key as id for item, ask BE about it
          list.slice(0, COUNT_OF_ENTRY).map((e, i) => {
            return(
              <div key={e.dateTime + i}>
                <Trans
                  i18nKey={`history:log_${e.action}`}
                  values={prepareData(e)}
                  components={{ bold: <b /> }}
                  tOptions={{ interpolation: { escapeValue: false } }}
                />
              </div>
            );})
        }
      </>
    ),
    [list],
  );

  if (list.length === 0) return null;
  return (
    <div className={styles.container}>
      <Popover>
        {text}
        {
          list.length > COUNT_OF_ENTRY ? (
            <p><Trans i18nKey={'history:additional_entries'} /></p>
          ) : null
        }
      </Popover>
    </div>
  );
};
