import { useCallback, useEffect, useMemo, useState } from 'react';
import { Decorator, FormApi, FormState } from 'final-form';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import find from 'lodash/find';
import { Party } from 'modules/common/types/partyTypes';
import { TPartyName } from 'domains/party/types';
import { ToastService } from 'utils/ToastService';
import { isPerformingArtist } from '../helpers';
import createDecorator from '../../utils/submit-listener';
//import { checkIsModified } from '../../utils/formValues';

interface ICondition<T extends TPartyName = TPartyName, P extends Party = Party> {
  message: string;
  condition: (prev: T, next: T, state: FormState<P>) => boolean;
  getValues: (prev: T, next: T, state: FormState<P>) => Record<string, any>;
}

type TToastGenerator<T extends TPartyName = TPartyName, P extends Party = Party> = (p: T, n: T, i: ICondition<T, P>[], state: FormState<P>) => ReadonlyArray<string>;

export function useSubmitSuccessToast<T extends Party = Party>() {
  const [timeoutId, setTimeoutId] = useState<number | null>(null);

  const { t } = useTranslation();

  const nameCondition = useMemo<ICondition<TPartyName, T>[]>(
    () => [
      {
        message: 'toasts:legal_name_has_changed',
        condition(prev, next, { initialValues }) {
          const prevIndex = get(initialValues, 'names', []).findIndex(n => n.isLegal);
          if (!prev?.isLegal === true && next?.isLegal === true && prevIndex > -1) return true;
          return false;
        },
        getValues: (_, next, { initialValues }) => ({
          nextName: next.nameValue,
          prevName: get(find(initialValues.names, 'isLegal'), 'nameValue'),
        }),
      },
      {
        message: 'toasts:privacy_indicator_has_been_changed',
        condition: (prev, next) => !!prev?.privacyType && prev.privacyType !== next?.privacyType,
        getValues: (_, next) => ({ name: next.nameValue, value: t(`enums:${next.privacyType}`) }),
      },
      {
        message: 'toasts:competency_has_been_removed',
        condition: (prev, next) => (isPerformingArtist(prev) && !isPerformingArtist(next)),
        getValues: (_, next) => ({ name: next.nameValue }),
      },
    ],
    [t],
  );

  const toastMessageGenerator = useCallback<TToastGenerator<TPartyName, T>>(
    (prev, next, items, state) => {
      return items.reduce((a, item) => {
        if (item.condition(prev, next, state)) {
          return [...a, t(item.message, item.getValues(prev, next, state))];
        }
        return a;
      }, [] as ReadonlyArray<string>);
    },
    [t],
  );

  const createToasts = useCallback<(list: ReadonlyArray<string>) => void>(
    (list) => {
      list.forEach((message) => { ToastService.success(message); });
    },
  [],
  );

  const setUpdatedNamesToForm = (initialValues: Partial<T>, newNames: TPartyName[], reset: (initialValues?: Partial<T>) => void) => {
    const timeId: number = window.setTimeout(() => {
      reset({ ...initialValues, names: newNames });
    }, 10);

    setTimeoutId(timeId);
  };

  const afterSubmitSucceeded = useCallback<(api: FormApi<T>) => void>(
    ({ getState, reset }) => {
      const state = getState();
      // TODO: Check a refactor of toast notifications for save and save + view option
      
      // const modified: boolean | undefined = checkIsModified(state);
      // // if (state.initialValues.id && modified && !state.validating && !state.invalid) {
      // //   createToasts([t(t('toasts:party_has_updated'))]);
      // // }

      if (!state.validating && !state.invalid) {
        const nameToasts = (prev: TPartyName, next: TPartyName) => toastMessageGenerator(prev, next, nameCondition, state);
        const toasts = state.values.names.reduce((a, v, i) => {
          const prev: TPartyName = get(state.initialValues, ['names', i], {});
          return [...a, ...nameToasts(prev, v)];
        }, [] as ReadonlyArray<string>);
        createToasts(toasts);
        setUpdatedNamesToForm(state.initialValues, state.values.names, reset);
      }
    },
  [],
  );

  useEffect(() => {
    () => {
      if (typeof timeoutId === 'number') {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  return useMemo<Decorator<T>>(
    () => createDecorator({ afterSubmitSucceeded }),
    [afterSubmitSucceeded],
  );
}
