import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from 'modules/common/components/Layout';
import { Subheader } from '@grow-components/Subheader/Subheader';
import { Creating } from './Creating';
import styles from './landing-page.module.scss';
import { QuickSearch } from 'modules/pages/LandingPage/QuickSearch/QuickSearch';
import { Widget } from 'modules/pages/LandingPage/Widget/Widget';
import { BuzzMill } from 'modules/pages/LandingPage/BuzzMill/BuzzMill';
import { UpcomingWorkspaces } from 'modules/pages/LandingPage/UpcomingWorkspaces/UpcomingWorkspaces';
import { RecentParties } from './RecentParties/RecentParties';
import { MdhqLinks } from './MdhqLinks/MdhqLinks';
import { ERecentPartiesAction } from './consts';
import { useSettings } from 'domains/env';

const LandingPage = () => {
  const { t } = useTranslation();
  const { settings } = useSettings();
  return (
    <Layout>
      <div className={styles.wrapper}>
        <Subheader
          location={t('liw')}
          mainInfo={t('party_workspace')}
          sticky
        />
        <div className={styles.container}>
          <Widget className={styles.itemA} title={t('quick_search')}><QuickSearch /></Widget>
          <Widget className={styles.itemB} title={t('start_creating')}><Creating /></Widget>
          <Widget className={styles.itemC} title={'Buzz Mill'}><BuzzMill dateFormat={settings?.dateFormat.toUpperCase()}/></Widget>
          <Widget className={styles.itemD} title={'Recently Modified Parties'}>
            <RecentParties userAction={ERecentPartiesAction.UPDATED} settings={settings}/>
          </Widget>
          <Widget className={styles.itemE} title={'Recently Created Parties'}>
            <RecentParties userAction={ERecentPartiesAction.CREATED} settings={settings}/>
          </Widget>
          <Widget className={styles.itemF} title={'Upcoming Workspaces'}><UpcomingWorkspaces /></Widget>
          <Widget className={styles.itemG}><MdhqLinks /></Widget>
        </div>
      </div>
    </Layout>
  );
};

export default LandingPage;
