import React, { FC, useCallback, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { SecureRoute, Security } from '@okta/okta-react';
import { ThemeProvider } from 'styled-components/macro';

import { initI18n } from 'utils/i18n';

// Polyfill to add compatibility w/ IE 11 & Edge
// import '@okta/okta-auth-js/polyfill';
import 'modules/common/styles/main.scss';
import theme from 'modules/common/components/styled/theme/Theme';

// Add focus outlines when users tab
// import { handleFirstTab } from 'modules/common/helpers/handleFirstTab';
import { AuthenticateUser } from './AuthenticateUser';
import NewRelicIntegration from 'modules/common/components/NewRelicIntegration';
import { Route, Switch, useHistory } from 'react-router-dom';
import NotFound from 'modules/common/components/errors/pages/NotFound';
import { GROWLoginCallback } from 'modules/common/components/errors/pages/LoginCallback';
import { oktaAuth } from 'config/oktaConfig';
import { Helmet } from 'react-helmet';
import { getAppTitlePrefix } from 'utils';
import { ToastContainer } from 'react-toastify';
import { ToastService } from 'utils/ToastService';

/** Quick wrapper that increases compatibility with IE11/10 (as a treat). Fixes min-height bug w/ flexbox.
 * @courtesy https://codepen.io/chriswrightdesign/pen/emQNGZ/
 */

const CALLBACK_PATH = process.env.REACT_APP_OKTA_CALLBACK_PATH;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.QA = {
  toast: ToastService
};
const App: FC = () => {
  const history = useHistory();
  const restoreOriginalUri = useCallback(
    async (_, originalUri: string) => {
      history.replace(toRelativeUrl(originalUri, window.location.origin));
    },
    [history],
  );

  useEffect(() => { initI18n(); }, []);

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>{getAppTitlePrefix()} LIW / Party Workspace</title>
      </Helmet>
      <NewRelicIntegration />
      <ErrorBoundary FallbackComponent={NotFound}>
        <div className="app-wrapper">
          <Security restoreOriginalUri={restoreOriginalUri} oktaAuth={oktaAuth}>
            <Switch>
              <Route path={CALLBACK_PATH} component={GROWLoginCallback} />
              <SecureRoute path={'*'}>
                <AuthenticateUser />
              </SecureRoute>
            </Switch>
          </Security>
        </div>
      </ErrorBoundary>
      <ToastContainer newestOnTop={true} />
    </ThemeProvider>
  );
};
export default App;
