import React, { FC, useCallback } from 'react';
import { TSelectOption } from 'common/types';
import { cn } from 'utils';
import styles from './list.module.scss';

interface IProps {
  options: ReadonlyArray<TSelectOption>;

  renderItem (data: TSelectOption): JSX.Element | string;

  className?: string;
  handleKey?: (e: React.KeyboardEvent<HTMLUListElement>) => void;
}

export const List: FC<IProps> = ({
  options,
  renderItem = (o) => o.value,
  className,
  handleKey,
}) => {

  const item = useCallback<(data: TSelectOption) => JSX.Element | string | number>(renderItem, []);

  const refHandler = useCallback<(el: HTMLUListElement) => void>(
    (element) => {
      if (element) element.focus();
    }, [],
  );

  return (
    <ul tabIndex={0} ref={refHandler} className={cn(styles.list, className)} onKeyDown={handleKey}>
      {
        options.map(o => (
          <li key={o.value} className={styles.item}>
            {item(o)}
          </li>
        ))
      }
    </ul>
  );
};
