import React, { FC } from 'react';
import styles from './PartyFieldsList.module.scss';
import { useTranslation } from 'react-i18next';


export type TPartyFieldItem = {
  title: string
  value: JSX.Element | string | undefined
  link?: string
}
type TPartyFieldsListProps = {
  items: TPartyFieldItem[]
  className?: string
}

export const PartyFieldsList: FC<TPartyFieldsListProps> = (props) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      {props.items.map(item => (  <div key={item.title} className={styles.item}>
        <div className={styles.title}>
          {item.title}
        </div>
        <div className={styles.value}>
          {item.value || t('common:no_data')}
        </div>
      </div>
      ))}
    </div>
  );
};
