import React, { FC, memo, useMemo } from 'react';
import { Field } from 'react-final-form';
import { LegatoFFSelect } from '@grow-components/Form';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import { MajorMinorGenre, MinorGenre } from 'modules/common/types/partyTypes';
import { indexBy } from 'utils';
import { useCurrentName } from '../useCurrentName';
import { TPartyName, ENameType } from 'domains/party/types';

interface IProps {
  namePath: string;
  dictionaries?: MajorMinorGenre[];
  disabled?: boolean;
}

const Minor: FC<IProps> = ({ namePath, dictionaries, disabled }) => {
  const { t } = useTranslation();
  const { values } = useCurrentName<TPartyName>(namePath);

  const majore = useMemo<Record<string, MajorMinorGenre>>(
    () => indexBy((dictionaries || []), 'majorGenreId'),
    [dictionaries],
  );

  const minor = useMemo<MinorGenre[]>(
    () => {
      if (!values.majorGenreId) return [];
      return get(majore, [values.majorGenreId, 'minorGenres'], []);
    },
    [majore, values.majorGenreId],
  );

  const options = useMemo(
    () => minor.map(
      ({ name, minorGenreId }) => ({ label: name, value: minorGenreId })
    ),
    [minor],
  );

  const isDisable = useMemo<boolean | undefined>(
    () => {
      if (disabled || minor.length === 0) return true;
      if (values.nameType !== ENameType.PKA) {
        return values.isLegal;
      }
    },
    [disabled, minor],
  );

  const placeholder = useMemo<string>(
    () => {
      if (!values.majorGenreId) return t('party_placeholders:select_major_genre_first');
      if (minor.length) return t('party_placeholders:select_minor_genre');
      return t('common:no_data');
    },
    [minor],
  );

  return (
    <Field
      name={`${namePath}.minorGenreId`}
      component={LegatoFFSelect}
      isFilterable={true}
      label={t('party_labels:minor_genre')}
      labelTooltip={t('tooltips:minor_genre')}
      placeholder={placeholder}
      disabled={isDisable}
      options={options}
    />
  );
};

export default memo(Minor);
