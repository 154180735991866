import React  from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Layout from 'modules/common/components/Layout';
import { FullScreenMessage } from '@grow-components/FullScreenMessage/FullScreenMessage';
import { Icon } from '@grow-components/Icon/Icon';

import styles from './error-pages.module.scss';

const NotFound = () => {
  const { t } = useTranslation();
  const thumbnail = (
    <div className={styles.thumbWrapper}>
      <Icon icon='exclamation' className={styles.exclamation} />
      <Icon icon='tree' className={styles.tree} />
    </div>
  );

  return (
    <>
      <Helmet>
        <title>GROW / Party Workspace | Page Not Found</title>
      </Helmet>
      <Layout contentClassName={styles.content}>
        <FullScreenMessage
          thumbnail={thumbnail}
          message={t('errors:oops')}
          details={t('errors:please_try_again')} />
      </Layout>
    </>
  );
};

export default NotFound;
