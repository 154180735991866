export enum EPartyHistoryAction {
  ADDED = 'ADDED',
  UPDATED = 'UPDATED',
  REMOVED = 'REMOVED'
}

export const PartyHistoryActions: Record<EPartyHistoryAction, string> = {
  [EPartyHistoryAction.ADDED]: 'Added',
  [EPartyHistoryAction.UPDATED]: 'Edited',
  [EPartyHistoryAction.REMOVED]: 'Removed'
};

export enum EPartyHistoryEntity {
  PARTY = 'PARTY',
  NAME = 'NAME',
  IDENTIFIER = 'IDENTIFIER',
  TRANSLATION = 'TRANSLATION',
  REMARK = 'REMARK',
  COMPETENCY = 'COMPETENCY'
}

export const PartyHistoryEntities: Record<EPartyHistoryEntity, string> = {
  [EPartyHistoryEntity.PARTY]: 'Party',
  [EPartyHistoryEntity.NAME]: 'Name',
  [EPartyHistoryEntity.IDENTIFIER]: 'Identifier',
  [EPartyHistoryEntity.TRANSLATION]: 'Translation',
  [EPartyHistoryEntity.REMARK]: 'Remark',
  [EPartyHistoryEntity.COMPETENCY]: 'Competency'
};

export type PartyHistoryItem = {
  action: EPartyHistoryAction;
  changedFrom: string | null;
  changedTo: string | null;
  dateTime: number;
  entity: EPartyHistoryEntity;
  field: string;
  nameValue: string;
  entityIdentifier: string | null;
  userName: string;
}

export const NestedEntityFields: Record<string, string> = {
  nameValue: 'Name Value',
  nameType: 'Name Type',
  privacyType: 'Privacy Type',
  isLegal: 'Legal Name',
  name: 'Name',
  languageVariant: 'Language',
  languageVariantId: 'Language',
  text: 'Comment',
  remarkType: 'Remark Type',
  minorGenreId: 'Minor Genre',
  majorGenreId: 'Major Genre',
  minorGenre: 'Minor Genre',
  majorGenre: 'Major Genre'
};

export enum EFilterFields {
  USER_NAME = 'userName',
  NAME_VALUE = 'nameValue',
  DATE = 'date',
}

export type TFilters = Record<string, string> & { dateTime?: [string, string] | string };