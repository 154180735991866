import React, { useCallback, useEffect, useImperativeHandle, forwardRef } from 'react';
import { ChangeEvent } from 'react-autosuggest';
import { SearchBar } from '../SearchBar';
import { Button, EButtonVariants } from '@grow-components/Button';
import styles from './Searchbox.module.scss';
import { Icon } from '@grow-components/Icon/Icon';
import { cn } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateAdvancedSearchParams,
  toggleAdvancedSearchForm,
  resetAdvancedSearchParams
} from 'redux/reducers/advancedSearchReducer';
import { useHistory } from 'react-router-dom';
import { TAdvancedSearchValues } from 'domains/search/types';
import { RootState } from 'redux/store';
import { useQueryParams } from 'modules/common/hooks/useQueryParams';
import { Tooltip } from '@grow-components/Tooltip';
import { useTranslation } from 'react-i18next';
import { THandleAdvancedSearch } from '../Header';

interface ISearchBoxProps {
  term: string,
  setTerm: (term: string) => void
}

const SearchBox = forwardRef<THandleAdvancedSearch, ISearchBoxProps>(({ term, setTerm }, ref) => {
  const { createURL } = useQueryParams<{ term: string }>();
  const dispatch = useDispatch();
  const {
    isFormShown: isAdvancedSearchFormShown
  } = useSelector((state: RootState) => state.advancedSearch);
  const h = useHistory();
  const { t } = useTranslation();

  const handleChangeSearchText = useCallback(({ newValue }: ChangeEvent) => {
    setTerm(newValue);
  }, [setTerm]);

  const handleSearch = useCallback(() => {
    dispatch(resetAdvancedSearchParams());
    h.push(createURL({ term: term }, '/search'));
  }, [term, createURL]);

  const handleAdvancedSearch = useCallback((values: TAdvancedSearchValues) => {
    dispatch(updateAdvancedSearchParams(values));
    h.push(createURL(values, '/advanced-search'));
    dispatch(toggleAdvancedSearchForm(false));
    setTerm('');
  }, [dispatch, toggleAdvancedSearchForm, h]);

  const closeAdvancedSearchOnSearchTermChange = () => {
    dispatch(toggleAdvancedSearchForm(false));
  };
  useEffect(closeAdvancedSearchOnSearchTermChange, [term, toggleAdvancedSearchForm]);

  useEffect(() => {
    if(isAdvancedSearchFormShown) {
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
    }
    else 
      document.body.style.overflow = 'visible';
  }, [isAdvancedSearchFormShown]);

  useImperativeHandle(ref, () => {
    return {
      handleAdvancedSearch
    };
  });

  return (
    <div className={styles.container}>
      <div className={styles.searchbarWrapper}>
        <SearchBar
          value={term}
          onChange={handleChangeSearchText}
          onSearch={handleSearch}
        />
      </div>
      <Tooltip text={t('tooltips:advanced_search_button')}>
        <Button
          data-test-id='advanced_search_open_button'
          className={cn(styles.advancedSearchButton, {
            [styles.active]: isAdvancedSearchFormShown
          })}
          variant={EButtonVariants.link}
          onClick={() => {
            dispatch(toggleAdvancedSearchForm(!isAdvancedSearchFormShown));
          }}
        >
          <Icon icon="sliders-up" className={styles.advancedSearchButtonIcon} />
        </Button>
      </Tooltip>
    </div>
  );
});

SearchBox.displayName = 'SearchBox';
export default SearchBox;
