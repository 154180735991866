import React, { FC, useCallback, useMemo, useState } from 'react';
import { cn } from 'utils';
import useDetectClickOutside from 'modules/common/hooks/useDetectClickOutside';
import { TCustomDropdownMenuItem, IDMenuItem } from './types';

import styles from './DropdownMenu.module.scss';

interface IDropdownMenuProps {
    renderDropdownToggle: ({ onClick }: {onClick: () => void}) => JSX.Element;
    items: IDMenuItem[];
    customRenderItems?: TCustomDropdownMenuItem[];
    className?: string;
}

export const DropdownMenu: FC<IDropdownMenuProps> = ({ renderDropdownToggle, items, customRenderItems }) => {
  const [ isMenuOpen, setIsMenuOpen ] = useState<boolean>(false);

  const toggleMenu = useCallback(() => setIsMenuOpen(!isMenuOpen), [isMenuOpen, setIsMenuOpen]);
  const handleClose = useCallback(() => setIsMenuOpen(false), [setIsMenuOpen]);
  const handleSelect = useCallback(
    (selectFn?: () => void) => {
      selectFn && selectFn();
      handleClose();
    },
    [handleClose]
  );

  const ref = useDetectClickOutside(handleClose);

  const toggleElement = useMemo(
    () => renderDropdownToggle({ onClick: toggleMenu }),
    [renderDropdownToggle, toggleMenu]
  );

  const menuContent = useMemo(
    () => (
      <ul className={styles.list}>
        {
          customRenderItems && customRenderItems.map((customRenderItem, index) =>
            <li key={`DropdownMenuActionItem_${customRenderItem.label}${index}`}
              className={cn(styles.item, customRenderItem.className)}
              onClick={customRenderItem.onSelect}>
              {customRenderItem?.content ? customRenderItem.content : customRenderItem.label}
            </li>)
        }
        {
          items.map((item) => item.component(handleSelect))
        }
      </ul>
    ),
    [items, customRenderItems],
  );

  return (
    <div ref={ref}>
      {toggleElement}
      <div className={styles.menuWrapper}>
        {
          isMenuOpen ? menuContent : null
        }
      </div>
    </div>
  );
};
