import React, { FC, Fragment } from 'react';
import styles from './Tooltip.module.scss';
import ReactTooltip, { Place, Type } from 'react-tooltip';
import { cn } from 'utils';
import { useEventListener } from 'modules/common/hooks/useEventListener';


type TContentTypes = 'INPUT'

type TTooltipProps = {
  text: string | JSX.Element,
  place?: Place
  block?: boolean
  className?: string,
  tooltipClassName?: string,
  clickable?: boolean,
  event?: string,
  eventOff?: string,
  type?: Type,
  contentType?: TContentTypes
  delayHide?: number;
}
let tooltipId = 0;
export const Tooltip: FC<TTooltipProps> = (props) => {
  
  const handleHideTooltip = (e: any) => {
    if (e.target.nodeName === props.contentType) {
      ReactTooltip.hide();
    }
  };

  useEventListener('click', handleHideTooltip);
  
  return (
    <Fragment>
      <span
        className={cn(styles.content, props.block && styles.block, props.className)}
        data-tip={true}
        data-for={`t_${tooltipId}`}
      >{props.children}</span>
      <ReactTooltip
        id={`t_${tooltipId++}`}
        effect="solid"
        place={props.place}
        delayShow={300}
        delayHide={props.delayHide}
        className={cn(styles.tooltip, props.tooltipClassName)}
        clickable={props.clickable}
        event={props.event}
        eventOff={props.eventOff}
        type={props.type}
      >
        {props.text}
      </ReactTooltip>
    </Fragment>
  );
};
