type TMatch<T, R> = {
  on: (predicate: (matcher: T) => boolean, cb: (matcher: T) => R) => TMatch<T, R>;
  otherwise: (cb: (matcher: T) => R) => R;
};

const matched = <T>(matcher: T) => ({
  on: () => matched(matcher),
  otherwise: () => matcher,
});

/**
 * @description - functor, which can be used to apply complex switch-case logic in FP style
 * @example
 * const message = match<string, string>(MODULE_NAME_ASSET)
 *  .on(moduleName => moduleName === MODULE_NAME_ASSET, moduleName => `${moduleName} recognised as Asset`)
 *  .on(moduleName => moduleName === MODULE_NAME_CONTACT, moduleName => `${moduleName} recognised as Contact`)
 *  .otherwise(() => { throw new Error('Error. Module was not matched by name!); });
 */
export const match = <T, R = unknown>(matcher: T): TMatch<T, R> => ({
  on: (predicate: (matcher: T) => boolean, cb: (matcher: T) => R) =>
    predicate(matcher) ? matched(cb(matcher)) : match(matcher),
  otherwise: (cb: (matcher: T) => R) => cb(matcher),
});
