import React, { FC, useMemo } from 'react';

export interface IGroupItem {
  label: string;
  value?: string | JSX.Element;
  getKey?(): string;
}

interface IProps {
  list: ReadonlyArray<IGroupItem>;
}

export const GroupItem: FC<IProps> = ({ list }) => {
  const fl = useMemo(
    () => list.filter(f => (typeof f.value !== 'undefined' && f.value !== null && f.value !== 'Invalid date')),
    [list],
  );

  return fl.length ? (
    <div className='isni-item__group'>
      {
        fl.map(e => (
          <dl className='isni-item__li' key={typeof e.getKey === 'function' ? e.getKey() : e.label}>
            <dt className='isni-item__label'>{e.label}</dt>
            <dd className='isni-item__value'>{e.value}</dd>
          </dl>
        ))
      }
    </div>
  ) : null;
};
