import React, { Fragment } from 'react';
import NewRelicDevSnippet from './NewRelicDevSnippet';
import NewRelicQaSnippet from './NewRelicQaSnippet';
import NewRelicStagingSnippet from './NewRelicStagingSnippet';
import NewRelicProdSnippet from './NewRelicProdSnippet';

const ENV_NAME = process.env.REACT_APP_ENV_NAME;
const isLocal = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

const NewRelicIntegration = () => {
  return (
    <Fragment>
      {!isLocal && ENV_NAME === 'Development' && <NewRelicDevSnippet />}
      {ENV_NAME === 'QA' && <NewRelicQaSnippet />}
      {ENV_NAME === 'Staging' && <NewRelicStagingSnippet />}
      {ENV_NAME === 'Production' && <NewRelicProdSnippet />}
    </Fragment>
  );
};

export default NewRelicIntegration;
