import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { cn } from 'utils';
import ImageUnavailable from './ImageUnavailable';
import { useSpotify, ISpotifyImageContainer } from '../Spotify';
import { getImageProps } from './helpers';

import styles from './artistImage.module.scss';

interface Props {
  artistId?: string;
  artistName?: string;
  className?: string;
  isCentred?: boolean;
  isRound?: boolean;
  expandable?: boolean;
  images?: ReadonlyArray<ISpotifyImageContainer>;
}

export const ArtistImage: React.FC<Props> = ({
  artistId,
  artistName,
  className,
  isCentred,
  isRound,
  expandable,
  images
}) => {
  const [displayImage, setDisplayImage] = useState(false);
  const [insideImage, setInsideImage] = useState(false);
  const [spotifyImageUrl, setSpotifyImageUrl] = useState<ReadonlyArray<ISpotifyImageContainer>|undefined>();
  const artistImages = useMemo<ReadonlyArray<ISpotifyImageContainer> | undefined>(() => images || spotifyImageUrl, [images, spotifyImageUrl]);
  const { getImagesBySpotifyId } = useSpotify();

  let timeout: any;

  const handleMouseEnter = () => {
    setInsideImage(true);
    if (setDisplayImage) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setDisplayImage(true);
      }, 1000);
    }
  };

  const handleMouseLeave = () => {
    setInsideImage(false);
    clearTimeout(timeout);
    if (setDisplayImage) {
      setDisplayImage(false);
    }
  };

  useEffect(() => {
    if (setDisplayImage && timeout === undefined) {
      if (displayImage && !insideImage) setDisplayImage(false);
    }
  }, [displayImage, setDisplayImage, timeout, insideImage]);

  useEffect(() => {
    setSpotifyImageUrl([]);
    let isMount = true;
    if (typeof artistId !== 'undefined' && !images) {
      getImagesBySpotifyId(artistId)
        .then((images) => {
          if(isMount && typeof images !== 'undefined') {
            setSpotifyImageUrl(images);
          }
        });
    }
    return () => {
      isMount = false;
    };
  }, [getImagesBySpotifyId, artistName, images]);

  return (
    <Fragment>
      {artistImages && artistImages.length > 0 ? (
        <div
          className={cn(styles.wrapper, className)}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onMouseOut={handleMouseLeave}
        >
          <img
            alt={artistName}
            className={cn('img-preview', { [styles.round]: isRound })}
            {...getImageProps(artistImages)}
          />
          {expandable && <div className={cn(styles['img-expanded-wrapper'], { [styles.centred]: isCentred })}>
            <img
              className={styles['img-expanded']}
              alt={artistName}
              {...getImageProps(artistImages)}
            />
            <div className={styles['img-source']}>Image from Spotify</div>
          </div>}
        </div>
      ) : (
        <ImageUnavailable className={className} isRound />
      )}
    </Fragment>
  );
};
