import { useCallback, useEffect, useRef } from 'react';
import { EKeyName } from 'utils/focus';
/**
 * Hook used to detect clicks outside a component (or an escape key press). onTriggered function is triggered on `click` or escape `keyup` event.
 * @param onTriggered
 */

const defaultOptions = {
  disabledEscape: false
};

const useDetectClickOutside = <T extends HTMLElement | null>(onTriggered: () => void, options = defaultOptions) => {
  const { disabledEscape } = options;
  const container = useRef<T>();
  const isFocusInside = useCallback(() => {
    setTimeout(() => {
      if (!container.current?.contains(document.activeElement)) {
        onTriggered();
      }
    }, 200);
  }, [onTriggered]);

  const keyListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === EKeyName.ESCAPE && !disabledEscape) {
        onTriggered();
      }
      isFocusInside();
    },
    [onTriggered]
  );

  const clickListener = useCallback(
    (e: MouseEvent) => {
      if (!container?.current) {
        onTriggered();
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (!(container.current! as any).contains(e.target)) {
        onTriggered?.();
      }
    },
    [onTriggered]
  );

  useEffect(() => {
    document.addEventListener('keydown', keyListener);
    document.addEventListener('click', clickListener);
    return () => {
      document.removeEventListener('keydown', keyListener);
      document.removeEventListener('click', clickListener);
    };
  }, [clickListener, keyListener]);

  return useCallback<(el: T) => void>((el) => { container.current = el; },[]);

};

export default useDetectClickOutside;
