import { EPartyType } from 'modules/common/types/partyTypes';

export enum EQueryType {
  FUZZY = 'FUZZY',
  EXACT = 'EXACT',
}

export type TQueryList = {
  index: number;
  searchTerm: string;
  types: ReadonlyArray<EQueryType>;
  excludeNameId?: number;
  excludePartyId?: number;
}

export type NameSearchAdditionalFields = {
  partyType: EPartyType;
  countryOfOriginId?: number;
}
