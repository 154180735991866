import React, { FC, useCallback, useMemo } from 'react';
import styles from './AdvancedSearch.module.scss';
import SearchParamRowStyles from '@grow-components/SearchParamRow/SearchParamRow.module.scss';
import { cn } from 'utils';
import { Button } from '@wmg-ae/legato';
import { Button as LegatoButton } from '@wmg-ae/legato';
import { Field, Form, FormRenderProps, useField } from 'react-final-form';
import { LegatoFFSelect } from '@grow-components/Form';
import { EPartyType, partyTypes } from 'modules/common/types/partyTypes';
import { formatPartyType } from 'modules/common/helpers/partyHelpers/formatPartyTypes';
import { useTranslation } from 'react-i18next';
import { enumsToSelectOptions } from 'modules/common/helpers/selectOptionsFormatters';
import { useDictionaries } from 'modules/common/hooks/useDictionaries';
import { TSelectOption } from 'common/types';
import { EAdvancedSearchOperator, ECompetencyQueryType, ELegalNameQueryTypes, TAdvancedSearchValues } from 'domains/search/types';
import { ENameType, EPartySubType } from 'domains/party/types';
import { LegatoDatePicker } from '@grow-components/Form/DatePicker/DatePicker';
import { FFActivityUsersInput } from '@grow-components/ActivityUsersInput/FFActivityUsersInput';
import { SearchParamRow } from '@grow-components/SearchParamRow/SearchParamRow';
import { advancedSearchOperators } from 'domains/search';
import { countryOptions } from 'modules/common/helpers/forms/frecuentOptionsFormat';
import { useSettings } from 'domains/env/hooks';
import { EButtonColors, EButtonStyles } from 'modules/common/types/legatoTypes';
import { LegatoFFTextInput } from '@grow-components/Form/TextInput/TextInput';


type TDateOperatorKey = 'createdOnOperator' | 'modifiedOnOperator'
type TDateAKey = 'createdOnA' | 'modifiedOnA';
type TDateBKey = 'createdOnB' | 'modifiedOnB';

type TAdvancedSearchProps = {
  initialValues: TAdvancedSearchValues
  onClose: () => void
  onSearch: (values: TAdvancedSearchValues) => void
  className?: string
}

const AdvancedSearchForm: FC<FormRenderProps<TAdvancedSearchValues> & TAdvancedSearchProps> = (props) => {
  const { form, handleSubmit } = props;
  const { t } = useTranslation();
  const { settings } = useSettings();
  const { t: tParty } = useTranslation('party_labels');
  const { t: tPartyPlaceholders } = useTranslation('party_placeholders');
  const { t: tSearch } = useTranslation('search');
  const { dictionaries } = useDictionaries();
  const values = form.getState().values;
  const onCloseBtnClick = useCallback(() => props.onClose(), [props.onClose]);

  const onNameTypeChangeHandler = useCallback((value: ENameType) => {
    form.change('nameType', value || undefined);
  }, [form]);

  const onLegalNameChangeHandler = useCallback((value: ELegalNameQueryTypes) => {
    form.change('legalName', value || undefined);
  }, [form]);
  
  const onPartyTypeChangeHandler = useCallback((value: EPartyType) => {
    form.change('partyType', value || undefined);
    form.change('partySubType', undefined);
  }, [form]);

  const onPersonTypeChangeHandler = useCallback((value: EPartySubType) => {
    form.change('partyType', EPartyType.PERSON || undefined);
    form.change('partySubType', value);
  }, [form]);

  const onGroupTypeChangeHandler = useCallback((value: EPartySubType) => {
    form.change('partyType', EPartyType.GROUP || undefined);
    form.change('partySubType', value);
  }, [form]);

  const onCompetencyChangeHandler = useCallback((value: ECompetencyQueryType) => {
    form.change('competency', value || undefined);
  }, [form]);

  const onCountryOfOriginChangeHandler = useCallback((val: number) => {
    if (!val || val === values.partyCountryOfOriginId) return form.change('partyCountryOfOriginId', undefined);
    form.change('partyCountryOfOriginId', val);
  }, [form]);
  const createdOnOperatorHandler = useCallback((value: EAdvancedSearchOperator) => {
    form.change('createdOnOperator', value);
    if (value !== EAdvancedSearchOperator.BETWEEN) {
      form.change('createdOnB', undefined);
    }
    if (!value) {
      form.change('createdOnA', undefined);
    }
  }, [form]);

  const modifiedOnOperatorHandler = useCallback((value: EAdvancedSearchOperator) => {
    form.change('modifiedOnOperator', value);
    if (value !== EAdvancedSearchOperator.BETWEEN) {
      form.change('modifiedOnB', undefined);
    }
    if (!value) {
      form.change('modifiedOnA', undefined);
    }
  }, [form]);
  const userFieldHandler = useCallback((field) => (value: string) => form.change(field as any, value), [form]);

  const partyTypeInput = useField<EPartyType>('partyType');
  const partyTypeValue = partyTypeInput.input.value;

  const createdOnOperatorInput = useField<EAdvancedSearchOperator>('createdOnOperator');
  const createdOnOperatorValue = createdOnOperatorInput.input.value;
  const modifiedOnOperatorInput = useField<EAdvancedSearchOperator>('modifiedOnOperator');
  const modifiedOnOperatorValue = modifiedOnOperatorInput.input.value;
  const dateOperatorOptions = useMemo<TSelectOption[]>(() => ([
    {
      label: 'Between',
      value: EAdvancedSearchOperator.BETWEEN
    }, {
      label: 'Begins',
      value: EAdvancedSearchOperator.GTE
    }, {
      label: 'Ends',
      value: EAdvancedSearchOperator.LTE
    }
  ]), []);

  const countryWithDeleteOptions = [
    ...countryOptions(settings?.lastUsedCountryOfOrigin, dictionaries)
  ];
  
  const competencyOptions = useMemo<TSelectOption[]>(() => ([
    {
      label: 'Performing Artist',
      value: ECompetencyQueryType.PERFORMING_ARTIST
    }, {
      label: 'No Competency Assigned',
      value: ECompetencyQueryType.NO_COMPETENCY_ASSIGNED
    }
  ]), []);

  const nameTypes = useMemo(() => ([
    {
      label: 'Primarily Known As',
      value: ENameType.PKA
    },
    {
      label: 'Also Known As',
      value: ENameType.AKA
    },
    {
      label: 'Formerly Known As',
      value: ENameType.FKA
    },
    {
      label: 'Also Known As & Formerly Known As',
      value: ENameType.AKA_FKA
    }
  ]), []);

  const legalNameTypes = useMemo(() => ([
    {
      label: 'Search Legal Names only',
      value: ELegalNameQueryTypes.LEGAL_ONLY
    },
    {
      label: 'Search all names excluding Legal Names',
      value: ELegalNameQueryTypes.EXCLUDE_LEGAL
    }
  ]), []);

  return (
    <form
      onSubmit={handleSubmit}
      className={cn(styles.container, props.className)}
    >
      <div className={cn('row', styles.formContent)}>
        {/*First column*/}
        <div className={cn('col-auto', styles.firstContentColumn)}>
          <h4 className={styles.title}>Advanced Search</h4>
          {/*Name*/}
          <SearchParamRow
            label={tParty('name')}
            controlsClassName={styles.selectFieldsWithInputs}
            labelTestId='advanced_search_name_label'
            controls={<>
              <Field
                component={LegatoFFSelect}
                name="nameOperator"
                options={advancedSearchOperators(tSearch)}
                className={styles.inputButton}
                testId='advanced_search_name_select'
                zIndex={11}
                customIcon
              />
              <Field
                placeholder={tPartyPlaceholders('enter_name')}
                component={LegatoFFTextInput}
                className={styles.inputButton}
                testId='advanced_search_name_input'
                aria-autocomplete='list'
                autoComplete='off'
                isClearable={true}
                name="nameValue"
              />
            </>
            }
          />
          {/*Party Type*/}
          <SearchParamRow
            label={tParty('party_type')}
            labelTestId='advanced_search_party_type_label'
            controls={
              <Field
                placeholder={tPartyPlaceholders('select_party_type')}
                className={SearchParamRowStyles.control}
                zIndex={11}
                component={LegatoFFSelect}
                name="partyType"
                options={partyTypes.map((partyType) => ({
                  label: formatPartyType(partyType),
                  value: partyType,
                }))}
                testId='advanced_search_party_type_select'
                onChange={onPartyTypeChangeHandler}
                customIcon
              />
            }
          />
          {/*Person/Group Type*/}
          <SearchParamRow
            label={tParty('person_group_type')}
            labelClassName={styles.nowrapLabel}
            controls={
              <Field
                disabled={!partyTypeValue}
                placeholder={tPartyPlaceholders('select_person_group_type')}
                className={SearchParamRowStyles.control}
                zIndex={11}
                component={LegatoFFSelect}
                name="partySubType"
                options={partyTypeValue === EPartyType.GROUP ? 
                  dictionaries?.groupTypes.map(enumsToSelectOptions) : dictionaries?.personTypes.map(enumsToSelectOptions)}
                onChange={partyTypeValue === EPartyType.GROUP ? onGroupTypeChangeHandler : onPersonTypeChangeHandler}
                customIcon
              />
            }
          />
          {/* Name Type */}
          <SearchParamRow
            label={tParty('name_type')}
            controls={
              <Field
                placeholder={tPartyPlaceholders('select_name_type')}
                className={SearchParamRowStyles.control}
                zIndex={11}
                component={LegatoFFSelect}
                name='nameType'
                options={nameTypes}
                onChange={onNameTypeChangeHandler}
                customIcon
              />
            }
          />
          {/*Competency*/}
          <SearchParamRow
            label={t('competency')}
            controls={
              <Field
                placeholder={tPartyPlaceholders('select_competency')}
                className={SearchParamRowStyles.control}
                zIndex={11}
                component={LegatoFFSelect}
                name="competency"
                options={competencyOptions}
                onChange={onCompetencyChangeHandler}
                customIcon
              />
            }
          />
          {/* Legal Name */}
          <SearchParamRow
            label={tParty('legal_name')}
            controls={
              <Field
                placeholder={tPartyPlaceholders('select_legal_name')}
                className={SearchParamRowStyles.control}
                zIndex={11}
                component={LegatoFFSelect}
                name='legalName'
                options={legalNameTypes}
                onChange={onLegalNameChangeHandler}
                customIcon
              />
            }
          />
        </div>
        {/*Second column*/}
        <div className={'col-auto mr-3'}>
          <div className={styles.closeBtn}>
            <LegatoButton
              icon="xmark"
              label={t('close')}
              containerStyle={EButtonStyles.link}
              onClick={onCloseBtnClick}
            />
          </div>
          {/*Country Of Origin Name*/}
          <SearchParamRow
            label={tParty('origin_country')}
            controls={
              <Field
                name="partyCountryOfOriginId"
                placeholder={t('party_placeholders:search_select_country')}
                className={SearchParamRowStyles.control}
                component={LegatoFFSelect}
                isFilterable={true}
                zIndex={11}
                options={countryWithDeleteOptions}
                //loading={isValidatingNames}
                onChange={onCountryOfOriginChangeHandler}
              />
            }
          />
          {/*Created By*/}
          <SearchParamRow
            label={tParty('created_by')}
            labelTestId='advanced_search_created_by_label'
            controls={
              <Field
                placeholder={tPartyPlaceholders('search_for_a_user')}
                className={SearchParamRowStyles.control}
                name="createdBy"
                component={FFActivityUsersInput}
                testId='advanced_search_created_by_input'
                onSelect={userFieldHandler('createdBy')}
              />
            }
          />
          {/*Created On*/}
          <SearchParamRow
            label={t('created_on')}
            controlsClassName={styles.selectFieldsWithInputs}
            controls={<>
              <Field
                placeholder={t('select')}
                className={SearchParamRowStyles.control}
                component={LegatoFFSelect}
                zIndex={11}
                name="createdOnOperator"
                options={dateOperatorOptions}
                onChange={createdOnOperatorHandler}
                customIcon
              />
              <Field
                name="createdOnA"
                component={LegatoDatePicker}
              />
              {createdOnOperatorValue === EAdvancedSearchOperator.BETWEEN && (
                <Field
                  name="createdOnB"
                  component={LegatoDatePicker}
                />
              )}
            </>}
          />
          {/*Modified By*/}
          <SearchParamRow
            label={t('last_modified_by')}
            labelTestId='advanced_search_modified_by_label'
            controls={
              <Field
                placeholder={tPartyPlaceholders('search_for_a_user')}
                className={SearchParamRowStyles.control}
                name="modifiedBy"
                component={FFActivityUsersInput}
                testId='advanced_search_modified_by_input'
                onSelect={userFieldHandler('modifiedBy')}
                update={true}
                customIcon
              />
            }
          />
          {/*Modified On*/}
          <SearchParamRow
            label={t('last_modified_on')}
            controlsClassName={styles.selectFieldsWithInputs}
            controls={<>
              <Field
                placeholder={t('select')}
                className={SearchParamRowStyles.control}
                component={LegatoFFSelect}
                zIndex={11}
                name="modifiedOnOperator"
                options={dateOperatorOptions}
                onChange={modifiedOnOperatorHandler}
                customIcon
              />
              <Field
                name="modifiedOnA"
                component={LegatoDatePicker}
              />
              {modifiedOnOperatorValue === EAdvancedSearchOperator.BETWEEN && (
                <Field
                  name="modifiedOnB"
                  component={LegatoDatePicker}
                />
              )}
            </>}
          />
          <div className={styles.actions}>
            <Button
              label="Clear All" containerStyle={EButtonStyles.outline}
              data-test-id='advanced_search_clear_button'
              colorType={EButtonColors.secondaryBlack}
              onClick={() => {
                form.reset({
                  nameOperator: EAdvancedSearchOperator.EQUALS,
                });
              }} />
            <Button
              label="Search"
              color={EButtonColors.info}
              data-test-id='advanced_search_submit_button'
              onClick={()=>form.submit()}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export const AdvancedSearch: FC<TAdvancedSearchProps> = (props) => {
  const sanitizeFormValues = useCallback((values: TAdvancedSearchValues) => {
    const { nameValue, nameOperator } = values;
    const sanitizedValues: TAdvancedSearchValues = { ...values };
    const keysToSanitize: (keyof TAdvancedSearchValues)[] = [];

    const nameAndOperatorAreValid = [nameValue, nameOperator].every(v => !!v);
    const validateDateGroup = (type: 'created' | 'modified'): boolean => {
      const operatorKey = `${type}OnOperator` as TDateOperatorKey;
      const dateAKey = `${type}OnA` as TDateAKey;
      const dateBKey = `${type}OnB` as TDateBKey;

      const operator = values[operatorKey];
      const dateA = values[dateAKey];
      const dateB = values[dateBKey];

      if (!operator) return false;
      if (operator === EAdvancedSearchOperator.GTE || operator === EAdvancedSearchOperator.LTE) return !!dateA;
      if (operator === EAdvancedSearchOperator.BETWEEN) return !(!dateA || !dateB);
      return false;
    };
    const createdOnValuesAreValid = validateDateGroup('created');
    const modifiedOnValuesAreValid = validateDateGroup('modified');
    if (!nameAndOperatorAreValid) keysToSanitize.push('nameValue', 'nameOperator');
    if (!createdOnValuesAreValid) keysToSanitize.push('createdOnOperator', 'createdOnA', 'createdOnB');
    if (!modifiedOnValuesAreValid) keysToSanitize.push('modifiedOnOperator', 'modifiedOnA', 'modifiedOnB');

    keysToSanitize.forEach(key => sanitizedValues[key] = undefined);
    props.onSearch(sanitizedValues);
  }, []);

  return (
    <Form<TAdvancedSearchValues>
      initialValues={props.initialValues}
      onClose={props.onClose}
      onSubmit={sanitizeFormValues}
      className={props.className}
      // eslint-disable-next-line
      //@ts-ignore
      component={AdvancedSearchForm}
    />
  );
};
