import { CSSProperties, useCallback, useEffect, useMemo, useRef } from 'react';
import { Decorator, FormApi, FormState, ValidationErrors } from 'final-form';
import compact from 'lodash/fp/compact';
import get from 'lodash/get';
import difference from 'lodash/difference';
import differenceWith from 'lodash/differenceWith';
import { Party } from 'modules/common/types/partyTypes';
import { ToastService } from 'utils/ToastService';
import createDecorator from '../../utils/submit-listener';
import React from 'react';

type TErrorsTuple = [string, string, string?]; // The first element is id (path to error), the second is validation message, third - extra param

function getErrors (errors: ValidationErrors, prefix = '', acc: TErrorsTuple[] = []): TErrorsTuple[] {
  let res = acc;
  if (typeof errors !== 'undefined') {
    for (const key in errors) {
      const item = errors[key];
      const path = compact([prefix, key]).join('.');
      if (typeof item === 'string') {
        res = [...res, [path, item]];
      } else {
        res = [...res, ...getErrors(item, path)];
      }
    }
  }
  return res;
}

const nameStyle: CSSProperties = {
  fontWeight: 700,
  fontFamily: 'Proxima Nova Bold',
  textDecoration: 'underline',
};

export function usePreSubmitErrorToast<T = Party> () {
  const goToNameFn = useCallback((id: string) => (e: React.MouseEvent) => {
    e.stopPropagation();
    const nameIndex = id.split('.')[1]; // names.IDX....
    const nameModuleElement = document.getElementById(`name_module_${nameIndex}`);
    if (nameModuleElement) {
      nameModuleElement.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth'
      });
    }
  }, []);
  const errorToasts = useRef<Record<string, string>>({});
  const currentToasts = useRef<string[]>([]);
  const createToasts = useCallback(
    (list: [string, string, string?][]) => {
      list.forEach(([id, message, name]) => {
        ToastService.error(
          <>
            {name && <span onClick={goToNameFn(id)}><b style={nameStyle}>{name}</b>: </span>}
            {message}
          </>, { toastId: id });
        errorToasts.current[id] = message;
      });
      errorToasts.current = {};
    },
    [],
  );

  const dismissToasts = useCallback<(ids: string[]) => void>(
    (ids) => {
      ids.forEach(id => {
        ToastService.dismiss(id);
        delete (errorToasts.current[id]);
      });
    }, [],
  );

  const getDiff = useCallback<(e: ValidationErrors) => [TErrorsTuple[], string[]]>(
    (errors) => {
      const list = getErrors(errors);
      const newIds = list.map(([id]) => id);
      const currentIds = Object.keys(errorToasts.current);
      const show = differenceWith(list, currentIds, ([a], b) => a === b);
      return [show, difference(currentIds, newIds)];
    }, [],
  );

  const checkIsCompetencyError = (errorMessage: string): boolean => {
    const testCompetencyRegExp = /Competency on PKA name is required/;
    return testCompetencyRegExp.test(errorMessage);
  };

  const enrichMessage = useCallback(
    (party: Party) =>
      (list: TErrorsTuple[]): TErrorsTuple[] =>
        list.map(([key, value]) => {
          if (key.startsWith('names.')) {
            if (checkIsCompetencyError(value)) {
              const currentName = party.names.find(name => name.nameType === 'PKA');
              return [
                key,
                value,
                currentName ? currentName.nameValue : '',
              ];
            }
            return [
              key,
              value,
              get(party, [...key.split('.').slice(0, 2), 'nameValue'])
            ];
          }
          return [key, value];
        }),
    [],
  );

  const afterSubmitFailed = useCallback<(api: FormApi<T>) => void>(
    ({ getState }) => {
      const { errors, submitErrors, values } = getState();
      let error = errors;
      if (submitErrors) error = submitErrors;
      const [show, dis] = getDiff(error);
      const enricher = enrichMessage(values as Party);
      createToasts(enricher(show));
      dismissToasts(dis);
    }, [createToasts, dismissToasts],
  );
  const onErrors = useCallback<(api: FormState<T>) => void>(
    ({ errors, validating }) => {
      if (!validating) {
        const listErrors = getErrors(errors).map(([id]) => id);
        dismissToasts(currentToasts.current.filter(item => !listErrors.some((id) => id === item)));
        currentToasts.current = listErrors;
      }
    }, [createToasts, dismissToasts, errorToasts],
  );

  const afterSubmitSucceeded = useCallback<(api: FormApi<T>) => void>(
    () => { dismissToasts(Object.keys(errorToasts.current)); }, []);

  const submitListener = useMemo<Decorator<T>>(
    () => createDecorator({
      afterSubmitSucceeded,
      afterSubmitFailed,
      onErrors
    }),
    [afterSubmitSucceeded, afterSubmitFailed],
  );

  useEffect(() => {
    return () => {
      dismissToasts(Object.keys(errorToasts.current));
    };
  }, []);

  return submitListener;
}
