import { TIsniInfo } from 'domains/isni/types';

export enum EIsniSearchParam {
  NAME = 'NAME',
  ISNI = 'ISNI',
  AMAZON = 'AMAZON',
  APPLE = 'APPLE',
  DEEZER = 'DEEZER',
  DISCOGS = 'DISCOGS',
  IPI = 'IPI',
  IPN = 'IPN',
  MBID = 'MBID',
  SPOTIFY = 'SPOTIFY',
  WIKIDATA = 'WIKIDATA',
}

export enum EIsniPageCameFrom {
  FORM = 'from-edit',
  SEARCH = 'from-search',
  DETAILS = 'from-party-detail'
}

export enum EIsniPageHashFlag {
  SEARCH_TAB_CLOSED = 'search_tab_closed'
}

export type TIsniSearchData = {
  name: string,
  nameId?: string,
  partyId: string,
  currentISNI?: string,
  searchParam: EIsniSearchParam,
  searchValue: string
  nameSearch?: string
}

export type TIsniSearchParams = {
  nameId: string
}

export type TIsniMoreInfoParams = {
  nameId: string,
  isni: string
}

export type TIsniQueryParams = {
  cameFrom: EIsniPageCameFrom
}

export type TIsniMoreInfoQueryParams = {
  cameFrom: EIsniPageCameFrom,
  partyId: string,
  partyName: string
}

export interface IIsniSearchFilter {
  data: {
    identifier: {
      isFull: boolean;
      type: EIsniSearchParam;
      value: string;
    }
  } | {
    name: string;
  }
}

export interface IRequestIsniVars {
  data: {
    uaid: string;
    quansicEntity: IQuansicEntity
  }
}

export interface IRequestIsniResponse {
  identifierProcure: {
    status: number;
    message?: string;
    party: ExternalParty & TIsniInfo;
    internalParty?: InternalParty;
  }
}

export interface InternalParty {
  nameValue: string;
  partyName?: string;
  partyId: number;
  nameId: number;
}

export interface ExternalParty {
  name: ExternalPartyName;
  isni: string;
  comment: string;
  identifiers: QuansicEntityIdentifier[];
}

export interface ExternalPartyName {
  firstname: string;
  lastname: string;
  nametype: QuansicEntityIdentifier[];
}

export interface IQuansicEntity {
  type: EQuansicEntityType
  // gender?: GenderType
  // relationships?: QuansicEntityRelationship[]
  birthdate?: string
  deathdate?: string
  comments?: string
  name: IQuansicEntityName
  // identifiers: QuansicEntityIdentifier[]
  countries?: string[]
}

export interface IQuansicEntityName {
  firstname?: string
  lastname: string
  nametype?: EQuansicEntityNameType
}

export enum EQuansicEntityNameType {
  REALNAME = 'realname',
  PSEUDONYM = 'pseudonym'
}

export enum EQuansicEntityType {
  PERSON = 'person',
  BAND = 'band'
}

export interface QuansicEntityIdentifier {
  type: QuansicEntityIdentifierType
  value: string
}

export enum QuansicEntityIdentifierType {
  ISNI = 'ISNI',
  ISRC = 'ISRC',
  MBID = 'MBID',
  IPI = 'IPI',
  IPN = 'IPN',
  IPIBN = 'IPIBN',
  IPINN = 'IPINN',
  ISWC = 'ISWC',
  MUBZ = 'MUBZ',
  discogs = 'discogs'
}