import { Decorator, FormApi, FormState } from 'final-form';

export interface Config<T> {
  beforeSubmit?: (api: FormApi<T>) => void | boolean;
  afterSubmitSucceeded?: (api: FormApi<T>) => void;
  afterSubmitFailed?: (api: FormApi<T>) => void;
  onErrors?: (state: FormState<T>) => void;
}

const maybeAwait = (maybePromise: Promise<any> | undefined, callback: () => void) => {
  if (maybePromise && typeof maybePromise.then === 'function') {
    // async
    maybePromise.then(callback);
  } else {
    // sync
    callback();
  }
};

const finalFormCreateDecorator = <T>({
  beforeSubmit,
  afterSubmitSucceeded,
  afterSubmitFailed,
  onErrors,
}: Config<T>): Decorator<T, Partial<T>> => (form: FormApi<T>) => {
    const originalSubmit = form.submit;

    const unsubscribeFormErrors = form.subscribe(
      (state) => {
        if (typeof onErrors === 'function') {
          onErrors(state);
        }
      },
      { errors: true, validating: true },
    );
    form.submit = () => {
      if (beforeSubmit) {
        if (beforeSubmit(form) === false) {
          return;
        }
      }
      const result = originalSubmit.call(form);
      if (afterSubmitSucceeded || afterSubmitFailed) {
        maybeAwait(result, () => {
          const { submitSucceeded, submitFailed } = form.getState();
          if (afterSubmitSucceeded && submitSucceeded) {
            afterSubmitSucceeded(form);
          }
          if (afterSubmitFailed && submitFailed) {
            afterSubmitFailed(form);
          }
        });
      }
      return result;
    };

    return () => {
      unsubscribeFormErrors();
      form.submit = originalSubmit;
    };
  };

export default finalFormCreateDecorator;
