import { TNameDuplicationResult } from 'graphql/queries';
import { EHiddenNameFields } from './constants';

export enum EValidationResult {
  PARENTHESIS = 'PARENTHESIS',
  PROHIBITED_WORDS = 'PROHIBITED_WORDS',
  CASING = 'CASING',
}

export interface INameFieldProps {
  exactMatchResult?: TNameDuplicationResult[];
  fuzzyMatchResults?: TNameDuplicationResult[];
  nameIndex: number;
  disabled?: boolean;
  isNameValidating?: boolean;
  fuzzyIgnored?: boolean;
  isEdit?: boolean;
  isRepeated: number
  isExpanded: boolean;
  onNameChanged: (name: string, nameIndex: number, fromCompetency: boolean) => void;
}

export type THiddenNameFields = {
  _fuzzyMatchesWereIgnoredOnce: boolean;
  _fuzzyMatchesAreIgnored: boolean;
  _isFuzzyMatchesShown: boolean;
  _hasExactMatch: boolean;
  [EHiddenNameFields.NAMING_STANDARDS_FORBIDDEN]: boolean;
  [EHiddenNameFields.IS_EXACT_MATCH_ARE_IGNORED]: boolean;
  _formNameId: string;
}

export interface IASTItem {
  term: string;
  isValid: boolean;
  isDouble?: boolean;
  isAllowedWord?: boolean;
}

export type TAST = IASTItem[];
