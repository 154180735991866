import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { cn, convertName, formatDateTime, moment } from 'utils';
import orderBy from 'lodash/orderBy';
import size from 'lodash/size';
import uniq from 'lodash/fp/uniq';
import omit from 'lodash/fp/omit';
import filter from 'lodash/fp/filter';
import compose from 'lodash/fp/compose';
import groupBy from 'lodash/groupBy';
import set from 'lodash/fp/set';
import { Icon } from '@grow-components/Icon/Icon';
import { DropdownMenuItem } from '@grow-components/DropdownMenu/DropdownMenuItem/DropdownMenuItem';
import { PartyParams } from 'modules/common/types/routeTypes';
import { EPartyType, MajorMinorGenre, MinorGenre } from 'modules/common/types/partyTypes';
import { Body } from 'modules/Table';
import { useDictionaries } from 'modules/common/hooks/useDictionaries';
import { tMessage } from 'modules/common/helpers/translation';
import {
  EPartyHistoryEntity,
  NestedEntityFields,
  PartyHistoryActions,
  PartyHistoryEntities,
  PartyHistoryItem,
  EFilterFields,
  TFilters,
} from './types';
import { SortBtn } from './SortBtn/SortBtn';
import { FilterBtn } from './FilterBtn/FilterBtn';

import styles from './PartyHistory.module.scss';
import { EFileExportType } from 'domains/search/graphql';
import { usePartyHistoryExport } from './usePartyHistoryExport';
import { useDateFilterItem, filterByDate } from './DateFilter';
import { PillsList } from './PillsList';
import { ExportBtn } from '../utils/ExportBtn';
import { useSettings } from 'domains/env';

interface IPartyHistoryProps {
  data: PartyHistoryItem[];
  partyType: EPartyType;
}

const flatMinorGenres = (majorGenres: MajorMinorGenre[] | undefined): MinorGenre[] => {
  if (!majorGenres) return [];

  return majorGenres.map(majorGenre => majorGenre.minorGenres).flat();
};

export const PartyHistory: FC<IPartyHistoryProps> = ({ data, partyType }) => {
  const { t } = useTranslation();
  const { dictionaries } = useDictionaries();
  const { id } = useParams<PartyParams>();
  const [sortDesc, setSortDesc] = useState(true);
  const [filters, setFilters] = useState<TFilters>({});
  const { settings } = useSettings();
  const { getPartyLogFile } = usePartyHistoryExport();

  const logFileExportHandler = (filetype: EFileExportType) => {
    getPartyLogFile({
      variables: {
        data: {
          partyId: id,
          logFileType: filetype
        }
      }
    });
  };

  const toggleSortOrder = useCallback(() => setSortDesc(!sortDesc), [sortDesc]);

  const getRegularItem = useCallback(
    (label: string, field: string, list: string[]) => ({
      label,
      subMenuItems: list.map(e => ({
        label: convertName(e),
        onSelect: () => setFilters(set(field)(e))
      }))
    }),
    [setFilters],
  );

  const createRegularItem = useCallback(
    (label: string, name: EFilterFields, list: string[]) =>
      function comp(handleSelect: () => void) {
        return (
          <DropdownMenuItem
            handleSelect={handleSelect}
            item={getRegularItem(label, name, list)}
            key={label}
          />
        );
      },
    [getRegularItem],
  );

  const dateFilter = useDateFilterItem('Date', EFilterFields.DATE, data, filters, setFilters);

  const historyFilterItems = useMemo(
    () => {
      const usersWorkedOnParty = uniq(data.map(item => item.userName));
      const pkaName = data.find(item => item.entityIdentifier === 'PKA')?.nameValue;
      const partyNames = Object.keys(groupBy(data, 'nameValue')).sort((name1, name2) => {
        if (name1 === pkaName) return -1;
        if (name2 === pkaName) return 1;
        return name1 > name2 ? 1 : -1;
      });

      return [
        {
          name: 'User',
          field: EFilterFields.USER_NAME,
          component: createRegularItem('User', EFilterFields.USER_NAME, usersWorkedOnParty),
        },
        {
          name: 'Names',
          field: EFilterFields.NAME_VALUE,
          component: createRegularItem('Names', EFilterFields.NAME_VALUE, partyNames),
        },
        dateFilter,
      ];
    },
    [data, setFilters, dateFilter],
  );

  const dataForRender = useMemo(() => {
    const sortedData = orderBy([...data], ['dateTime'], [sortDesc ? 'desc' : 'asc']);
    
    const filterIsDeleteField = (item: PartyHistoryItem) => item.field !== 'isDeleted';

    if (!size(filters)) return filter(filterIsDeleteField, sortedData);

    return compose(
      filter(filterIsDeleteField),
      filter(omit('dateTime')(filters)),
      filter(filterByDate(filters)),
    )(sortedData);
  }, [data, sortDesc, filters]);

  const formatEntityIdentifier = useCallback((dataItem: PartyHistoryItem) => {
    if (!dataItem.entityIdentifier) {
      // TODO: refactor mapping
      switch (dataItem.field) {
      case 'countryOfOrigin': return ` - ${t('party_labels:origin_country')}`;
      case 'languageOfOrigin': return ` - ${t('party_labels:origin_language')}`;
      case 'dateOfBeginningDay':
        return partyType.toLowerCase() === EPartyType.PERSON.toLowerCase() ?
          ` - ${t('history:day_birth')}` : ` - ${t('history:day_formed')}`;
      case 'dateOfBeginningMonth':
        return partyType.toLowerCase() === EPartyType.PERSON.toLowerCase() ?
          ` - ${t('history:month_birth')}` : ` - ${t('history:month_formed')}`;
      case 'dateOfBeginningYear':
        return partyType.toLowerCase() === EPartyType.PERSON.toLowerCase() ?
          ` - ${t('history:year_birth')}` : ` - ${t('history:year_formed')}`;
      case 'dateOfEndDay':
        return partyType.toLowerCase() === EPartyType.PERSON.toLowerCase() ?
          ` - ${t('history:day_dead')}` :  ` - ${t('history:day_disbanded')}`;
      case 'dateOfEndMonth':
        return partyType.toLowerCase() === EPartyType.PERSON.toLowerCase() ?
          ` - ${t('history:month_dead')}` : ` - ${t('history:month_disbanded')}`;
      case 'dateOfEndYear':
        return partyType.toLowerCase() === EPartyType.PERSON.toLowerCase() ?
          ` - ${t('history:year_dead')}` : ` - ${t('history:year_disbanded')}`;
      case 'partySubType':  return partyType.toLowerCase() === EPartyType.PERSON.toLowerCase() ?
        ` - ${t('party_labels:person_type')}` : ` - ${t('party_labels:group_type')}`;
      case 'partyType': return ` - ${t('party_labels:party_type')}`;
      case 'signed': return ` - ${t('party_labels:signed_label')}`;
      default: return null;
      }
    }

    switch (dataItem.entity) {
    case EPartyHistoryEntity.TRANSLATION:
      return ` - ${dataItem.entityIdentifier}`;
    case EPartyHistoryEntity.REMARK: case EPartyHistoryEntity.IDENTIFIER:
      return ` - ${tMessage(dataItem.entityIdentifier)}`;
    default:
      return ` - ${dataItem.entityIdentifier}`;
    }
  }, []);

  const formatValue = useCallback((value: string | null, dataItem: PartyHistoryItem) => {
    if (!value) return <i>Empty</i>;

    switch (dataItem.field) {
    case 'partyType': case 'partySubType': case 'remarkType': case 'privacyType': case 'competencyType': return tMessage(value);
    case 'dateOfBeginningMonth': case 'dateOfEndMonth': return moment(value, 'M').format('MMMM');
    case 'countryOfOrigin': return value;
    case 'languageOfOrigin': return value;
    case 'languageVariantId': return dictionaries?.translationLanguages
      .find(lang => lang?.languageVariantId.toString() === value)?.displayName;
    case 'competencyId': {
      const competencyValue = dictionaries?.competencies
        .find(competencies => competencies.partyType.toLowerCase() === partyType.toLowerCase())?.competencies
        .find(competency => competency.id === +value)?.value;

      return competencyValue ? tMessage(competencyValue) : <i>Empty</i>;
    }
    case 'majorGenreId': return dictionaries?.majorGenres.find(genre => genre.majorGenreId === +value)?.name;
    case 'minorGenreId': return flatMinorGenres(dictionaries?.majorGenres).find(genre => genre.minorGenreId === +value)?.name;
    }

    return value;
  }, []);

  const formatField = useCallback((dataItem: PartyHistoryItem) => {
    const nestedEntities = new Set<EPartyHistoryEntity>([
      EPartyHistoryEntity.NAME,
      EPartyHistoryEntity.TRANSLATION,
      EPartyHistoryEntity.REMARK
    ]);

    if (nestedEntities.has(dataItem.entity)) return ` ${NestedEntityFields[dataItem.field]}`;

    return null;
  }, []);

  const renderRow = useCallback((dataItem: PartyHistoryItem, index: number) =>
    <tr key={`partyLogItem_${index}_${dataItem.dateTime}`}>
      <td>
        <div className={styles.textWrapper}>
          <b>{dataItem.nameValue} </b>
          (<b>{PartyHistoryEntities[dataItem.entity]}</b>
          {formatEntityIdentifier(dataItem)})
          {formatField(dataItem)}
          <b> {PartyHistoryActions[dataItem.action]} </b>
          by
          <b> {convertName(dataItem.userName)}</b>
        </div>
      </td>
      <td>
        <div className={styles.textWrapper}>
          {formatValue(dataItem.changedFrom, dataItem)}
          <Icon icon='arrow-right' className={styles.arrow} />
          {formatValue(dataItem.changedTo, dataItem)}
        </div>
      </td>
      <td className={styles.timeWidth}>{formatDateTime(dataItem.dateTime, settings?.timeFormat || 'hh:mm a')}</td>
      <td>{formatDateTime(dataItem.dateTime, settings?.dateFormat?.toUpperCase() || 'MM/DD/YYYY')}</td>
    </tr>, [settings]);

  return <>
    <div className={styles.actionsWrapper}>
      <SortBtn sortDesc={sortDesc} toggleSort={toggleSortOrder} />
      <FilterBtn filters={omit<TFilters, 'dateTime'>('dateTime')(filters)} setFilters={setFilters} filterBy={historyFilterItems} />
      <ExportBtn 
        exportHandler={logFileExportHandler} 
        tooltip 
        tooltipMessage={t('tooltips:kebab_menu')} 
        buttonContent= {<Icon icon='ellipsis-vertical' />}
      />
    </div>
    <PillsList filters={filters} setState={setFilters} settings={settings} />
    <div className={cn(styles.historyCount, { [styles.activeTop]: filters.dateTime })}>Showing {dataForRender.length} Entries </div>
    {dataForRender.length ?
      <table className={styles.historyTable}>
        <Body renderRow={renderRow} rows={dataForRender} />
      </table>
      : <span>No audit history available</span>}
  </>;
};
