import { TSelectOption } from 'common/types';
import { useEffect, useRef, useState } from 'react';
import { EKeyName } from 'utils/focus';
import { TOption } from '../../../@grow-components/Form/SmartSelect/SmartSelect';

export const useArrowKeys = (onAction: (option: TOption & TSelectOption) => void, onTab: () => void, options: TOption[] & TSelectOption[]) => {
  const containerRef = useRef<HTMLElement>();
  const elementActive = useRef<HTMLLIElement>({} as HTMLLIElement);
  const elementPosition = useRef<number>(0);
  const activePosition = useRef<number>(0);
  const firstRender = useRef(0);
  const [activeOption, setActiveOption] = useState(options[activePosition.current]);
  const [mouseOver, setMouseOver] = useState(false);

  const changeActiveOption = (e: KeyboardEvent) => {
    setMouseOver(false);
    if (e.key === EKeyName.ARROW_KEY_DOWM && (activePosition.current + 1 < options.length)) {
      activePosition.current = activePosition.current + 1;
      elementActive.current = containerRef?.current?.children[activePosition.current] as HTMLLIElement;
      setActiveOption(options[activePosition.current]);
    }
    if (e.key === EKeyName.ARROW_KEY_UP && (activePosition.current > 0)) {  
      activePosition.current = activePosition.current - 1;
      elementActive.current = containerRef?.current?.children[activePosition.current] as HTMLLIElement;
      setActiveOption(options[activePosition.current]);
    }
    if (e.key === EKeyName.TAB && (activeOption?.value !== undefined)) {
      if (firstRender.current !== 0) {
        onAction(activeOption);
        onTab();
      } 
      firstRender.current = 1;
    }
    if (e.key === EKeyName.ENTER && (activeOption?.value !== undefined)) {
      e.preventDefault();
      onAction(activeOption);
    }
  };
  useEffect(() => {
    window.addEventListener('keyup',changeActiveOption);
    return () => {
      window.removeEventListener('keyup',changeActiveOption); 
    }; 
  }, [options, activeOption, elementActive]);
  
  const offMouseOver = () => {
    setMouseOver(true);
  };
  const onMouseOver = () => {
    setMouseOver(false);
  };

  useEffect(() => {
    elementActive.current = containerRef?.current?.children[activePosition.current] as HTMLLIElement;
    elementPosition.current = elementActive.current.offsetHeight;
    if (containerRef.current) {
      containerRef.current.addEventListener('mouseover', offMouseOver);
      containerRef.current.addEventListener('mouseout', onMouseOver);
    }
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('mouseover', offMouseOver);
        containerRef.current.addEventListener('mouseout', onMouseOver);
      }
    };
  }, [containerRef.current]);
  
  return { containerRef, activeOption, mouseOver };
};
