import React, { FC, useCallback, useRef } from 'react';
import { setElementProperty, EPosition } from '@grow-components/Popover';
import { useTranslation } from 'react-i18next';
import styles from './warning.module.scss';
import { Icon } from '@grow-components/Icon/Icon';
import { EValidationResult } from '../types';

interface IProps {
  isCanShow?: boolean;
  warnings: ReadonlyArray<EValidationResult>,
}

export const Warning: FC<IProps> = ({ warnings, isCanShow }) => {

  const { t } = useTranslation();

  const elementRef = useRef<Element | null>();

  const container = useRef<HTMLDivElement | null>();

  const containerRef = useCallback<(el: HTMLDivElement | null) => void>(
    (el) => {
      setElementProperty(el, EPosition.rightTop);
      container.current = el;
      if (el && el.focus) {
        el.classList.add(styles.focused);
        el.focus();
      }
    },
  [],
  );

  const handleBlur = useCallback(() => {
    if (container.current) {
      container.current.classList.remove(styles.focused);
    }
  }, []);

  return (warnings.length && isCanShow) ? (
    <div className={styles.container}>
      <div
        ref={ref => elementRef.current = ref}
        data-tip='tooltip'
        className={styles.wrapper}
        data-event="click"
      >
        <Icon icon="triangle-exclamation" className={styles.icon} />
        <div
          className={styles.popover}
          ref={containerRef}
          tabIndex={1}
          onBlur={handleBlur}
        >
          {
            warnings.map(w => <p key={w}>{t(`tooltips:warning_${w}`)}</p>)
          }
        </div>
      </div>
    </div>
  ) : null;
};
