//legato button options
export enum EButtonStyles {
  link = 'link',
  solid = 'solid',
  outline = 'outline'
}

export enum EButtonSizes {
  mini = 'xs',
  small = 'sm',
  large = 'lg',
  medium = 'md'
}

export enum EButtonHTMLTypes {
  button = 'button',
  submit = 'submit',
  reset = 'reset'
}

export enum EButtonColors {
  primary = 'primary',
  info = 'info',
  success = 'success',
  warning = 'warning',
  danger = 'danger',
  dark = 'dark',
  secondaryBlack = 'secondary-black',
  secondaryWhite = 'secondary-white'
}