import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';

const OKTA_DOMAIN = process.env.REACT_APP_OKTA_DOMAIN;
const authServerPath = process.env.REACT_APP_AUTH_SERVER_PATH;
const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const CALLBACK_PATH = process.env.REACT_APP_OKTA_CALLBACK_PATH;

const ISSUER = `${OKTA_DOMAIN}${authServerPath}`;

const REDIRECT_URI = `${window.location.origin}${CALLBACK_PATH}`;

const config: OktaAuthOptions = {
  issuer: ISSUER,
  clientId: CLIENT_ID,
  redirectUri: REDIRECT_URI,
  pkce: true,
  tokenManager: {
    expireEarlySeconds: 2 * 60,
    storage: 'localStorage'
  },
};
export const oktaAuth = new OktaAuth({
  ...config,
  scopes: ['groups', 'openid', 'profile', 'email'],
});
