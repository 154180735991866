import { useCallback, useEffect, useRef } from 'react';
import { IGROWKeys, IGROWSession, storageFactory, useStorage } from 'utils/storage';

const LINE_PER_PAGE = 20;

export const usePaginationSize = () => {

  const sizeStore = useRef<number>(LINE_PER_PAGE);

  const Storage = useCallback(() => storageFactory(window.localStorage), []);
  const storage = useStorage<IGROWSession>(Storage);

  useEffect(() => {
    storage.get(IGROWKeys.PAGE_SIZE, LINE_PER_PAGE).then((l) => { sizeStore.current = l; });
  }, [storage]);

  const getSize = useCallback(() => {
    return sizeStore.current;
  }, [sizeStore]);

  const setSize = useCallback<(size: number) => void>(
    (size) => {
      storage.set(IGROWKeys.PAGE_SIZE, size);
      sizeStore.current = size;
    }, [storage, sizeStore],
  );

  return {
    getSize,
    setSize
  };
};
