import { useQuery } from '@apollo/client';
import { TPartyGetResponse, TPartyGetVars } from './types';
import { getPartyDates, nameRemarksSortByDateFn } from '../utils';
import { TPartyEntryDetail, TPartyName } from '../types';
import { GET_PARTY } from '../graphql';
import { EIdentifierType } from 'modules/common/types/partyTypes';

function transformResponse (partyData: TPartyEntryDetail | undefined): TPartyEntryDetail | undefined {
  if (partyData === undefined) return undefined;
  partyData.names.forEach(name => {
    if (Array.isArray(name.remarks)) {
      name.remarks.sort(nameRemarksSortByDateFn);
    }
  });
  return partyData;
}

export const useGetParty = (partyId: number) => {
  const {
    loading: isPartyLoading,
    data: partyData
  } = useQuery<TPartyGetResponse, TPartyGetVars>(GET_PARTY, {
    skip: !partyId,
    fetchPolicy: 'no-cache',
    variables: {
      partyId: partyId
    }
  });
  const transformedParty = transformResponse(partyData?.partyGet);
  return {
    isPartyLoading,
    partyData: transformedParty,
    getNameById: (nameId: number) => {
      if (!transformedParty) return undefined;
      return transformedParty.names.find(n => n.nameId === nameId);
    },
    getIdentifierFromName: (name: TPartyName | undefined, identifierType: EIdentifierType) => {
      if (!name || !name.identifiers) return undefined;
      return name.identifiers.find(identifier => identifier.identifierType === identifierType);
    },
    getPartyDates: () => getPartyDates(transformedParty?.party)
  };
};
