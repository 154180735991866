export type OktaUser = {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  locale: string;
  name: string;
  preferred_username: string;
  sub: string;
  updated_at: string;
  zoneinfo: string;
};

export enum EPermissionType {
  SEARCH_FOR_PARTY = 'search_for_party',
  VIEW_ARTIST_RECORD = 'view_artist_record',
  ASSIGN_ISNI = 'assign_isni',
  EDIT_ISNI = 'edit_isni',
  SEARCH_ISNI = 'search_isni',
  REQUEST_NEW_ISNI = 'request_new_isni',
  REMOVE_ISNI = 'remove_isni',
  CREATE_PARTY = 'create_party',
  EDIT_PARTY = 'edit_party',
  DELETE_NAME =  'delete_name',
  VIEW_PARTY = 'view_party',
  VIEW_HISTORY = 'view_history',
  REPUBLISH_ACTION = 'republish_action',
  EDIT_PERFORMING_ARTIST = 'edit_perf_artist_competency',
  REMOVE_PERFORMING_ARTIST = 'remove_perf_artist_competency',
  MANAGE_TRANSLATIONS = 'manage_translations',
  BYPASS_DUPLICATE_CHECKING = 'bypass_duplicate_checking',
}

export enum EUserGroup {
  SUPER_USER = 'Super User',
  DATA_USER = 'Data User',
}
