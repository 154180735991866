import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tooltip } from '@grow-components/Tooltip';
import { cn } from 'utils';
import { SearchName } from 'modules/common/types/searchNameTypes';
import ResultDetails from './ResultDetails';
import { tMessage } from 'modules/common/helpers/translation';
import { ToastService } from 'utils/ToastService';
import { IsniLookup } from 'modules/common/components/isniLookup/IsniLookup';
import { formatISNIStatic } from 'modules/common/helpers/formatISNI';
import { BROADCAST_CHANNELS, useBroadcastChannel } from 'modules/common/hooks/useBroadcastChannel';
import { ENamePrivacyType } from 'modules/common/types/partyTypes';

import styles from './result.module.scss';
import { Icon } from '@grow-components/Icon/Icon';
import { EIsniPageCameFrom } from 'modules/common/types/isniTypes';

interface Props {
  results: SearchName;
  elementKey: React.Key;
  idx: string;
}

const Result: React.FC<Props> = ({ results, elementKey, idx }) => {
  const [detailsOpen, setDetailsOpen] = useState(false);

  const {
    nameId,
    nameValue,
    nameType,
    privacyType,
    nameIsLegal,
    nameIsni,
    partyId,
    partyType,
    partyCountryOfOriginName,
    nameInternalComment,
  } = results;

  const [assignedIsni, setAssignedIsni] = useState(nameIsni?.toString());
  const removeIsni = useCallback(() => setAssignedIsni(undefined), [setAssignedIsni]);
  const { receiveMessage: handleIsniSelected } = useBroadcastChannel(BROADCAST_CHANNELS.ISNI_ASSIGN);

  const isniSelectedToastId = 'isni_selected_and_saved';

  const isniSelectHandler = useCallback((msg) => {
    if (msg.selectedIsni && nameId.toString() === msg.nameId) {
      setAssignedIsni(msg.selectedIsni);
      ToastService.success(
        <Trans
          i18nKey="toasts:isni_selected_and_saved"
          values={{ isni: formatISNIStatic(msg.selectedIsni), name: nameValue }}
        />, { toastId: isniSelectedToastId });
    }
  }, [setAssignedIsni]);

  useEffect(() => {
    handleIsniSelected(isniSelectHandler);
  }, [assignedIsni]);

  useEffect(()=> {
    if (window.location.hash === `#isniSaved:${partyId}`) {
      ToastService.success(
        <Trans
          i18nKey="toasts:isni_selected_and_saved"
          values={{ isni: formatISNIStatic(nameIsni), name: nameValue }}
        />);
    }

    return () => {
      ToastService.dismiss(isniSelectedToastId);
    };
  },[]);

  const { t } = useTranslation();
  const path = useMemo(
    () => `/party/${partyId}`,
    [partyId, partyType],
  );

  const toggleResultDetails = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setDetailsOpen(() => !detailsOpen);
    },
    [detailsOpen, setDetailsOpen],
  );

  return (
    <>
      <tr className={cn('result-item', { [styles.open]: detailsOpen })}>
        <td data-test-id={`search_table_party_type_label_${idx}`}>{tMessage(partyType)}</td>
        <td className={styles.name}>
          <Link data-test-id={`search_table_name_label_${idx}`} className={styles.link} to={path}>{nameValue}</Link>
        </td>
        <td data-test-id={`search_table_name_type_label_${idx}`} >{nameType}</td>
        <td data-test-id={`search_table_legal_name_label_${idx}`}>{t(nameIsLegal ? 'yes' : 'no')}</td>
        <td data-test-id={`search_table_isni_container_${idx}`}>
          <IsniLookup
            name={results}
            page={EIsniPageCameFrom.SEARCH}
            currentIsni={assignedIsni}
            elementKey={elementKey}
            removeIsni={removeIsni}
            disabled={privacyType === ENamePrivacyType.INTERNAL_TO_WMG}
            isSearchResultUse={true}
          />
        </td>
        <td data-test-id={`search_table_country_label_${idx}`} >{partyCountryOfOriginName}</td>
        <td>
          <div className="row">
            <div data-test-id={`search_table_internal_comment_label_${idx}`} className="col-md-9">
              {nameInternalComment}
            </div>
            <div className="col-md-3 flex justify-end">
              <Tooltip text={t('tooltips:expand_chevron')}>
                <button
                  className="btn btn-no-bg p-0 btn-toggle-result-details"
                  data-test-id={`search_table_results_button_${idx}`}
                  onClick={(e) => toggleResultDetails(e)}
                  type="button"
                >
                  <Icon icon={detailsOpen ? 'angle-up' : 'angle-down'}/>
                </button>
              </Tooltip>
            </div>
          </div>
        </td>
      </tr>

      {
        detailsOpen ? (
          <ResultDetails
            partyId={partyId}
            partyType={partyType}
            nameId={nameId}
          />
        ) : null
      }
    </>
  );
};

export default Result;
