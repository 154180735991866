import { GET_PARTY_LOG_FILE } from 'graphql/queries';
import { autoDownload } from 'utils/autoDownload';
import { useLazyQuery } from '@apollo/client';

export const usePartyHistoryExport = () => {
  const [ getPartyLogFile ] = useLazyQuery(GET_PARTY_LOG_FILE, {
    fetchPolicy: 'no-cache',
    onCompleted(data) { // init auto-download from signed url
      const { url: signedUrl, filename } = data?.partyGetLogFile;
      autoDownload(signedUrl, filename);
    }
  });

  return  { getPartyLogFile };
};
