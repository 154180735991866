import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, EButtonVariants } from '@grow-components/Button/Button';
import { Icon } from '@grow-components/Icon/Icon';
import { cn } from 'utils';
import useDetectClickOutside from 'modules/common/hooks/useDetectClickOutside';
import { formatISNIStatic } from 'modules/common/helpers/formatISNI';
import { IsniLookupModal } from 'modules/common/components/isniLookup/IsniLookupModal/IsniLookupModal';
import { TPartyName } from 'domains/party/types';
import { usePermissions } from 'modules/common/hooks/usePersmissions';
import { SearchName } from 'modules/common/types/searchNameTypes';
import 'modules/common/components/isniLookup/IsniLookup.scss';
import Tooltip from '../utils/Tooltip';
import { Tooltip as NewTooltip } from '@grow-components/Tooltip/Tooltip';
import { EIsniPageCameFrom } from 'modules/common/types/isniTypes';
import { BROADCAST_CHANNELS, useBroadcastChannel } from 'modules/common/hooks/useBroadcastChannel';
import { ENamePrivacyType } from 'modules/common/types/partyTypes';
import { ToastService } from 'utils/ToastService';

interface IIsniLookupProps {
  className?: string;
  name: SearchName | TPartyName,
  page?: EIsniPageCameFrom
  currentIsni?: string | undefined
  removeIsni?: () => void
  disabled?: boolean
  isSearchResultUse?: boolean
  elementKey?: React.Key;
}

export const IsniLookup: FC<IIsniLookupProps> = ({
  className,
  name,
  page,
  currentIsni,
  removeIsni,
  disabled,
  isSearchResultUse,
  elementKey
}) => {
  const [showModal, setShowModal] = useState(false);
  const [tooltipId] = useState(Math.random().toString(36).substring(7));
  const toggleModal = useCallback<(e: React.MouseEvent<HTMLButtonElement>) => void>(
    (e) => {
      e.stopPropagation();
      setShowModal(prev => !prev);
    }, [setShowModal, showModal]
  );
  const ref = useDetectClickOutside(() => setShowModal(false));

  const { t } = useTranslation();

  const { closeChannel: closeIsniSearchChannel } = useBroadcastChannel(BROADCAST_CHANNELS.ISNI_SEARCH);
  const { closeChannel: closeIsniAssignChannel } = useBroadcastChannel(BROADCAST_CHANNELS.ISNI_ASSIGN);
  const { closeChannel: closeIsniDetailsChannel } = useBroadcastChannel(BROADCAST_CHANNELS.ISNI);

  useEffect(() => {
    return () => {
      closeIsniSearchChannel();
      closeIsniAssignChannel();
      closeIsniDetailsChannel();
    };
  }, []);

  const onClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { partyCountryOfOriginName } = name as SearchName;
    if (isSearchResultUse && !partyCountryOfOriginName) {
      ToastService.error(
        <>
          {name.nameValue && <><b className={'toast-name'}>{name.nameValue}</b>: </>}
          {t('toasts:party_country_is_required')}
        </>, { toastId: elementKey }
      );
    } else {
      toggleModal(e);
    }
  };

  const btnClassName = useMemo(() =>
    cn('form-control', {
      'btn-isni-link': !page || page === EIsniPageCameFrom.SEARCH,
      'btn-dropdown': page === EIsniPageCameFrom.FORM,
      'btn-dropdown--open': showModal,
    }), [page, showModal]);

  const modalClassName = useMemo(() => page ? `isni-modal-${page}` : 'isni-modal-search', [page]);

  const { canAssignISNI, canEditISNI } = usePermissions();

  const btn = useCallback<(title: string) => JSX.Element>(
    (title) => (
      <Button variant={EButtonVariants.link} className={btnClassName} disabled={disabled} onClick={onClickHandler}>
        <span>{title}</span>
        {name.privacyType === ENamePrivacyType.INTERNAL_TO_WMG && <Icon icon="eye-slash" />}
        <div className="arrow-append">
          <Icon icon="chevron-down" className={cn('btn--icon', { 'btn--icon--open': showModal } )} />
        </div>
      </Button>
    ), [toggleModal, btnClassName, disabled],
  );

  const isniDisabled = useCallback<(title: string) => JSX.Element | null>(
    (title) => {
      if (page === EIsniPageCameFrom.FORM) return (
        <span>
          <select className="form-control" placeholder={title} defaultValue={title} disabled>
            <option value={title} disabled>{title}</option>
          </select>
        </span>
      );
      if (!page || page === EIsniPageCameFrom.SEARCH) return btn(title);
      return null;
    },
  [disabled],
  );

  const handleRemoveIsni = useCallback(() => {
    removeIsni && removeIsni();
    setShowModal(false);
  }, [removeIsni, setShowModal]);

  const isniBtn = useMemo(
    () => {
      if (disabled) return isniDisabled(typeof currentIsni === 'undefined' ? t('search:assign_isni') : formatISNIStatic(currentIsni));
      if (typeof currentIsni === 'undefined' && canAssignISNI) return btn(t('search:assign_isni'));
      if (canEditISNI) return btn(formatISNIStatic(currentIsni));
      return formatISNIStatic(currentIsni);
    },
    [currentIsni, canAssignISNI, canEditISNI, disabled, showModal],
  );

  const getIsniTooltip = useMemo(
    () => (name.privacyType === ENamePrivacyType.INTERNAL_TO_WMG && page === EIsniPageCameFrom.SEARCH) ? t('tooltips:isni_cannot_be_selected_internal') : t('tooltips:isni'),
    [name.privacyType, page],
  );

  return <div className={className}>
    {page === EIsniPageCameFrom.FORM && (
      <label className='label' htmlFor="">
        <NewTooltip text={t('tooltips:isni')}>
          ISNI
        </NewTooltip>
      </label>
    )}
    {
      page !== EIsniPageCameFrom.FORM ? (
        <NewTooltip text={getIsniTooltip}>
          {isniBtn}
        </NewTooltip>
      ) : <div className='readMode'>
        {isniBtn}
      </div>
    }
    {disabled && (
      <Tooltip
        id={`isni-privacy-tip-${tooltipId}`}
        place="top"
        delayShow={100}
      >
        {t('search:internal_to_wmg')}
      </Tooltip>
    )}
    <div ref={ref}>
      <IsniLookupModal
        name={name}
        currentIsni={currentIsni}
        className={modalClassName}
        page={page}
        removeIsni={handleRemoveIsni}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </div>
  </div>;
};
