import React from 'react';
import styled from 'styled-components/macro';
import Banner from './Banner';
import { Icon } from '@grow-components/Icon/Icon';

const TimeoutBannerWrapper = styled(Banner)<{ alertColor: boolean }>`
  background-color: ${(props) =>
    props.alertColor
      ? props.theme.colors.alertBg
      : props.theme.colors.timeoutBg};
  color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) =>
  theme.spacing.medium};
  button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-right: ${({ theme }) => theme.spacing.medium};
  }
  i {
    margin-right: ${({ theme }) => theme.spacing.small};
  }
`;

interface Props {
  remainingTime: string;
  onClick: () => void;
  showAlertColor: boolean;
}

const TimeoutBanner: React.FC<Props> = ({
  remainingTime,
  onClick,
  showAlertColor,
}) => (
  <TimeoutBannerWrapper alertColor={showAlertColor}>
    <p>
      <Icon icon={'clock'} />
      You have {remainingTime} remaining to save your work before you are logged
      out
    </p>
    <button className="btn btn-default" onClick={onClick}>
      Stay Logged In
    </button>
  </TimeoutBannerWrapper>
);

export default TimeoutBanner;
