import { gql } from '@apollo/client';

export const GET_PARTY_ASSETS = gql`
  query getPartyAssets($uaid:Int!){
    getGCDMArtistInfo(data: {uaid: $uaid}){
      assetCount
      productCount
      artistCompanyData {
        companyTitle
        companyCode
        relationship
      }
    }
  }
`;

export type TGetPartyAssetsVars = {
  uaid: string
}

export type TGetPartyAssetsResponse = {
  getGCDMArtistInfo: {
    assetCount: number
    productCount: number
    artistCompanyData: {
      companyTitle: string
      relationship: string
    }[]
  }
}

