import { TIdentifier, TRelationship, TIsniInfo, TIsniPersonalInfo } from './types';
import { EIdentifierType } from 'modules/common/types/partyTypes';

export const keyIdentifiersByType = (identifiersArray: TIdentifier[]): Record<EIdentifierType, string> => {
  const identifiers = {} as Record<EIdentifierType, string>;
  identifiersArray.forEach((identifier: TIdentifier) => identifiers[identifier.type] = identifier.value);
  return identifiers;
};

export const formatRelationshipType = (type: string | undefined): string => {
  if (!type) return '';

  const capitalizedType = type[0].toUpperCase() + type.slice(1);
  const splitType = capitalizedType.match(/[A-Z][a-z]+|[0-9]+/g);

  if (!splitType) return capitalizedType;

  return splitType.join(' ');
};

export const mapRelationships = (relationships: TRelationship[]) => relationships.map(relationship => {
  if (!relationship.identifiers) return null;
  const isni = relationship.identifiers.find(identifier => identifier.type === EIdentifierType.ISNI);

  return {
    name: relationship.name,
    type: formatRelationshipType(relationship.relationshipType),
    isni: isni?.value || ''
  };
});

export const formatPersonalInfo = (isni: TIsniInfo): TIsniPersonalInfo | undefined => {
  if (!isni) return undefined;
  const { birthDate = null, deathDate = null, nationality = null } = isni;
  return {
    birthDate,
    deathDate,
    nationality,
  };
};