import { useCallback, useMemo } from 'react';
import { Decorator, FormApi } from 'final-form';
import { useTranslation } from 'react-i18next';
import { ToastService, toastGlobalConfig } from 'utils/ToastService';
import { Party } from 'modules/common/types/partyTypes';
import { TPartyName } from 'domains/party/types';
import createDecorator from '../../utils/submit-listener';
import { hasUUID, isPerformingArtist } from '../helpers';

// https://jira.wmg.com/browse/GP-2791

function indexById(names?: TPartyName[]): Record<string, string> {
  return (names || []).reduce((a, v) => v.nameId ? ({ ...a, [v.nameId]: v.nameValue }) : a, {});
}

export function useSubmitWarningToast<T extends Party = Party>() {

  const { t } = useTranslation();

  const afterSubmitSucceeded = useCallback<(api: FormApi<T>) => void>(
    ({ getState, reset }) => {
      const { initialValues, values } = getState();
      const initNameByIds = indexById(initialValues.names);
      const nameByIds = indexById(values.names);
      (initialValues.names || []).forEach(({ nameId, nameValue }) => {
        const current = nameId && nameByIds[nameId];
        if (typeof nameId !== 'undefined' && typeof current === 'undefined') {
          ToastService.warning(t('toasts:name_has_been_deleted', { name: nameValue }));

          // GP-4079 remove deleted names from initial form values
          reset({ ...initialValues, names: values.names.filter(name => name.nameId !== nameId) });
        }
      });
      (values.names || []).forEach((name) => {
        const prev = name.nameId && initNameByIds[name.nameId];

        if (typeof prev !== 'undefined' && prev !== name.nameValue && (hasUUID(name) || isPerformingArtist(name))) {
          ToastService.warning(t('toasts:name_has_changed', { name: name.nameValue }), { autoClose: toastGlobalConfig.autoClose });
        }
      });

    },
  [],
  );

  return useMemo<Decorator<T>>(
    () => createDecorator({ afterSubmitSucceeded }),
    [afterSubmitSucceeded],
  );
}
