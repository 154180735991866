export enum EStorageKeys {
  namePrivacy = 'namePrivacy'
}

export enum EDateTimeFormat {
  MM_DD_YYYY_Slash = 'MM/DD/YYYY',
  MM_DD_YYYY_Dash = 'MM-DD-YYYY',
  DD_MM_YYYY_Slash = 'DD/MM/YYYY',
  DD_MMM_YYYY_Dash = 'DD-MMM-YYYY',
  DD_MMM_YYYY_SpaceDash = 'DD - MMM - YYYY',
  YYYY_MM_DD_Dash = 'YYYY-MM-DD',
  H_MM_SS = 'h:mm:ss',
  HH_MM_A = 'hh:mm A',
  H_MM_SS_A = 'h:mm:ss A',
  H_MM = 'H:mm'
}

export enum EIsniPartyType {
  PERSON = 'Person',
  BAND = 'Band'
}
