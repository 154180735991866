import { useQuery } from '@apollo/client';
import { TPartyIds, TPartyImageResponse, TPartyImageVars } from './types';
import { PARTY_GET_IMAGE } from '../graphql/partyGetSpotifyImages';

export const usePartyImage = ({ partyId, partyUAID, nameId, isni, spotifyId }: TPartyIds) => {
  const { loading, data } = useQuery<TPartyImageResponse, TPartyImageVars>(PARTY_GET_IMAGE, {
    fetchPolicy: 'no-cache', //this is applied because when you save a party the current image is not updated
    variables: {
      data: {
        partyId,
        partyUAID,
        nameId,
        isni,
        spotifyId
      },
    }
  });

  return {
    loadingImages: loading,
    dataImages: data?.partyGetSpotifyImages.images
  };
};
