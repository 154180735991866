import React, { FC, useCallback, useEffect } from 'react';
import { TSelectOption } from 'common/types';
import { TOption } from '../StyledSelect';
import { cn } from 'utils';
import useDetectClickOutside from 'modules/common/hooks/useDetectClickOutside';
import { useArrowKeys } from 'modules/common/hooks/useArrowKeys';
import styles from './Dropdown.module.scss';

type TDropdownProps = {
  onSelect: (o: TOption) => void
  options: TOption[] & TSelectOption[]
  onClose: () => void
  onChangeTab: () => void
  value: TOption & TSelectOption
}
export const Dropdown: FC<TDropdownProps> = (props) => {
  const { options, onClose, onSelect, onChangeTab } = props;
  const ref = useDetectClickOutside(onClose);
  const { containerRef: listRef, activeOption, mouseOver } = useArrowKeys((o: any) => { onSelect(o);}, onChangeTab, options);
  
  const selectOptionHandler = useCallback((o: TOption) => () => { 
    if (o.value !== undefined) {
      onSelect(o);
      onClose();
    }
  }, [onSelect, onClose]);

  useEffect(() => {
    listRef?.current?.focus();
  }, []);

  return (
    <div ref={ref} className={styles.dropdown}>
      {options.length > 0 && (
        <ul ref={listRef as any} tabIndex={-1} className={styles.resultsList}>
          {options.map(option => {
            return (
              <li
                key={option.key}
                className={cn(styles.item, { [styles.isActive]: option.key === activeOption?.key && !mouseOver })}
                onClick={selectOptionHandler(option)}
              >
                <span>{option.label}</span>
                {
                  option.labelAddon && <span className={styles.addon}>{option.labelAddon}</span>
                }
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};