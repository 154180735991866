import React, { FC } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  to: string;
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
}

export const Button: FC<IProps> = ({ to, disabled, children, className }) => {
  return disabled ? (
    <button type='button' disabled={disabled} className={className}>{children}</button>
  ) : (
    <Link to={to} className={className}>{children}</Link>
  );
};
