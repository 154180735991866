import { gql } from '@apollo/client';

export const FILE_EXPORT_ADVANCED_SEARCH = gql`
    query FileExportAdvancedSearch(
        $operations: [AdvancedSearchOperation!]!
        $filetype: SEARCH_FILE_TYPE!
    ) {
        fileExportAdvancedSearch(data: {
            request: {
                operations: $operations
            }
            filetype: $filetype
        }) {
            url
            filename
        }
    }
`;
