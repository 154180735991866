import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DropDown, IRenderArguments, List } from 'modules/common/components/DropDown';
import styles from './export-btn.module.scss';
import { Icon } from '@grow-components/Icon/Icon';
import { EFileExportType } from 'domains/search/graphql';
import { Tooltip } from '@grow-components/Tooltip';

interface IExportBtnProps {
  disabled?: boolean;
  exportHandler: (fileType: EFileExportType) => void;
  buttonContent: JSX.Element;
  tooltip?: boolean;
  tooltipMessage?: string;
}

export const ExportBtn: FC<IExportBtnProps> = ({ disabled, exportHandler, buttonContent, tooltip, tooltipMessage }) => {

  const { t } = useTranslation();

  const EXPORT_OPTIONS = useMemo(() => [
    // TODO: switch to regular csv (and xls) icon version when fontawesome 6 pro is available
    { value: 'CSV', label: <>Export as CSV <Icon className={styles.optionIcon} icon='file-csv' /></> },
    { value: 'XLS', label: <>Export as XLS <Icon className={styles.optionIcon} icon='file-excel' /></> },
  ], [t]);

  const renderBtn = useCallback<(p: () => void ) => JSX.Element>((handleClick) =>(<button
    disabled={disabled}
    type="button"
    onClick={handleClick}
    className={styles.btn}
  >
    <div className={styles.buttonContent}>{buttonContent}</div>
  </button>), [buttonContent, disabled]);

  const header = useCallback<(p: IRenderArguments) => JSX.Element>(
    ({ updateStatus }) => ( tooltip ?
      <Tooltip text={tooltipMessage as string} place={'bottom'}>
        {renderBtn(() => updateStatus(f => !f))}
      </Tooltip> : <>
        {renderBtn(() => updateStatus(f => !f))}
      </>

    ),
  [t, disabled],
  );

  const item = useCallback(
    ({ value, label, updateStatus }) => {

      const handleClickExport = () => {
        updateStatus(false);
        exportHandler(value);
      };
      return (
        <div onClick={handleClickExport} className={styles.link}>{label}</div>
      );
    },[exportHandler],
  );

  const content = useCallback<(p: IRenderArguments) => JSX.Element>(
    ({ updateStatus }) => (
      <List
        options={EXPORT_OPTIONS}
        renderItem={(d) => item({ ...d, updateStatus })}
        className={styles.list}
      />
    ),
  [item],
  );

  return (
    <DropDown
      className={styles.container}
      renderHead={header}
      renderContent={content}
    />
  );
};
