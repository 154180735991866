import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SearchName } from 'modules/common/types/searchNameTypes';
import {
  NameSearchType,
  SearchCategory,
  SearchValueType,
  ISNIEligibilityType,
  ISearchNamesSort,
} from 'modules/common/types/searchTypes';

export interface SearchState {
  searchTerm: string;
  submittedSearchTerm: string | number;
  searchCategory: SearchCategory;
  submittedSearchCategory: SearchCategory;
  pageNum: number;
  pageSize: number;
  searchTriggered: boolean;
  runQuery: boolean;
  searchLoading: boolean;
  searchComplete: boolean;
  improperInput: boolean;
  results: SearchName[];
  totalCount: number;
  ISNIEligibleCount: number;
  ISNIIneligibleCount: number;
  displayedCount: number;
  ISNIEligibleFilter: string;
  improperInputMessage: string;
  error: string | null;
  sort?: ISearchNamesSort;
}

export const searchCategoryOptions: SearchCategory[] = [
  {
    id: 1,
    name: 'Name Begins With',
    value: SearchValueType.NAME,
    type: NameSearchType.BEGINS_WITH,
  },
  {
    id: 2,
    name: 'Name Contains',
    value: SearchValueType.NAME,
    type: NameSearchType.CONTAINS,
  },
  {
    id: 3,
    name: 'Name Exact Match',
    value: SearchValueType.NAME,
    type: NameSearchType.EXACT_MATCH,
  },
  { id: 4, name: 'ISNI', value: SearchValueType.ISNI },
  { id: 5, name: 'UAID', value: SearchValueType.UAID },
];

export const initialState = (init: Partial<SearchState> = {}): SearchState => ({
  searchTerm: '',
  submittedSearchTerm: '',
  searchCategory: searchCategoryOptions[0],
  submittedSearchCategory: searchCategoryOptions[0],
  pageNum: 1,
  pageSize: 25,
  searchTriggered: false,
  runQuery: false,
  searchLoading: false,
  searchComplete: false,
  improperInput: false,
  results: [],
  totalCount: 0,
  ISNIEligibleCount: 0,
  ISNIIneligibleCount: 0,
  displayedCount: 0,
  ISNIEligibleFilter: ISNIEligibilityType.ALL,
  improperInputMessage: '',
  error: null,
  ...init,
});

const searchSlice = createSlice({
  name: 'search',
  initialState: initialState(),
  reducers: {
    updateSearchTerm(state, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
    },
    updateSearchCategory(state, action: PayloadAction<SearchCategory>) {
      state.searchCategory = action.payload;
    },
    resetSearchTerm(state) {
      state.searchTerm = '';
    },
    updateSubmittedSearchCategory(
      state,
      action: PayloadAction<SearchCategory>
    ) {
      state.submittedSearchCategory = action.payload;
    },
    updateISNIEligibleFilter(state, action: { payload: string }) {
      state.ISNIEligibleFilter = action.payload;
      state.runQuery = true;
      state.pageNum = 1;
      switch (state.ISNIEligibleFilter) {
      case ISNIEligibilityType.ALL:
        state.displayedCount = state.totalCount;
        break;
      case ISNIEligibilityType.ISNI_ELIGIBLE:
        state.displayedCount = state.ISNIEligibleCount;
        break;
      case ISNIEligibilityType.ISNI_INELIGIBLE:
        state.displayedCount = state.ISNIIneligibleCount;
        break;
      }
    },
    updatePageSizeFilter(state, action: { payload: string }) {
      state.ISNIEligibleFilter = action.payload;
      state.runQuery = true;
      state.pageNum = 1;
      state.pageSize = parseInt(action.payload);
    },
    triggerSearch(
      state,
      action: {
        payload: {
          searchTerm: string | number;
          pageNum: number;
          pageSize: number;
          sort?: ISearchNamesSort,
        };
      }
    ) {
      state.searchTriggered = true;
      state.runQuery = true;
      state.error = null;
      state.submittedSearchTerm = action.payload.searchTerm;
      state.pageNum = action.payload.pageNum;
      state.totalCount = 0;
      state.ISNIEligibleCount = 0;
      state.ISNIIneligibleCount = 0;
      state.displayedCount = 0;
      state.improperInput = false;
      state.sort = action.payload.sort;
    },
    changeOrderId(
      state,
      action: {
        payload: ISearchNamesSort,
      }
    ) {
      state.searchTriggered = true;
      state.runQuery = true;
      state.error = null;
      state.improperInput = false;
      state.sort = action.payload;
      // state.
    },
    searchLoading(state) {
      state.searchLoading = true;
      state.runQuery = false;
      state.improperInput = false;
      state.searchComplete = false;
    },
    searchSuccess(
      state,
      action: {
        payload: {
          data: SearchName[];
          totalCount: number;
        };
      }
    ) {
      state.searchLoading = false;
      state.searchComplete = true;
      state.results = [...action.payload.data];
      state.totalCount = action.payload.totalCount;
    },
    searchFailed(state, action: { payload: string }) {
      state.searchLoading = false;
      state.searchComplete = true;
      state.error = action.payload;
      state.totalCount = 0;
      state.ISNIEligibleCount = 0;
      state.ISNIIneligibleCount = 0;
      state.displayedCount = 0;
    },
    triggerImproperInput(state, action: { payload: string }) {
      state.improperInput = true;
      state.results = [];
      state.improperInputMessage = action.payload;
      state.searchTriggered = true;
      state.searchComplete = true;
      state.totalCount = 0;
      state.ISNIEligibleCount = 0;
      state.ISNIIneligibleCount = 0;
      state.displayedCount = 0;
    },

    updateError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    resetSearch() {
      return { ...initialState() };
    },
  },
});

export const {
  updateSearchTerm,
  updateSearchCategory,
  resetSearchTerm,
  updateSubmittedSearchCategory,
  updateISNIEligibleFilter,
  updatePageSizeFilter,
  triggerSearch,
  triggerImproperInput,
  resetSearch,
  searchLoading,
  changeOrderId,
  searchSuccess,
  searchFailed,
  updateError,
} = searchSlice.actions;
export default searchSlice.reducer;
