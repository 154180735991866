import { gql } from '@apollo/client';

export const PARTY_FRAGMENTS = gql`
  fragment PartyName on NameEntity {
    nameId
    nameValue
    nameType
    privacyType
    isLegal
    majorGenreId
    minorGenreId
    nameCreatedBy
    nameCreatedAt
    nameUpdatedAt
    nameUpdatedBy
    translations {
      nameTransId
      name
      nameId
      languageVariantId
    }
    competencies {
      nameId
      competencyId
      nameCompetencyId
    }
    remarks {
      remarkId
      remarkType
      text
      lastModifiedBy
      lastModifiedAt
    }
    identifiers {
      id
      identifierType
      value
      createdBy
      updatedBy
    }
  }
`;

export const LOCK_FRAGMENTS = gql`
  fragment PartyLockFields on PartyLock {
    createdAt
    createdBy
    active
    partyId
    updatedAt
    updatedBy
  }
`;
