import React from 'react';
import { BasicSpinner } from '@grow-components/Spinners/BasicSpinner';
import { NavigationLink } from 'modules/common/types/routeTypes';
import Header from '../Header';
import Footer from '../Footer';
import { cn } from 'utils';
import styles from './layouy.module.scss';

interface Props {
  current?: string;
  navigationLinks?: NavigationLink[];
  loading?: boolean;
  contentClassName?: string
}
const Layout: React.FC<Props> = ({
  current,
  navigationLinks,
  loading,
  children,
  contentClassName
}) => {

  return (
    <>
      <Header
        current={current}
        navigationLinks={navigationLinks}
        loggedIn
      />
      <main className={cn('flex-grow', contentClassName)}>
        {children}
        {
          loading ? (
            <BasicSpinner className={styles.spinner} />
          ) : null
        }
      </main>
      <Footer />
    </>
  );
};

export default Layout;
