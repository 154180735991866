import React from 'react';
import { cssTransition, toast, ToastContent, ToastOptions } from 'react-toastify';
import ToastIcon from './ToastIcon';
import ToastCloseIcon from './ToastCloseIcon/ToastCloseIcon';

export enum EIconTypes {
  close = 'close',
  coffee = 'coffee',
  error = 'circle-exclamation',
  warning = 'triangle-exclamation',
  success = 'check',
  info = 'circle-info',
}

const fade = cssTransition({
  enter: 'fade-in',
  exit: 'fade-out'
});

export const toastGlobalConfig: ToastOptions = {
  transition: fade,
  theme: 'colored',
  position: 'top-right',
  autoClose: 3200,
  draggable: false,
  hideProgressBar: true,
  icon: ToastIcon,
  closeButton: ToastCloseIcon,
};
type TShowToastFn = (content: ToastContent, options?: ToastOptions) => void

interface IToastService {
  info: TShowToastFn
  success: TShowToastFn
  warning: TShowToastFn
  error: TShowToastFn
  dismiss(id: string | number): void
  dismissAll(): void
}

const getIcon = (type: EIconTypes) => (<ToastIcon iconName={type} />);

export const ToastService: IToastService = {
  success: (content, options) => {
    toast.success(content, {
      ...toastGlobalConfig,
      icon: getIcon(EIconTypes.success),
      ...options
    });
  },
  info: (content, options) => {
    toast.info(content, { ...toastGlobalConfig, icon: getIcon(EIconTypes.info), ...options });
  },
  warning: (content, options) => {
    toast.warn(content, { ...toastGlobalConfig, ...{ autoClose: false }, icon: getIcon(EIconTypes.warning), ...options });
  },
  error: (content, options) => {
    toast.error(content, { ...toastGlobalConfig, ...{ autoClose: false }, icon: getIcon(EIconTypes.error), ...options });
  },
  dismiss: toast.dismiss,
  dismissAll: () => toast.dismiss(),
};
