import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ArtistImage } from 'modules/common/components/ArtistImage/ArtistImage';
import { moment } from 'utils';
import { AssignIsniBtn } from 'modules/common/components/isniAssign/AssignIsniBtn';
import { isniTypeDependantLabels } from 'domains/party/utils';
import { TIsniInfo } from 'domains/isni/types';
import { useQueryParams } from 'modules/common/hooks/useQueryParams';
import { TIsniMoreInfoQueryParams } from 'modules/common/types/isniTypes';

import styles from './IsniGeneralInfo.module.scss';
import { changeDateDashToSlash } from 'modules/common/components/PartyHistory/helpers';
import { useSettings } from 'domains/env';
import { formatPersonalInfo } from 'domains/isni/utils';
import { usePartyImage } from 'domains/party/hooks/usePartyImage';

interface IIsniGeneralInfoProps {
  isniData: TIsniInfo,
  assignedToDifferentParty: boolean
}

export const IsniGeneralInfo: FC<IIsniGeneralInfoProps> = ({ isniData, assignedToDifferentParty }) => {
  if (!isniData) return null;

  const { t: tLabels } = useTranslation('party_labels');
  const { t } = useTranslation();
  const { params } = useQueryParams<TIsniMoreInfoQueryParams>();
  const { birthDate, deathDate, type, name, isni, identifiers, identifiers: { ISNI }, comment, countryOfOrigin } = isniData;
  const { dataImages } = usePartyImage({ isni });
  const { settings } = useSettings();

  const age = useMemo(() => {
    const parsedBirthDate = moment(birthDate);
    if (!deathDate) return `(${parsedBirthDate.toNow(true)} ago)`;

    const parsedDeathDate = moment(deathDate);
    return `(aged ${parsedDeathDate.diff(parsedBirthDate, 'years')})`;
  }, [birthDate, deathDate, settings]);

  const labels = useMemo(() => isniTypeDependantLabels(type), [type]);

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.artistAvatarWrapper}>
        <ArtistImage
          images={dataImages}
          artistName={name}
          className={styles.artistAvatar}
          isCentred
          isRound
          expandable />
        <AssignIsniBtn
          isni={isni}
          ids={identifiers}
          isniComment={comment}
          partyName={params.partyName}
          partyId={params.partyId}
          notAssignable={assignedToDifferentParty}
          infoOkText='Return to Edit Page'
          personalInfo={formatPersonalInfo(isniData)}
          buttonMessage={tLabels('assign_isni')}
          buttonClassName={styles.button}
          assignFromDetails
        />
      </div>
      <div className={styles.data}>
        <div className={styles.item}>
          <div className={styles.label}>{tLabels('party_type')}</div>
          <div className={styles.values}>{type || t('common:no_data')}</div>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>{tLabels(labels.dateStart)}</div>
          <div className={styles.values}>{changeDateDashToSlash(birthDate, settings?.dateFormat?.toUpperCase() || 'MM/DD/YYYY') || t('common:no_data')} {birthDate && !deathDate && age}</div>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>{tLabels(labels.dateEnd)}</div>
          <div className={styles.values}>{changeDateDashToSlash(deathDate, settings?.dateFormat?.toUpperCase() || 'MM/DD/YYYY') || t('common:no_data')} {type === 'person' && age}</div>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>{tLabels('origin_country')}</div>
          <div className={styles.values}>{countryOfOrigin || t('common:no_data')}</div>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>ISNI</div>
          <div className={styles.values}>{ISNI || t('common:no_data')}</div>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>Comments</div>
          <div className={styles.values}>{comment || t('common:no_data')}</div>
        </div>
      </div>
    </div>
  );
};
