import { gql } from '@apollo/client';

export const GET_SETTINGS = gql`
  query settingsGet {
      settingsGet {
          language
          lastUsedCountryOfOrigin
          lastUsedLanguageOfOrigin
          timeFormat
          dateFormat
      }
  }
`;
