import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@grow-components/Tooltip';
import { Icon } from '@grow-components/Icon/Icon';
import styles from './isni-credit.module.scss';
import { cn } from 'utils';

// TODO: replace with generic <SourceCredit /> component
export const IsniCredit = ({ bold }: { bold?: boolean }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Tooltip text={t('tooltips:source_quansic_screen_text')} place="bottom" className={cn(styles.content, {
        [styles.bold]: bold
      })}>
        <Icon icon="circle-info" className={styles.icon} />
        Info: Quansic
      </Tooltip>
    </div>
  );
};
