import { gql } from '@apollo/client';

export const ADD_ISNI = gql`
  mutation AddISNI($data: NameAssignIdentifierInput!) {
    nameAssignIdentifier(data: $data)
  }
`;

export const UPDATE_ISNI = gql`
  mutation UpdateISNI($data: NameUpdateIdentifierInput!) {
    nameUpdateIdentifier(data: $data)
  }
`;

export const DELETE_ISNI = gql`
  mutation DeleteISNI($data: NameDeleteIdentifierInput!) {
    nameRemoveIdentifier(data: $data)
  }
`;

export const PROCURE_ISNI = gql`
  mutation ProcureISNI($data: nameRequestIdentifierInput!) {
    nameRequestIdentifier(data: $data)
  }
`;

export const PROCURE_ISNI_SUPERUSER = gql`
  mutation ProcureISNISuperuser($data: IdentifierProcureInput!) {
    identifierProcure(data: $data) {
      status
      message
      party {
        isni
        name {
          firstname
          lastname
        }
        identifiers {
          type
          value
        }
        comment
      }
      internalParty {
        nameValue
        partyId
        nameId
        partyName
      }
    }
  }
`;

export const REASSIGN_IDS = gql`
  mutation NameReAssignIdentifiers($data: NameReAssignIdentifiersInput!) {
    nameReAssignIdentifiers(data: $data) 
}`;

export const DELETE_IDS = gql`
  mutation NameRemoveAllIdentifiers($data: NameRemoveAllIdentifiersInput!) {
    nameRemoveAllIdentifiers(data: $data)
}`;

