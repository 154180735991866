import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { toNumber } from 'utils';

const LINE_PER_PAGE = 20;

export const useQuery = (totalCount: number) => {
  const { location, createHref } = useHistory();

  const { href, isShow } = useMemo(
    () => {
      const params = new URLSearchParams(location.search);
      const size = toNumber(params.get('size'), LINE_PER_PAGE);
      params.set('size', (size + LINE_PER_PAGE).toString());
      return {
        href: createHref({
          pathname: location.pathname,
          search: params.toString(),
        }),
        isShow: size < totalCount,
      };
    },
    [location, createHref, totalCount],
  );

  return {
    href,
    isShow,
  };
};
