import { ESearchType } from 'domains/search/graphql';

export type SearchCategory = SearchCategoryType | NameSearchCategoryType;

export type SearchCategoryType = {
  id: number;
  name: string;
  value: SearchValueType;
  type?: NameSearchType;
};

export type NameSearchCategoryType = SearchCategoryType & {
  type: NameSearchType;
};

export enum NameSearchType {
  CONTAINS = 'CONTAINS',
  BEGINS_WITH = 'BEGINS_WITH',
  EXACT_MATCH = 'EXACT_MATCH',
}

export enum SearchValueType {
  NAME = 'name',
  ISNI = 'isni',
  UAID = 'uaid',
}

export enum ISNIEligibilityType {
  ALL = 'ALL',
  ISNI_ELIGIBLE = 'ISNI_ELIGIBLE',
  ISNI_INELIGIBLE = 'ISNI_INELIGIBLE',
}

export enum ESortType {
  COUNTRY_OF_ORIGIN = 'COUNTRY_OF_ORIGIN',
  INTERNAL_COMMENTS = 'INTERNAL_COMMENTS',
  ISNI = 'ISNI',
  LEGAL_NAME = 'LEGAL_NAME',
  NAME_TYPE = 'NAME_TYPE',
  NAME_VALUE = 'NAME_VALUE',
  PARTY_TYPE = 'PARTY_TYPE',
}

export interface ISearchNamesSort {
  desc: boolean;
  type: ESortType;
}

export type SearchNamesFilterType = {
  searchType: ESearchType
  searchTerm: string;
  skip: number;
  size: number;
  sort?: ISearchNamesSort;
};

export type ArtistSearchFilterType = {
  pageIndex: number;
  pageSize: number;
  isIsniEligible?: boolean;
};
