import React, { FC } from 'react';
import { cn } from 'utils';
import { WarningIcon } from '@grow-components/Icon/IconWithTooltip';
import styles from './label.module.scss';

interface IProps {
  children: JSX.Element;
  label: string | JSX.Element;
  className?: string;
  error?: string;
}

export const Label: FC<IProps> = ({ children, label, className, error }) => {
  return (
    <label className={cn(styles.container, className)}>
      <div data-element="label" className={styles.label}>{label}{error ? (<WarningIcon text={error} className={styles.warning} />) : null}</div>
      {children}
    </label>
  );
};
