import React, { FC } from 'react';
import { usePartyImage } from 'domains/party/hooks/usePartyImage';
import { ArtistImage } from 'modules/common/components/ArtistImage';

import styles from './assets-block.module.scss';

interface IAssetsBlockProps {
    artistName?: string;
    assetCount: number;
    productCount: number;
    partyUAID: string;
}

export const AssetsBlock: FC<IAssetsBlockProps> = ({
  artistName,
  assetCount,
  productCount,
  partyUAID
}) => {
  const { dataImages } = usePartyImage({ partyUAID: Number(partyUAID) });
  return (
    <div className={styles.wrapper}>
      <ArtistImage
        artistName={artistName}
        isRound
        images={dataImages}
        className={styles.image} />
      <div className={styles.countWrapper}>
        <span className={styles.label}>GCDM Products</span>
        <span className={styles.count}>{productCount}</span>
        <span className={styles.label}>GCDM Assets</span>
        <span className={styles.count}>{assetCount}</span>
      </div>
    </div>
  );
};