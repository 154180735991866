import React, { FC } from 'react';
import { Field } from 'react-final-form';

interface IProps {
  when: string;
  is: string;
  children: React.ReactNode;
}

export const Condition: FC<IProps>  = ({ when, is, children }) => {
  return (
    <Field name={when} subscription={{ value: true }}>
      {
        ({ input: { value } }) => (value === is ? children : null )
      }
    </Field>
  );
};
