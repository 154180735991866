import React, { FC } from 'react';

import styles from './full-screen-message.module.scss';

interface IFullScreenMessageProps {
    thumbnail?: JSX.Element;
    message: string;
    details?: string;
}

export const FullScreenMessage: FC<IFullScreenMessageProps> = ({ thumbnail, message, details }) => (
  <div className={styles.wrapper}>
    {thumbnail && <div className={styles.thumbnail}>{thumbnail}</div>}
    <span className={styles.message}>{message}</span>
    {details && <span className={styles.details}>{details}</span>}
  </div>
);
