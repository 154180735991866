import React, { useCallback } from 'react';
import { Tooltip } from '@grow-components/Tooltip';
import { HistoryTooltip } from './HistoryTooltip';
import { THistoryGroup } from './types';
import { useSettings } from 'domains/env';

export const useHistoryElement = (group: THistoryGroup | undefined) => {
  const { settings } = useSettings();
  
  const label = useCallback(
    ({ tooltip, label, ...rest }) => (
      <>
        <Tooltip text={tooltip}>{label}</Tooltip>
        <HistoryTooltip data={group} settings={settings} {...rest} />
      </>
    ),
    [group, settings],
  );
  return {
    label
  };
};
