import React, { FC, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { createViewPartyPageLink } from 'utils';
import { useGetPartyByUAID } from 'domains/party/hooks/useGetPartyByUAID';
import { FullScreenSpinner } from '@grow-components/Spinners';

interface IPartyUAIDParams {
  uaid: string;
}

export const PartyUAIDPage: FC = () => {
  const history = useHistory();
  const { uaid } = useParams<IPartyUAIDParams>();
  const { party, loading } = useGetPartyByUAID(uaid);

  useEffect(() => {
    if (loading) return;

    if (!party) {
      history.push('/not-found');
    } else {
      const { partyId } = party;
      history.push(createViewPartyPageLink(partyId));
    }

  }, [party, loading]);

  return <FullScreenSpinner />;
};