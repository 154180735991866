import { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_SETTINGS } from '../graphql';
import { SAVE_SETTINGS } from '../graphql/saveSettings';
import { ISettings, ELanguage } from '../types';
import { EDateTimeFormat } from 'common/consts';

export const useSettings = () => {

  const { data, refetch } = useQuery<{ settingsGet: ISettings }>(GET_SETTINGS);

  const [saveSettings] = useMutation<{ settingsUpdate: ISettings }, { data: ISettings }>(SAVE_SETTINGS, {
    update(cache, { data }) {
      const prev = cache.readQuery<{ settingsGet: ISettings }>({ query: GET_SETTINGS });
      cache.writeQuery({
        query: GET_SETTINGS,
        data: {
          settingsGet: {
            ...prev?.settingsGet,
            language: data?.settingsUpdate.language,
            timeFormat: data?.settingsUpdate.timeFormat,
            dateFormat: data?.settingsUpdate.dateFormat
          },
        }
      });
    },
  });

  const updateSettings = useCallback<(language: ELanguage, timeFormat: EDateTimeFormat, dateFormat: EDateTimeFormat) => Promise<ISettings | undefined>>(
    async (language, timeFormat, dateFormat) => {
      try {
        const { data } = await saveSettings({ variables: { data: { language, timeFormat, dateFormat } } });
        return data?.settingsUpdate;
      } catch (err) {
        console.warn(err);
      }
    },
  [saveSettings],
  );
  
  return {
    settings: data?.settingsGet,
    refetch,
    updateSettings
  };
};
