import React, { FC, useCallback } from 'react';
import { Trans } from 'react-i18next';
import { Pill } from '@grow-components/Pill';
import { TParamsEntry, useQuery } from './useQuery';
import styles from './pill-list.module.scss';
import { ddMMToMMddFormat } from 'modules/common/components/PartyHistory/helpers';
import { useSettings } from 'domains/env';
import { useDictionaries } from 'modules/common/hooks/useDictionaries';
import { BasicSpinner } from '@grow-components/Spinners';
import { Redirect, useHistory } from 'react-router-dom';

interface IProps {
  dict?: ReadonlyArray<Record<string, string>>;
}

export const PillsList: FC<IProps> = () => {
  const { list, handleRemove } = useQuery();
  const { settings } = useSettings();
  const { location } = useHistory();
  const { getCountry, loading: loadingDictionaries } = useDictionaries();

  const transformValue = useCallback((key: string, value: string, format: string | undefined): { value: string } => {
    if (key === 'partyCountryOfOriginId') {
      return {
        value: getCountry(+value as number)?.name as string
      };
    }
    if (key === 'nameType' && value === 'AKA,FKA') {
      return {
        value: 'AKA + FKA'
      };
    }
    if (key === 'legalName' && value === 'true') {
      return {
        value: 'Legal Name'
      };
    }
    if (key === 'legalName' && value === 'false') {
      return {
        value: 'Non-Legal Name'
      };
    }
    if (key === 'createdBy' || key === 'modifiedBy') {
      return {
        value,
      };
    }

    if (key === 'createdBy' || key === 'modifiedBy') {
      return {
        value,
      };
    }

    if(key.includes('_BETWEEN')){
      const formatValue = value.split(' - ');
      return {
        value: `${ddMMToMMddFormat(formatValue[0], format)} - ${ddMMToMMddFormat(formatValue[1], format)}`
      };
    }

    if(key.includes('created_') || key.includes('modified_')){
      return {
        value: ddMMToMMddFormat(value, format)
      };
    }

    return {
      value
    };
  },[getCountry, loadingDictionaries]);

  const getLabel = useCallback<(d: TParamsEntry) => JSX.Element>(
    ([key, value]) => (
      <Trans
        i18nKey={key}
        ns="search_query"
        values={transformValue(key, value, settings?.dateFormat || 'MM/DD/YYYY')}
        components={{ bold: <b /> }}
        tOptions={{ interpolation: { escapeValue: false } }}
      />
    ),
  [transformValue, settings]
  );

  if (list.length === 0) {
    return (
      <Redirect to={location.pathname} />
    );
  }

  return (
    <ul className={styles.list}>
      {
        list.map(el => (
          <li key={el[0]} className={styles.item}>
            <Pill id={el[0].toLowerCase()} label={loadingDictionaries ? <BasicSpinner className={styles.spinner} /> : getLabel(el)} onAction={() => handleRemove(el)} />
          </li>
        ))
      }
    </ul>
  );
};
