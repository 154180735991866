import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { cn, millisToMinutesAndSeconds, timeType } from 'utils';
import { Icon } from '@grow-components/Icon/Icon';
import { Tooltip } from '@grow-components/Tooltip';

import styles from './expandable-datatable.module.scss';

interface IExpandableDataTableProps {
  title: string;
  titleTooltip?: string;
  labels: {
    key: string
    label: string
  }[];
  data: any[];
  evenCellsWidth?: boolean;
  initialItemsNumber?: number;
  sticky?: boolean;
  noSortable?: boolean;
}
interface ISortTypes {
  field: string,
  order: 'ASC' | 'DESC',
}

const DEFAULT_ITEMS_NUMBER = 3;
const MAX_ITEMS_NUMBER = 100;

export const ExpandableDataTable: FC<IExpandableDataTableProps> = ({
  title,
  titleTooltip,
  labels,
  data,
  evenCellsWidth,
  initialItemsNumber,
  sticky,
  noSortable
}) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = useCallback(() => setExpanded(!expanded), [expanded]);
  const [sortableData, setSortableData] = useState([] as any[]);
  const sort = useRef<ISortTypes>({
    order: 'ASC',
    field: ''
  });

  const getOrderedData = useCallback(() => {
    const arrayForSort = [...sortableData];
    if (sort.current.order === 'ASC') {
      setSortableData(arrayForSort.sort((a: any, b: any) => {
        if (!a[sort.current.field]) return 1;
        return a[sort.current.field] > b[sort.current.field] ? 1 : -1;
      })); 
    }
    if (sort.current.order === 'DESC') {
      setSortableData(arrayForSort.sort((a: any, b: any) => {
        if (!a[sort.current.field]) return 1;
        return a[sort.current.field] < b[sort.current.field] ? 1 : -1;
      })); 
    }
  }, [sort.current, sortableData, setSortableData]);

  const headerClickHandler = useCallback((key: string) => {
    if (noSortable) return;
    
    if (sort.current.field === key) {
      sort.current.order = sort.current.order === 'DESC' ? 'ASC' : 'DESC';
    } else {
      sort.current = {
        field: key,
        order: 'ASC'
      };
    }
    getOrderedData();
  }, [sort.current, noSortable, sortableData]);

  useEffect(() => {
    if (data) {
      setSortableData(data as []);
    }
  }, [data]);

  const checkColumnSpace = (labelCount: number, index: number) => {
    if(labelCount > 3)
      return 'col-md-2';
    if(labelCount - 1 === index)
      return labelCount === 3 ? 'col-md-2' : 'col-md-3';
    return 'col-md-1';
  };

  const renderData = useCallback(() => {
    const itemsNumber = initialItemsNumber || DEFAULT_ITEMS_NUMBER;
    const trimmedData = expanded ? sortableData.slice(0, MAX_ITEMS_NUMBER) : sortableData.slice(0, itemsNumber);

    return trimmedData.map((item, index) => <tr key={index}>
      {labels.map((labelItem, labelIndex) =>
        <td className={cn(styles.cell, checkColumnSpace(labels.length, labelIndex))} key={labelItem.key}>
          {timeType.includes(labelItem.key) ? millisToMinutesAndSeconds(item[labelItem.key]) : item[labelItem.key]}
        </td>)}
    </tr>);
  }, [sortableData, expanded]);

  const sortButton = useCallback(
    (d, label) => (
      <button
        type="button"
        className={cn(styles.button, {
          [styles.desc]: d === 'ASC',
          [styles.active]: (d === sort.current.order && label === sort.current.field)
        })}
      />),
    [sort.current.order, sort.current.field],
  );


  return <div className={styles.wrapper}>
    <div className={styles.header}>
      {titleTooltip ?
        <Tooltip text={titleTooltip}><h4 className={styles.title}>{title}</h4></Tooltip>
        : <h4 className={styles.title}>{title}</h4>
      }
      {data.length > 2 && <span onClick={toggleExpanded} className={styles.expand}>
        View {data.length > MAX_ITEMS_NUMBER ? MAX_ITEMS_NUMBER : data.length} <Icon className={styles.icon} icon={expanded ? 'chevron-up' : 'chevron-down'} />
      </span>}
    </div>
    <table className={styles.table}>
      <thead className={cn({ [styles.sticky]: sticky })}>
        <tr>
          {labels.map((label, index) =>
            <th 
              className={cn(styles.label, !evenCellsWidth && checkColumnSpace(labels.length, index))} 
              key={label.key} 
              onClick={() => headerClickHandler(label.key)}
            >
              <div className={cn(styles.cellCol, { [styles.noSortable]: noSortable })}>
                <span>{label.label}</span>
                {!noSortable && (<div className={styles.btnGroup}>
                  {sortButton('ASC', label.key)}
                  {sortButton('DESC', label.key)}
                </div>)}  
              </div>
            </th>)}
        </tr>
      </thead>
      <tbody>{renderData()}</tbody>
    </table>
  </div>;
};
