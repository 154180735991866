import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStoredParty, PartyState } from './types';

const initialState: PartyState = {
  createdParties: {},
};

const partySlice = createSlice({
  name: 'party',
  initialState,
  reducers: {
    addPartyToList(state, { payload }: PayloadAction<IStoredParty>) {
      return {
        ...state,
        createdParties: { ...state.createdParties, [payload.id]: payload },
      };
    },
  },
});

export const { addPartyToList } = partySlice.actions;

export default partySlice.reducer;
