import React, { FC } from 'react';
import { Icon } from '@grow-components/Icon/Icon';
import { WarningIcon } from '@grow-components/Icon/IconWithTooltip';
import { BasicSpinner } from '@grow-components/Spinners';
import { ESpinnerSize } from '@grow-components/Spinners/consts';
import { Tooltip } from '@grow-components/Tooltip';
import { cn } from 'utils';
import commonStyles from '@grow-components/Form/CommonStyles.module.scss';
type TLabelProps = {
  label: string | JSX.Element
  required?: boolean
  loading?: boolean
  labelTooltip?: string
  error?: string
}
export const Label: FC<TLabelProps> = ({ labelTooltip, loading, label, required, error }) => {
  return (
    <label className={cn('control-label', commonStyles.inputLabel)}>
      {labelTooltip ? (
        <Tooltip text={labelTooltip}>{label}</Tooltip>
      ) : (
        <span>{label}</span>
      )}
      {required && <Icon icon="asterisk" className={commonStyles.iconInLabel} />}
      {loading && <BasicSpinner size={ESpinnerSize.SMALL} className={commonStyles.labelSpinner} />}
      {!loading && error && (<WarningIcon text={error} className={commonStyles.iconInLabel} />)}
    </label>
  );
};
