import { ISearchHistoryItem, SearchNamesResult } from 'graphql/types';
import { EPartyType } from 'modules/common/types/partyTypes';
import { ESuggestionType } from './SuggestionItem';
import { IStoredItem } from '../types';

export function listAdapter<T, K>(adapter: (d: K, i: number) => T) {
  return (list: ReadonlyArray<K>) => list.map(adapter);
}

export function recentlySearchedAdapter(d: ISearchHistoryItem, i: number) {
  return {
    partyId: i,
    partyType: EPartyType.PERSON,
    type: ESuggestionType.STORED,
    title: d.searchTerm,
    description: '',
  };
}

export function suggestionSearchAdapter(suggestion: SearchNamesResult) {
  return {
    partyId: suggestion.partyId,
    partyType: suggestion.partyType,
    title: suggestion.nameValue,
    description: suggestion.partyCountryOfOriginName ?? '',
    type: suggestion.partyType === EPartyType.PERSON ? ESuggestionType.PERSON : ESuggestionType.GROUP,
  };
}

export function storedListAdapter(d: IStoredItem, i: number) {
  return {
    partyId: i,
    partyType: EPartyType.PERSON,
    type: ESuggestionType.STORED,
    title: d.term,
    description: '',
  };
}

export function historyToStorage(date: number): (d: ISearchHistoryItem) => IStoredItem {
  return (d: ISearchHistoryItem) => ({
    date,
    term: d.searchTerm,
  });
}
