import { Button, EButtonVariants } from '@grow-components/Button';
import { GenericModal } from '@grow-components/GenericModal/GenericModal';
import { Icon } from '@grow-components/Icon/Icon';
import { BasicSpinner } from '@grow-components/Spinners';
import { ESpinnerSize } from '@grow-components/Spinners/consts';
import { useAssignIsni } from 'domains/isni/hooks/useAssignISNI';
import { formatISNIStatic } from 'modules/common/helpers/formatISNI';
import { BROADCAST_CHANNELS, useBroadcastChannel } from 'modules/common/hooks/useBroadcastChannel';
import { useQueryParams } from 'modules/common/hooks/useQueryParams';
import {
  EIsniPageCameFrom,
  IRequestIsniResponse,
  TIsniMoreInfoQueryParams,
  TIsniQueryParams, TIsniSearchParams
} from 'modules/common/types/isniTypes';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { cn } from 'utils';
import styles from './RequestIsniResultModal.module.scss';
import { formatPersonalInfo } from 'domains/isni/utils';
import { TPartyEntryDetail } from 'domains/party/types';
import { reformatIsni } from 'modules/common/components/isniAssign/helpers';
import { Party } from 'modules/common/types/partyTypes';
import { useSaveParty } from 'domains/party/hooks';
import { useDictionaries } from 'modules/common/hooks/useDictionaries';
import { ToastService } from 'utils/ToastService';
import { usePartyLock } from 'domains/party/hooks/usePartyLock';
import { useParams } from 'react-router-dom';

type TRequestIsniResultModalProps = {
  partyId: number;
  name: string;
  data: IRequestIsniResponse['identifierProcure'];
  previous: () => void;
  requestFromDetails?: boolean;
  partyData?: TPartyEntryDetail
}
export const RequestIsniResultModal: FC<TRequestIsniResultModalProps> = ({ partyId, name, data, previous, requestFromDetails, partyData }) => {
  const { t } = useTranslation();
  const { params: { cameFrom } } = useQueryParams<TIsniQueryParams>();
  const { nameId } = useParams<TIsniSearchParams>();
  const { createURL: isniMoreInfoUrl } = useQueryParams<TIsniMoreInfoQueryParams>();
  const { status, party, internalParty } = data;
  const isni = party?.isni ?? '';
  const isniComment = party?.comment ?? '';
  const entityName = `${party?.name?.firstname ?? ''} ${party?.name?.lastname ?? ''}`.trim(); 
  const identifiers = party?.identifiers ?? [];
  const { savePartyHandler, loading } = useSaveParty(partyId);
  const { lockParty } = usePartyLock(partyId);
  const { getCountryByISO } = useDictionaries();
  const { sendTMessage } = useBroadcastChannel(BROADCAST_CHANNELS.ISNI);
  const personalInfo = formatPersonalInfo(party);
  const succeeded = status === 201 && !internalParty;
  const isniFound = status === 200 || !!internalParty;
  const onAssignedIsni = useCallback(async () => {
    if (succeeded) return;
    try {
      if ((status === 200 || internalParty?.partyId === partyId) && cameFrom === EIsniPageCameFrom.SEARCH) {
        const partyValues = reformatIsni(internalParty?.nameValue, {
          partyData: partyData as TPartyEntryDetail,
          isniComment,
          ids: identifiers.map(item => ({ identifierType: item.type, value: item.value })) as [],
        }, getCountryByISO);

        await savePartyHandler(partyValues as Party);
      }

      if (requestFromDetails) sendTMessage.assignedISNI();
      window.close();
    } catch(err: any) {
      ToastService.error(t(err.message));
      await lockParty();
      return err;
    }
  }, [succeeded]);
  
  const mapIdentifiers = useCallback(() => {
    return identifiers.map(({ type,value })=>({ identifierType:type.toUpperCase(), value }));
  }, [identifiers]);

  const { assignIsni, loading: isAssignIsniLoading } = useAssignIsni(isni, mapIdentifiers(), isniComment, personalInfo, onAssignedIsni);

  useEffect(()=>{
    if(succeeded) {
      assignIsni();
      return;
    }
  },[succeeded]);

  const onGoBack = useCallback(() => {
    if (requestFromDetails) sendTMessage.assignedISNI();
    window.close();
  }, []);

  const backBtn = useMemo(() => {
    let backBtnKey;
    switch (cameFrom) {
    case EIsniPageCameFrom.DETAILS:
      backBtnKey = 'back_to_detail';
      break;
    case EIsniPageCameFrom.FORM:
      backBtnKey = 'back_to_form';
      break;
    case EIsniPageCameFrom.SEARCH: default:
      backBtnKey = 'back_to_search';
      break;
    }
    return <Button
      text={t(backBtnKey)}
      variant={EButtonVariants.info}
      onClick={onGoBack}
    />;
  }, [cameFrom]);

  const previousBtn = useMemo(() =><Button
    text={t('close')}
    variant={EButtonVariants.default}
    onClick={previous}
  />,[]);

  const assignBtn = useMemo(() => <Button
    text={t('confirm')}
    variant={EButtonVariants.info}
    onClick={assignIsni}
    disabled={isAssignIsniLoading || loading}
  />,[assignIsni, isAssignIsniLoading]);

  const seePartyBtn = useMemo(() => { 
    const seeParty = () => {
      window.open(`/party/${internalParty?.partyId}`);
    };

    return <Button
      text={t('see_party')}
      icon='arrow-up-right-from-square'
      variant={EButtonVariants.default}
      onClick={seeParty}
      className={styles.moreInfoBtn} />;
  },[assignIsni, isAssignIsniLoading, isni, internalParty]);

  const moreInfoBtn = useMemo(() => { 
    const goToMoreInfo = () => {
      const queryParams = {
        cameFrom,
        partyId,
        partyName: name
      };
      window.open(isniMoreInfoUrl(queryParams, `/isni-more-info/${nameId}/${isni}`, window.location.hash));
    };

    return <Button
      text={t('search:more_info')}
      icon='arrow-up-right-from-square'
      variant={EButtonVariants.default}
      onClick={goToMoreInfo}
      className={styles.moreInfoBtn} />;
  },[assignIsni, isAssignIsniLoading, isni, internalParty]);


  const modalStatus = () => {
    
    if (succeeded) return {
      buttons: (<>
        {backBtn}
      </>),
      icon: <Icon icon='circle-check' className={cn(styles.icon, styles.success)} />,
      message: <div className={styles.message}><Trans 
        i18nKey='modal:request_isni_result_success' 
        values={{ isni: formatISNIStatic(isni), name }} 
        components={{ bold: <b/>, br: <><br/><br/></> }}/></div>
    };
    if (isniFound) {
      if (partyId !== internalParty?.partyId) {
        return {
          buttons: (<>
            {previousBtn}
            {seePartyBtn}
            {moreInfoBtn}
          </>),
          icon: <Icon icon='triangle-exclamation' className={cn(styles.icon, styles.warning)} />,
          message: <div className={cn(styles.message, styles.isniFound)}><Trans 
            i18nKey='modal:request_isni_result_isni_found_elsewhere' 
            values={{ isni: formatISNIStatic(isni), entityName: entityName.length ? entityName : internalParty?.nameValue, internalPartyName: internalParty?.partyName, name }} 
            components={{ bold: <b/>, br: <><br/><br/></> }}/></div>
        };
      }
      else return {
        buttons: (<>
          {previousBtn}
          {assignBtn}
          {moreInfoBtn}
        </>),
        icon: <Icon icon='triangle-exclamation' className={cn(styles.icon, styles.warning)} />,
        message: <div className={cn(styles.message, styles.isniFound)}><Trans 
          i18nKey='modal:request_isni_result_isni_found' 
          values={{ isni: formatISNIStatic(isni), entityName: entityName.length ? entityName : internalParty?.nameValue, name }} 
          components={{ bold: <b/>, br: <><br/><br/></> }}/></div>
      };
    }
    if (status === 409) return {
      buttons: (<>
        {previousBtn}
      </>),
      icon: <Icon icon='triangle-exclamation' className={cn(styles.icon, styles.error)} />,
      message: <div className={styles.message}><Trans i18nKey='modal:request_isni_result_error_409'/></div>
    };
    if (status.toString().startsWith('4')) return {
      buttons: (<>
        {previousBtn}
      </>),
      icon: <Icon icon='triangle-exclamation' className={cn(styles.icon, styles.error)} />,
      message: <div className={styles.message}><Trans 
        i18nKey='modal:request_isni_result_error_4xx' 
        values={{ name }} 
        components={{ bold: <b/> }}/></div>
    };
    return {
      buttons: (<>
        {previousBtn}
      </>),
      icon: <Icon icon='triangle-exclamation' className={cn(styles.icon, styles.error)} />,
      message: <div className={styles.message}><Trans i18nKey='modal:request_isni_result_error'/></div>
    };
  };

  const { buttons, icon, message } = modalStatus();

  return <GenericModal
    name="request_isni_result_modal"
    title={t('request_isni')}
    footer={
      <div className={styles.actions}>
        {buttons}
      </div>
    }
    onClose={previous}
  >
    {isAssignIsniLoading || loading ? (
      <BasicSpinner size={ESpinnerSize.MEDIUM} />
    ) : (
      <div className={styles.wrapper}>
        {icon}
        
        {message}
      </div>
    )}
  </GenericModal>;
};
