import React, { FC, useCallback, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import { Button } from '@grow-components/Button';
import { Dialog, EDialogType } from '@grow-components/Popup';
import { TNameDuplicationResult } from 'graphql/queries';
import { usePermissions } from 'modules/common/hooks/usePersmissions';
import { NameDuplicatesTable } from '../../NameDuplicatesTable';
import { reducer, initialState, EActionType } from './reducer';

interface IProps {
  data: TNameDuplicationResult[];
  namePath: string;
  isShow: boolean;
}

export const ExactMatchResult: FC<IProps> = ({ isShow, data, namePath }) => {

  const { t } = useTranslation();
  const { resetFieldState, change, batch } = useForm();
  const { canDuplicateName } = usePermissions();
  const [state, dispatch] = useReducer(reducer, initialState);

  const setInputFocus = useCallback(
    () => { document.querySelector<HTMLInputElement>(`input[name="${namePath}.nameValue"]`)?.focus(); },
    [namePath],
  );

  const handleReset = useCallback(
    () => {
      batch(() => {
        change(`${namePath}.nameValue`, '');
        change(`${namePath}._hasExactMatch`, false);
        change(`${namePath}._isExactMatchAreIgnored`, false);
        resetFieldState(`${namePath}.nameValue`);
      });
      dispatch({ type: EActionType.reset });
      setInputFocus();
    },
    [resetFieldState, setInputFocus, batch, change, dispatch, namePath],
  );
  const handleCreate = useCallback(
    () => { dispatch({ type: EActionType.openDialog }); },
    [],
  );

  const handleAction = useCallback(
    () => {
      batch(() => {
        change(`${namePath}._hasExactMatch`, false);
        change(`${namePath}._isExactMatchAreIgnored`, true);
        change(`${namePath}._fuzzyMatchesWereIgnoredOnce`, true);
        change(`${namePath}._fuzzyMatchesAreIgnored`, true);
      });
      dispatch({ type: EActionType.reset });
      setInputFocus();
    },
    [dispatch, change, namePath, setInputFocus],
  );

  return isShow ? (
    <div className="mb-20">
      <p>{t('party_labels:db_exact_match_warning')}</p>
      <NameDuplicatesTable data={data} />
      {
        canDuplicateName ? (
          <div className="flex justify-end align-center">
            <Button
              text={t('party_labels:reset')}
              onClick={handleReset}
            />
            <Button
              className="ml-15"
              text={t('party_labels:create_name')}
              onClick={handleCreate}
            />
          </div>
        ) : null
      }
      <Dialog
        name={t('modal:create_duplicate_name')}
        isOpen={state.dialog}
        type={EDialogType.destructive}
        onClose={handleReset}
        onAction={handleAction}
        cancelText={t('go_back')}
        actionText={t('continue')}
      >
        {t('modal:create_duplicate_name_content')}
      </Dialog>
    </div>
  ) : null;
};
