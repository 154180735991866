import React, { useCallback, useMemo } from 'react';
import { Field, useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { StyledInput, MaskedField, Label } from '@grow-components/Form';
import get from 'lodash/get';
import styles from './search-field.module.scss';

const PARAMS = {
  ISNI: {
    placeholder: 'enter_16_Digit_isni',
    mask: [
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      ' ',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      ' ',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      ' ',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /([0-9]|x)/i,
      ' ',
    ]
  }
};

export const SearchField = () => {

  const { input: { value: type } } = useField('type', { subscription: { value: true } });
  const { t } = useTranslation();

  const pram = useMemo(
    () => get(PARAMS, type),
    [type],
  );

  const getPlaceholder = useMemo(
    () => t('search_by_id', { id: t(`enums:${type}`) }),
    [type],
  );

  const getField = useCallback(
    ({ input }) => typeof pram !== 'undefined' ? (
      <MaskedField mask={pram.mask} placeholder={t(pram.placeholder)} className={styles.styledInput} {...input} />
    ) : (
      <StyledInput autoComplete="off" type="text" {...input} placeholder={getPlaceholder} className={styles.styledInput} />
    ),
    [pram, getPlaceholder],
  );

  return (
    <Label label={t('search_term')} className={styles.label}>
      <Field name="term" render={getField} />
    </Label>
  );
};
