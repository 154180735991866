import { gql, useQuery } from '@apollo/client';

const GET_ACTIVITY_USERS_CREATED_BY = gql`
  query GetActivityUsers($searchString:String!) {
    getActivityUsers(data: {field: nameCreatedBy,value: $searchString}){
      activityUsers
    }
  }
`;

const GET_ACTIVITY_USERS_UPDATE_BY = gql`
  query GetActivityUsers($searchString:String!) {
    getActivityUsers(data: {field: nameUpdatedBy,value: $searchString}){
      activityUsers
    }
  }
`;
type TGetActivityUsersResponse = {
  getActivityUsers: {
    activityUsers: string[]
  }
}
type TGetActivityUsersVars = {
  searchString?: string
}
export const useUserSearch = (searchString?: string, skipLoading?: boolean, updated?: boolean) => {
  const { data, loading } = useQuery<TGetActivityUsersResponse, TGetActivityUsersVars>(updated ? GET_ACTIVITY_USERS_UPDATE_BY : GET_ACTIVITY_USERS_CREATED_BY , {
    skip: !searchString || searchString.length < 2 || skipLoading,
    variables: {
      searchString: searchString
    }
  });
  const users = data?.getActivityUsers.activityUsers || [];
  return { users, loading };
};
