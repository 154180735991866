import {
  ECompetencyType,
  EDataQualityType,
  EIdentifierType,
  EOrganizationType,
  EPartyType,
  ENamePrivacyType,
  ERemarkType,
} from 'modules/common/types/partyTypes';
import { ENameType, EPartySubType } from 'domains/party/types';
import { ECompetencyQueryType } from 'domains/search/types';

const translation: Record<string, string> = {
  [ECompetencyType.PERFORMING_ARTIST]: 'Performing Artist',
  [ECompetencyType.SONGWRITER]: 'Songwriter',
  [ECompetencyQueryType.NO_COMPETENCY_ASSIGNED]: 'No Competency Assigned',
  [EDataQualityType.VERIFIED]: 'Verified',
  [EDataQualityType.UNVERIFIED]: 'Unverified',
  [EPartySubType.BAND]: 'Band',
  [EPartySubType.ORCHESTRA]: 'Orchestra',
  [EIdentifierType.APPLE]: 'Apple ID',
  [EIdentifierType.DPID]: 'DPID',
  [EIdentifierType.DISCOGS]: 'Discogs ID',
  [EIdentifierType.IPI]: 'IPI',
  [EIdentifierType.IPN]: 'IPN',
  [EIdentifierType.ISNI]: 'ISNI',
  [EIdentifierType.MBID]: 'MusicBrainz ID',
  [EIdentifierType.PARTICIPANT_ID]: 'Participant ID',
  [EIdentifierType.SPOTIFY]: 'Spotify ID',
  [EIdentifierType.UAID]: 'UAID',
  [EIdentifierType.WIKIDATA]: 'Wikidata ID',
  [EIdentifierType.AMAZON]: 'Amazon ID',
  [EIdentifierType.DEEZER]: 'Deezer ID',
  [EIdentifierType.MERGED_ISNI]: 'Merged ISNI',
  [ENameType.PKA]: 'PKA',
  [ENameType.FKA]: 'FKA',
  [ENameType.AKA]: 'AKA',
  [EOrganizationType.DSP]: 'DSP',
  [EOrganizationType.PUBLISHER]: 'Publisher',
  [EOrganizationType.THIRD_PARTY_LABEL]: 'Third Party Label',
  [EOrganizationType.WMG_COMPANY]: 'WMG Company',
  [EPartyType.PERSON]: 'Person',
  [EPartyType.GROUP]: 'Group',
  [EPartyType.ORGANIZATION]: 'Organization',
  [EPartySubType.NATURAL_PERSON]: 'Natural Person',
  [EPartySubType.CHARACTER]: 'Character',
  [ENamePrivacyType.PUBLIC]: 'Public',
  [ENamePrivacyType.INTERNAL_TO_WMG]: 'Internal to WMG',
  [ERemarkType.ARTIST_CLEANUP]: 'Artist Cleanup',
  [ERemarkType.ARTIST_VERIFIED]: 'Artist Verified',
  [ERemarkType.REASON_FOR_REVISION]: 'Reason for Revision',
  [ERemarkType.OTHER]: 'Other',
  [ERemarkType.INTERNAL_COMMENT]: 'Internal Comment',
  [ERemarkType.WEBSITE_URL]: 'Website URL',
  [ERemarkType.ARTIST_CREATED_ON_THE_FLY]: 'Artist created on the fly',
  [ERemarkType.BAND_GROUP_MEMBERS_INCLUDE]: 'Band / Group Members Include',
  [ERemarkType.COMPETENCY_CHANGE]: 'Competency Change',
  [ERemarkType.ISNI_DISAMBIGUATION_COMMENT]: 'ISNI Disambiguation Comment',
  [ERemarkType.ADDITIONAL_INFO]: 'Additional Info'
};

export function tMessage<T extends string>(id: T): string {
  const translatedMessage = translation[id];
  if (typeof translatedMessage === 'undefined') throw new Error(`No translation found for ${id}`);
  return translatedMessage;
}
