import React, { FC, useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Layout } from 'modules/common/components/Layout';
import { cn } from 'utils';
import { createInitialValuesFromExistingParty } from 'modules/common/components/forms/CreateEditPartyForm/utils/formValues';
import { PartyParams } from 'modules/common/types/routeTypes';
import { useDictionaries } from 'modules/common/hooks/useDictionaries';
import { CreateEditPartyForm } from 'modules/common/components/forms/CreateEditPartyForm';
import { useGetParty } from 'domains/party/hooks';
import { usePermissions } from 'modules/common/hooks/usePersmissions';
import { usePartyLock } from 'domains/party/hooks/usePartyLock';
import { useIsPartyLocked } from 'modules/common/hooks/useIsPartyLocked';

const EditParty: FC = () => {

  const { loading: loadingDictionaries } = useDictionaries();

  const { id } = useParams<PartyParams>();

  const { isPartyLoading, partyData } = useGetParty(+id);

  // if party is locked redirect to view page
  const isPartyLocked = useIsPartyLocked(partyData);

  const { canEditParty } = usePermissions();

  const { lockParty, updateLockParty, unlockParty } = usePartyLock(partyData?.party.partyId);

  useEffect(() => {
    if (partyData)
      lockParty();
    return () => {
      if (partyData) {
        unlockParty();
      }
    };
  }, [partyData]);

  useEffect(() => {
    const updateLock = setInterval(() => {
      updateLockParty();
    }, 10000);
    return () => {
      clearInterval(updateLock);
    };
  }, []);

  // TODO: change to "Party not found" page when created instead of redirect
  if (!isPartyLoading && partyData && isPartyLocked) {
    return <Redirect to={`/party/${id}`} />;
  }

  if (!isPartyLoading && partyData && !canEditParty(partyData.party)) {
    return <Redirect to={'/not-found'} />;
  }

  if (!id || !isPartyLoading && !partyData) return <Redirect to={'/not-found'} />;

  return (
    <Layout loading={isPartyLoading || loadingDictionaries}>
      <div className={cn('container')}>
        {partyData && (
          <CreateEditPartyForm
            isEdit={true}
            initialValues={createInitialValuesFromExistingParty(partyData)}
          />
        )}
      </div>
    </Layout>
  );
};

export const EditPartyPage = React.memo(EditParty);
