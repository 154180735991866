import { moment } from 'utils';
import { TIsniInfo } from 'domains/isni/types';

export const IDS_LABELS: Record<string, any> = {
  ISNI: {
    label: 'ISNI',
    linkKey: 'ISNI'
  },
  MBID: {
    label: 'MusicBrainz ID',
    linkKey: 'MBID'
  },
  DPID: {
    label: 'DPID',
    linkKey: 'DPID'
  },
  APPLE: {
    label: 'Apple ID',
    linkKey: 'APPLE'
  },
  SPOTIFY: {
    label:'Spotify ID',
    linkKey: 'SPOTIFY'
  },
  AMAZON: {
    label: 'Amazon ID',
    linkKey: 'AMAZON'
  },
  DEEZER: {
    label: 'Deezer ID',
    linkKey: 'DEEZER'
  },
  IPN:{
    label: 'IPN',
    linkKey: 'IPN'
  },
  IPI: {
    label:'IPI',
    linkKey: 'IPI'
  },
  DISCOGS: {
    label:'Discogs ID',
    linkKey: 'DISCOGS'
  },
  WIKIDATA: {
    label: 'Wikidata ID',
    linkKey: 'WIKIDATA'
  },
  PARTICIPANT_ID: {
    label: 'Participant ID',
    linkKey: 'PARTICIPANT_ID'
  },
  MERGED_ISNI: {
    label: 'Merged ISNI',
    linkKey: 'MERGED_ISNI'
  }
};

export const age = (isniData: any) => {
  const parsedBirthDate = moment(isniData.birthDate);
  if (!isniData.deathDate) return `(${parsedBirthDate.toNow(true)} ago)`;

  const parsedDeathDate = moment(isniData.deathDate);
  return `(aged ${parsedDeathDate.diff(parsedBirthDate, 'years')})`;
};

export const spotifyId = (isniData: any) => {
  const id = isniData.identifiers.find((identifier: any) => identifier.type === 'SPOTIFY');
  return id ? id.value : undefined;
};

export const assignedToDifferentParty = (isniData?: TIsniInfo, partyId?: string | number): boolean => {
  if (!isniData) return false;
  if (!partyId) return !!isniData.assignedNames.length;
  return !!isniData.assignedNames.filter((n: any) => +n.partyId !== +partyId).length;
};