import { BasicSpinner } from '@grow-components/Spinners';
import { ESpinnerSize } from '@grow-components/Spinners/consts';
import { Badge, Icon, Input, InputGroup, Button } from '@wmg-ae/legato';
import { TEditableIds } from 'modules/common/types/partyTypes';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styles from '../IdentifiersInfo.module.scss';
import cn from 'classnames';
import { OnChange } from 'react-final-form-listeners';

type PropFormField = {
  forId: TEditableIds;
  label: string;
  icon: any;
  loading: boolean;
  searchResult: { data: any, err: any };
  validated: boolean;
  onSubmit: any;
  openForm: boolean;
  defaultValue: string;
  validationSetter: (name: TEditableIds, makeValid: boolean, value: string) => void;
};

const initialStateSearchResult = {
  data: undefined,
  err: undefined
};

export const SearchIdForm: React.FC<PropFormField> = ({
  forId,
  label,
  icon,
  loading = false,
  searchResult,
  validated,
  validationSetter,
  onSubmit,
  openForm,
  defaultValue = ''
}) => {
  const [searchResultData, setSearchResultData] = useState<any>(initialStateSearchResult);
  const initialValidation = useRef(true);
  const initialValue = useRef(defaultValue);
  const { t } = useTranslation();

  const handleOnChangeField = (
    value: string
  ) => {
    setSearchResultData(initialStateSearchResult);
    validationSetter(forId, false, value);
  };

  useEffect(() => {
    if(defaultValue)
      onSubmit(defaultValue);
  }, []);

  useEffect(() => {
    // this is a workaround because there's no way to change the icon prefix to "fal" in the Input component from legato
    document.querySelectorAll('.fa-triangle-exclamation')?.forEach(el => el.classList.add('fal'));

    if(initialValidation.current && initialValue.current && searchResultData.data?.artist) {
      validationSetter(forId, true, initialValue.current);
      initialValidation.current = false;
    }
  }, [searchResultData]);

  useEffect(() => {
    setSearchResultData(searchResult);
  }, [searchResult.data, searchResult.err]);

  useEffect(() => {
    setSearchResultData(initialStateSearchResult);
  }, [openForm]);

  const handleSearch = (value: string) => {
    validationSetter(forId, false, value);
    onSubmit(value);
  };

  const ResultParty = useMemo(() => {
    if(searchResultData.data?.artist)
      return (
        <Badge
          rounded
          size='lg'
          containerStyle='tint'
          colorType={validated ? 'white' : 'dark'}
          text={
            <p>
              {searchResultData.data.artist?.name}
              <Icon name={icon} prefix='fab' className='pl-5'/>
            </p> as any
          }
        />
      );

    if(searchResultData.err)
      return <span className={cn(styles.alreadyAssignedErrMsg, styles.errorMsg, 'lgt-clr-b-core')}>{searchResultData.err?.message}</span>;

    if(searchResultData.data?.artist === null && !searchResultData.err)
      return <p className={cn(styles.errorMsg, 'lgt-clr-b-core')}>{t('errors:id_not_found')}</p>;
  }, [searchResultData, validated]);

  return (
    <Form  onSubmit={onSubmit} initialValues={{ [forId]: defaultValue }}>
      {({ values }) => (
        <form>
          <div className='row' style={{ alignItems: 'end' }}>
            <div className='col-4'>
              <Field name={forId}>
                {({ input }) => (
                  <InputGroup
                    label={label}
                    validation={{
                      type: (searchResultData.err?.message || (searchResultData.data?.artist === null && !searchResultData.err)) ? 'error' : undefined
                    }}
                    validationIconPlacement='input'
                  >
                    <Input
                      {...input}
                      enterKeyHint="false"
                      placeholder={t('party_placeholders:enter_paste_id')}
                    />
                  </InputGroup>
                )}
              </Field>
              <OnChange name={forId}>
                {(val) => handleOnChangeField(val)}
              </OnChange>
            </div>
            <div className='col-auto ml-4 mr-3'>
              <Button
                disabled={loading}
                onClick={() => handleSearch(values[forId])}
                icon={
                  loading ? (
                    <BasicSpinner size={ESpinnerSize.SMALL} />
                  ) : (
                    'search'
                  )
                }
                colorType='secondary-black'
                containerStyle='outline'
                className={cn(values[forId]?.length > 0 ? 'visible' : 'invisible', styles.searchIdButton)}
              >
                {t('common:search')}
              </Button>
            </div>

            <div className='col-lg-5 col-md-4 col-sm-3 ml-20'>
              {ResultParty}
            </div>

            <div className='col-1'>
              {searchResultData.data?.artist && !validated && (
                <Button
                  containerStyle='outline'
                  onClick={() => validationSetter(forId, true, values[forId])}
                >
                  {t('common:validate')}?
                </Button>
              )}
              {validated && (
                <p className={styles.idValidatedMsg}>
                  {t('common:validated')}
                  <Icon name='badge-check' className="pl-6" />
                </p>
              )}
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
