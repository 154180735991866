import { useCallback, useMemo } from 'react';
import { BroadcastChannel } from 'broadcast-channel';
import { TIsniSearchData } from 'modules/common/types/isniTypes';

export enum BROADCAST_CHANNELS {
  ISNI = 'isniUnifiedChannel',
  ISNI_SEARCH = 'isniSearch',
  ISNI_ASSIGN = 'isniAssign',
  USER_UTILS = 'userChannel',
}

export enum EBroadCastMessageType {
  REQ_ISNI_DATA = 'REQ_ISNI_DATA',
  RESP_ISNI_DATA = 'RESP_ISNI_DATA',
  ASSIGNED = 'ASSIGNED',
  ASSIGNING_INTERRUPTED = 'ASSIGNING_INTERRUPTED',
  NAME_ID_READY = 'NAME_ID_READY', // Uses for inform about that particular nameId is ready
  NAME_ID_ACTIVATE = 'NAME_ID_ACTIVATE',
  CLOSE_SEARCH_TAB = 'CLOSE_SEARCH_TAB',
}

export const REQ_TIMEOUT = 1000;

interface TBMessage<P> {
  type: EBroadCastMessageType;
  payload: P;
}

function reqISNIData(payload: string) {
  return {
    type: EBroadCastMessageType.REQ_ISNI_DATA,
    payload,
  };
}

function respISNIData(payload: TIsniSearchData) {
  return {
    type: EBroadCastMessageType.RESP_ISNI_DATA,
    payload,
  };
}

function assigningInterrupted(payload: string) {
  return {
    type: EBroadCastMessageType.ASSIGNING_INTERRUPTED,
    payload,
  };
}

enum EAssignISNIPlace {
  DETAIL = 'DETAIL',
}

function assignedISNI(payload: EAssignISNIPlace) {
  return {
    type: EBroadCastMessageType.ASSIGNED,
    payload,
  };
}

function nameIdIsReady(payload: string) {
  return {
    type: EBroadCastMessageType.NAME_ID_READY,
    payload,
  };
}

function activateName(payload: TIsniSearchData) {
  return {
    type: EBroadCastMessageType.NAME_ID_ACTIVATE,
    payload,
  };
}

function searchTabClosed(payload: Record<string, any>) {
  return {
    type: EBroadCastMessageType.CLOSE_SEARCH_TAB,
    payload,
  };
}

type TSender<P> = (data: P) => void;

function subHandlerFactory<T extends EBroadCastMessageType, P>(type: T, fn: TSender<P>) {
  return (message: TBMessage<P>): void => {
    if (type === message.type) {
      fn(message.payload);
    }
  };
}

export const useBroadcastChannel = (channelName: BROADCAST_CHANNELS) => {
  const channel = new BroadcastChannel(channelName);

  const sendMessage = useCallback((message: Record<string, any>) => channel.postMessage(message), []);

  const receiveMessage = useCallback(
    (receiverFn: (message: Record<string, any>) => void) => {
      channel.onmessage = receiverFn;
    },
    []
  );

  const sendHandshake = useCallback((handshakeData: Record<string, any>) => {
    channel.postMessage(handshakeData);
  }, []);

  const receiveHandshake = useCallback((receiverFn: (message: Record<string, any>) => void) => {
    channel.onmessage = receiverFn;
  }, []);

  const closeChannel = useCallback(() => channel.close(), []);

  const sendTypedMessage = useCallback(
    <P>(message: TBMessage<P>) => { channel.postMessage(message); },
    [channel],
  );

  const sendTypedMessageFactory = useCallback(
    <P>(fn: (d: P) => TBMessage<P>) => (payload: P) => { channel.postMessage(fn(payload)); },
    [channel],
  );

  // const sendTypedPromisfiedFactory = useCallback(
  //   <P>(condition: (d: TBMessage<P>) => boolean, sender: (d: P) => TBMessage<P>) => (data: P) => new Promise((resolve, reject) => {
  //     const tmr = setTimeout(() => { reject('TIMEOUT'); }, REQ_TIMEOUT);
  //     const hndl = (msg: TBMessage<P>) => {
  //       if (condition(msg)) {
  //         resolve(msg.payload);
  //         clearTimeout(tmr);
  //         channel.removeEventListener('message', hndl);
  //       }
  //     };
  //     channel.addEventListener('message', hndl);
  //     sendTypedMessage(sender(data));
  //   }),
  //   [],
  // );

  const sendTMessage = useMemo(
    () => ({
      reqISNIData(id: string): Promise<TIsniSearchData> {
        return new Promise((resolve, reject) => {
          const tmr = setTimeout(() => { reject('TIMEOUT'); }, REQ_TIMEOUT);
          const hndl = (msg: TBMessage<TIsniSearchData>) => {
            if (msg.type === EBroadCastMessageType.RESP_ISNI_DATA && msg.payload.nameId === id) {
              resolve(msg.payload);
              clearTimeout(tmr);
              channel.removeEventListener('message', hndl);
            }
          };
          channel.addEventListener('message', hndl);
          sendTypedMessage(reqISNIData(id));
        });
      },
      respISNIData: sendTypedMessageFactory(respISNIData),
      assignedISNI(data: EAssignISNIPlace = EAssignISNIPlace.DETAIL) { sendTypedMessage(assignedISNI(data)); },
      assigneingInterrupted: sendTypedMessageFactory(assigningInterrupted),
      nameIdIsReady(id: string): Promise<TIsniSearchData> {
        return new Promise((resolve, reject) => {
          const tmr = setTimeout(() => { reject('TIMEOUT'); }, REQ_TIMEOUT);
          const hndl = (msg: TBMessage<TIsniSearchData>) => {
            if (msg.type === EBroadCastMessageType.NAME_ID_ACTIVATE && msg.payload.nameId === id) {
              resolve(msg.payload);
              clearTimeout(tmr);
              channel.removeEventListener('message', hndl);
            }
          };
          channel.addEventListener('message', hndl);
          sendTypedMessage(nameIdIsReady(id));
        });
      },
      activateName: sendTypedMessageFactory(activateName),
      searchTabClosed: sendTypedMessageFactory(searchTabClosed),
    }),
    [sendTypedMessage],
  );

  const subscribeOnMessage = useCallback(
    <T extends TBMessage<P>, P>(type: T['type'], fn: TSender<P>) => {
      const subHandler = subHandlerFactory(type, fn);
      channel.addEventListener('message', subHandler);
      return () => {
        channel.removeEventListener('message', subHandler);
      };
    },
    [],
  );

  return {
    sendMessage,
    receiveMessage,
    sendHandshake,
    receiveHandshake,
    closeChannel,
    channel,
    sendTMessage,
    subscribeOnMessage,
  };
};
