import React, { FC, useEffect } from 'react';
import { LoadMore } from '../LoadMore';
import { ResultTable } from './ResultTable';
import { SearchName } from 'modules/common/types/searchNameTypes';

type IProps = {
  searchTerm: string
  results?: SearchName[]
  totalCount: number
  loading: boolean
  className?: string
}

export const SearchResults: FC<IProps> = ({
  results,
  totalCount,
  loading,
  searchTerm,
  className
}) => {

  useEffect(() => { window.scrollTo(0, 0); }, [searchTerm]);

  return (
    <div className="col-md-12 col-md-offset-0 search-results">
      <ResultTable data={results} isLoading={loading} className={className} />
      <LoadMore totalCount={totalCount} loading={loading} />
    </div>
  );
};
