import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import compose from 'lodash/fp/compose';
import compact from 'lodash/fp/compact';
import join from 'lodash/fp/join';
import map from 'lodash/fp/map';
import { EDateTimeFormat } from 'common/consts';

export type TParamsEntry = [string, string];

const enumsList = ['partyType', 'competency', 'partySubType'];
const filteredKeys = ['size', 'modifiedOnA', 'modifiedOnB', 'nameOperator', 'createdOnA', 'createdOnB', 'sortDesc', 'sortBy'];
const bulkKeys: Record<string, ReadonlyArray<string>> = {
  modified_BETWEEN: ['modifiedOnA', 'modifiedOnB', 'modifiedOnOperator'],
  modified_GTE: ['modifiedOnOperator', 'modifiedOnA'],
  modified_LTE: ['modifiedOnOperator', 'modifiedOnA'],
  created_BETWEEN: ['createdOnA', 'createdOnB', 'createdOnOperator'],
  created_GTE: ['createdOnOperator', 'createdOnA'],
  created_LTE: ['createdOnOperator', 'createdOnA'],
  nameValue: ['nameOperator', 'nameValue'],
};

const sortIndex: Record<string, number> = {
  nameValue: 0,
  nameOperator: 1,
  partyType: 2,
  partySubType: 3,
  competency: 4,
  createdBy: 5,
  createdOnOperator: 6,
  createdOnA: 7,
  createdOnB: 8,
  modifiedBy: 9,
  modifiedOnOperator: 10,
  modifiedOnA: 11,
  modifiedOnB: 12,
};

function enumsTranslate(tf: (k: string) => string) {
  return ([key, value]: TParamsEntry): TParamsEntry => {
    if (enumsList.includes(key)) {
      return [key, tf(value)];
    }
    return [key, value];
  };
}

function periodConveyor(params: URLSearchParams, value: string) {
  return (prefix: 'created' | 'modified'): TParamsEntry => [
    [prefix, value].join('_'),
    compose(
      join(' - '),
      map((d: string) => moment(d).format(EDateTimeFormat.DD_MM_YYYY_Slash)),
      compact,
    )([
      params.get(`${prefix}OnA`),
      params.get(`${prefix}OnB`),
    ]),
  ];
}

function periodModifier(params: URLSearchParams) {
  return ([key, value]: TParamsEntry): TParamsEntry => {
    const modifier = periodConveyor(params, value);
    if (key === 'createdOnOperator') {
      return modifier('created');
    }
    if (key === 'modifiedOnOperator') {
      return modifier('modified');
    }
    return [key, value];
  };
}

export const useQuery = () => {
  const { location, push } = useHistory();
  const { t: tEnums } = useTranslation('enums');

  const getParams = useCallback(() => new URLSearchParams(location.search), [location]);

  const handleRemove = useCallback<(e: TParamsEntry) => void>(
    ([key]) => {
      const params = getParams();
      if (Array.isArray(bulkKeys[key])) {
        bulkKeys[key].forEach(k => { params.delete(k); });
      } else {
        params.delete(key);
      }
      push({ pathname: location.pathname, search: params.toString() });
    },
  [getParams],
  );

  const list = useMemo(
    () => {
      const params = getParams();
      return [...params.entries()]
        .filter(([key, value]) => !!value && !filteredKeys.includes(key))
        .sort(([keyA], [keyB]) => sortIndex[keyA] - sortIndex[keyB])
        .map(
          compose(
            enumsTranslate(tEnums),
            periodModifier(params)
          ),
        );
    },
    [getParams],
  );

  return {
    list,
    handleRemove,
  };
};
