import { useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { TPartyName } from 'domains/party/types';
import get from 'lodash/get';

export function useCurrentName<T extends TPartyName = TPartyName>(namePath: string) {
  const { values } = useFormState();

  const fieldValue = useMemo<T>(
    () => get(values, namePath),
    [values],
  );

  return {
    values: fieldValue,
  };
}
