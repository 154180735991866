import set from 'lodash/fp/set';
import compose from 'lodash/fp/compose';
import { Country, EIdentifierType, ERemarkType, Identifier } from 'modules/common/types/partyTypes';
import { ENameType, TPartyEntryDetail } from 'domains/party/types';
import { createInitialValuesFromExistingParty } from '../forms/CreateEditPartyForm/utils/formValues';
import { TIsniPersonalInfo } from 'domains/isni/types';
import { partyDatesFormatter } from 'domains/party/utils';

interface IState {
  confirmationModal: boolean;
  loading: boolean;
}

export enum EActionType {
  CHECK_AVAILABLE = 'CHECK_AVAILABLE',
  CHECK_SUCCESS = 'CHECK_SUCCESS',
  CHECK_FAILURE = 'CHECK_FAILURE',
  HIDE_MODAL = 'HIDE_MODAL',
}

interface IAction {
  type: EActionType;
}

export function reducer(state: IState, action: IAction) {
  switch(action.type) {
  case EActionType.CHECK_AVAILABLE:
    return set('loading')(true)(state); 

  case EActionType.CHECK_SUCCESS:
    return compose(
      set('load')(false),
      set('confirmationModal')(true),
    )(state);

  case EActionType.CHECK_FAILURE:
    return set('loading')(false)(state);

  case EActionType.HIDE_MODAL:
    return set('loading')(false)(state);

  default:
    throw new Error();
  }
}


export function reformatIsni(
  partyName: string | undefined,
  isniValues: {
    ids: Identifier[],
    targetNameType?: ENameType,
    partyData: TPartyEntryDetail,
    isniComment: string | undefined,
    personalInfo?: TIsniPersonalInfo
  },
  getCountry: (x: string | undefined) => Country | undefined){

  const _mapOfChangeAuthors: string[] = isniValues.ids
    .filter((item: Identifier) => item.identifierType !== EIdentifierType.ISNI && item.identifierType !== EIdentifierType.MERGED_ISNI)
    .map((item: Identifier) => `identifiers[?(@.identifierType=='${item.identifierType}')]`);

  if (isniValues.isniComment) {
    _mapOfChangeAuthors.push('remarks');
  }

  let partyFormatted = createInitialValuesFromExistingParty(isniValues.partyData);

  if (isniValues.targetNameType === ENameType.PKA) {
    const { formattedDates, quansicRegister } = partyDatesFormatter(isniValues.partyData.party, isniValues.personalInfo);

    partyFormatted = {
      ...partyFormatted,
      ...formattedDates
    };

    _mapOfChangeAuthors.push(...quansicRegister);

    if (!partyFormatted.countryOfOriginId && isniValues.personalInfo?.nationality) {
      const isniCountry = getCountry(isniValues.personalInfo.nationality);
      _mapOfChangeAuthors.push('party.countryOfOriginId');
      partyFormatted.countryOfOriginId = isniCountry?.countryId;
    }
  }

  const newNames = partyFormatted.names.map((item) => item.nameValue !== partyName ? item :
    {
      ...item,
      identifiers: isniValues.ids,
      translations: item.translations && item.translations.length > 0 ? item.translations : [{ languageVariantId: 0, name: '' }],
      remarks: isniValues.isniComment ? [...(item.remarks?.filter(item => item.remarkType !== ERemarkType.ADDITIONAL_INFO) || []), { remarkType: ERemarkType.ADDITIONAL_INFO, text: isniValues.isniComment }]
        : (item.remarks || []),
      _mapOfChangeAuthors
    });

  return {
    ...partyFormatted,
    names: newNames
  };
}

export const initialArg = {
  confirmationModal: false,
  loading: false,
};
