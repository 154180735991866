export default function calculateResultNums(
  currentPage: number,
  totalCount: number,
  pageSize: number
) {
  let shorten = 0;
  if (pageSize * currentPage > totalCount) {
    shorten = pageSize * currentPage - totalCount;
  }
  const startNum = currentPage * pageSize - (pageSize - 1);
  const endNum = currentPage * pageSize - shorten;
  return {
    startNum,
    endNum,
  };
}
