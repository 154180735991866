import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Layout from 'modules/common/components/Layout';
import { GET_NAME_BY_ID, SEARCH_ISNI } from 'graphql/queries';
import { IsniSearchResults } from 'modules/pages/IsniSearchPage/components';
import {
  EIsniPageCameFrom,
  EIsniSearchParam,
  IIsniSearchFilter,
  TIsniMoreInfoQueryParams,
  TIsniSearchData,
  TIsniSearchParams
} from 'modules/common/types/isniTypes';
import { ENamePrivacyType } from 'modules/common/types/partyTypes';
import { BROADCAST_CHANNELS, EBroadCastMessageType, useBroadcastChannel } from 'modules/common/hooks/useBroadcastChannel';
import { OverlaySpinner } from '@grow-components/Spinners';
import { Subheader } from '@grow-components/Subheader/Subheader';
import { IsniCredit } from 'modules/common/components/IsniCredit';
import { Button } from '@wmg-ae/legato';

import styles from './IsniSearchPage.module.scss';
import { RequestIsniModal } from './components/RequestIsniModal/RequestIsniModal';
import { usePermissions } from 'modules/common/hooks/usePersmissions';
import { ISNILookupProvider } from 'modules/common/components/ISNI';
import { ToastService } from 'utils/ToastService';
import { useQueryParams } from 'modules/common/hooks/useQueryParams';
import { EButtonColors, EButtonStyles } from 'modules/common/types/legatoTypes';

export const IsniSearchPage: FC = () => {
  const { t } = useTranslation();
  const { nameId } = useParams<TIsniSearchParams>();
  const [isniData, setIsniData] = useState<TIsniSearchData | null>(null);
  const { subscribeOnMessage, sendTMessage } = useBroadcastChannel(BROADCAST_CHANNELS.ISNI);
  const [isIsniRequestModalVisible, setIsniRequestModalVisible] = useState(false);
  const { replace, location } = useHistory<TIsniSearchData | null>();
  const { canRequestNewISNI } = usePermissions();

  const { params } = useQueryParams<TIsniMoreInfoQueryParams>();
  const cameFrom = params.cameFrom;

  useEffect(() => {
    let mount = true;
    sendTMessage.reqISNIData(nameId)
      .then(msg => {
        mount && setIsniData(msg);
        replace(location.pathname + location.search, { ...msg });
      })
      .catch(() => {
        if (cameFrom === EIsniPageCameFrom.SEARCH) {
          const isniData = JSON.parse(localStorage.getItem('isniDataFromSearch') as string);
          mount && setIsniData(isniData);
          replace(location.pathname + location.search + location.hash, { ...isniData });
        }
        else if (location.state && 'partyId' in location.state && mount) {
          replace(['/party', location.state.partyId, 'edit'].join('/'), undefined);
          const toastId = 'isni_selection_was_interrupted';
          ToastService.warning(
            t('toasts:isni_selection_process_was_interrupted'),
            { toastId },
          );
        }
      });
    return () => {
      mount = false;
    };
  }, []);

  const assignedFromDetailsReceiver = useCallback(() => { window.close(); }, []);

  const handleClose = useCallback(
    (id: string) => {
      if(id === nameId) {
        setTimeout(() => window.close(), 200);
      }
    },
    [nameId],
  );

  useEffect(() => {
    const unsubAssignISNI = subscribeOnMessage(EBroadCastMessageType.ASSIGNED, assignedFromDetailsReceiver);
    const unsubAssigningInterrupted = subscribeOnMessage(EBroadCastMessageType.ASSIGNING_INTERRUPTED, handleClose);
    return () => {
      unsubAssignISNI();
      unsubAssigningInterrupted();
    };
  }, [isniData]);

  // TODO: move to hook
  const [searchIsni, { data, error }] = useLazyQuery<any, IIsniSearchFilter>(SEARCH_ISNI);
  const [privacyStatus, setPrivacyStatus] = useState<ENamePrivacyType>();

  const [getNameById] = useLazyQuery(GET_NAME_BY_ID, {
    variables: {
      data: {
        nameId
      }
    },
    onCompleted: (data) => {
      setPrivacyStatus(data.nameGetById.name.privacyType);
    }
  });

  useEffect(() => {
    if (!isNaN(parseInt(nameId)) && parseInt(nameId).toString() === nameId) {
      getNameById();
    } else {
      setPrivacyStatus(ENamePrivacyType.PUBLIC);
    }
  }, []);

  useEffect(() => {
    if (isniData && privacyStatus && privacyStatus !== ENamePrivacyType.INTERNAL_TO_WMG) {
      const { searchParam, searchValue: value } = isniData;

      searchIsni({
        variables: {
          data: {
            ...(searchParam === EIsniSearchParam.NAME ? { name: value } : {
              identifier: {
                isFull: false,
                type: searchParam,
                value,
              },
            }),
          }
        }
      });
    }
  }, [isniData, privacyStatus]);

  return (
    <Layout>
      {(!isniData || !data && !error) && <OverlaySpinner />}
      {(!!isniData && data) && <div className="container pt-10">
        <Subheader
          location="ISNI Lookup"
          mainInfo={isniData.searchValue}
          additionalInfo={t('showing_results_count', { count: data.searchIsni.isni.length })}
          rightSideBlock={
            <div className={styles.headerActions}>
              <Button
                containerStyle={EButtonStyles.link}
                className={styles.button}
                color={EButtonColors.primary}
                label={t('request_isni')}
                onClick={() => setIsniRequestModalVisible(true)}
                disabled={!canRequestNewISNI}
              />
              <IsniCredit />
            </div>
          }
          sticky
        />
        <ISNILookupProvider>
          <IsniSearchResults isniData={isniData} results={data.searchIsni.isni} />
        </ISNILookupProvider>
        {isIsniRequestModalVisible && (
          <RequestIsniModal
            partyId={+isniData.partyId}
            nameId={+nameId}
            onClose={() => setIsniRequestModalVisible(false)}
          />
        )}
      </div>}
      {error && error.message}
    </Layout>
  );
};
