import React, { FC, useMemo } from 'react';
import Layout from 'modules/common/components/Layout';
import { Link, useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useMainSearch } from 'domains/search/hooks/useMainSearch';
import { SearchHeader } from 'modules/pages/SearchPage/components/SearchHeader';
import { useQueryParams } from 'modules/common/hooks/useQueryParams';
import { SearchResults } from './components/ResultsBox/SearchResults';
import { TMainSearchURLParams } from 'domains/search/types';
import calculateResultNums from 'modules/common/helpers/calculateResultNums';
import { useQueryMetaParams } from 'modules/common/hooks/useQueryMetaParams';
import { Subheader } from '@grow-components/Subheader/Subheader';
import { EmptySearchResult } from '@grow-components/EmptySearchResult/EmptySearchResult';
import { ExportBtn } from 'modules/common/components/utils/ExportBtn';
import { EFileExportType, ESearchType } from 'domains/search/graphql';
import { useFileExportSearch } from 'domains/search/hooks/useFileExportSearch';
import { Icon } from '@grow-components/Icon/Icon';
import NotFound from 'modules/common/components/errors/pages/NotFound';

import styles from './search-page.module.scss';
import { ToastService } from 'utils/ToastService';

export const SearchPage: FC = () => {
  const { t } = useTranslation('common');
  const { t: tSearch } = useTranslation('search');
  const { t: tEmptySearch } = useTranslation('empty_search');
  const { search } = useLocation();
  const { params } = useQueryParams<TMainSearchURLParams>();
  const { queryMetaParams: { size, skip } } = useQueryMetaParams();
  const { term } = params;
  const { data, loading, error } = useMainSearch(term);
  const page = skip / size + 1;

  const totalCount = data?.totalCount ?? 0;
  const { startNum, endNum } = useMemo(() => {
    return calculateResultNums(
      page,
      totalCount,
      size,
    );
  }, [totalCount, size, page]);

  const isMainLoading = useMemo(
    () => typeof data === 'undefined' && loading,
    [data, loading],
  );
  const emptyResultActions = useMemo(() => <div className='empty-result-actions'>
    <Link to="/party/person/create" className="btn btn-primary btn-black">
      {t('create_person')}
    </Link>
    <Link to="/party/group/create" className="btn btn-primary btn-black">
      {t('create_group')}
    </Link>
  </div>, []);

  const { fileExportSearch } = useFileExportSearch();

  const searchExportHandler = (filetype: EFileExportType) => {
    ToastService.info(t('toasts:export_search'));
    fileExportSearch({
      variables: {
        searchTerm: term,
        searchType: ESearchType.FULL_SEARCH,
        filetype
      },
    });
  };

  if (error) return (
    <NotFound />
  );

  return (
    <Layout loading={isMainLoading}>
      {!search.length ? (
        <SearchHeader />
      ) : (
        <div className="container">
          <div className="row results-box">
            <Subheader location={tSearch('search_results')}
              mainInfo={term}
              additionalInfo={
                `Showing ${term} ${totalCount === 1 ? 'result ' : 'results '}${startNum} - ${endNum} of ${totalCount}`
              }
              sticky
              noShrink
              rightSideBlock={
                <div className={styles.exportBtn}>
                  <ExportBtn
                    disabled={totalCount === 0}
                    exportHandler={searchExportHandler}
                    buttonContent={<>Export <Icon icon='file-arrow-down' /></>}
                  />
                </div>
              }
              className={styles.subheader}
            />
            {(data && totalCount) ?
              <SearchResults
                results={data.names || []}
                searchTerm={term}
                loading={loading}
                totalCount={data.totalCount}
              /> :
              <EmptySearchResult
                headline={tEmptySearch('bummer')}
                message={<Trans i18nKey={'empty_search:standard_search_msg'} values={{ term }} />}
                actions={emptyResultActions}
              />
            }
          </div>
        </div>
      )}
    </Layout>
  );
};

