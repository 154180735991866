import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import isEqual from 'lodash/isEqual';
import { useOktaAuth } from '@okta/okta-react';
import { getPermissionsData } from 'graphql/types';
import { GET_PERMISSIONS } from 'graphql/queries';
import { useUser } from 'domains/env';
import { TPartyEntity } from 'domains/party/types';
import { EPermissionType, EUserGroup } from '../types/authTypes';

type TPermission = Record<EPermissionType, true>;

export type TRestriction = ((permissions: TPermission) => boolean) | boolean;

export const usePermissions = () => {
  const { authState } = useOktaAuth();
  const user = useUser();

  const [permissions, setPermissions] = useState<TPermission>();

  const { loading: pending, data } = useQuery<getPermissionsData>(
    GET_PERMISSIONS, {
      skip: authState.isPending,
      fetchPolicy: 'cache-first',
    }
  );

  const getPermissions = useCallback<() => TPermission>(
    () => (data?.permissions || []).reduce((a, p) => ({ ...a, [p.name]: true }), {} as TPermission),
  [data],
  );

  useEffect(
    () => {
      if (!authState.isPending && !pending) {
        const p = getPermissions();
        if (!isEqual(permissions, p)) {
          setPermissions(p);
        }
      }
    },
    [authState.isPending, pending, setPermissions, permissions],
  );

  const filterPermission = useCallback<(restriction: TRestriction) => boolean>(
    (r) => {
      if (typeof r === 'boolean') return r;
      if (typeof permissions !== 'undefined') {
        if (typeof r === 'function') return r(permissions);
      }
      return false;
    },
  [permissions],
  );

  const checkPermission = useCallback<(permissionType: EPermissionType) => boolean>(
    (p) => {
      if (typeof permissions !== 'undefined') {
        return permissions[p];
      }
      return false;
    }, [permissions],
  );

  const isAuthenticated = useMemo(() => typeof permissions !== 'undefined', [permissions]);
  const canReadParty = useMemo(() => checkPermission(EPermissionType.VIEW_PARTY), [checkPermission]);
  const canCreateParty = useMemo(() => checkPermission(EPermissionType.CREATE_PARTY), [checkPermission]);
  // const canEditParty = permissions?.includes(EPermissionType.EDIT_PARTY);
  const canAssignISNI = useMemo(() => checkPermission(EPermissionType.ASSIGN_ISNI), [checkPermission]);
  const canEditISNI = useMemo(() => checkPermission(EPermissionType.EDIT_ISNI), [checkPermission]);
  const canRequestNewISNI = useMemo(() => checkPermission(EPermissionType.REQUEST_NEW_ISNI), [checkPermission]);
  const canRemoveISNI = useMemo(() => checkPermission(EPermissionType.REMOVE_ISNI), [checkPermission]);
  const canViewHistory = useMemo(() => checkPermission(EPermissionType.VIEW_HISTORY), [checkPermission]);
  const manageTranslations = useMemo(() => checkPermission(EPermissionType.MANAGE_TRANSLATIONS), [checkPermission]);
  const canDeleteName = useMemo(() => checkPermission(EPermissionType.DELETE_NAME), [checkPermission]);
  const canEditParty = useCallback<(party: TPartyEntity) => boolean>(
    (party) => {
      if (!user.isAuthenticated) return false;
      if (typeof party === 'undefined') return false;
      if (user.groups.includes(EUserGroup.SUPER_USER)) return true;
      if (user.groups.includes(EUserGroup.DATA_USER)) {
        return party.createdById === user.uid;
      }
      if (permissions && permissions[EPermissionType.EDIT_PARTY]) return true;
      return false;
    },
  [user, permissions],
  );

  const canEditPerfArtistCompetency = useMemo(() => checkPermission(EPermissionType.EDIT_PERFORMING_ARTIST), [checkPermission]);
  const canRemovePerfArtistCompetency = useMemo(() => checkPermission(EPermissionType.REMOVE_PERFORMING_ARTIST), [checkPermission]);
  const canDuplicateName = useMemo(() => checkPermission(EPermissionType.BYPASS_DUPLICATE_CHECKING), [checkPermission]);
  const canRepublishToRAD = useMemo(() => checkPermission(EPermissionType.REPUBLISH_ACTION), [checkPermission]);

  return {
    pending,
    isAuthenticated,
    canReadParty,
    canCreateParty,
    canEditParty,
    canAssignISNI,
    canEditISNI,
    canRemoveISNI,
    canRequestNewISNI,
    permissions,
    filterPermission,
    // canEditPartyName,
    canViewHistory,
    canEditPerfArtistCompetency,
    canRemovePerfArtistCompetency,
    manageTranslations,
    canDuplicateName,
    canDeleteName,
    canRepublishToRAD
  };
};
