import {
  CompetencyType,
  EPartyType,
  Party,
  PartyCompetency,
  Translation
} from 'modules/common/types/partyTypes';
import { TPartyName } from 'domains/party/types';
import compose from 'lodash/fp/compose';
import get from 'lodash/get';
import omit from 'lodash/fp/omit';
import { TDictionaries } from './types';

type TFactoryAdapter<T> = (d: T) => T;

function accessor<T extends Record<string, any>, K extends keyof T>(adapter: (d: T[K]) => T[K], name: K, def?: T[K]) {
  return (d: T): T => {
    const data = get(d, name, def) as T[K];
    const rest = omit(name)(d);
    return ({ ...rest, [name]: adapter(data) } as T);
  };
}

function translationsAdapter(translations?: Translation[]): Translation[] {
  if (translations && translations.length) return translations;
  return [{
    languageVariantId: 0,
    name: '',
  }];
}

function competenciesFactoryAdapter(dict: ReadonlyArray<number>) {
  const empty = [{ competencyId: 0 }];
  return (competencies?: PartyCompetency[]): PartyCompetency[] => {
    if (typeof competencies === 'undefined') return empty;
    const c = competencies.filter(f => dict.includes(f.competencyId));
    if (c.length) return c;
    return empty;
  };
}

function prepareCompetencies(competencies: CompetencyType[]) {
  const competencyMaper = (c: CompetencyType) => ({ [c.partyType]: c.competencies.map(e => e.id) });
  const def = {} as Record<EPartyType, number[]>;
  return competencies.reduce((a, v) => ({ ...a, ...competencyMaper(v) }), def);
}

export function initialValuesFactoryAdapter(dict?: TDictionaries) {
  if (typeof dict === 'undefined') return (d: Party) => d;
  const { competencies } = dict;
  return (data: Party) => {
    const { partyType } = data;

    const competenciesAdapter = competenciesFactoryAdapter(prepareCompetencies(competencies)[partyType]);

    const adapterFactory: TFactoryAdapter<TPartyName> = compose(
      accessor(competenciesAdapter, 'competencies'),
      accessor(translationsAdapter, 'translations'),
    );

    const names = data.names.reduce((a, v) => [...a, adapterFactory(v)], [] as TPartyName[]);

    return {
      ...data,
      names,
    };

  };
}
