import styled from 'styled-components/macro';

const Banner = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  text-align: center;
  z-index: ${({ theme }) => theme.zIndex.level2}; ;
`;
export default Banner;
