
export interface IStorage<T> {
  get<K extends keyof T, V extends T[K]>(key: K, def?: V): Promise<V>;
  set<K extends keyof T, V extends T[K]>(key: K, value: V): Promise<void>;
}

export type StorageFactory<T> = () => IStorage<T>;

export enum IGROWKeys {
  PAGE_SIZE = 'PAGE_SIZE',
}

export interface IGROWSession {
  [IGROWKeys.PAGE_SIZE]: number,
}
