import { useCallback, useEffect } from 'react';
import axios from 'axios';
import { useLazyQuery } from '@apollo/client';
import { GET_SPOTIFY_TOKEN, } from 'graphql/queries';
import { ISpotifyToken } from 'graphql/types';
import { ISpotifyResp, ISpotifyImageContainer } from './types';

export const useSpotify = () => {

  const [
    getSpotifyToken,
    { data: tokenData },
  ] = useLazyQuery<ISpotifyToken>(GET_SPOTIFY_TOKEN);

  useEffect(() => { getSpotifyToken(); }, []);

  const getImagesBySpotifyId = useCallback<(id: string) => Promise<ReadonlyArray<ISpotifyImageContainer> | undefined>>(
    async(artistId) => {
      if (typeof tokenData?.getSpotifyToken === 'undefined') return undefined;
      try {
        const { data: { images } } = await axios.get<ISpotifyResp>(
          `https://api.spotify.com/v1/artists/${artistId}`,
          {
            headers: {
              Authorization: `Bearer ${tokenData?.getSpotifyToken.access_token}`,
            },
          }
        );
        return images;
      } catch (error: any) {
        if (error.response.status === 401) {
          getSpotifyToken();
        } else {
          return undefined;
        }
      }
    },
  [tokenData, getSpotifyToken],
  );

  return {
    getImagesBySpotifyId,
  };
};
