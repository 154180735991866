import React, { FC } from 'react';
import { Tooltip } from '@grow-components/Tooltip';
import styles from './PartyDateInputGroup.module.scss';
import { Field, useField } from 'react-final-form';
import { LegatoFFTextInput, LegatoFFSelect } from '../Form';
import { monthsAsSelectOptions } from 'modules/common/helpers/selectOptionsFormatters';
import { useTranslation } from 'react-i18next';

type TPartyDateInputGroupProps = {
  title: string
  titleTooltip: string
  className?: string
  dayInputName: string
  monthInputName: string
  yearInputName: string
}

export const PartyDateInputGroup: FC<TPartyDateInputGroupProps> = (props) => {
  const { title, titleTooltip, dayInputName, monthInputName, yearInputName } = props;
  const { t } = useTranslation();

  const field = useField(monthInputName);

  const validateDate = (value: string) => {
    if (!value) return undefined;
    if(value.length === 1 && isNaN(+value)) return undefined;
    const onlyNums = value.replace(/[^\d]/g, '');
    return onlyNums;
  };

  return (
    <div className={'party-date-input-group'}>
      <div className={styles.title}>
        <Tooltip text={titleTooltip}>
          <span>{title}</span>
        </Tooltip>
      </div>
      <div className={styles.inputsGroup}>
        <Field
          name={yearInputName}
          component={LegatoFFTextInput}
          placeholder={t('party_placeholders:enter_year')}
          parse={validateDate}
          maxLength={4}
        />
        <Field
          name={monthInputName}
          className={field.input.value ? styles.selectYear : null}
          inputClassName={styles.inputsGroup__select}
          component={LegatoFFSelect}
          placeholder={t('party_placeholders:select_month')}
          options={monthsAsSelectOptions}
        />
        <Field
          name={dayInputName}
          component={LegatoFFTextInput}
          parse={validateDate}
          placeholder={t('party_placeholders:enter_day')}
          maxLength={2}
        />
      </div>
    </div>
  );
};
