import React, { FC, useRef, useState } from 'react';
import { cn } from 'utils';
import { FieldRenderProps, useForm } from 'react-final-form';
import { TSelectOption } from 'common/types';
import { Tooltip } from '@grow-components/Tooltip/Tooltip';
import { Icon } from '@grow-components/Icon/Icon';
import { Label } from './Label';
import { Dropdown } from './Dropdown';
import { findTabStop } from 'utils/findAllInputs';
import styles from './StyledSelect.module.scss';

type TSelectProps = JSX.IntrinsicElements['select'] & {
  label?: string | JSX.Element
  labelTooltip?: string
  disabledTooltip?: string
  placeholder?: string
  className?: string
  options?: TOption[] & TSelectOption[]
  error?: string
  name: string
  loading?: boolean
  customIcon?: boolean
}
export type TOption = {
  value?: string
  label: string
  heading?: boolean
  labelAddon?: string
  key?: string
  type?: string
}


const CustomSelect: FC<TSelectProps> = (props) => {
  const { label, required, placeholder, options = [], className, name, error, loading, labelTooltip, disabledTooltip,customIcon, value, ...intrinsicProps } = props;
  const form = useForm();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>({} as HTMLInputElement);
  const dropdownOptions = placeholder ? [{ label: placeholder, value: '', key: 'placeholder' }, ...options] : options;
  const currentOption = options.find(o =>o?.value?.toString() === value?.toString());

  const handleSelect = (option: TOption | TSelectOption) => {
    form.change(name, option.value);
  };
  const onToggleModal = () => {
    setIsOpen(prev => !prev);
  };

  const focusTheNextElement = () => {
    const nextEl = findTabStop(inputRef.current, 'next');
    setIsOpen(false);
    nextEl.focus();
  };

  const renderControl = () => (
    <div className={styles.container}>
      <div onClick={onToggleModal} className={styles.inputContainer}>
        <input
          ref={inputRef}
          name={name}
          className={
            cn('form-control',
              { [styles.triggerOpen]: isOpen },
              { [styles.trigger]: !isOpen },
              { [styles.placeholder]: !value },
            )}
          onFocus={onToggleModal}
          readOnly          
          value={ currentOption?.label || value || placeholder }
        />
      </div>
      <Icon
        icon='chevron-down'
        className={cn(styles.icon, { [styles['icon--open']]: isOpen })}
      />
      {isOpen && (<Dropdown
        options={dropdownOptions}
        onSelect={handleSelect}
        onClose={() => setIsOpen(false)}
        value={currentOption as TOption & TSelectOption}
        onChangeTab={focusTheNextElement}
      />)}
    </div>);

  return (
    <div 
      className={cn('form-group', {
        'required': required,
        'has-error': !!error,
        [styles.wrapperIcon]: customIcon
      }, className)}
    >
      {label && (<Label 
        error={error} 
        loading={loading} 
        labelTooltip={labelTooltip} 
        label={label} 
        required={required} 
      />)}
      {intrinsicProps.disabled && disabledTooltip ? (
        <Tooltip text={disabledTooltip}>
          {renderControl()}
        </Tooltip>
      ) : (renderControl())}
    </div>
  );
};


export const FFStyledSelect: FC<FieldRenderProps<string> & TSelectProps & { forceError?: boolean }> = (props) => {
  const { input, meta, forceError, loading, ...rest } = props;
  return (
    <CustomSelect
      {...input}
      loading={meta.validating || loading}
      error={forceError
        ? meta.error
        : (meta.touched && meta.error)
      }
      {...rest}
    />
  );
};
