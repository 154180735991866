import React, { FC, useMemo, Dispatch, SetStateAction, useCallback } from 'react';
import { Pill } from '@grow-components/Pill';
import omit from 'lodash/fp/omit';
import { TFilters } from '../types';
import { dateChangeFormat } from '../helpers';
import styles from './pills-list.module.scss';
import { ISettings } from 'domains/env/types';

interface IProps<T = TFilters> {
  filters: T;
  setState: Dispatch<SetStateAction<T>>;
  settings: ISettings | undefined
}

export const PillsList: FC<IProps> = ({ filters, setState, settings }) => {
  const label = useMemo<string | undefined>(() => {
    if (typeof filters.dateTime === 'string') return dateChangeFormat(filters.dateTime, settings?.dateFormat.toUpperCase());
    if (Array.isArray(filters.dateTime)) return filters.dateTime.map((item)=>dateChangeFormat(item, settings?.dateFormat.toUpperCase())).join(' - ');
    return undefined;
  }, [filters, settings]);

  const handleAction = useCallback(() => { setState(omit('dateTime')); }, [setState]);
  return (
    <ul className={styles.container}>
      {
        label ? (
          <li className={styles.item}>
            <Pill label={label} onAction={handleAction} />
          </li>
        ) : null
      }
    </ul>
  );
};
