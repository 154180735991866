import { useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { TEQFactory, TEntry } from './types';

export function eqRecord<T extends Record<string, any>, K extends keyof T>(item: T, eqProperty: TEQFactory<T, K>) {
  const eqEntry = (a: boolean, [key, value]: TEntry<T, K>): boolean => a ? a : eqProperty(key, value);
  return Object.entries(item).reduce((a, v) => eqEntry(a, v as TEntry<T, K>), false);
}

export function eqFactory<T extends Record<string, any>, K extends keyof T>(init: T): TEQFactory<T, K> {
  return (key, value) => {
    switch (true) {
    case typeof key === 'string' && key.startsWith('_'):
      return false;
    case typeof init === 'undefined':
      return true;
    case value === null:
    case typeof value === 'undefined':
    case typeof value === 'boolean':
    case typeof value === 'string':
    case typeof value === 'number': {
      if (key in init) {
        return init[key] !== value;
      }
      return true;
    }
    case Array.isArray(value): {
      if (value.length !== init[key]?.length) {
        return true;
      } else {
        return value.reduce((a: boolean, v: any, i: number) => {
          if (a) return a;
          return eqFactory(init[key])(i, v);
        }, false);
      }
    }
    case typeof value === 'object': {
      return eqRecord(value, eqFactory(init[key]));
    }
    default:
      return false;
    }
  };
}

export function isModified<T extends Record<string, any>>(prev: T, next: T): boolean {
  try {
    return eqRecord(next, eqFactory(prev));
  } catch (err) {
    console.warn(err);
  }
  return true;
}

export const usePartyFormModified = (initialValues: Record<string, any>) => {
  const { values } = useFormState<Record<string, any>>();

  return useMemo(
    () => isModified(initialValues, values),
    [values]
  );
};
