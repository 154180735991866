import { Slider } from '@wmg-ae/legato';
import React, { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { cn } from 'utils';
import styles from './switch.module.scss';

type TInput = Omit<JSX.IntrinsicElements['input'], 'type'>;

interface IProps extends TInput {
  label: string | JSX.Element;
  className?: string;
  error?: string;
}

export const Switch: FC<IProps> = ({ label, error, className, ...intrinsicProps }) => {
  return (
    <>
      <label className={cn(styles.container, className)}>
        <input
          className={styles.input}
          {...intrinsicProps}
          type="checkbox"
        />
        <span className={styles.label}>{label}</span>
      </label>
      {error}
    </>
  );
};

export const FFSwitch: FC<FieldRenderProps<string> & IProps> = (props) => {
  const { input, meta, forceError, ...rest } = props;
  return (
    <Switch
      {...input}
      error={forceError
        ? meta.error
        : (meta.touched && meta.error)
      }
      {...rest}
    />
  );
};

export const LegatoSwitch: FC<FieldRenderProps<string> & IProps> = (props) => {
  const { input, meta, forceError, ...rest } = props;
  return (
    <Slider
      {...input}
      error={forceError
        ? meta.error
        : (meta.touched && meta.error)
      }
      {...rest}
    />
  );
};
