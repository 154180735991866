import { useLazyQuery } from '@apollo/client';
import { TSearchAppleIDResponse, TSearchAppleIDVars } from './types';
import { SEARCH_APPLE_ID } from '../graphql/partySearchIdentifiers';

export const useSearchAppleID = (partyId = 0, countryId = 0) => {

  const [searchAppleID, { loading, data, error }] = useLazyQuery<TSearchAppleIDResponse, TSearchAppleIDVars>(SEARCH_APPLE_ID, {
    fetchPolicy: 'no-cache',
  });

  const handler = (appleId: string) => {
    searchAppleID({ variables: {
      data: {
        partyId,
        countryId,
        appleId
      }
    } });
  };

  return { handler, loading, data, error };
};
