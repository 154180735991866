/**
 * Generates an external link for an artist based on a unique ID. (Example: creates a link to an artist's Spotify page when a Spotify ID is provided).
 * @param label
 * @param id
 */
import { EIdentifierType } from 'modules/common/types/partyTypes';
import { tMessage } from 'modules/common/helpers/translation';

type Link = {
  label: string;
  link: string | null;
}

export const createExternalLinks = (label: EIdentifierType | string, value: string): Link => {
  switch (label) {
  // ignore IPN and IPI
  case EIdentifierType.MBID:
    return {
      link: `https://musicbrainz.org/artist/${value}`,
      label: tMessage(label),
    };

  case EIdentifierType.APPLE:
    return {
      link: `https://music.apple.com/artist/${value}`,
      label: tMessage(label),
    };
  case EIdentifierType.DISCOGS:
    return {
      link: `https://discogs.com/artist/${value}`,
      label: tMessage(label),
    };
  case 'SPOTIFY':
    return {
      link: `https://open.spotify.com/artist/${value}`,
      label: tMessage(label),
    };
  case 'WIKIDATA':
    return {
      link: `https://wikidata.org/wiki/${value}`,
      label: tMessage(label),
    };

  case EIdentifierType.UAID:
    return {
      link: null,
      label: tMessage(label),
    };

  case EIdentifierType.PARTICIPANT_ID:
    return {
      link: null,
      label: tMessage(label),
    };

  case EIdentifierType.IPI:
    return {
      link: null,
      label: tMessage(label),
    };
  case EIdentifierType.IPN:
    return {
      link: null,
      label: tMessage(label),
    };
  case EIdentifierType.ISNI:
    return {
      link: null,
      label: tMessage(label),
    };
  case EIdentifierType.AMAZON:
    return {
      link: `https://music.amazon.com/artists/${value}`,
      label: tMessage(label),
    };
  case EIdentifierType.DEEZER:
    return {
      link: `https://www.deezer.com/artist/${value}`,
      label: tMessage(label),
    };
  case EIdentifierType.MERGED_ISNI: 
    return {
      link: null,
      label: tMessage(label),
    };
  default:return { link: null, label: '' };
  }
};
