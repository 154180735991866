import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import Layout from 'modules/common/components/Layout';
import { RecordChangeTimeAndAuthor } from '@grow-components/RecordChangeTimeAndAuthor/RecordChangeTimeAndAuthor';
import { Button, EButtonVariants } from '@grow-components/Button';
import { Tabs } from '@grow-components/Tabs';
import { ESpinnerSize } from '@grow-components/Spinners/consts';
import { BasicSpinner } from '@grow-components/Spinners/BasicSpinner';
import PartyInfo from 'modules/pages/PartyPage/components/PartyInfo';
import { useGetParty } from 'domains/party/hooks';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { PartyParams } from 'modules/common/types/routeTypes';
import { useDictionaries } from 'modules/common/hooks/useDictionaries';
import { GET_PARTY_LOG } from 'graphql/queries';
import { PartyHistory } from 'modules/common/components/PartyHistory/PartyHistory';
import { Subheader } from '@grow-components/Subheader/Subheader';
import styles from './PartyHistoryPage.module.scss';


export const PartyHistoryPage: FC = () => {
  const { t } = useTranslation();
  const { t: tHistory } = useTranslation('history');
  const { t: tPartyLabels } = useTranslation('party_labels');
  const { id } = useParams<PartyParams>();
  const { isPartyLoading, partyData } = useGetParty(+id);
  const { loading: loadingDictionaries } = useDictionaries();
  const pkaName = partyData?.names[0];
  const { data, loading } = useQuery(GET_PARTY_LOG, {
    variables: {
      data: {
        partyId: id
      }
    }
  });

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const currentLocation = useMemo(() =>
    currentTabIndex === 0 ? tHistory('page_history') : tHistory('field_history'),
  [currentTabIndex]);

  const goBack = useCallback(() => {
    window.close();
  }, []);

  const historyGroupByEntity = useMemo(() => {
    if (data?.partyGetLog.log) {
      return data?.partyGetLog.log.reduce((a: any, v: any) => ({
        ...a,
        [v.entity]: [...(get(a, v.entity, [])), v],
      }), {});
    }
    return {};
  }, [data]);

  const onTabChange = useCallback((tabIndex: string) => setCurrentTabIndex(+tabIndex), []);

  return (
    <Layout loading={isPartyLoading || loadingDictionaries} contentClassName="blue-background">
      {partyData && (
        <div className={'container'}>
          <Subheader location={currentLocation}
            mainInfo={pkaName?.nameValue}
            additionalInfo={<RecordChangeTimeAndAuthor
              type={'created'}
              createdAt={pkaName?.nameCreatedAt}
              createdBy={pkaName?.nameCreatedBy}
            />}
            rightSideBlock={
              <>
                {<Button
                  className={styles.backButton}
                  text={tPartyLabels('return_to_party')}
                  variant={EButtonVariants.link}
                  icon="chevron-left"
                  iconClassname={styles.backIcon}
                  iconLeft={true}
                  onClick={goBack}
                />}
              </>
            }
            className="blue-background"
          />
          {loading && <BasicSpinner size={ESpinnerSize.SMALL} />}
          {data &&
          <Tabs tabs={[
            {
              title: tHistory('page_history'),
              tooltip: t('tooltips:page_history_tab'),
              component: <PartyHistory data={data?.partyGetLog.log} partyType={partyData.party.partyType} />
            },
            {
              title: tHistory('field_history'),
              tooltip: t('tooltips:filed_history_tab'),
              component: <PartyInfo party={partyData} historyGroupByEntity={historyGroupByEntity} type="history" />
            }
          ]}
          className='blue-background'
          sticky
          onSelect={onTabChange}
          />}
        </div>
      )}
    </Layout>
  );
};
