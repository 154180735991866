import React, { useMemo } from 'react';
import get from 'lodash/get';
import { MenuItem } from './MenuItem'; 
import { DateFilter } from './DateFilter'; 
import { PartyHistoryItem } from '../types';
import { TSetState, TState } from './types';

export const useDateFilterItem = (
  name: string,
  field: string,
  data: PartyHistoryItem[],
  filters: TState,
  setFilters: TSetState
) => {

  const [min, max] = useMemo<[number, number]>(
    () => data.reduce((a, { dateTime }) => [
      Math.min(a[0], dateTime),
      Math.max(a[1], dateTime)
    ], [get(data, [0, 'dateTime']), 0]),
    [data],
  );

  const subMenu = useMemo(
    () => ({
      data,
      renderItem() {
        return (
          <DateFilter
            min={min}
            max={max}
            state={filters}
            setState={setFilters}
          />
        );
      }
    }),
    [min, max, filters, setFilters],
  );

  const menuItem = useMemo(() => (
    <MenuItem
      key={name}
      label={name}
      subMenu={subMenu}
    />
  ), [name, subMenu]);

  return {
    name,
    field,
    component: () => menuItem,
  };
};
