import { useUser } from 'domains/env/hooks/useUser';
import { TPartyEntryDetail } from 'domains/party/types';
import { useMemo } from 'react';

export const useIsPartyLocked = (partyData: TPartyEntryDetail | undefined): boolean | undefined => {
  const lastName = useUser().getItem('lastName');
  const firstName = useUser().getItem('firstName');

  return useMemo(() => partyData ? partyData.party.lock?.active
    && !partyData.party.lock?.createdBy.includes(firstName) && !partyData.party.lock?.createdBy.includes(lastName)
    : false, [partyData]);
};
