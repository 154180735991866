import { TNameDuplicationResult } from 'graphql/queries';

export enum EActionType {
  reset = 'RESET',
  openDialog = 'OPEN_DIALOG',
  setResult = 'SET_RESULT,'
}

type IAction = {
  type: EActionType.openDialog | EActionType.reset,
} | {
  type: EActionType.setResult;
  data: TNameDuplicationResult[];
}

interface IState {
  dialog: boolean;
  results: TNameDuplicationResult[];
}

export const initialState: IState = { dialog: false, results: [] };

export function reducer(state: IState, action: IAction) {
  switch (action.type) {

  case EActionType.reset:
    return initialState;

  case EActionType.openDialog:
    return {
      ...state,
      dialog: true,
    };

  case EActionType.setResult:
    return {
      ...state,
      results: action.data,
    };

  default:
    throw new Error('Unexpected action');
  }
}
