export const formatIdleTime = (time: number) => {
  if (time > 60) {
    const minutes = Math.floor(time / 60);
    return `${minutes}m`;
  } else if (Number(time) < 0) {
    return '0s';
  } else {
    return `${time}s`;
  }
};
