import React, { FC } from 'react';
import { TNameDuplicationResult } from 'graphql/queries';
import { formatISNIStatic } from 'modules/common/helpers/formatISNI';
import { useTranslation } from 'react-i18next';

type TNameDuplicatesTableProps = {
  data: TNameDuplicationResult[]
}

export const NameDuplicatesTable: FC<TNameDuplicatesTableProps> = ({ data }) => {
  const { t } = useTranslation('party_labels');
  return (
    <table className="table">
      <thead>
        <tr>
          <th>{t('party_type')}</th>
          <th>{t('name')}</th>
          <th>{t('name_type')}</th>
          <th>ISNI</th>
          <th>{t('origin_country')}</th>
        </tr>
      </thead>
      <tbody>
        {data.map(name => (
          <tr key={name.nameId}>
            <td>{name.partyType}</td>

            <td>
              {/* target blank is a business requirement */}
              {/* eslint-disable-next-line */}
              <a target="_blank" href={`/party/${name.partyId}`}>{name.nameValue}</a>
            </td>
            <td>{name.nameType}</td>
            <td>{name.nameIsni ? formatISNIStatic(name.nameIsni) : name.nameIsni}</td>
            <td>{name.partyCountryOfOriginName}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
