import React, { FC, Fragment, useMemo } from 'react';
import { Field } from 'react-final-form';
import { LegatoFFTextInput, LegatoFFSelect } from '@grow-components/Form';
import { ITranslationGroupProps } from './types';

export const TranslationGroup: FC<ITranslationGroupProps> = ({
  languages,
  isAlreadySelected,
  placeholders,
  disabled,
  translationName,
}) => {

  const options = useMemo(
    () => {
      if (typeof languages !== 'undefined') {
        return  languages.map(t => ({
          label: `${isAlreadySelected(t.languageVariantId) ? '* ' : ''}${t.displayName}`,
          value: t.languageVariantId,
          isDisabled: isAlreadySelected(t.languageVariantId)
        }));
      }
      return [];
    },
    [languages, isAlreadySelected],
  );

  return (
    <Fragment>
      <Field
        name={`${translationName}.languageVariantId`}
        component={LegatoFFSelect}
        disabled={disabled}
        placeholder={placeholders[0]}
        options={options}
      />
      <Field
        component={LegatoFFTextInput}
        disabled={disabled}
        placeholder={placeholders[1]}
        name={`${translationName}.name`}
        className={'mb-0'}
      />
    </Fragment>
  );
};
