import { useQuery } from '@apollo/client';
import { GetCountriesLanguagesGenresData } from 'graphql/types';
import { GET_COUNTRIES_LANGUAGES_GENRES } from 'graphql/queries';

export const useDictionaries = () => {
  const {
    loading,
    data
  } = useQuery<GetCountriesLanguagesGenresData>(GET_COUNTRIES_LANGUAGES_GENRES);
  const dictionaries = data?.lookupGetCollection;
  return {
    loading,
    dictionaries: dictionaries,
    getCountry: (countryId: number | undefined) => dictionaries?.countries.find(c => c.countryId === countryId),
    getCountryByISO: (countryIso: string | undefined) => dictionaries?.countries.find(c => c.isoCode === countryIso)
  };
};
