import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IsniResultItem } from 'modules/pages/IsniSearchPage/components/IsniSearchResults/IsniResultItem';
import { EEventType, useEventListener } from 'modules/common/hooks/useEventListener';
import { TIsniSearchData, TIsniSearchParams } from 'modules/common/types/isniTypes';

import './IsniSearchResults.scss';
import { usePartyLock } from 'domains/party/hooks/usePartyLock';

interface ISearchResultsProps {
  results: any[]
  isniData: TIsniSearchData
}

export const IsniSearchResults: FC<ISearchResultsProps> = ({ results, isniData }) => {
  const { nameId } = useParams<TIsniSearchParams>();
  const { lockParty } = usePartyLock(+isniData.partyId);
  const handleAssignFromDetails = (e: StorageEvent) => {
    if (e.key === `isniData_${nameId}` && e.newValue) {
      const { assignedFromDetails, ...rest } = JSON.parse(e.newValue);

      if (assignedFromDetails) {
        localStorage.setItem(`isniData_${nameId}`, JSON.stringify(rest));
        window.close();
      }
    }
  };

  useEffect(() => {
    lockParty();
  }, []);

  useEventListener(EEventType.storage, handleAssignFromDetails);

  return (
    <>
      {
        results.map(item => (
          <IsniResultItem
            key={`isniSearchResult_${item.isni}`}
            item={item}
            isniSearchData={isniData}
          />
        ))
      }
    </>
  );
};
