import React, { memo, FC, useMemo } from 'react';
import { Switch } from 'react-router-dom';
import { SecureRoute } from '@okta/okta-react';
import AccessDenied from 'modules/common/components/errors/pages/AccessDenied';
import { TRestriction } from 'modules/common/hooks/usePersmissions';
import { routes } from './routes';

interface IProps {
  filterPermission(r: TRestriction): boolean;
  ready: boolean;
}

const Router: FC<IProps> = ({ filterPermission, ready }) => {

  const availableRoute = useMemo(
    () => routes.filter(f => filterPermission(f.restriction)),
    [filterPermission]
  );

  return (
    <Switch>
      {
        availableRoute.map(r => (
          <SecureRoute key={r.id} {...r.props} component={r.component()} />
        ))
      }
      {
        ready ? (
          <SecureRoute path={'*'} component={AccessDenied} />
        ) : null
      }
    </Switch>
  );
};

export default memo(Router);
