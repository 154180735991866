import React, { FC, useCallback, useMemo } from 'react';
import { Button, EButtonSizes, EButtonVariants } from '@grow-components/Button/Button';
import { useFieldArray } from 'react-final-form-arrays';
import { SectionHeader } from '@grow-components/Form/InputsArray/SectionHeader';
import { cn } from 'utils';
import { Tooltip } from '@grow-components/Tooltip/Tooltip';
import styles from './InputsArray.module.scss';
import createEditPartyFormStyles from 'modules/pages/Party/CreateEditPartyForm.module.scss';

type TInputsArrayProps = {
  name: string
  title: string
  titleTooltip?: string
  addButtonText?: string
  addButtonTooltip: string
  deleteButtonTooltip: string
  component: (name: string, idx: number) => JSX.Element;
  onBeforeDelete?(fn: () => void, idx: number): void;
  disabled?: boolean;
}

export const InputsArray: FC<TInputsArrayProps> = (props) => {
  const { title, addButtonText = 'Add', name, disabled } = props;
  const { fields } = useFieldArray(name);

  const deleteHandler = useCallback(
    (idx) => () => {
      const fn = () => fields.remove(idx);
      if (typeof props.onBeforeDelete !== 'undefined') {
        props.onBeforeDelete(fn, idx);
      } else {
        fn();
      }
    },
    [fields],
  );

  const button = useMemo(
    () => disabled ? undefined : (
      <Tooltip text={props.addButtonTooltip}>
        <Button
          className={createEditPartyFormStyles.addButton}
          text={addButtonText}
          size={EButtonSizes.mini}
          variant={EButtonVariants.link}
          icon="plus"
          onClick={() => fields.push({})}
        />
      </Tooltip>
    ),
    [disabled, addButtonText, fields],
  );

  return (
    <div className={styles.container}>
      <SectionHeader
        title={<span className="flex align-center">
          {props.titleTooltip?(
            <Tooltip text={props.titleTooltip}>{title}</Tooltip>
          ):(title)}
        </span>}
        button={button}
      />
      <div className={cn(styles.inputsArray, 'columns-4')}>
        {fields.map((fieldName, idx) => (
          <div className={styles.inputsArrayItem} key={fieldName}>
            {props.component(fieldName, idx)}
            {
              disabled ? null : (
                <div className="flex justify-end">
                  <Tooltip text={props.deleteButtonTooltip}>
                    <Button
                      variant={EButtonVariants.link}
                      icon="trash-can"
                      size={EButtonSizes.small}
                      onClick={deleteHandler(idx)}
                      disabled={(fields?.length ?? 0) < 2}
                    />
                  </Tooltip>
                </div>
              )
            }
          </div>
        ))}
      </div>
    </div>
  );
};

