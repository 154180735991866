import React, { FC, useEffect, useMemo } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { LegatoFFSelect } from '@grow-components/Form';
import { enumsToSelectOptions } from 'modules/common/helpers/selectOptionsFormatters';
import { IFieldProps, IItemTypeProps } from './types';
import { EPartyType } from 'modules/common/types/partyTypes';

export const ItemType: FC<IItemTypeProps> = ({ dictionaries, partyType, isLoading }) => {

  const { t } = useTranslation();

  const props = useMemo<IFieldProps>(
    () => {
      if (partyType === EPartyType.PERSON) {
        return {
          name: 'partySubType',
          label: t('party_labels:person_type'),
          labelTooltip: t('tooltips:person_type'),
          placeholder: t('party_placeholders:select_person_type'),
          options: dictionaries?.personTypes.map(enumsToSelectOptions),
        };
      }
      if (partyType === EPartyType.GROUP) {
        return  {
          name: 'partySubType',
          label: t('party_labels:group_type'),
          labelTooltip: t('tooltips:group_type'),
          placeholder: t('party_placeholders:select_group_type'),
          options: dictionaries?.groupTypes.map(enumsToSelectOptions),
        };
      }
      throw new TypeError('Unexpected Party Type ' + partyType);
    },
    [partyType, dictionaries],
  );

  useEffect(() => {
    const element = document.querySelector<HTMLSelectElement>(`[name=${props.name}]`);
    if (element) {
      element.focus();
    }
  }, []);

  return (
    <Field
      {...props}
      component={LegatoFFSelect}
      required={true}
      disabled={isLoading}
    />
  );
};
