import React, { FC, useCallback, useMemo } from 'react';
import { Field } from 'react-final-form';
import { tMessage } from 'modules/common/helpers/translation';
import { LegatoFFSelect } from '@grow-components/Form';
import { useConfirmation } from './useConfirmation';
import { ICompetencyItemProps } from './types';
import { ENameType } from 'domains/party/types';
import { usePermissions } from 'modules/common/hooks/usePersmissions';

export const CompetenciesItem: FC<ICompetencyItemProps> = ({
  name,
  nameValues,
  placeholder,
  disabledTooltip,
  competency,
  required,
  isDisabled,
  isAlreadySelected,
}) => {
  const { canEditPerfArtistCompetency,canRemovePerfArtistCompetency } = usePermissions();
  const { element } = useConfirmation(name, false);

  const option = useCallback(
    (c) => ({
      label: `${isAlreadySelected(c.id) ? '* ' : ''}${tMessage(c.value)}`,
      value: c.id,
      isDisabled: isAlreadySelected(c.id)
    }),
    [isAlreadySelected],
  );

  const optionList = useMemo(() => competency.map(option), [option, competency]);

  return (
    <>
      <Field
        name={`${name}.competencyId`}
        component={LegatoFFSelect}
        className={'mb-0'}
        disabled={isDisabled}
        validate={() => {
          if (nameValues.competencies?.find((competency)=>((competency?.competencyId === 0 || competency?.competencyId === undefined) && nameValues.nameType === ENameType.PKA))
            && !canRemovePerfArtistCompetency
            && !canEditPerfArtistCompetency) {
            return 'Competency of performing artist needed to change name type';
          }
        }}
        required={required}
        placeholder={placeholder}
        disabledTooltip={disabledTooltip}
        options={optionList}
      />
      {
        element
      }
    </>
  );
};
