import { gql } from '@apollo/client';

export const FILE_EXPORT_SEARCH = gql`
    query FileExportSearch(
        $searchTerm: String!
        $searchType: SEARCH_TYPE!
        $filetype: SEARCH_FILE_TYPE!
    ) {
        fileExportSearch(data: {
            request: {
                searchTerm: $searchTerm
                searchType: $searchType
            }
            filetype: $filetype
        }) {
            url
            filename
        }
    }
`;
