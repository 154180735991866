import { useQuery } from '@apollo/client';
import { ESearchType, NAMES_SEARCH } from '../graphql';
import { TMainSearchResponse, TMainSearchVars } from 'domains/search/hooks/types';
import { useQueryMetaParams } from 'modules/common/hooks/useQueryMetaParams';

export const useMainSearch = (searchTerm: string) => {
  const { queryMetaParams } = useQueryMetaParams();
  const {
    loading,
    data,
    error
  } = useQuery<TMainSearchResponse, TMainSearchVars>(
    NAMES_SEARCH,
    {
      fetchPolicy: 'no-cache',
      variables: {
        searchTerm: searchTerm,
        searchType: ESearchType.FULL_SEARCH,
        ...queryMetaParams
      },
      skip: !searchTerm
    });
  const results = data?.searchNames;
  return { loading, data: results, error };
};
