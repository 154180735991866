import React, { FC, useMemo } from 'react';
import { cn } from 'utils';
import { TPartyName } from 'domains/party/types';
import { Identifier } from 'modules/common/types/partyTypes';
import { EIsniPageCameFrom } from 'modules/common/types/isniTypes';
import { IsniDelete } from 'modules/common/components/IsniDelete/IsniDelete';
import { usePermissions } from 'modules/common/hooks/usePersmissions';
import { SearchName } from 'modules/common/types/searchNameTypes';
import { useFocus } from 'utils/focus';
import { useIsni } from '../useIsni';
import { ISNILookup } from './Form';

import './IsniLookupModal.scss';

interface IIsniLookupModalProps {
  name: TPartyName | SearchName;
  className: string;
  currentIsni?: string;
  page?: EIsniPageCameFrom;
  removeIsni?: () => void;
  showModal: boolean;
  setShowModal: (state: boolean) => void;
}

export const IsniLookupModal: FC<IIsniLookupModalProps> = ({
  name,
  currentIsni,
  className,
  page,
  removeIsni,
  showModal,
  setShowModal
}) => {
  const { canRemoveISNI } = usePermissions();
  const refContainer = useFocus();
  const { makeSearch } = useIsni({
    currentISNI: currentIsni,
    page,
    name,
    hideModal: () => setShowModal(false),
  });

  const identifiers = useMemo<Identifier[] | undefined>(() => {
    if ('identifiers' in name) return name.identifiers;
    return undefined;
  }, [name]);

  return showModal ? (
    <div ref={refContainer} className={cn('isni-modal', className)} onClick={(e) => e.stopPropagation()}>
      <ISNILookup name={name} makeSearch={makeSearch} />
      {
        (canRemoveISNI && name.nameId && currentIsni) && (
          <div className="isni-modal__delete-wrapper">
            <IsniDelete
              nameId={name.nameId}
              name={name.nameValue}
              page={page}
              identifiers={identifiers}
              removeIsni={removeIsni}
            />
          </div>
        )
      }
    </div>
  ) : null;
};
