import React, { FC, useMemo } from 'react';
import styles from '@grow-components/Remarks/Remarks.module.scss';
import { Button, EButtonSizes, EButtonVariants } from '@grow-components/Button';
import { Tooltip } from '@grow-components/Tooltip';
import { useTranslation } from 'react-i18next';
import { formatDateTime, moment } from 'utils';
import { EDateTimeFormat } from 'common/consts';
import { ISettings } from 'domains/env/types';

type TRemarkCardProps = {
  title: string
  author?: string
  text: string
  date?: string
  onDelete?: () => void
  onEdit?: () => void
  readonly?: boolean
  format?: ISettings
}

export const RemarkCard: FC<TRemarkCardProps> = (props) => {
  const { title, text, author, date, readonly, format } = props;
  const { t } = useTranslation();
  const lastModifiedAt = useMemo(() => {
    const lastModifiedAt = moment.parseZone(date).unix();
    return {
      valid: moment(date).isValid(),
      date: formatDateTime(lastModifiedAt, format?.dateFormat.toUpperCase() || EDateTimeFormat.MM_DD_YYYY_Slash),
      time: formatDateTime(lastModifiedAt, format?.timeFormat || EDateTimeFormat.HH_MM_A)
    };
  }, [date, format]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Tooltip text={t('tooltips:remark_type')}>
          <div className={styles.title}>{title}</div>
        </Tooltip>
        <Tooltip text={t('tooltips:remark_text')}>
          <div className={styles.body}>{text}</div>
        </Tooltip>
        <div className={styles.footer}>
          <span className={styles.author}>{author}</span>
          {lastModifiedAt.valid && <span className={styles.date}>
            <span>{lastModifiedAt.time}</span>
            <span>{lastModifiedAt.date}</span>
          </span>}
          <span className={styles.actions}>
            {typeof props.onDelete === 'function' && !readonly && (
              <Button
                icon="trash-can"
                variant={EButtonVariants.link}
                size={EButtonSizes.small}
                onClick={props.onDelete}
              />
            )}
            {typeof props.onDelete === 'function' && !readonly && (
              <Button
                icon="pencil"
                variant={EButtonVariants.link}
                size={EButtonSizes.small}
                onClick={props.onEdit}
              />
            )}
          </span>
        </div>
      </div>
    </div>
  );
};
