import React, { FC, useEffect } from 'react';
import { Router } from 'Router';
import { usePermissions } from 'modules/common/hooks/usePersmissions';
import { BasicSpinner } from '@grow-components/Spinners/BasicSpinner';
import { useIdle } from 'modules/common/components/IdleAlert';
import { useSettings } from 'domains/env';
import { setLang } from 'utils/i18n';
import { BROADCAST_CHANNELS, useBroadcastChannel } from 'modules/common/hooks/useBroadcastChannel';
import { isTheSameUser } from 'utils/validateCurrentUser';
import { useOktaAuth } from '@okta/okta-react';
import styles from './modules/common/components/Layout/layouy.module.scss';

export const AuthenticateUser: FC = () => {
  const { filterPermission, pending, isAuthenticated } = usePermissions();
  const { receiveMessage, closeChannel: closeUserChannel } = useBroadcastChannel(BROADCAST_CHANNELS.USER_UTILS);
  const { settings, updateSettings } = useSettings();
  const { authState } = useOktaAuth();

  useEffect(() => {
    if (settings) setLang(settings?.language);
  }, [settings]);

  useEffect(() => {
    isTheSameUser(authState.idToken?.claims.email as string);
  }, []);

  useEffect(() => {
    receiveMessage((data: any) => updateSettings(data.language, data.timeFormat, data.dateFormat));
    return () => {
      closeUserChannel();
    };
  }, []);

  const { element } = useIdle();

  return (
    <>
      <Router filterPermission={filterPermission} ready={!pending && isAuthenticated} />
      {
        pending ? (
          <BasicSpinner className={styles.spinner} />
        ) : null
      }
      {
        element
      }
    </>
  );
};
