import React, { useCallback, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { useSaveParty } from 'domains/party/hooks';
import { createEditPartyPageLink, createViewPartyPageLink } from 'utils';
import { addPartyToList, IStoredParty } from 'redux/reducers/party';
import { validate } from 'modules/common/components/forms/CreateEditPartyForm/utils/validation';
import { TIsniSearchData } from 'modules/common/types/isniTypes';
import {
  checkIsModified,
  createInitialValuesFromExistingParty,
  storedPartyAdapter
} from 'modules/common/components/forms/CreateEditPartyForm/utils/formValues';
import { ToastService } from 'utils/ToastService';
import { PartyForm } from './Form';
import { usePreSubmitErrorToast, useSubmitSuccessToast, useSubmitWarningToast } from './submittimgToast';
import { TWrapperProps } from './types';
import { useSettings } from 'domains/env';
import { formatMessageModal } from '../utils/errorMessageModa';
import { usePartyLock } from 'domains/party/hooks/usePartyLock';
import { TPartyName } from 'domains/party/types';
import { FormApi } from 'final-form';
import { Party } from 'modules/common/types/partyTypes';
import { nameRemarksSortByDateFn } from 'domains/party/utils';

enum EHistoryStateStatus {
  cancel = 'cancel',
  submit = 'submit',
}

type THistoryState = EHistoryStateStatus | undefined | TIsniSearchData;

const CreateEditPartyForm: React.FC<TWrapperProps> = (props) => {
  const { initialValues, isEdit = false } = props;
  const [isAborting, setIsAborting] = useState(false);
  const { t } = useTranslation();
  const { id: partyId, partyType: initialPartyType } = initialValues;
  const dispatch = useDispatch();
  const { push  } = useHistory<THistoryState>();
  const { savePartyHandler } = useSaveParty(partyId);
  const savePartyToStore = (party: IStoredParty) => dispatch(addPartyToList(party));
  const { refetch } = useSettings();
  const { lockParty, unlockParty } = usePartyLock(partyId);

  const preSubmitErrorToast = usePreSubmitErrorToast();
  const submitSuccessToast = useSubmitSuccessToast();
  const submitWarningToast = useSubmitWarningToast();

  const handleCancel = useCallback(async () => {
    ToastService.dismissAll();
    setIsAborting(true);
    if (isEdit && initialValues.id) {
      await unlockParty();
      setIsAborting(false);
      push(createViewPartyPageLink(initialValues.id), EHistoryStateStatus.cancel);
    } else {
      push('/', EHistoryStateStatus.cancel);
    }
  }, [initialValues.id, initialPartyType]);

  const setResponseProperties = (formNames: TPartyName[], responseNames: TPartyName[], form: FormApi<Party, Partial<any>>) => {
    for (const formName of formNames) {
      const { nameValue } = formName;
      for (const responseName of responseNames) {
        if (formName.nameValue === responseName.nameValue) {
          const identifiersFromResponse = responseName.identifiers;
          const nameIdFromResponse = responseName.nameId;
          const remarksFromResponse = responseName.remarks?.sort(nameRemarksSortByDateFn);
          const indexFormName = form.getState().values.names.findIndex(formName => formName.nameValue === nameValue);
          form.mutators.update('names', indexFormName, {
            ...formName,
            nameCreatedAt: responseName.nameCreatedAt,
            nameCreatedBy: responseName.nameCreatedBy,
            nameUpdatedAt: responseName.nameUpdatedAt,
            nameUpdatedBy: responseName.nameUpdatedBy,
            identifiers: identifiersFromResponse,
            nameId: nameIdFromResponse,
            remarks: remarksFromResponse,
            competencies: responseName.competencies?.length ? responseName.competencies : formName.competencies,
            translations: responseName.translations?.length ? responseName.translations : formName.translations
          });
        }
      }
    }
  };

  const handleSubmit = useCallback(
    async (values, form) => {
      try {
        const response = await savePartyHandler(values);
        if (!isEdit) {
          ToastService.success(t('toasts:party_has_created'));
        }
        if (typeof response !== 'undefined') {
          savePartyToStore(storedPartyAdapter(response));
          setResponseProperties(form.getState().values.names, response.names, form);
          const { party: { partyId } } = response;
          await refetch();
          const isModified = checkIsModified(form.getState());
          if (isModified && isEdit) {
            ToastService.success(t('toasts:party_has_updated'));
          }
          if (values.saveType) {
            return push(createViewPartyPageLink(partyId), EHistoryStateStatus.submit);
          }
          if (!isEdit) return push(createEditPartyPageLink(partyId), EHistoryStateStatus.submit);
          else{ 
            lockParty();
            //this takes the values given by the response and converts them for changing the initialValues
            form.restart(createInitialValuesFromExistingParty(response));
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          return ({ ['names']: [{ 'nameValue': formatMessageModal(error.message) }] });
        }
      }
    },
    [history],
  );

  return (
    <>
      <Form
        initialValues={initialValues}
        validate={validate}
        decorators={[ preSubmitErrorToast, submitSuccessToast, submitWarningToast ]}
        initialValuesEqual={() => true}
        subscription={{
          submitting: true,
          pristine: true,
          values: true
        }}
        mutators={{
          ...arrayMutators,
        }}
        onSubmit={handleSubmit}
        isEdit={isEdit}
        init={initialValues}
        aborting={isAborting}
        onCancel={handleCancel}
        // eslint-disable-next-line
        //@ts-ignore
        component={PartyForm}
        keepDirtyOnReinitialize
      />
    </>
  );
};

export default memo(CreateEditPartyForm);
