import { gql } from '@apollo/client';

export const SEARCH_APPLE_ID = gql`
    query searchAppleId($data: PartySearchAppleIdentifierInput!) {
        partySearchAppleIdentifier(data: $data) {
          artist {
            id
            type
            genreNames
            name
            url
          }
        }
    }
`;

export const SEARCH_SPOTIFY_ID = gql`
    query searchSpotifyId($data: PartySearchSpotifyIdentifierInput!) {
        partySearchSpotifyIdentifier(data: $data) {
          artist {
            id
            type
            genreNames
            name
            url
          }
        }
    }
`;

