import React, { FC } from 'react';
import { Icon } from '@grow-components/Icon/Icon';
import styles from './pill.module.scss';

interface IProps {
  label: string | JSX.Element;
  onAction(): void;
  removeDisabled?: boolean,
  id?: string
}

export const Pill: FC<IProps> = ({ onAction, label, removeDisabled, id }) => {
  return (
    <div data-test-id={`advanced_search_pill_${id}`} className={styles.container}>
      <div className={styles.label}>{label}</div>
      <button data-test-id={`advanced_search_pill_delete_button_${id}`} type="button" onClick={onAction} className={styles.btn} disabled={removeDisabled}>
        <Icon icon="xmark" className={styles.icon} />
      </button>
    </div>
  );
};
