import React, { FC, useCallback, useState } from 'react';
import styles from './RequestIsniModal.module.scss';
import { Button } from '@wmg-ae/legato';
import { Trans, useTranslation } from 'react-i18next';
import { GenericModal } from '@grow-components/GenericModal/GenericModal';
import { useGetParty, useSaveParty } from 'domains/party/hooks';
import { BasicSpinner } from '@grow-components/Spinners';
import { ESpinnerSize } from '@grow-components/Spinners/consts';
import { tMessage } from 'modules/common/helpers/translation';
import { EIdentifierType, EPartyType, Identifier, Party, PartyType } from 'modules/common/types/partyTypes';
import { useDictionaries } from 'modules/common/hooks/useDictionaries';
import { WarningIcon } from '@grow-components/Icon/IconWithTooltip';
import { useGetPartyAssets } from 'domains/party/hooks/useGetPartyAssets';
import { useRequestISNI } from 'domains/isni/hooks/useRequestISNI';
import { TPartyEntity, TPartyEntryDetail, TPartyName } from 'domains/party/types';
import { RequestIsniResultModal } from './RequestIsniResultModal/RequestIsniResultModal';
import { dateChangeFormat } from 'modules/common/components/PartyHistory/helpers';
import { useSettings } from 'domains/env';
import { convertDate } from 'modules/common/helpers/dateHelpers';
import { EIsniPageCameFrom, EIsniSearchParam, IIsniSearchFilter, TIsniQueryParams } from 'modules/common/types/isniTypes';
import { useApolloClient } from '@apollo/client';
import { SEARCH_ISNI } from 'graphql/queries';
import { TIsniSearchResponse } from 'domains/isni/types';
import { useQueryParams } from 'modules/common/hooks/useQueryParams';
import { reformatIsni } from 'modules/common/components/isniAssign/helpers';

const MIN_ASSETS_COUNT = 1;

type TRequestIsniModalProps = {
  partyId: number;
  nameId: number;
  onClose: () => void;
  requestFromDetails?: boolean;
}
const FULL_DATE_LENGTH = 3;

export const RequestIsniModal: FC<TRequestIsniModalProps> = ({ partyId, nameId, onClose, requestFromDetails }) => {
  const { t } = useTranslation();
  const { getCountry, getCountryByISO } = useDictionaries();
  const { t: tParty } = useTranslation('party_labels');
  const { partyData, isPartyLoading, getNameById, getIdentifierFromName, getPartyDates } = useGetParty(partyId);
  const [isniRequestCompleted, setIsniRequestCompleted] = useState(false);
  const [existingIsniRequestLoading, setExistingIsniRequestLoading] = useState<boolean>(false);
  const [requestIsniData, setRequestIsniData] = useState<any>(null);
  const { settings } = useSettings();
  const client = useApolloClient();
  const { params } = useQueryParams<TIsniQueryParams>();
  const { savePartyHandler } = useSaveParty(partyId);
  const party = partyData?.party;
  const name = getNameById(nameId);
  const uaid = getIdentifierFromName(name, EIdentifierType.UAID)?.value;
  const startDateLabel = party?.partyType === EPartyType.PERSON ? tParty('date_birth') : tParty('date_formed');
  const endDateLabel = party?.partyType === EPartyType.PERSON ? tParty('date_death') : tParty('date_disbanded');
  const { startDate, endDate } = getPartyDates();
  const { data: assets, loading: loadingAssets } = useGetPartyAssets(uaid);
  const assetsCount = assets?.assetCount ?? 0;
  const isAssetsCountValid = assetsCount >= MIN_ASSETS_COUNT;
  const {
    loading: isRequestIsniLoading,
    requestIsni,
    getFirstLastName
  } = useRequestISNI(party as TPartyEntity, name as TPartyName);
  const isAnyLoading = isPartyLoading || loadingAssets || isRequestIsniLoading || existingIsniRequestLoading;

  const submitIsniRequestHandler = useCallback(async () => {
    try {
      const data = await requestIsni(uaid as string);
      if (data?.status === 201 && 'party' in data && !data.internalParty) {
        setExistingIsniRequestLoading(true);
        const existingISNI = await client.query<{ searchIsni: TIsniSearchResponse }, IIsniSearchFilter>({
          query: SEARCH_ISNI,
          variables: {
            data: {
              identifier: {
                isFull: true,
                value: data.party.isni,
                type: EIsniSearchParam.ISNI,
              }
            }
          },
        });
        const { isni } = existingISNI.data.searchIsni;
        const [isniInfo] = isni;
        if (params.cameFrom === EIsniPageCameFrom.SEARCH) {
          // generate the party update from here
          const isniComment = isniInfo ? isniInfo.comment : data.party.comment;
          const ids = isniInfo ? isniInfo.identifiers : data.party.identifiers;
          // parse values for partySubmit
          const partyValues = reformatIsni(name?.nameValue, {
            partyData: partyData as TPartyEntryDetail,
            isniComment,
            ids: ids.map(item => ({ identifierType: item.type, value: item.value })) as Identifier[],
          }, getCountryByISO);

          await savePartyHandler(partyValues as Party);
        }

        return isniInfo ? setRequestIsniData({
          ...data,
          party: {
            ...data.party,
            ...isniInfo
          }
        }) : setRequestIsniData(data);
      }
      if (data) {
        return setRequestIsniData(data);
      }
    } catch(err) {
      console.error(err);
    } finally {
      setExistingIsniRequestLoading(false);
      setIsniRequestCompleted(true);
    }
  }, [requestIsni, uaid]);

  const renderNameFields = useCallback((nameString: string, partyType: PartyType) => {
    const { firstname, lastname } = getFirstLastName(nameString, partyType);

    const primaryFieldValue = firstname ? firstname : lastname;
    const secondaryFieldValue = firstname ? lastname : null;

    const primaryFieldLabel = secondaryFieldValue ? t('first_name') : t('name');
    const secondaryFieldLabel = t('last_name');

    return (
      <>
        <div className={styles.paramsItem}>
          <div className={styles.paramsLabel}><b>{primaryFieldLabel}</b></div>
          <div>{primaryFieldValue}</div>
        </div>
        {secondaryFieldValue && (
          <div className={styles.paramsItem}>
            <div className={styles.paramsLabel}><b>{secondaryFieldLabel}</b></div>
            <div>{secondaryFieldValue}</div>
          </div>
        )}
      </>
    );
  }, []);
  const canSubmit = !isAnyLoading && uaid && isAssetsCountValid;

  return !isniRequestCompleted ? (
    <GenericModal
      name="request_isni_modal"
      title={t('request_isni')}
      footer={
        <div className={styles.actions}>
          <Button
            label={t('cancel')}
            containerStyle={'outline'}
            onClick={() => onClose()}
            className={styles.cancelButton}
            colorType={'info'}
          />
          <Button
            label={t('submit')}
            disabled={!canSubmit}
            onClick={submitIsniRequestHandler}
            containerStyle={'solid'}
            colorType={'info'}
          />
        </div>
      }
      onClose={onClose}
    >
      {isPartyLoading || isRequestIsniLoading || existingIsniRequestLoading
        ? (
          <BasicSpinner size={ESpinnerSize.MEDIUM} />
        ) : (
          uaid && name && party
            ? (
              <>
                <p className={styles.helpText}>{t('modal:request_isni_modal_help_text')}</p>
                <div className={styles.section}>
                  <div className={styles.sectionTitle}>{t('basic_information')}</div>
                  <div className={styles.sectionContent}>
                    <div>
                      <div className={styles.paramsRow}>
                        <div className={styles.paramsItem}>
                          <div className={styles.paramsLabel}><b>{tParty('party_type')}</b></div>
                          <div>{tMessage(party.partyType)}</div>
                        </div>
                        {renderNameFields(name.nameValue, party.partyType)}
                      </div>
                      <div className={styles.paramsRow}>
                        <div className={styles.paramsItem}>
                          <div className={styles.paramsLabel}>
                            <b>{tParty('origin_country')}</b>
                            {!party.countryOfOriginId && (
                              <WarningIcon text={t('warnings:request_isni_country_warning')} />
                            )}
                          </div>
                          <div>{getCountry(party.countryOfOriginId)?.name ?? '—'}</div>
                        </div>
                      </div>
                      <div className={styles.paramsRow}>
                        <div className={styles.paramsItem}>
                          <div className={styles.paramsLabel}><b>{startDateLabel}</b></div>
                          <div>{startDate?.split(' ').length === FULL_DATE_LENGTH ? 
                            dateChangeFormat(startDate, settings?.dateFormat?.toUpperCase()) : convertDate(startDate?.split(' ') as string[])}</div>
                        </div>
                        {endDate && (
                          <div className={styles.paramsItem}>
                            <div className={styles.paramsLabel}><b>{endDateLabel}</b></div>
                            <div>{endDate?.split(' ').length === FULL_DATE_LENGTH ? 
                              dateChangeFormat(endDate, settings?.dateFormat?.toUpperCase()) : convertDate(endDate?.split(' ') as string[])}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.section}>
                  <div className={styles.sectionTitle}>
                    {t('assets')}
                    {(!loadingAssets && !isAssetsCountValid) && (
                      <WarningIcon text={t('warnings:request_isni_assets_warning')} />
                    )}
                  </div>
                  <div className={styles.sectionContent}>
                    {loadingAssets ? (
                      <BasicSpinner size={ESpinnerSize.SMALL} />
                    ) : (
                      <div>
                        <Trans
                          i18nKey="modal:request_isni_modal_artist_has_assets"
                          values={{
                            count: assetsCount
                          }}
                        />
                        <p>
                          {isAssetsCountValid ? (
                            <span className={styles.success}>{t('modal:request_isni_modal_enough_assets')}</span>
                          ) : (
                            <span className={styles.warning}>{t('modal:request_isni_modal_min_assets')}</span>
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <p className={styles.helpText}>You can not request an isni without UAID assigned to the name</p>
            )
        )
      }
    </GenericModal>
  ) : (
    <RequestIsniResultModal
      partyId={partyId}
      name={name?.nameValue as string}
      data={requestIsniData}
      partyData={partyData}
      previous={() => {setIsniRequestCompleted(false);}}
      requestFromDetails={requestFromDetails}
    />
  );
};
