import { gql } from '@apollo/client';
export const PARTY_GET_IMAGE = gql`
  query partyGetSpotifyImages($data: PartyGetSpotifyImagesInput!) {
    partyGetSpotifyImages(data: $data) {
      images {
        height
        url
        width
      }
    }
  }
`;
