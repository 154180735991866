import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicSpinner } from '@grow-components/Spinners';
import { PartyPreview } from '@grow-components/PartyPreview';
import { ESpinnerSize } from '@grow-components/Spinners/consts';
import { useGetMyRecentParties } from 'domains/party/hooks';
import { TRecentPartiesEntry } from 'domains/party/hooks/types';
import { ERecentPartiesAction } from '../consts';

import styles from './recentParties.module.scss';
import { ISettings } from 'domains/env/types';

interface IRecentPartiesProps {
  userAction: ERecentPartiesAction
  settings?: ISettings
}

const RECENT_PARTIES_SIZE = 6;

export const RecentParties: FC<IRecentPartiesProps> = ({ userAction, settings }) => {
  const { t: tLanding } = useTranslation('landing');
  const { recentPartiesData, recentPartiesLoading } = useGetMyRecentParties(RECENT_PARTIES_SIZE, userAction);

  const emptyPlaceholder = useMemo(() => {
    switch (userAction) {
    case ERecentPartiesAction.CREATED: return tLanding('empty_created_parties');
    case ERecentPartiesAction.UPDATED: return tLanding('empty_modified_parties');
    default: return '';
    }
  }, [userAction]);

  const partiesList = useMemo(() => {
    if (!recentPartiesData) return null;

    return recentPartiesData.map((entry: TRecentPartiesEntry, index) =>
      <PartyPreview key={`myRecentPartiesEntry_${userAction}_${index}`} dateFormat={settings?.dateFormat} partyEntry={entry} userAction={userAction} />);
  }, [recentPartiesData, userAction, settings]);

  return  <>
    {recentPartiesLoading && <div className={styles.spinnerWrapper}>
      <BasicSpinner size={ESpinnerSize.MEDIUM} />
    </div>}
    {recentPartiesData && <div className={styles.recentPartiesWrapper}>
      {!recentPartiesData.length ?
        <div className={styles.emptyRecentParties}>{emptyPlaceholder}</div>
        :
        <>{ partiesList }</>
      }
    </div>}
  </>;
};