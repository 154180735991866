import jwt_decode from 'jwt-decode';
import { EUserGroup } from 'domains/env/types';
import { DecodedToken } from '../types/tokenTypes';
import makeTitleCase from './makeTitleCase';

const envName = process.env.REACT_APP_ENV_NAME;

// this represents a mapping between envs and okta groups
// e.g. in dev/qa/muat we use dev okta groups, in other envs - dedicated groups
const envsToGroups: any = {
  Development: 'dev',
  QA: 'dev',
  UAT: 'uat',
  'UAT-R3': 'uat',
  MUAT: 'dev',
  Staging: 'staging',
  'STAGING-R3': 'staging',
  Production: 'prod',
  'PROD-R3': 'prod',
  'PROD': 'prod',
};

/**
 * Decodes a JWT, returning the decoded token as an object
 * @param token
 */
export const parseToken: (token: string) => DecodedToken = (token) => {
  return jwt_decode(token);
};

export const parseUserInfoFromToken = (token: string) => {
  const parsedToken = parseToken(token);
  return parsedToken.sub;
};

/**
 * Create a two-letter initial based on a provided user name
 * @param username
 */
export const createUserInitials = (username: string) => {
  const parsedName = username.split(' ');
  if (parsedName.length === 1) {
    return parsedName[0][0];
  } else if (username.split(', ').length === 2) {
    const reversedName = username.split(', ').reverse();
    return reversedName[0][0] + reversedName[1][0];
  }
  return parsedName[0][0] + parsedName[1][0];
};

const formatName = (group: string): EUserGroup => {
  const sliced = group.slice(group.lastIndexOf('_') + 1);
  const separated = sliced.replace(/([A-Z])/g, ' $1').trim();
  return separated as EUserGroup;
};

export const formatGroupNames = (groups: string[]): ReadonlyArray<EUserGroup> => {
  const groupSet: Set<EUserGroup> = new Set();
  groups.forEach((group) => {
    if (
      envName &&
      group?.toLowerCase().includes(`_${envsToGroups[envName]?.toLowerCase()}_`)
    ) {
      const formattedGroup = formatName(group);
      groupSet.add(formattedGroup);
    }
  });
  return Array.from(groupSet);
};

export const handleGroups = (groups: string[]) => {
  return formatGroupNames(groups);
};

export const grabNameFromEmail = (email: string) => {
  const emailArr = email.split('.');
  if (emailArr.length === 1) return email;
  let endIdx;
  if (emailArr.length === 2) {
    endIdx = 0;
  } else endIdx = 1;
  emailArr.splice(endIdx + 1);
  const atIdx = emailArr[endIdx].indexOf('@');
  emailArr[endIdx] = emailArr[endIdx].slice(0, atIdx);
  const nameString = emailArr.join(' ');
  return makeTitleCase(nameString);
};

/**
 * Returns true only if the user's permissions correspond with those of a superuser.
 * @param permissions
 */
export const identifySuperuser = (permissions: string[]) => {
  if (
    permissions.includes('assign_isni') &&
    permissions.includes('edit_isni') &&
    permissions.includes('remove_isni') &&
    permissions.includes('request_new_isni')
  ) {
    return true;
  }
  return false;
};
