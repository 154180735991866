import { Type } from 'graphql/types';

export const concatArtistName = (
  firstName: string | null,
  lastName: string
) => {
  if (!firstName) return lastName;
  return `${firstName} ${lastName}`;
};

export const formatArtistBirthLabel = (artistType: Type | undefined) => {
  if (!artistType) return 'Deceased Date';
  if (artistType === Type.BAND) {
    return 'Formed Date:';
  } else return 'Birth Date:';
};

export const formatArtistDeathLabel = (artistType: Type | undefined) => {
  if (!artistType) return 'Deceased Date';
  if (artistType === Type.BAND) {
    return 'Disbanded Date:';
  } else return 'Deceased Date:';
};

/**
 * Capitalizes first letter of a string
 * @param str
 */
export const capitalizeFirstLetter = (str: string | null) => {
  if (!str) return '';
  return str[0].toUpperCase() + str.toLowerCase().slice(1);
};

export const formatFirstNameQuansic = (str: string) => {
  const strArr = str.split(' ');
  if (strArr.length <= 1) return '';
  return strArr.slice(0, strArr.length - 1).join(' ');
};

export const formatLastNameQuansic = (str: string) => {
  const strArr = str.split(' ');
  if (strArr.length <= 1) return str;
  return strArr.slice(strArr.length - 1).join(' ');
};
