import { useMutation } from '@apollo/client';
import { TPartyEntity, TPartyName } from 'domains/party/types';
import { getPartyDates } from 'domains/party/utils';
import { PROCURE_ISNI_SUPERUSER } from 'graphql/mutations';
import { useDictionaries } from 'modules/common/hooks/useDictionaries';
import {
  EQuansicEntityType,
  IQuansicEntity,
  IQuansicEntityName,
  IRequestIsniResponse,
  IRequestIsniVars
} from 'modules/common/types/isniTypes';
import { EPartyType, PartyType } from 'modules/common/types/partyTypes';

export const useRequestISNI = (party: TPartyEntity, name: TPartyName) => {
  const { getCountry } = useDictionaries();
  const [
    procureIdentifier, { loading, error }
  ] = useMutation<IRequestIsniResponse, IRequestIsniVars>(PROCURE_ISNI_SUPERUSER);

  const getFirstLastName = (nameString: string, partyType: PartyType) => {
    const wordsDelimiter = ' ';
    let lastname = '';
    let firstname = null;

    if (partyType === EPartyType.GROUP) {
      lastname = nameString;
      firstname = null;
    } else {
      const nameAsArr = nameString.split(wordsDelimiter);
      const wordsInName = nameAsArr.length;
      if (wordsInName === 1) {
        lastname = nameString;
        firstname = null;
      } else if (wordsInName === 2) {
        firstname = nameAsArr[0];
        lastname = nameAsArr[1];
      } else if (wordsInName > 2) {
        lastname = nameAsArr.pop() as string; // last word
        firstname = nameAsArr.join(wordsDelimiter); // all, except of the last word
      }
    }

    return {
      firstname,
      lastname
    };
  };

  const getQuansicEntity = (party: TPartyEntity, name: TPartyName): IQuansicEntity => {
    const { firstname, lastname } = getFirstLastName(name.nameValue, party.partyType);
    const quansicName: IQuansicEntityName = { lastname };
    if (firstname !== null) quansicName.firstname = firstname;
    const countryIsoCode = getCountry(party.countryOfOriginId)?.isoCode;

    const quansicEntity: IQuansicEntity = {
      type: party.partyType === EPartyType.GROUP ? EQuansicEntityType.BAND : EQuansicEntityType.PERSON,
      name: quansicName,
      countries: countryIsoCode ? [countryIsoCode] : [],
    };
    const { startDateArray, endDateArray } = getPartyDates(party);

    const quansicDayMonth = (d: number): string => d < 10 ? ('0' + d).slice(-2) : '' + d;

    if (startDateArray?.length) quansicEntity.birthdate = startDateArray.map(quansicDayMonth).join('-');
    if (endDateArray?.length) quansicEntity.deathdate = endDateArray.map(quansicDayMonth).join('-');

    return quansicEntity;
  };

  const requestIsni = async (uaid: string) => {
    try {
      const res = await procureIdentifier({
        variables: {
          data: {
            uaid,
            quansicEntity: getQuansicEntity(party, name),
          }
        }
      });

      const data = res.data?.identifierProcure;

      return data;
    } catch (err) {
      console.warn(err);
      return { status: 504 }; // timeout
    }
  };

  return { loading, error, requestIsni, getFirstLastName };
};
