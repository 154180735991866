import { Icon } from '@grow-components/Icon/Icon';
import { Datepicker, Input } from '@wmg-ae/legato';
import { format, isValid } from 'date-fns';
import { useSettings } from 'domains/env';
import React, { FC, useCallback, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { FieldRenderProps } from 'react-final-form';

import styles from './DatePicker.module.scss';
interface TDatePickerProps extends FieldRenderProps<string> {
  placeholder?: string;
  dateFormat?: string;
}

export const toInternalFormat = (date: string | number | Date): string => format(new Date(date), 'yyyy-MM-dd');

export const FFDatePicker: FC<TDatePickerProps> = (props) => {
  const { name, input: { value, onChange }, placeholder, dateFormat } = props;
  const { settings } = useSettings();
  const selectedDate = useMemo<Date | null>(
    () => {
      const date = new Date(value);
      if (value === null) return null;
      if (isValid(date)) return date;
      return null;
    },
    [value],
  );
  const handleChange = useCallback(
    (date: Date) => {
      if (date && isValid(date)) {
        onChange(date.toISOString());
      } else {
        onChange(null);
      }
    },
    [onChange],
  );
  return (
    <div className={styles.wrapper}>
      <ReactDatePicker
        placeholderText={placeholder || settings?.dateFormat}
        dateFormat={dateFormat || settings?.dateFormat?.replace('mm', 'MM')}
        selected={selectedDate}
        disabledKeyboardNavigation
        name={name}
        className={'form-control'}
        onChange={handleChange}
      />
      <Icon icon='calendar' className={styles.icon} /> 
    </div>
  );
};

export const LegatoDatePicker: FC<TDatePickerProps> = (props) => {
  const { name, input: { value, onChange }, placeholder, dateFormat } = props;
  const { settings } = useSettings();
  const [ icon, setIcon ] = useState(false); 

  const selectedDate = useMemo<Date | null>(
    () => {
      const date = new Date(value);
      if (value === null) return null;
      if (isValid(date)) return date;
      return null;
    },
    [value],
  );
  const handleChange = useCallback(
    (date: Date) => {
      if (date && isValid(date)) {
        onChange(date.toISOString());
      } else {
        onChange(null);
      }
    },
    [onChange],
  );
  return (
    <div className={styles.wrapper}>
      <Datepicker
        placeholder={placeholder || settings?.dateFormat}
        dateFormat={dateFormat || settings?.dateFormat?.replace('mm', 'MM')}
        selected={selectedDate}
        disabledKeyboardNavigation
        name={name}
        onCalendarOpen={()=>setIcon(true)}
        onCalendarClose={()=>setIcon(false)}
        customInput={<Input
          className={!icon ? styles.blueIcon : styles.whiteIcon} 
          suffixIcon={'calendar-days'}
          enterKeyHint
        />}
        shouldCloseOnSelect={true}
        onChange={handleChange}
      />
    </div>
  );

  
};
