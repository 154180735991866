import React, { FC } from 'react';

import styles from './EmptySearchResult.module.scss';

interface IEmptySearchResultProps {
    headline?: string | JSX.Element;
    message?: string | JSX.Element;
    actions?: JSX.Element;
}

export const EmptySearchResult: FC<IEmptySearchResultProps> = ({ headline, message, actions }) =>
  <div className={styles.wrapper}>
    <div className={styles.content}>
      <div className={styles.headline}>{headline}</div>
      <div className={styles.messageWrapper}>
        <div className={styles.message}>{message}</div>
        {actions}
      </div>
    </div>
  </div>;