import React, { FC } from 'react';
import { cn } from 'utils';

import styles from './imageUnavailable.module.scss';
import { Icon } from '@wmg-ae/legato';

interface IImageUnavailableProps {
  className?: string;
  isRound?: boolean;
}

const ImageUnavailable: FC<IImageUnavailableProps> = ({ className, isRound }) => {
  return (
    <div className={cn(styles.wrapper, { [styles.round]: isRound }, className)}>
      <Icon className={cn(styles['img-placeholder'], { [styles.round]: isRound })} name='user-music' />
    </div>
  );
};

export default ImageUnavailable;
