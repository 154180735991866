import React, { FC } from 'react';
import { Layout } from 'modules/common/components/Layout';
import { cn, convertPartyTypeStringToEnum } from 'utils';
import { RouteComponentProps } from 'react-router';
import { createInitialValuesFromScratch } from 'modules/common/components/forms/CreateEditPartyForm/utils/formValues';
import { useParams } from 'react-router-dom';
import { useDictionaries } from 'modules/common/hooks/useDictionaries';
import { CreateEditPartyForm } from 'modules/common/components/forms/CreateEditPartyForm';

type TCreatePartyPageURLProps = {
  partyType: string
}
type TCreatePartyPageProps = RouteComponentProps<TCreatePartyPageURLProps>

const CreateParty: FC<TCreatePartyPageProps> = () => {
  const { loading: loadingDictionaries } = useDictionaries();
  const { partyType: URLPartyType } = useParams<{ partyType: string }>();
  const partyType = convertPartyTypeStringToEnum(URLPartyType);

  return (
    <Layout loading={loadingDictionaries}>
      <div className={cn('container')}>
        <CreateEditPartyForm
          initialValues={createInitialValuesFromScratch(partyType)}
        />
      </div>
    </Layout>
  );
};

export const CreatePartyPage = React.memo(CreateParty);
