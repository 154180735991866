import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Layout from 'modules/common/components/Layout';
import { Icon } from '@grow-components/Icon/Icon';
import { FullScreenMessage } from '@grow-components/FullScreenMessage/FullScreenMessage';

import styles from './error-pages.module.scss';

const AccessDenied = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>GROW / {t('party_workspace')} | {t('smth_wrong')}</title>
      </Helmet>
      <Layout contentClassName={styles.content}>
        <FullScreenMessage
          thumbnail={<Icon icon='music-slash' className={styles.musicSlash} />}
          message={t('errors:not_authorized')}
          details={t('errors:please_contact_admin')} />
      </Layout>
    </>
  );
};

export default AccessDenied;
