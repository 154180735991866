import {
  EAdvancedSearchField,
  EAdvancedSearchOperator,
  TAdvancedSearchOperation,
  TAdvancedSearchValues
} from 'domains/search/types';

export const useAdvancedSearchOperations = (vars: TAdvancedSearchValues): TAdvancedSearchOperation[] => {
  const operations: TAdvancedSearchOperation[] = [];
  const nameOperation: TAdvancedSearchOperation = {
    field: EAdvancedSearchField.NAME_VALUE,
    operator: vars.nameOperator as EAdvancedSearchOperator,
    value: vars.nameValue as string
  };
  const nameTypeOperation: TAdvancedSearchOperation = {
    field: EAdvancedSearchField.NAME_TYPE,
    operator: EAdvancedSearchOperator.IN,
    value: vars.nameType as string
  };
  const nameIsLegalOperation: TAdvancedSearchOperation = {
    field: EAdvancedSearchField.NAME_IS_LEGAL,
    operator: EAdvancedSearchOperator.EQUALS,
    value: vars.legalName as string
  };
  const partyTypeOperation: TAdvancedSearchOperation = {
    field: EAdvancedSearchField.PARTY_TYPE,
    operator: EAdvancedSearchOperator.EQUALS,
    value: vars.partyType as string
  };
  const partySubTypeOperation: TAdvancedSearchOperation = {
    field: EAdvancedSearchField.PARTY_SUB_TYPE,
    operator: EAdvancedSearchOperator.EQUALS,
    value: vars.partySubType as string
  };
  const partyCountryOfOriginOperation: TAdvancedSearchOperation = {
    field: EAdvancedSearchField.PARTY_COUNTRY_OF_ORIGIN,
    operator: EAdvancedSearchOperator.EQUALS,
    value: vars.partyCountryOfOriginId as string //getCountry(parseInt(vars.partyCountryOfOriginId as string))?.name as string
  };
  const competencyOperation: TAdvancedSearchOperation = {
    field: EAdvancedSearchField.COMPETENCY,
    operator: EAdvancedSearchOperator.EQUALS,
    value: vars.competency as string
  };
  const createdByOperation: TAdvancedSearchOperation = {
    field: EAdvancedSearchField.NAME_CREATED_BY,
    operator: EAdvancedSearchOperator.EQUALS,
    value: vars.createdBy as string
  };
  const modifiedByOperation: TAdvancedSearchOperation = {
    field: EAdvancedSearchField.NAME_UPDATED_BY,
    operator: EAdvancedSearchOperator.EQUALS,
    value: vars.modifiedBy as string
  };
  if (vars.nameValue) operations.push(nameOperation);
  if (vars.nameType) operations.push(nameTypeOperation);
  if (vars.partyType) operations.push(partyTypeOperation);
  if (vars.legalName) operations.push(nameIsLegalOperation);
  if (vars.partySubType) operations.push(partySubTypeOperation);
  if (vars.partyCountryOfOriginId) operations.push(partyCountryOfOriginOperation);
  if (vars.competency) operations.push(competencyOperation);
  if (vars.createdBy) operations.push(createdByOperation);
  if (vars.modifiedBy) operations.push(modifiedByOperation);

  if (vars.createdOnOperator) {
    if (vars.createdOnOperator === EAdvancedSearchOperator.BETWEEN) {
      if (vars.createdOnA && vars.createdOnB) {
        operations.push({
          field: EAdvancedSearchField.NAME_CREATED_AT,
          operator: EAdvancedSearchOperator.GTE,
          value: vars.createdOnA
        });
        operations.push({
          field: EAdvancedSearchField.NAME_CREATED_AT,
          operator: EAdvancedSearchOperator.LTE,
          value: vars.createdOnB
        });
      }
    } else {
      if (vars.createdOnA) {
        operations.push({
          field: EAdvancedSearchField.NAME_CREATED_AT,
          operator: vars.createdOnOperator,
          value: vars.createdOnA
        });
      }
    }
  }
  if (vars.modifiedOnOperator) {
    if (vars.modifiedOnOperator === EAdvancedSearchOperator.BETWEEN) {
      if (vars.modifiedOnA && vars.modifiedOnB) {
        operations.push({
          field: EAdvancedSearchField.NAME_UPDATED_AT,
          operator: EAdvancedSearchOperator.GTE,
          value: vars.modifiedOnA
        });
        operations.push({
          field: EAdvancedSearchField.NAME_UPDATED_AT,
          operator: EAdvancedSearchOperator.LTE,
          value: vars.modifiedOnB
        });
      }
    } else {
      if (vars.modifiedOnA) {
        operations.push({
          field: EAdvancedSearchField.NAME_UPDATED_AT,
          operator: vars.modifiedOnOperator,
          value: vars.modifiedOnA
        });
      }
    }
  }
  return operations;
};
