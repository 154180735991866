import React, { FC, useCallback, useRef, useState } from 'react';
import styles from './popover.module.scss';
import useDetectClickOutside from 'modules/common/hooks/useDetectClickOutside';
import { setElementProperty } from './helpers';
import { cn } from 'utils';

interface IProps {
  children: (JSX.Element | null)[];
}

export const Popover: FC<IProps> = ({ children }) => {

  const [isOpen, setOpen] = useState<boolean>(false);

  const handleToggle = useCallback(() => { setOpen(s => !s); },[]);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  const ref = useDetectClickOutside(handleClose);
  const container = useRef<HTMLDivElement | null>();

  const containerRef = useCallback<(el: HTMLDivElement | null) => void>(
    (el) => {
      setElementProperty(el);
      container.current = el;
    },
  [],
  );

  return (
    <div ref={ref} className={cn(styles.container, { [styles.visible]: isOpen })}>
      <button
        type="button"
        className={styles.btn}
        onClick={handleToggle}
      />
      {
        isOpen ? (
          <div className={styles.popover} ref={containerRef}>
            {
              children
            }
          </div>
        ) : null
      }
    </div>
  );
};
