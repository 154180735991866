import React, { FC, ReactElement, useState } from 'react';
import { Tabs as LegatoTabs } from '@wmg-ae/legato';
import { cn } from 'utils';

import styles from './Tabs.module.scss';

interface ITabItem {
  title: string;
  tooltip: string;
  component: ReactElement;
}

interface ITabsProps {
  tabs: ITabItem[];
  selectedItemIdx?: string;
  sticky?: boolean;
  className?: string;
  onSelect?: (tabIndex: string) => void;
}

export const Tabs: FC<ITabsProps> = ({ tabs, selectedItemIdx, sticky, className, onSelect }) => {
  if (!tabs.length) return null;

  const [currentTabIndex, setCurrentTabIndex] = useState<string>(selectedItemIdx || '0');

  const list = tabs.map((item, idx) => (
    {
      id: idx + '',
      title: item.title
    }
  ));

  const onSelectTab = (tabIndex: string) => {
    setCurrentTabIndex(tabIndex);
    onSelect && onSelect(tabIndex);
  };

  return <div className={styles.tabsWrapper}>
    <LegatoTabs
      list={list}
      onClick={ ({ id }) => onSelectTab(id) }
      initialTabId={currentTabIndex}
      mode={'in-page'}
      className={cn(styles.tabs, {
        [styles.sticky]: sticky
      },'demo-rm',className)}
    />
    {tabs[+currentTabIndex]?.component}
  </div>;
};
