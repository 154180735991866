import React, { FC, SyntheticEvent, useCallback, useMemo } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { FFCheckbox } from '@grow-components/Form';
import { useCurrentName } from '../useCurrentName';
import { ENameType } from 'domains/party/types';
import { isPerformingArtist } from '../../helpers';
import styles from './legal-name.module.scss';

interface IProps {
  namePath: string;
  disabled?: boolean;
  nameIndex: number;
  onChange(data: { nameIndex: number, checked: boolean }): void;
}

export const LegalName: FC<IProps> = ({ namePath, disabled, nameIndex, onChange }) => {
  const { t } = useTranslation();
  const { values } = useCurrentName(namePath);

  const changeHandler = useCallback(
    (e: SyntheticEvent<HTMLInputElement>) => {
      onChange({ nameIndex: nameIndex, checked: e.currentTarget.checked });
    },
    [onChange, nameIndex],
  );

  const isDisabled = useMemo<boolean | undefined>(
    () => {
      if (disabled) return true;
      if (values.nameType !== ENameType.PKA && !values.isLegal) {
        if (isPerformingArtist(values)) return true;
      }
    },
    [disabled, values],  
  );

  return (
    <Field
      name={`${namePath}.isLegal`}
      component={FFCheckbox}
      type="checkbox"
      label={t('party_labels:same_as_legal')}
      labelTooltip={t('tooltips:legal_name_indicator')}
      disabled={isDisabled}
      onChange={changeHandler}
      className={styles.field}
    />
  );
};
