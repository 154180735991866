import compose from 'lodash/fp/compose';
import set from 'lodash/fp/set';
import moment from 'moment';
import { TState, IFormFilter, EOperator } from './types';

interface IFDate {
  dateTime: number;
}

export const filterByDate = <T extends IFDate>({ dateTime: fdt }: TState) => {
  if (typeof fdt === 'string') {
    const base = moment(fdt);
    return ({ dateTime }: T) => base.isSame(moment.unix(dateTime), 'day');
  }
  if (Array.isArray(fdt) && fdt.length === 2) {
    const from = moment(fdt[0]);
    const to = moment(fdt[1]);
    return ({ dateTime }: T) => {
      const base = moment.unix(dateTime);
      return from.isSameOrBefore(base, 'day') && to.isSameOrAfter(base, 'day');
    };
  }
  return () => true;
};

export const validate = <T extends Record<string, string>, V extends IFormFilter>(min: number, t: (m: string) => string) => ({ from, to, ...values }: V): T => {
  const isValidRangeFrom = values.operator === EOperator.RANGE && from && moment(from).isValid();
  const isValidRangeTo = values.operator === EOperator.RANGE && to && moment(to).isValid();
  return compose(
    (err: T) => {
      if (isValidRangeFrom && isValidRangeTo && moment(from).isAfter(to)) return set('from')(t('errors:from_is_after_then_to'))(err);
      return err;
    },
    (err: T) => {
      if (isValidRangeFrom && moment(from).isAfter(new Date())) return (set('from')(t('errors:from_is_after_then_today'))(err));
      return err;
    },
    (err: T) => {
      if (isValidRangeTo && moment(to).isBefore(moment.unix(min), 'day')) return (set('to')(t('errors:to_is_before_than_min'))(err));
      return err;
    },
  )({} as T);
};

export const isValid = <T extends Record<string, string>, V extends IFormFilter>(fn: (v: V) => Partial<T>) => (values: V) => {
  const errors = fn(values);
  for (const key in errors) {
    if(errors.hasOwnProperty(key)) return false;
  }
  return true;
};

export const modifyValues = <V extends [Date, Date]>({ min }: { min: number }) => (values: V): V => {
  return compose(
    (v: V) => {
      if (moment(v[0]).isBefore(moment.unix(min), 'day')) {
        return [moment.unix(min).toDate(), v[1]];
      }
      return v;
    },
    (v: V) => {
      if (moment(v[1]).isAfter(moment())) {
        return [v[0], moment().toDate()];
      }
      return v;
    },
  )(values);
};
