import React, { FC } from 'react';
import styles from './UpcomingWorkspaces.module.scss';
import { WorkspacePreview } from '@grow-components/WorkspacePreview/WorkspacePreview';
import pwLogo from 'assets/images/workspace-logos/product-workspace-logo.svg';
import asLogo from 'assets/images/workspace-logos/assets-workspace-logo.svg';
import wwLogo from 'assets/images/workspace-logos/works-workspace-logo.svg';
import { useTranslation } from 'react-i18next';

type TUpcomingWorkspacesProps = {
  className?: string
}

export const UpcomingWorkspaces: FC<TUpcomingWorkspacesProps> = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <WorkspacePreview
        className={styles.listItem}
        title={t('liw')}
        subtitle="Assets Workspace"
        icon={<img src={asLogo} alt="pw-logo" />}
        description="Media and WMG IP."
      />
      <WorkspacePreview
        className={styles.listItem}
        title={t('liw')}
        subtitle="Works Workspace"
        icon={<img src={wwLogo} alt="pw-logo" />}
        description="Publishing and licensing."
      />
      <WorkspacePreview
        className={styles.listItem}
        title={t('liw')}
        subtitle="Product Workspace"
        icon={<img src={pwLogo} alt="pw-logo" />}
        description="Production and sales."
      />
    </div>
  );
};
