import { ISpotifyImageContainer } from '../Spotify';
import imgPlaceholder from 'assets/images/Grow-No-Image-Placeholder@3x.webp';

interface EImageProps {
  src: string;
  srcset?: string;
  size?: string;
  className?: string;
  alt?: string;
}

export function getImageProps(images: ReadonlyArray<ISpotifyImageContainer>): EImageProps {

  if (images.length === 0) {
    return {
      className: 'img-placeholder',
      src: imgPlaceholder,
      alt: 'Grow Artist Image Placeholder'
    };
  }
  const [src] = images.reduce(
    (a, v) => v.width > a[1] ? [v.url, v.width] : a,
    [images[0].url, images[0].width],
  );
  
  // TODO: Make adaptive
  // const srcset = images.reduce(
  //   (a, v) => [...a, `${v.url} ${v.width}w`],
  //   [] as string[],
  // ).join(',');
  // const size =
  return  {
    src,
    // srcset,
    // size,
  };
}
