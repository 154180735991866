import React, { FC, Fragment, useCallback, useMemo } from 'react';
import { cn } from 'utils';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@grow-components/Tooltip';
import { Body, ESortDirection, Header, IHeaderData, IHeaderMeta } from 'modules/Table';
import Result from '../Result/Result';

import { ESortType, ISearchNamesSort } from 'modules/common/types/searchTypes';

import styles from './result-table.module.scss';
import { useQueryMetaParams } from 'modules/common/hooks/useQueryMetaParams';

interface IProps {
  data?: ReadonlyArray<Record<string, any>>;
  isLoading: boolean;
  sort?: ISearchNamesSort;
  className?: string;
}

export const ResultTable: FC<IProps> = ({
  data,
  isLoading,
  className
}) => {

  const { t } = useTranslation();
  const { queryMetaParams, set } = useQueryMetaParams();
  const handleSort = useCallback(
    (type: ESortType, direction: string) => {
      set({
        sortBy: type,
        sortDesc: direction === ESortDirection.DESC
      });

    }, [set],
  );

  const fields: ReadonlyArray<IHeaderData<ESortType>> = useMemo(
    () => [
      {
        id: ESortType.PARTY_TYPE, classes: ['name', 'col-md-1'], label: (
          <Tooltip text={t('tooltips:party_type')}>{t('party_labels:party_type')}</Tooltip>
        ), sortable: true
      },
      { id: ESortType.NAME_VALUE, classes: ['name', 'col-md-3'], label: t('party_labels:name'), sortable: true },
      {
        id: ESortType.NAME_TYPE, classes: ['name', 'col-md-1'], label: (
          <Tooltip text={t('tooltips:search_name_type')}>{t('party_labels:name_type')}</Tooltip>
        ), sortable: true
      },
      {
        id: ESortType.LEGAL_NAME, classes: ['name', 'col-md-1', 'uaid-col'], label: (
          <Tooltip text={t('tooltips:search_legal_name')}>{t('party_labels:same_as_legal')}</Tooltip>
        ), sortable: true
      },
      {
        id: ESortType.ISNI, classes: ['isni', 'col-md-2', 'isni-col'], label: (
          <Tooltip text={t('tooltips:isni')}>ISNI</Tooltip>
        ), sortable: true
      },
      {
        id: ESortType.COUNTRY_OF_ORIGIN, classes: ['country', 'col-md-2'], label: (
          <Tooltip text={t('tooltips:country_of_origin')}>{t('party_labels:origin_country')}</Tooltip>
        ), sortable: true
      },
      {
        id: ESortType.INTERNAL_COMMENTS, classes: ['comments', 'col-md-2'], label: (
          <Tooltip text={t('tooltips:search_internal_comments')}>{t('party_labels:internal_comments')}</Tooltip>
        ), sortable: true
      },
    ],
    [t],
  );

  const getRowKey = useCallback((data) => data.nameId, []);

  const renderRow = useCallback(
    (data, idx) => (
      <Result key={getRowKey(data)} idx={idx} results={data} elementKey={getRowKey(data)} />
    ),
    [],
  );

  const metaFactory = useCallback<(d?: ESortDirection) => IHeaderMeta>(
    (direction) => ({ sortDirection: direction }),
  [],
  );

  const getMeta = useCallback<(d: IHeaderData<ESortType>, i: number) => IHeaderMeta>(
    (data) => {
      if (typeof queryMetaParams.sort !== 'undefined' && data.id === queryMetaParams.sort.type) {
        if (queryMetaParams.sort.desc) return metaFactory(ESortDirection.DESC);
        return metaFactory(ESortDirection.ASC);
      }
      return metaFactory(undefined);
    },
  [queryMetaParams],
  );

  return typeof data !== 'undefined' ? (
    <Fragment>
      <table className={cn(styles.table, className)}>
        <Header fields={fields} action={handleSort} getMeta={getMeta} className={styles.header} />
        {data.length > 0 ? (
          <Body
            rows={data}
            getRowKey={getRowKey}
            renderRow={renderRow}
          />
        ) : (
          <tbody>
            <tr>
              <td colSpan={7} className={styles.noResultsMessage}>We are unable to locate any results for provided search
                params.
              </td>
            </tr>
          </tbody>
        )}
      </table>
      {
        isLoading ? ( <div className={styles.spinner} /> ) : null
      }
    </Fragment>
  ) : null;
};
