import React, { FC } from 'react';
import { BasicSpinner } from '@grow-components/Spinners/BasicSpinner';

import styles from './FullScreenSpinner.module.scss';

export const FullScreenSpinner: FC = () => {
  return (
    <div className={styles.container}>
      <BasicSpinner />
    </div>
  );
};
