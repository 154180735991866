import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Artist,
  isniMatch,
  isniOverlap,
} from 'modules/common/types/artistTypes';
import { ArtistResult, ISNIOverlapResult } from 'graphql/types';

export interface ArtistState {
  currentArtist: Artist;
  currentArtistImage: string;
  loadingArtistData: boolean;
  loadingArtistError: string | null;
  addISNITriggered: boolean;
  editISNITriggered: boolean;
  isniSearchTriggered: boolean;
  isniMatches: isniMatch[];
  isniOverlap: isniOverlap[];
  showOverlapWarningArtist: boolean;
  showOverlapWarningModal: boolean;
  noOverlap: boolean;
  duplicateISNI: boolean;
  loadingISNIMatches: boolean;
  loadingISNIOverlap: boolean;
  isniOverlapError: string | null;
  queryComplete: boolean;
  triggerSaveISNI: boolean;
  saveISNIInput: string;
  loadingSaveISNI: boolean;
  saveISNIResult: string | null;
  saveISNIError: string | null;
  isniMatchError: string | null;
  searchTerm: string;
  submittedSearchTerm: string;
  inputType: string;
  showDeleteModal: boolean;
  loadingDeleteISNI: boolean;
  deleteISNIError: string | null;
  displaySpinner: boolean;
  displayRequestForm: boolean;
  displaySuperuserRequestForm: boolean;

  // more info
  displayMoreInfo: boolean;
  moreInfoISNI: string | null;
}

const initialState: ArtistState = {
  currentArtist: {} as Artist,
  currentArtistImage: '',
  loadingArtistData: false,
  loadingArtistError: null,
  addISNITriggered: false,
  editISNITriggered: false,
  isniSearchTriggered: false,
  isniMatches: [],
  isniOverlap: [],
  showOverlapWarningArtist: false,
  showOverlapWarningModal: false,
  noOverlap: false,
  duplicateISNI: false,
  loadingISNIMatches: false,
  loadingISNIOverlap: false,
  isniOverlapError: null,
  queryComplete: false,
  triggerSaveISNI: false,
  saveISNIInput: '',
  loadingSaveISNI: false,
  saveISNIResult: null,
  saveISNIError: null,
  isniMatchError: null,
  searchTerm: '',
  submittedSearchTerm: '',
  inputType: 'isni',
  showDeleteModal: false,
  loadingDeleteISNI: false,
  deleteISNIError: null,
  displaySpinner: true,
  displayRequestForm: false,
  displaySuperuserRequestForm: false,

  // MORE INFO
  displayMoreInfo: false,
  moreInfoISNI: null,
};

const artistSlice = createSlice({
  name: 'artist',
  initialState,
  reducers: {
    // ARTIST DATA
    resetState() {
      return initialState;
    },
    getArtistLoading(state) {
      state.loadingArtistData = true;
      state.displayRequestForm = false;
      state.displayMoreInfo = false;
      state.currentArtist = {} as Artist;
      state.displaySpinner = true;
    },
    getArtistSuccess(state, action: PayloadAction<ArtistResult>) {
      state.loadingArtistData = false;
      state.currentArtist = action.payload;
      state.displaySpinner = false;
    },
    getArtistFailed(state, action: PayloadAction<string>) {
      state.loadingArtistError = action.payload;
      state.loadingArtistData = false;
      state.displaySpinner = false;
    },

    // UPDATE ARTIST IMAGE
    updateArtistImage(state, action: PayloadAction<string>) {
      state.currentArtistImage = action.payload;
    },

    // ISNI DATA
    triggerISNISearch(state, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
      state.submittedSearchTerm = action.payload;
      state.isniMatches = [];
      state.isniSearchTriggered = true;
      state.duplicateISNI = false;
      state.loadingISNIMatches = true;
      state.isniMatchError = null;
    },
    resetTriggerISNISearch(state) {
      state.isniSearchTriggered = false;
      state.isniMatches = [];
    },
    openISNISelectAdd(state) {
      state.addISNITriggered = true;
    },
    openISNISelectEdit(state) {
      state.editISNITriggered = true;
    },
    closeISNISelect(state) {
      state.addISNITriggered = false;
      state.editISNITriggered = false;
      state.duplicateISNI = false;
      state.isniOverlap = [];
      state.showOverlapWarningArtist = false;
      state.showOverlapWarningModal = false;
      state.isniMatches = [];
      state.isniSearchTriggered = false;
    },
    triggerDuplicateISNI(state) {
      state.duplicateISNI = true;
    },
    updateSaveISNIInput(state, action: PayloadAction<string>) {
      state.saveISNIInput = action.payload;
    },

    // ISNI OVERLAP
    checkForISNIOverlapLoading(state) {
      state.loadingISNIOverlap = true;
      state.duplicateISNI = false;
      state.isniOverlap = [];
      state.queryComplete = false;
      state.noOverlap = false;
      state.displaySpinner = true;
      state.isniOverlapError = null;
    },
    checkForISNIOverlapSuccess(
      state,
      action: PayloadAction<ISNIOverlapResult[]>
    ) {
      if (action.payload.length) {
        if (state.displayMoreInfo) {
          state.showOverlapWarningModal = true;
        } else {
          state.showOverlapWarningArtist = true;
        }
        state.noOverlap = false;
        state.triggerSaveISNI = false;
        state.displaySpinner = false;
      } else {
        state.triggerSaveISNI = true;
        state.showOverlapWarningArtist = false;
        state.showOverlapWarningModal = false;
        state.noOverlap = true;
      }
      state.loadingISNIOverlap = false;
      state.queryComplete = true;
      state.isniOverlap = action.payload;
    },
    checkForISNIOverlapFailed(state, action: PayloadAction<string>) {
      state.loadingISNIOverlap = false;
      state.queryComplete = true;
      state.displaySpinner = false;
      state.isniOverlapError = action.payload;
    },
    cancelOverlapWarning(state) {
      state.isniOverlap = [];
      state.showOverlapWarningArtist = false;
      state.showOverlapWarningModal = false;
      state.noOverlap = false;
    },

    // SAVE ISNI
    saveISNILoading(state, action: PayloadAction<string>) {
      state.triggerSaveISNI = true;
      state.saveISNIInput = action.payload;
      state.displaySpinner = true;
    },
    saveISNISuccess(state) {
      state.triggerSaveISNI = false;
      state.currentArtist.isni = state.saveISNIInput;
      state.displaySpinner = false;
      state.displayMoreInfo = false;
    },
    saveISNIFailed(state, action: PayloadAction<string>) {
      state.triggerSaveISNI = false;
      state.displaySpinner = false;
      state.displayMoreInfo = false;
      state.saveISNIError = action.payload;
    },
    resetISNIResults(state) {
      state.isniMatches = [];
      state.isniSearchTriggered = false;
      state.addISNITriggered = false;
      state.editISNITriggered = false;
    },
    getISNIMatchesSuccess(state, action: PayloadAction<isniMatch[]>) {
      state.loadingISNIMatches = false;
      state.isniMatches = action.payload;
    },
    updateISNIMatches(state) {
      state.loadingISNIMatches = true;
      state.duplicateISNI = false;
      state.isniMatches = [];
      state.isniOverlap = [];
      state.showOverlapWarningArtist = false;
      state.showOverlapWarningModal = false;
    },
    triggerISNIError(state, action: PayloadAction<string>) {
      state.loadingISNIMatches = false;
      state.isniMatchError = action.payload;
      state.isniSearchTriggered = true;
    },
    updateSearchTerm(state, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
    },
    resetISNISearch(state) {
      state.isniMatches = [];
      state.duplicateISNI = false;
      state.isniSearchTriggered = false;
      state.addISNITriggered = false;
      state.editISNITriggered = false;
      state.isniOverlap = [];
      state.showOverlapWarningArtist = false;
      state.showOverlapWarningModal = false;
    },
    updateInputType(state, action: PayloadAction<string>) {
      state.inputType = action.payload;
      state.duplicateISNI = false;
      state.isniMatches = [];
      state.isniSearchTriggered = false;
      state.isniOverlap = [];
      state.showOverlapWarningArtist = false;
      state.showOverlapWarningModal = false;
    },
    triggerDeleteModal(state) {
      state.showDeleteModal = true;
    },
    cancelDeleteModal(state) {
      state.showDeleteModal = false;
    },
    deleteISNILoading(state) {
      state.showDeleteModal = false;
      state.loadingDeleteISNI = true;
      state.deleteISNIError = null;
      state.displaySpinner = true;
    },
    deleteISNISuccess(state) {
      state.currentArtist.isni = '';
      state.loadingDeleteISNI = false;
      state.displaySpinner = false;
      state.displayMoreInfo = false;
    },
    deleteISNIFailed(state, action: PayloadAction<string>) {
      state.loadingDeleteISNI = false;
      state.deleteISNIError = action.payload;
      state.displaySpinner = false;
      state.displayMoreInfo = false;
    },
    getUpdatedISNILoading(state) {
      state.displaySpinner = true;
    },
    getupdatedISNISuccess(state, action: PayloadAction<string>) {
      state.currentArtist.isni = action.payload;
      state.displaySpinner = false;
    },
    retryISNISearch(state) {
      state.isniSearchTriggered = true;
    },
    cancelSearch(state) {
      state.isniSearchTriggered = false;
    },
    toggleRequestForm(state) {
      state.displayRequestForm = !state.displayRequestForm;
    },
    toggleSuperuserRequestForm(state) {
      state.displaySuperuserRequestForm = !state.displaySuperuserRequestForm;
    },

    // MORE INFO
    toggleMoreInfo(state, action: PayloadAction<string | null>) {
      if (!state.displayMoreInfo && action.payload) {
        state.moreInfoISNI = action.payload;
      }
      state.displayMoreInfo = !state.displayMoreInfo;
      state.loadingISNIOverlap = false;
      state.duplicateISNI = false;
    },
  },
});

export const {
  resetState,
  getArtistLoading,
  getArtistSuccess,
  getArtistFailed,
  updateArtistImage,
  triggerISNISearch,
  resetTriggerISNISearch,
  openISNISelectAdd,
  openISNISelectEdit,
  closeISNISelect,
  triggerDuplicateISNI,
  updateSaveISNIInput,
  checkForISNIOverlapLoading,
  checkForISNIOverlapSuccess,
  checkForISNIOverlapFailed,
  cancelOverlapWarning,
  saveISNILoading,
  saveISNISuccess,
  resetISNIResults,
  getISNIMatchesSuccess,
  updateISNIMatches,
  triggerISNIError,
  updateSearchTerm,
  resetISNISearch,
  updateInputType,
  triggerDeleteModal,
  cancelDeleteModal,
  deleteISNILoading,
  deleteISNISuccess,
  deleteISNIFailed,
  retryISNISearch,
  cancelSearch,
  toggleRequestForm,
  toggleSuperuserRequestForm,
  toggleMoreInfo,
  getUpdatedISNILoading,
  getupdatedISNISuccess,
  saveISNIFailed,
} = artistSlice.actions;
export default artistSlice.reducer;
