import { ApolloClient, InMemoryCache, from, split, Operation, HttpLink, ApolloLink } from '@apollo/client';
import { createAuthLink, AUTH_TYPE } from 'aws-appsync-auth-link';
import { appsyncConfig } from './appsyncConfig';

type TGetToken = () => string;

const { aws_appsync_graphqlEndpoint, aws_appsync_region } = appsyncConfig;

const linkSplit = ({ query }: Operation) => {
  const definition = query.definitions[0];
  return !(definition.kind === 'OperationDefinition' && definition.operation === 'subscription');
};

const httpLinkSearch = (uri: string) => new HttpLink({ uri });

const link = (getJwtToken: TGetToken) => from([
  new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
      const context = operation.getContext();
      if (response.data && context.requestContext) {
        response.data.context = context.requestContext;
      }
      return response;
    });
  }),
  createAuthLink({
    url: aws_appsync_graphqlEndpoint as string,
    region: aws_appsync_region as string,
    auth: {
      type: AUTH_TYPE.OPENID_CONNECT,
      jwtToken: getJwtToken,
    }
  }),
  split(linkSplit, httpLinkSearch(aws_appsync_graphqlEndpoint as string)),
]);

export const apolloClient = (getToken: TGetToken) => {
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: link(getToken),
  });
};

export function getTokenFromLStorage(): string {
  const oktaStore = window.localStorage.getItem('okta-token-storage');
  if (typeof oktaStore === 'string') {
    try {
      const { idToken: { value: token } } = JSON.parse(oktaStore);
      return token as string;
    } catch (err) {
      console.warn(err);
    }
  }
  throw new Error('UNAUTHORIZED USER');
}


