import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './mdhq-links.module.scss';
import widgetStyles from '../Widget/Widget.module.scss';

type TLinkItem = {
  labelKey: string;
    value: string;
}

const MDHQ_LINKS: TLinkItem[] = [
  {
    labelKey: 'searching_parties',
    value: 'https://wmg.continu.co/#/view/articles/60da480fe3cdaf0013eba3c0',
  }, {
    labelKey: 'creating_parties',
    value: 'https://wmg.continu.co/#/view/articles/60da4b9e93c8a200134dd343',
  }, {
    labelKey: 'assigning_isnis',
    value: 'https://wmg.continu.co/#/view/articles/60db85b5ec88570013590727',
  }, {
    labelKey: 'glossary',
    value: 'https://wmg.continu.co/#/view/articles/60bfbba38d3f170013d5d3a2',
  }
];

const OTHER_LINKS: TLinkItem[] = [
  {
    labelKey: 'known_issues',
    value: 'https://wmg.sharepoint.com/sites/glb.GDO/GROW-Comms/party-workspace/SitePages/Known-Issues-List.aspx',
  }, {
    labelKey: 'feedback',
    value: 'mailto:LIWSupport@wmg.com?subject=User Feedback',
  }, {
    labelKey: 'faqs',
    value: 'https://wmg.continu.co/#/view/articles/6262f02ccd6fae00131cc5ec',
  }
];

export const MdhqLinks: FC = () => {
  const { t: tLanding } = useTranslation('landing');

  const renderLinksList = useCallback((links: TLinkItem[]) => {
    return (
      <div className={styles.links}>
        {links.map((link) => (
          <a href={link.value}
            target='_blank'
            rel="noopener noreferrer"
            key={`link_item-${link.labelKey}${link.value}`}
            className={styles.link}
          >
            {tLanding(link.labelKey)}
          </a>
        ))}
      </div>
    );
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.linksBlock}>
        <span className={widgetStyles.title}>{tLanding('mdhq')}</span>
        {renderLinksList(MDHQ_LINKS)}
      </div>
      <div className={styles.linksBlock}>
        <span className={widgetStyles.title}>{tLanding('known_issues_faqs')}</span>
        {renderLinksList(OTHER_LINKS)}
      </div>
    </div>
  );
};
