import React, { FC, useCallback, useState } from 'react';
import { LegatoFFSelect } from '@grow-components/Form';
import { Dialog, EDialogType } from '@grow-components/Popup';
import { ENamePrivacyType } from 'modules/common/types/partyTypes';
import { Field, useForm } from 'react-final-form';
import { useTranslation, Trans } from 'react-i18next';

interface IPrivacyModeProps {
  formName: string,
  nameValue: string;
  initialType: ENamePrivacyType;
  disabled: boolean | undefined;
}

export const PrivacyMode: FC<IPrivacyModeProps> = ({ formName, initialType, nameValue, disabled }) => {
  const { t } = useTranslation('party_labels');
  const form = useForm();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [type, setType] = useState(initialType);
  const hideConfirmModal = useCallback(() => setShowConfirmModal(false), []);

  const onInitPrivacyChange = useCallback((value: ENamePrivacyType) => {
    setShowConfirmModal(true);
    setType(value);
  },[type,setType]);

  const onConfirmPrivacyChange = useCallback(() => {
    form.change(`${formName}` as any, type);
    hideConfirmModal();
  }, [form, type]);
  return (
    <>
      <Field
        forceError={true}
        label={t('privacy_type')}
        labelTooltip={t('tooltips:privacy_indicator')}
        required={true}
        disabled={disabled}
        component={LegatoFFSelect}
        name={formName}
        options={[ENamePrivacyType.PUBLIC, ENamePrivacyType.INTERNAL_TO_WMG].map(privacyType => ({
          label: t(privacyType.toLowerCase()),
          value: privacyType
        }))}
        onChange={onInitPrivacyChange}
      />
      <Dialog
        name={t('modal:confirm_privacy_indicator_update')}
        isOpen={showConfirmModal}
        type={EDialogType.confirm}
        onClose={hideConfirmModal}
        onAction={onConfirmPrivacyChange}
        actionText={t('common:confirm')}
        cancelText={t('common:no')}
      >
        <Trans
          i18nKey="modal:are_you_sure_you_want_to_change_the_privacy_indicator"
          values={{ type: t(type?.toLowerCase()), nameValue }}
        />
      </Dialog>
    </>
  );
};
