import { EIdentifierType } from 'modules/common/types/partyTypes';
import { TPartyName } from 'domains/party/types';

export const PERFORMING_ARTIST_ID = 2;

export const isPerformingArtist = ({ competencies }: TPartyName) => Boolean(competencies?.filter(Boolean).some(({ competencyId }) => competencyId == PERFORMING_ARTIST_ID));

export function hasISNI({ identifiers }: TPartyName) {
  if (typeof identifiers === 'undefined') return false;
  return identifiers.some(i => i.identifierType === EIdentifierType.ISNI && i.value);
}

export function hasUUID({ identifiers }: TPartyName): boolean {
  return Boolean(identifiers?.some(e => e.identifierType === EIdentifierType.UAID));
}


export function formatNameQuotes(name: string): string {
  return name.split('').map((item) => {
    if (item === '\'') {
      return '\\\'';
    }
    return item;
  }).join('');
}