import { useLazyQuery } from '@apollo/client';
import { PUBLISH_TO_RAD } from '../graphql/publishToRad';
import { TPublishToRadResponse, TPublishToRadVars } from './types';
import { ToastService } from 'utils/ToastService';
import { useTranslation } from 'react-i18next';

export const usePublishToRAD = (pkaName: string) => {
  const { t } = useTranslation();

  const [publishToRAD, { loading }] = useLazyQuery<TPublishToRadResponse, TPublishToRadVars>(PUBLISH_TO_RAD, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ partyPublishToRAD })  => {
      if(partyPublishToRAD?.result)
        ToastService.success(t('toasts:republished', { pkaName }));
      else {
        ToastService.error(t('errors:republish_action_failed', { pkaName }));
        console.error(partyPublishToRAD);
      }
    },
    onError: (error) => {
      ToastService.error(t('errors:republish_action_failed', { pkaName }));
      console.error(error);
    }
  });

  const publishToRadHandler = (partyId: number) => {
    publishToRAD({ variables: {
      partyId
    } });
  };

  return { publishToRadHandler, publishToRadLoading: loading };
};