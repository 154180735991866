import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-final-form';
import get from 'lodash/get';
import { EPartyType, ERemarkType } from 'modules/common/types/partyTypes';
import { Warning } from './Warning';
import { validate } from '../validate';
import { EHiddenNameFields } from '../constants';
import { EUseWarning, TName } from './types';
import { EValidationResult } from '../types';

export function useWarning({ nameIndex, searchResultShown, isNameValidating, nameModified }: EUseWarning) {
  const { getState, registerField, change } = useForm();
  const { values, initialValues } = getState();
  const nameStandardsInitialValues = { casingStandard: false, parenthesisStandard: false, wordsStandard: false };

  const currentNameValues= useMemo<TName>(
    () => values.names[nameIndex],
    [values, nameIndex],
  );

  const hiddenFieldName = useMemo<string>(
    () => `names[${nameIndex}].${EHiddenNameFields.NAMING_STANDARDS_FORBIDDEN}`,
    [nameIndex],
  );
  const isNameAllowedWarning = useMemo(
    () => Boolean(
      currentNameValues.remarks?.some(
        e => {
          const oldOrNewRemark = e.remarkId || e.added; // this condition is to make sure that the remark was added (confirm button clicked)
          return e.remarkType === ERemarkType.INTERNAL_COMMENT && oldOrNewRemark;
        }
      ),
    ),
    [currentNameValues],
  );

  const canShowWarning = useMemo<boolean>(
    () => {
      if (nameModified) return false;
      if (isNameValidating) return false;
      if (get(initialValues, ['names', nameIndex, 'nameValue']) === currentNameValues.nameValue) return false;
      if (searchResultShown) return false;
      if (isNameAllowedWarning) return false;
      return currentNameValues._fuzzyMatchesWereIgnoredOnce;
    },
    [nameModified, isNameValidating, searchResultShown, currentNameValues, initialValues, nameIndex, isNameAllowedWarning],
  );

  const warnings = useMemo(() => {
    let validations = validate(currentNameValues.nameValue);
    if (values.partyType === EPartyType.GROUP) {
      validations = validations.filter(item => item !== EValidationResult.PARENTHESIS);
    }
    return !isNameAllowedWarning ? validations : [];
    // values.partyType was added because there is a warning that doesn't apply to parties of type group
  }, [currentNameValues.nameValue, isNameAllowedWarning, values.partyType]); 
  const renderWarning = useMemo(
    () => (
      <Warning
        isCanShow={canShowWarning}
        warnings={warnings}
      />
    ),
    [canShowWarning, warnings],
  );


  useEffect(() => {
    registerField(
      hiddenFieldName,
      () => null,
      { value: true },
      { initialValue: nameStandardsInitialValues }
    );
  }, [hiddenFieldName, registerField]);
  useEffect(() => {
    if (!isNameAllowedWarning) {
      return change(hiddenFieldName, {
        casingStandard: warnings.includes(EValidationResult.CASING),
        parenthesisStandard: warnings.includes(EValidationResult.PARENTHESIS),
        wordsStandard: warnings.includes(EValidationResult.PROHIBITED_WORDS)
      });
    }
    return change(hiddenFieldName, nameStandardsInitialValues);
  }, [change, isNameAllowedWarning, canShowWarning, hiddenFieldName, warnings]);

  return {
    renderWarning,
    warningTypes: warnings, //added this as part to have the validations in the new legato fields: https://wmggt.atlassian.net/browse/GP-3691
    currentNameValues,
  };
}
