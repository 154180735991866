import { Reducer } from 'react';
import set from 'lodash/fp/set';
import compose from 'lodash/fp/compose';

export interface IState {
  confirmationModal: boolean;
  interruptModal: boolean;
  loading: boolean;
}

export enum EActionType {
  CHECK_AVAILABLE = 'CHECK_AVAILABLE',
  CHECK_SUCCESS = 'CHECK_SUCCESS',
  CHECK_FAILURE = 'CHECK_FAILURE',
  HIDE_MODAL = 'HIDE_MODAL',
  OPEN_SOURCE_REQUEST = 'OPEN_SOURCE_REQUEST',
  OPEN_SOURCE_SUCCESS = 'OPEN_SOURCE_SUCCESS',
  OPEN_SOURCE_FAILURE = 'OPEN_SOURCE_FAILURE',
}

export interface IAction {
  type: EActionType;
}

export type TReducer = Reducer<IState, IAction>;

export function reducer(state: IState, action: IAction) {
  switch(action.type) {
  case EActionType.CHECK_AVAILABLE:
    return set('loading')(true)(state);

  case EActionType.CHECK_SUCCESS:
    return compose(
      set('loading')(false),
      set('confirmationModal')(true),
    )(state);

  case EActionType.CHECK_FAILURE:
    return compose(
      set('loading')(false),
      set('interruptModal')(true),
    )(state);

  case EActionType.HIDE_MODAL:
    return compose(
      set('loading')(false),
      set('confirmationModal')(false),
    )(state);

  case EActionType.OPEN_SOURCE_REQUEST:
    return compose(
      set('loading')(true),
    )(state);
  
  case EActionType.OPEN_SOURCE_SUCCESS:
    return compose(
      set('loading')(false),
      set('interruptModal')(false),
    )(state);

  case EActionType.OPEN_SOURCE_FAILURE:
    return compose(
      set('loading')(false),
      set('interruptModal')(false),
    )(state);

  default:
    throw new Error();
  }
}

export const initialArg = {
  confirmationModal: false,
  loading: false,
  interruptModal: false,
};
