import React, { useEffect, useState } from 'react';
import { Dropdown, Button, Icon } from '@wmg-ae/legato';
import styles from 'modules/pages/Party/CreateEditPartyForm.module.scss';
import { EButtonSizes } from 'modules/common/types/legatoTypes';

type List = {
    'key': string,
    'title': string
}

type TSaveDropdown = {
    list: List[],
    onSelect: () => void
}

const SaveDropdown = ({ onSelect, list }: TSaveDropdown) => {
  const [arrow, setArrow] = useState(true);

  useEffect(()=>{
    setTimeout(()=>{
      const element = document.getElementsByClassName('lgt-trigger-placement-bottomLeft')[0];
      if(typeof element === 'object')
        element.addEventListener('focusout', ()=>{setArrow(!!element);});
    }, 50);
  }, [arrow]);
    
  const handleArrow = () => {
    const element = document.getElementsByClassName('lgt-trigger-placement-bottomLeft')[0];
    return setArrow(!!element);
  };

  return ( 
    <Dropdown className={styles.saveDropdown} onSelect={onSelect} list={list}>
      <Button
        className={styles.dropdownButton}
        onClick={handleArrow}
        icon={<Icon
          name={arrow ? 'chevron-down' : 'chevron-up'}
          className={styles.saveIcon}
        />}
        size={EButtonSizes.mini}
        containerStyle={'outline'}
      />
    </Dropdown>
  );
};
 
export default SaveDropdown;