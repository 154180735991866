import React, { FC, PropsWithChildren } from 'react';
import { LegatoFFSelect } from '@grow-components/Form';
import { TSelectOption } from 'common/types';
import { EDateTimeFormat } from 'common/consts';
import { Field, FormRenderProps } from 'react-final-form';
import moment from 'moment';
import { Label, Button } from '@wmg-ae/legato';
import { EButtonStyles, EButtonColors } from '../../../../types/legatoTypes';
import { FFStyledSelect } from '../../../../../../@grow-components/Form/StyledSelect/StyledSelect';
import styles from './UserForm.module.scss';

interface IUserFormProps {
  onCancel?: () => void
}

export const UserForm: FC<FormRenderProps & IUserFormProps> = (props) => {
  const { form, onCancel } = props;
  const languageList: TSelectOption[] = [
    { value: 'EN', label: 'English' },
    { value: 'ES', label: 'Español' },
    { value: 'FR', label: 'Français' },
    { value: 'ZH', label: '简体中文' },
    { value: 'RU', label: 'Русский' },
    { value: 'HI', label: 'हिंदी' },
    { value: 'DE', label: 'Deutsch' },
  ];
  
  const dateTimeList = [
    {
      label: EDateTimeFormat.MM_DD_YYYY_Slash,
      value: 'mm/dd/yyyy' 
    },
    {
      label: EDateTimeFormat.DD_MM_YYYY_Slash, 
      value: 'dd/mm/yyyy'
    },
  ];

  const timeList = [
    {
      label: '24 Hours',
      labelAddon: moment().format(EDateTimeFormat.H_MM),
      value: EDateTimeFormat.H_MM,
      key:'24h'
    },
    {
      label: '12 Hours',
      labelAddon: moment().format(EDateTimeFormat.HH_MM_A),
      value: EDateTimeFormat.HH_MM_A,
      key:'12h'
    },
  ];

  const getSelect = (props: PropsWithChildren<any>) => {
    return <LegatoFFSelect {...props} name={props.input.name} input={props.input} meta={props.meta} zIndex='999'  />;
  };
  
  return (
    <form>
      <div className={styles.selectContent}>
        <div className={styles.selectBlock}>
          <Label className={styles.label} text='Language'/>
          <Field
            name='language'
            component={(props) => getSelect(props)}
            options={languageList}
          />
        </div>
        <div className={styles.selectBlock}>
          <Label className={styles.label} text='Date Format'/>
          <Field
            name='dateFormat'
            options={dateTimeList}
            component={getSelect}
          />
        </div>
        <div className={styles.selectBlock}>
          <Field
            label={'Time Format'}
            name='timeFormat'
            options={timeList}
            component={FFStyledSelect}
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          onClick={onCancel}
          colorType={EButtonColors.secondaryBlack}
          containerStyle={EButtonStyles.outline}
        >
          Cancel
        </Button>
        <Button
          onClick={form.submit}
          colorType={EButtonColors.secondaryBlack}
        >
          Save And Close
        </Button>
      </div>
    </form>
  );
};
