import React, { FC, useEffect, useState, useMemo, memo } from 'react';
import { useParams } from 'react-router-dom';
import { BROADCAST_CHANNELS, useBroadcastChannel } from 'modules/common/hooks/useBroadcastChannel';
import { TIsniSearchParams, TIsniSearchData } from 'modules/common/types/isniTypes';
import { ISNILookupContext } from './context';
import { ILookupContext } from './types';

interface IProps {
  children: React.ReactNode;
}

const Provider: FC<IProps> = ({ children }) => {

  const { nameId } = useParams<TIsniSearchParams>();
  const { sendTMessage } = useBroadcastChannel(BROADCAST_CHANNELS.ISNI);

  const [isniData, setIsniData] = useState<TIsniSearchData>({} as TIsniSearchData);

  useEffect(() => {
    let mount = true;
    sendTMessage.reqISNIData(nameId)
      .then(msg => { mount && setIsniData(msg); })
      .catch(err => console.warn(err));
    return () => {
      mount = false;
    };
  }, []);

  const values = useMemo<ILookupContext>(
    () => ({
      data: isniData,
      activate: () => sendTMessage.activateName(isniData),
    }),
    [isniData],
  );

  return (
    <ISNILookupContext.Provider value={values}>
      {children}
    </ISNILookupContext.Provider>
  );
};

export default memo(Provider);
