import { combineReducers } from '@reduxjs/toolkit';
import searchReducer from './searchReducer';
import artistReducer from './artistReducer';
import alertReducer from './alertReducer';
import { partyReducer } from './party';
import { advancedSearchSlice } from 'redux/reducers/advancedSearchReducer';

const rootReducer = combineReducers({
  party: partyReducer,
  search: searchReducer,
  artist: artistReducer,
  alert: alertReducer,
  advancedSearch: advancedSearchSlice.reducer
});

export default rootReducer;
