/**
 * Formats an ISNI by adding a space after every four characters (e.g. `1234 5678 9012 3456`). Works on static numbers.
 * @param isni
 */
export const formatISNIStatic = (isni: string | undefined) => {
  if (!isni) return '';
  const compressedISNI = isni?.replace(/ /g, '');
  return compressedISNI.replace(/\S{4}(?=.)/g, '$& ');
};

export const createLinkISNI = (isni: string | undefined) => {
  if (!isni) return '';
  return `https://isni.org/isni/${isni}`;
};

/**
 * Converts an ISNI from a readable format (a space after every 4 characters) to a more compact one by removing all spaces (e.g. `1234 5678 9012 3456` to `1234567890123456`).
 * @param isni
 */
export const compressISNI = (isni: string) => {
  if (!isni) return '';
  return isni.replace(/ /g, '');
};

/**
 * Validates ISNI strings according to the ISNI standard specified in ISO 27729. Returns `true` if the string entered is a valid ISNI, and returns `false` otherwise.
 * @param isni
 */
export const validateISNI = (isni: string) => {
  const weighingFactors = [10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2, 1];
  if (isni.length !== 16) return false;

  const validChars = '0123456789xX';
  const nums = '0123456789';
  const choppedISNI = isni.slice(0, 15);
  for (const el of choppedISNI) {
    if (!nums.includes(el)) return false;
  }
  if (!validChars.includes(isni[15])) return false;

  const total = choppedISNI
    .split('')
    .reduce(
      (acc: number, curr: string, idx: number) =>
        acc + Number(curr) * weighingFactors[idx],
      0
    );

  let validator;
  for (let i = 0; i <= 10; i++) {
    const sum = total + i;
    if (sum % 11 === 1) {
      validator = i;
      break;
    }
  }
  if (validator === 10) validator = 'X';
  if (isni[15].toUpperCase() === String(validator)) return true;
  return false;
};
