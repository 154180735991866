import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

type TParamValue = string | number | undefined | null
type TParams = Record<string, TParamValue>

export const useQueryParams = <T extends Record<string, string>> (prefix = '') => {
  const h = useHistory();
  /**
   * Returns current GET params string (including "?")
   */
  const getParamsString = useCallback(() => {
    return h.location.search;
  }, [h.location.search]);

  /**
   * Returns params object from url.
   * uses current url by default
   */
  const getParams = useCallback((url?: string): T => {
    const urlToParse = url ?? getParamsString();
    const paramsMap = new URLSearchParams(urlToParse);
    let record = {} as T;
    for (const [key, value] of paramsMap.entries()) {
      record = { ...record, [key]: value };
    }
    return record;
  }, [getParamsString]);

  const params: T = useMemo(() => getParams(), [getParams]);
  /**
   * Returns a correct serialized string with provided params
   * null and undefined values will be skipped
   */
  const serializeParams = useCallback((params: TParams) => {
    const paramsMap = new URLSearchParams();
    for (const [key, value] of Object.entries(params)) {
      if (typeof value !== 'undefined' && value !== null) {
        paramsMap.set(key, value.toString());
      } else {
        paramsMap.delete(key);
      }
    }
    return paramsMap.toString();
  }, []);
  /**
   * Returns a correct search url string with "?"
   */
  const createURL = useCallback((params: TParams, prefix = '', hash ='') => {
    return `${prefix}?${serializeParams(params)}${hash}`;
  }, [serializeParams]);

  /**
   * Pushes/replaces url with params
   */
  const setURL = useCallback((params: TParams, replaceMode = false) => {
    const url = createURL(params, prefix);
    if (replaceMode) h.replace(url);
    else h.push(url);
  }, [h, createURL]);

  const updateUrlParam = useCallback((param: string, value: TParamValue) => {
    const params: TParams = getParams();
    params[param] = value;
    setURL(params, true);
  }, [getParams, setURL]);

  const updateUrlParams = useCallback((paramsToUpdate: Record<string, TParamValue>) => {
    const params: TParams = getParams();
    for (const [param, value] of Object.entries(paramsToUpdate)) {
      params[param] = value;
    }
    setURL(params, true);
  }, [getParams, setURL]);

  return {
    params,
    getParamsString,
    getParams,
    serializeParams,
    createURL,
    setURL,
    updateUrlParam,
    updateUrlParams
  };
};
