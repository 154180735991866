import React, { useCallback, useEffect, useState } from 'react';
import Banner from '../styled/banners/TimeoutBanner';
import { formatIdleTime } from 'modules/common/helpers/idleTimer';

interface Props {
  getRemainingTime(): number;
  onClick: () => void;
  showAlertColor: boolean;
  offset: number;
}

const IdleAlert: React.FC<Props> = ({
  getRemainingTime,
  onClick,
  showAlertColor,
  offset,
}) => {

  const getTime = useCallback(
    () => Math.round((getRemainingTime() - offset) / 1000),
    [],
  );

  const [time, setTime] = useState<number>(getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(getTime());
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Banner
      remainingTime={formatIdleTime(time)}
      onClick={onClick}
      showAlertColor={showAlertColor}
    />
  );
};

export default IdleAlert;
