import tuple from '../modules/common/helpers/tuple';

import { Identifier, isniMatch as ISNIResult, } from '../modules/common/types/artistTypes';

import {
  CompetencyType,
  Country,
  ENamePrivacyType,
  EPartyType, ERemarkType,
  Identifier as NameIdentifier,
  Language,
  MajorMinorGenre,
  PartyCompetency,
  Remark,
  Translation
} from 'modules/common/types/partyTypes';
import { EPermissionType } from '../modules/common/types/authTypes';
import {
  EPartySubType,
  ENameType,
  TPartyEntity,
  TPartyEntryDetail as NEWTPartyEntryDetail,
} from 'domains/party/types';
import { TElasticName } from 'graphql/queries';
import { TMainSearchResponse, TMainSearchVars } from 'domains/search/hooks/types';

// GET PERMISSIONS
export interface getPermissionsData {
  permissions: Permission[];
}

export type Permission = {
  name: EPermissionType;
};

export interface SearchNamesResult {
  nameId: number
  nameValue: string
  nameType: ENameType;
  privacyType: ENamePrivacyType;
  nameIsLegal?: boolean;
  nameIsni?: string;
  partyId: number;
  partyType: EPartyType;
  partyCountryOfOriginId?: number;
  partyCountryOfOriginName?: string;
  nameInternalComment: string;
}

/**
 * @deprecated use domains/search/graphql TMainSearchResponse instead
 */
export type getSearchNamesData = TMainSearchResponse

/**
 * @deprecated use domains/search/graphql TMainSearchVars instead
 */
export type getSearchNamesVars = TMainSearchVars

// GET ARTISTS
interface ArtistsResult {
  uaid: number;
  legalName: string;
  displayName: string;
  formatCode: string;
  countryOfOriginCode: string;
  isni: string;
  internalComments: string;
}

export interface getArtistsData {
  artists: {
    artists: ArtistsResult[];
    totalCount: number;
    isniEligibleCount: number;
    isniNotEligibleCount: number;
  };
}

export interface getArtistsVars {
  filter: {
    name?: string | number;
    uaid?: string | number;
    isni?: string | number;
    pageIndex: number;
    pageSize: number;
    isIsniEligible?: boolean;
  };
}

// GET ARTIST
export interface ArtistResult {
  uaid: number;
  legalName: string;
  displayName: string;
  formatCode: string;
  typeCode: string;
  majorGenreCode: string;
  minorGenreCode: string;
  countryOfOriginCode: string;
  languageOfOriginCode: string;
  isni: string;
  internalComments: string;
}

export interface getArtistData {
  artists: { artists: ArtistResult[] };
}

export interface getArtistVars {
  filter: {
    uaid: number;
  };
}

// GET ISNI

export enum Type {
  PERSON = 'person',
  FICTIONAL_CHARACTER = 'fictionalCharacter',
  BAND = 'band',
  ORGANIZATION = 'organisation',
  UNKNOWN = 'unknown',
}

export interface getISNIData {
  isni: { isni: ISNIResult[] };
}

export interface getISNIVars {
  filter: {
    isni?: string;
    name?: {
      limit?: number;
      value: string;
    };
  };
}

// GET ISNI OVERLAP
export interface ISNIOverlapResult {
  displayName: string;
  uaid: number;
  isni: string;
}

export interface getISNIOverlapData {
  artists: { artists: ISNIOverlapResult[] };
}

export interface getISNIOverlapVars {
  filter: { isni: string };
}

// GET MORE ARTIST INFO
type GetMoreInfoContribution = {
  title: string;
  role: string;
  value: string;
};

export type Name = {
  firstname: string;
  lastname: string;
  nametype: string;
};

type AssociatedNames = Name & {
  language: string;
};

type Relationship = {
  name: Name;
  identifier: Identifier;
  type: string;
};

export interface getMoreArtistInfoResult extends ISNIResult {
  identifiers: Identifier[];
  isrcContributions: GetMoreInfoContribution[];
  iswcContributions: GetMoreInfoContribution[];
  names: AssociatedNames[];
  relationships: Relationship[];
}

export interface getMoreArtistInfoData {
  isni: { isni: getMoreArtistInfoResult[] };
}

export interface getMoreArtistInfoVars {
  filter: { isni: string; isFull: boolean };
}

// Audit History
type AuditContent = {
  category: string;
  message: string;
  timestamp: string;
  username: string;
  detail: string;
};

export type AuditSearchData = {
  auditSearch: {
    content: AuditContent[];
    totalElements?: number;
    totalPages?: number;
    pageNumber?: number;
  };
};

export type PartyEntityInput = {
  partyId: number;
  partySubType?: EPartySubType;
  dateOfBeginningYear?: number;
  dateOfBeginningMonth?: number;
  dateOfBeginningDay?: number;
  dateOfEndYear?: number;
  dateOfEndMonth?: number;
  dateOfEndDay?: number;
  countryOfOriginId?: number;
  languageOfOriginId?: number;
}

export type NameEntityInput = {
  nameId?: number;
  nameValue: string;
  nameType: ENameType;
  privacyType: ENamePrivacyType;
  isLegal?: boolean;
  majorGenreId?: number;
  minorGenreId?: number;
  translations?: Translation[];
  competencies?: PartyCompetency[];
  remarks?: Remark[];
  identifiers?: NameIdentifier[];
  mapOfChangeAuthors?: string[]
  isExactMatchAreIgnored?: boolean
};

export type MapOfChangeAuthorsInput = {
  path: string;
  author: string;
}

// PARTY COLLECTION LOOKUPS

export type GetCountriesLanguagesGenresData = {
  lookupGetCollection: {
    identifierTypes: any
    translationLanguages: Language[];
    languages: Language[];
    countries: Country[];
    majorGenres: MajorMinorGenre[];
    competencies: CompetencyType[];
    groupTypes: string[];
    partyTypes: EPartyType[];
    personTypes: string[];
    remarkTypes: ERemarkType[];
  };
};

// DUPLICATE NAME CHECK

export const partyTypes = tuple('person', 'group');

export type NameShortened = {
  nameId: number;
  isLegal: boolean;
  nameType: ENameType;
  nameValue: string;
};

export enum EExactMatchType {
  COMMON = 'COMMON',
  EXACT_MATCH = 'EXACT_MATCH',
  NAMING_STANDARDS = 'NAMING_STANDARDS',
}

type NameCheckErrors = {
  index: number;
  property: string;
  message: string;
  name: TElasticName;
  type: EExactMatchType.EXACT_MATCH;
} | {
  index: number;
  message: string;
  property: string;
  name: null;
  type: EExactMatchType.NAMING_STANDARDS;
};

export type DuplicateNameCheckExactInput = {
  data: {
    countryOfOriginId?: number;
    names: Partial<NameShortened>[];
    partyType: EPartyType;
  };
};

export type DuplicateNameCheckExactData = {
  nameBulkValidate: {
    errors: NameCheckErrors[];
  };
};

export type GetPartyInput = {
  data: {
    partyId: number;
  };
};

/**
 * @deprecated
 * use TPartyEntity instead
 */
export type PartyEntity = TPartyEntity

/**
 * @deprecated
 * use TPartyEntryDetail from domains/party/types instead
 */
export type TPartyEntryDetail = NEWTPartyEntryDetail

export interface ISearchHistoryItem {
  searchTerm: string;
}

export interface ISpotifyToken {
  getSpotifyToken: {
    access_token: string;
  }

}

export type ISearchHistoryList = ReadonlyArray<ISearchHistoryItem>;

export interface ISearchHistory {
  searchHistoryGet: {
    items: ISearchHistoryList;
  };
}

export interface ISearchHistoryParams {
  size: number;
}

export interface ISpotifyToken {
  getSpotifyToken: {
    access_token: string;
  }
}
