import { TDictionaries } from 'modules/common/types/partyTypes';

export const countryOptions = (field: [number] | undefined, dictionaries: TDictionaries | undefined) => {

  const fieldArray: any = [{ key: 'CouAl', label: 'Countries (Alphabetical)', heading: true },
    ...(dictionaries?.countries.map((country, idx) => ({
      key: idx + country.name,
      label: country.name,
      value: country.countryId,
    })) || [])];
      
  if(field && field.length > 0){
      
    fieldArray.unshift(...(field.map((countryId, idx) =>{
      const a = dictionaries?.countries.find(country=>country.countryId === countryId);
      return {
        type: 'lastUsed', 
        key: `${idx}-${a?.name}_lastUsed`,
        label: a?.name,
        value: a?.countryId,
      };
    })));
    fieldArray.unshift({ key: 'ReUsed', label: 'Recently Used', heading: true });
  }
      
  return fieldArray;
};


export const languageOptions = (field: [number] | undefined, dictionaries: TDictionaries | undefined) => {

  const fieldArray: any = [{ key: 'LaAl', label: 'Languages (Alphabetical)', heading: true },
    ...(dictionaries?.languages.map((t, idx) => ({
      key: idx + t.displayName,
      label: t.displayName,
      value: t.languageVariantId
    })) || [])];
      
  if(field && field.length > 0){
      
    fieldArray.unshift( ...(field?.map((languageId, idx)=>{
      const a = dictionaries?.languages.find(t=>t.languageVariantId === languageId);
      return {
        type: 'lastUsed', 
        key: `${idx}-${a?.displayName}_lastUsed`,
        label: a?.displayName,
        value: a?.languageVariantId, 
      };
    })));
    fieldArray.unshift({ key: 'ReUsed', label: 'Recently Used', heading: true });
  }
    
  return fieldArray;
};