import { useCallback, useEffect, useRef } from 'react';
import pick from 'lodash/pick';
import { IGROWKeys, IGROWSession, storageFactory, useStorage } from 'utils/storage';
import { ISearch, TSearchNames } from './types';
import { useQuery } from 'modules/common/hooks/useQuery';
import { toNumber } from 'utils';
import { ESearchType } from 'domains/search/graphql';

/**
 * @deprecated
 */
export function useSearchQuery() {

  const sizeStore = useRef<number>(25);

  const Storage = useCallback(() => storageFactory(window.localStorage), []);
  const storage = useStorage<IGROWSession>(Storage);

  useEffect(() => {
    storage.get(IGROWKeys.PAGE_SIZE, 25).then((l) => { sizeStore.current = l; });
  }, [storage]);

  const inAdapter = useCallback<(data: TSearchNames) => ISearch>(
    ({ searchTerm, size, sort, page }) => {
      return {
        term: searchTerm.toString(),
        page: page.toString(),
        size: size.toString(),
        sort: sort?.type,
        desc: sort?.desc === true ? 'true' : undefined,
      };
    },
  [],
  );

  const outAdapter = useCallback<(data: ISearch) => TSearchNames>(
    (data) => {
      const { term, page: pageString, size: sizeString, sort, desc } = pick(data, ['term', 'page', 'size', 'sort', 'desc']);
      const page = toNumber(pageString, 1);
      const size = toNumber(sizeString, sizeStore.current);
      const sortProps = typeof sort !== 'undefined' ? {
        type: sort,
        desc: desc === 'true',
      } : undefined;
      return {
        searchType: ESearchType.FULL_SEARCH,
        searchTerm: term || '',
        size,
        page,
        sort: sortProps,
      };
    }, [],
  );

  const { getValues, values, getURL, setURL } = useQuery<ISearch, TSearchNames>(inAdapter, outAdapter, '/search?');

  const setSize = useCallback<(size: number) => void>(
    (size) => {
      storage.set(IGROWKeys.PAGE_SIZE, size);
      sizeStore.current = size;
      setURL({
        page: 1,
        size,
      });
    },
  [],
  );

  return {
    getValues,
    setSize,
    values,
    setURL,
    getURL,
  };

}
