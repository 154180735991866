import React, { FC } from 'react';

interface Props {
  className?: string
}

export const Layout: FC<Props> = ({ children , className }) => {
  return (
    <thead className={className}>
      <tr>
        {
          children
        }
      </tr>
    </thead>
  );
};
