import { useCallback, useMemo } from 'react';
import { TGetStorage } from './components/Storages/types';
import { IStoredList } from './types';
import uniqBy from 'lodash/uniqBy';

const STORAGE_KEY = 'RECENTLY_SEARCHED_TERM_LIST';

export function useStorage(getStorage: TGetStorage<IStoredList>) {

  const storage = useMemo(() => getStorage(STORAGE_KEY, []), [getStorage]);

  const init = useCallback<(l: IStoredList) => Promise<IStoredList>>(
    async (list) => {
      await storage.set(list);
      return storage.get();
    },
  [],
  );

  const cleanList = useCallback<(l: IStoredList) => IStoredList>(
    (list) => [...list].sort((a, b) => b.date - a.date).slice(0, 6),
  [],
  );

  const getList = useCallback<() => Promise<IStoredList>>(
    async () => cleanList(await storage.get()),
  [storage, cleanList],
  );

  const addItem = useCallback<(item: string) => Promise<IStoredList>>(
    async (term) => {
      const prev = await getList();
      const list = uniqBy(
        [{ term, date: new Date().getTime() }].concat(prev),
        ({ term }) => term
      );
      const newList = cleanList(list);
      await storage.set(newList);
      return newList;
    },
  [storage, cleanList, getList],
  );

  return {
    getList,
    addItem,
    init,
  };
}
