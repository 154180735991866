import React, { FC } from 'react';
import { cn } from 'utils';

import styles from './logo.module.scss';
import { useTranslation } from 'react-i18next';

const growLandingUrl = process.env.REACT_APP_GROW_LANDING_URL;

const ENV_MAPPING: Record<string, string> = {
  Development: 'dev',
  QA: 'qa',
  UAT: 'uat',
  MUAT: 'muat',
  Staging: 'staging',
  'STAGING-R3': 'staging'
};

export const Logo: FC = () => {
  const env = ENV_MAPPING[process.env.REACT_APP_ENV_NAME || ''];
  const { t } = useTranslation();

  return <div className={styles.wrapper}>
    <div className={cn(styles.stripe, styles[`stripe_${env}`])} />
    <a href={`${growLandingUrl}home`} className={styles.link}>
      <div className={cn(styles.logo, styles[`logo_${env}`])} />
      <span className={cn(styles.grow, styles[`grow_${env}`])}>{t('liw')}{env && ` / ${env.toUpperCase()}`}</span>
    </a>
  </div>;
};