import { ENameType, TPartyName } from 'domains/party/types';
import get from 'lodash/get';
import compact from 'lodash/fp/compact';
import { EQueryType, TQueryList } from './types';
import { isPerformingArtist } from '../../helpers';

export const omitByIndex = <T extends ReadonlyArray<any>>(index: number) => (r: T) => r.filter((_, i) => i !== index);

function checkForFuzzy(name: TPartyName): boolean {
  if (get(name, '_fuzzyMatchesAreIgnored', false)) return false;
  return true;
}

function checkForExact(name: TPartyName): boolean {
  if (!isPerformingArtist(name) && name.nameType !== ENameType.PKA) return false;
  if (get(name, '_isExactMatchAreIgnored', false)) return false;
  return true;
}

function prepareTypes(names: TPartyName[], index: number, excludeQuery?: boolean): ReadonlyArray<EQueryType> {
  const name = names[index];
  if (typeof name === 'undefined' || name.nameValue.trim().length === 0) return [];
  return compact([
    checkForFuzzy(name) && !excludeQuery ? EQueryType.FUZZY : undefined,
    checkForExact(name) ? EQueryType.EXACT : undefined
  ]);
}

function prepareName(names: TPartyName[], index: number, id: number | undefined, excludeFuzzy?: boolean): ReadonlyArray<TQueryList> {
  const types = prepareTypes(names, index, excludeFuzzy);
  return types.length > 0 ?[{
    index,
    searchTerm: names[index].nameValue,
    excludeNameId: names[index].nameId,
    excludePartyId: id,
    types,
  }] : [];
}

export function prepareQuery(names: TPartyName[], index?: number, id?: number | undefined, excludeFuzzyValidation?: boolean) {
  if (typeof index === 'number') {
    return prepareName(names, index, id, excludeFuzzyValidation);
  } else {
    return names.reduce((a, v, index) => {
      const name = prepareName(names, index, id, excludeFuzzyValidation);
      if (name.length === 1) return [...a, ...name];
      return a;
    }, [] as ReadonlyArray<TQueryList>);
  }
}

export function getIgnoredPath<T>(names: ReadonlyArray<T>): ReadonlyArray<keyof T> {
  return names.reduce(
    (a, v, idx) => {
      let path = [] as ReadonlyArray<keyof T>;
      if (get(v, '_fuzzyMatchesAreIgnored', false)) path = [...path, `names[${idx}]._fuzzyMatchesAreIgnored` as keyof T];
      if (get(v, '_isExactMatchAreIgnored', false)) path = [...path, `names[${idx}]._isExactMatchAreIgnored` as keyof T];
      return [...a, ...path];
    },
    [] as ReadonlyArray<keyof T>,
  );
}
