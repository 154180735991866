import React, { FC, useCallback, useState } from 'react';
import { cn } from 'utils';
import { TDropdownMenuItem } from '../types';

import styles from '../DropdownMenu.module.scss';

interface IDropdownMenuItemProps {
  item: TDropdownMenuItem;
  handleSelect(fn?: () => void): void;
}

export const DropdownMenuItem: FC<IDropdownMenuItemProps> = ({ item, handleSelect }) => {
  const [isSubMenuVisible, setSubMenuVisible] = useState(false);

  const onSelectSubMenuItem = useCallback((subMenuItem: TDropdownMenuItem) => () => {
    handleSelect(subMenuItem.onSelect);
    setSubMenuVisible(false);
  }, [handleSelect, setSubMenuVisible]);

  return <li
    className={styles.item}
    onMouseEnter={() => setSubMenuVisible(true)}
    onMouseLeave={() => setSubMenuVisible(false)}
  >
    {item.label}
    {(item.subMenuItems && isSubMenuVisible) &&
            <ul className={cn(styles.list, styles.submenu)}>
              {item.subMenuItems.map((subMenuItem, subMenuItemIndex) =>
                <li key={`DropdownSubMenuItem_${subMenuItem.label}${subMenuItemIndex}`}
                  className={styles.item}
                  onClick={onSelectSubMenuItem(subMenuItem)}
                >
                  {subMenuItem.label}
                </li>)}
            </ul>}
  </li>;
};