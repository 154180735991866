import React, { FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-final-form';
import get from 'lodash/get';
import { InputsArray } from '@grow-components/Form';
import { useConfirmation } from '@grow-components/Popup';
import { TranslationGroup } from './TranslationGroup';
import { isAlreadySelected } from '../helpers';
import { ITranslationProps } from './types';

export const Translation: FC<ITranslationProps> = ({
  disabled,
  languages,
  namePath,
  values,
}) => {
  const { t } = useTranslation();
  const removeRef = useRef<() => void>();
  const { values: formValues } = useFormState();

  const isSelected = useCallback(
    (idx: number) => isAlreadySelected(idx, 'languageVariantId', values),
    [values],
  );


  const group = useCallback(
    (translationName, idx) => (
      <TranslationGroup
        translationName={translationName}
        idx={idx}
        placeholders={[t('party_placeholders:select_language'), t('party_placeholders:enter_translation')]}
        disabled={disabled}
        isAlreadySelected={isSelected(idx)}
        languages={languages}
      />
    ),
    [disabled, t, isSelected],
  );

  const deleteHandler = useCallback(() => {
    if (typeof removeRef.current === 'function') {
      removeRef.current();
    }
  }, []);

  const [onHandler, dialog] = useConfirmation(
    deleteHandler,
    (idx: number) => {
      const translation = get(formValues, `${namePath}.translations[${idx}]`);
      if (typeof translation.nameTransId !== 'undefined') return true;
      deleteHandler();
      return false;
    }, {
      name: t('modal:delete_translation_confirmation'),
      message: t('modal:delete_translation_confirmation_message'),
      actionText: t('delete'),
    },
  );

  const deleteProxy = useCallback((fn: () => void, idx) => {
    removeRef.current = fn;
    onHandler(idx);
  }, [onHandler]);

  return (
    <>
      <InputsArray
        title={t('party_labels:translations')}
        disabled={disabled}
        titleTooltip={t('tooltips:translations')}
        name={`${namePath}.translations`}
        addButtonText={t('party_labels:new_translation')}
        addButtonTooltip={t('tooltips:add_translation_button')}
        deleteButtonTooltip={t('tooltips:delete_translation')}
        component={group}
        onBeforeDelete={deleteProxy}
      />
      {
        dialog
      }
    </>
  );
};
