import React, { FC } from 'react';

export const Layout: FC = ({ children }) => {
  return (
    <tbody>
      {
        children
      }
    </tbody>
  );
};
