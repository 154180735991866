import { useLazyQuery } from '@apollo/client';
import { TSearchSpotifyIDResponse, TSearchSpotifyIDVars } from './types';
import { SEARCH_SPOTIFY_ID } from '../graphql/partySearchIdentifiers';

export const useSearchSpotifyID = (partyId = 0, countryId = 0) => {

  const [searchSpotifyID, { loading, data, error }] = useLazyQuery<TSearchSpotifyIDResponse, TSearchSpotifyIDVars>(SEARCH_SPOTIFY_ID, {
    fetchPolicy: 'no-cache',
  });

  const handler = (spotifyId: string) => {
    searchSpotifyID({ variables: {
      data: {
        partyId,
        countryId,
        spotifyId
      }
    } });
  };

  return { handler, loading, data, error };
};
