import React, { useMemo, useState } from 'react';
import { createExternalLinks } from 'modules/common/helpers/createExternalLink';
import { Identifier } from 'modules/common/types/partyTypes';
import { IdentifierItem } from './IdentifierItem';
import { SectionHeader } from '@grow-components/Form/InputsArray/SectionHeader';
import { useTranslation } from 'react-i18next';
import styles from './IdentifiersInfo.module.scss';
import { Tooltip } from '@grow-components/Tooltip/Tooltip';
import { Button } from '@wmg-ae/legato';
import { useLocation } from 'react-router-dom';
import { AddIdentifiersModal } from './AddIdentifiers';
import { EButtonSizes } from 'modules/common/types/legatoTypes';

interface Props {
  identifiers: Identifier[] | undefined;
  nameIdx?: number;
}

export const IdentifiersInfo: React.FC<Props> = ({ identifiers = [], nameIdx = 0 }) => {
  const [showAddIds, setShowAddIds] = useState(false);
  const { t } = useTranslation('party_labels');
  const location = useLocation();

  const isInEditPage = useMemo(() => location.pathname.includes('edit'), [
    location.pathname,
  ]);

  return (
    <div>
      <SectionHeader
        title={
          <Tooltip text={t('tooltips:additional_ids')}>
            {t('additional_ids')}
          </Tooltip>
        }
        className={styles.headerBlock}
        button={
          isInEditPage ? (
            <Button
              containerStyle='link'
              icon='edit'
              size={EButtonSizes.mini}
              label={t('party_labels:edit_ids')}
              onClick={() => setShowAddIds(true)}
            />
          ) : undefined
        }
      />
      <div className='columns-4'>
        {identifiers?.length ? (
          identifiers.map((id) => {
            const { link, label } = createExternalLinks(
              id.identifierType,
              id.value
            );
            return (
              label.length > 0 && (
                <div key={id.value}>
                  <IdentifierItem
                    value={id.value}
                    link={link}
                    label={label}
                    clipboardMark={t('toasts:is_copied', { label })}
                  />
                </div>
              )
            );
          })
        ) : (
          <div className='no-data-available'>{t('no_ids')}</div>
        )}
      </div>
      { isInEditPage &&
        <AddIdentifiersModal
          nameIdx={nameIdx}
          ids={identifiers}
          isOpen={showAddIds}
          onClose={() => setShowAddIds(false)}
        />
      }
    </div>
  );
};
