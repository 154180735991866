import Tooltip from 'modules/common/components/utils/Tooltip';
import moment from 'moment';
import React, { FC } from 'react';
import { cn } from 'utils';
import styles from './RecordChangeTimeAndAuthor.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { useSettings } from 'domains/env';

type TRecordChangeTimeAndAuthorProps = {
  type: 'created' | 'edited'
  createdAt?: string
  createdBy?: string
  editedAt?: string
  editedBy?: string
  className?: string
}
let tooltipIdCounter = 0;

const DateMetaTooltip: FC<TRecordChangeTimeAndAuthorProps> = (props) => {
  const { type, editedAt, createdAt, createdBy, editedBy } = props;
  const { t } = useTranslation();

  const createTimestamp = moment(createdAt);
  const editTimestamp = moment(editedAt);
  const timestampToShow = type === 'edited' ? editTimestamp : createTimestamp;
  const tooltipId = `timestamp_${type}_${++tooltipIdCounter}`;
  const { settings } = useSettings();

  return (
    <>
      <span
        data-tip={true}
        data-for={tooltipId}
        className={cn(styles.timestamp, styles[type])}
      >{timestampToShow.fromNow()}</span>
      <Tooltip id={tooltipId} delayShow={200} place={type === 'created' ? 'bottom' : 'top'}>
        <div className={styles.tooltipBody}>
          <div className={styles.tooltipRow}>{t('created_by')} {createdBy}: <br />{createTimestamp.format(settings?.dateFormat?.toUpperCase())} - {createTimestamp.format(settings?.timeFormat)}</div>
          {type === 'edited' && (
            <div className={styles.tooltipRow}>{t('edited_by')} {editedBy}: <br />{editTimestamp.format(settings?.dateFormat?.toUpperCase())} - {editTimestamp.format(settings?.timeFormat)}</div>
          )}
        </div>
      </Tooltip>
    </>
  );
};
export const RecordChangeTimeAndAuthor: FC<TRecordChangeTimeAndAuthorProps> = (props) => {
  const { type, createdBy, editedBy } = props;
  const { t } = useTranslation();

  return (
    <div className={cn(styles.container, props.className)}>
      {type === 'created' && (
        <>
          {t('created_by')} {createdBy}&nbsp;
          <DateMetaTooltip {...props} />
        </>
      )}
      {type === 'edited' && (
        <>
          <Trans
            i18nKey="last_edited_by_with_time_and_author"
            values={{
              author: editedBy
            }}
            components={{
              tooltip: <DateMetaTooltip {...props} />
            }}
          />
        </>
      )}
    </div>
  );
};
