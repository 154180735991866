import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Subheader } from '@grow-components/Subheader/Subheader';
import { FullScreenSpinner } from '@grow-components/Spinners';
import { ExpandableDataTable } from '@grow-components/ExpandableDataTable/ExpandableDataTable';
import Layout from 'modules/common/components/Layout';
import { useQueryParams } from 'modules/common/hooks/useQueryParams';
import { useGetPartyAssets } from 'domains/party/hooks/useGetPartyAssets';
import { AssetsBlock } from './components/AssetsBlock/AssetsBlock';
import styles from './gcdm-more-info.module.scss';
import { SourceCredit } from '@grow-components/SourceCredit/SourceCredit';
import { useTranslation } from 'react-i18next';

type TGCDMMoreInfoParams = {
    uaid: string;
}

type TGCDMMoreInfoQueryParams = {
    pkaName: string;
    spotifyId: string;
}

const companiesLabels = [
  {
    key: 'companyTitle',
    label: 'Related Companies'
  },
  {
    key: 'companyCode',
    label: 'Code'
  },
  {
    key: 'relationship',
    label: 'Relationship Type'
  }
];

export const GCDMMoreInfoPage: FC = () => {
  const { uaid } = useParams<TGCDMMoreInfoParams>();
  const { getParams } = useQueryParams<TGCDMMoreInfoQueryParams>();
  const { pkaName } = getParams();
  const { data: assets, loading: loadingAssets } = useGetPartyAssets(uaid);
  const { t } = useTranslation();
  return <Layout>
    {loadingAssets && <FullScreenSpinner />}
    {assets && <div className='container'>
      <Subheader
        location='GCDM Details'
        mainInfo={pkaName}
        additionalInfo={<>UAID <span className={styles.uaid}>{uaid}</span></>}
        rightSideBlock={
          <SourceCredit 
            source='GCDM' 
            tooltipContent={t('tooltips:source_gcdm_screen_text')} 
          />
        }
        sticky
      />
      <div className={styles.content}>
        <AssetsBlock
          partyUAID={uaid}
          artistName={pkaName}
          assetCount={assets.assetCount}
          productCount={assets.productCount} />
        <div className={styles.table}>
          <ExpandableDataTable
            title="Company List"
            labels={companiesLabels}
            data={assets.artistCompanyData}
            evenCellsWidth
            initialItemsNumber={8}
            sticky
          />
        </div>
      </div>
    </div>}
  </Layout>;
};
