import React from 'react';
import { Trans } from 'react-i18next';
import { Icon } from '@grow-components/Icon/Icon';
import { usePermissions } from 'modules/common/hooks/usePersmissions';
import { Button } from './Button';
import styles from './creating.module.scss';

export const Creating = () => {
  const { canCreateParty } = usePermissions();
  return (
    <div data-elememnt="start-creating" >
      <ul className={styles.btnGroup}>
        <li className={styles.item}>
          <Button to="/party/person/create" disabled={!canCreateParty} className={styles.btn}>
            <Icon icon="user" className={styles.icon} />
            <Trans i18nKey="create_person" defaults="Create a Person" />
          </Button>
          <p className={styles.caption}>
            <Trans i18nKey="create_person_caption" defaults="Create a party based around a singular artist or songwriter." />
          </p>
        </li>
        <li className={styles.item}>
          <Button to="/party/group/create" disabled={!canCreateParty} className={styles.btn}>
            <Icon icon="users" className={styles.icon} />
            <Trans i18nKey="create_group" defaults="Create a Group" />
          </Button>
          <p className={styles.caption}>
            <Trans i18nKey="create_group_caption" defaults="Create a party for a group of musicians or a band." />
          </p>
        </li>
      </ul>
    </div>
  );
};
