import React, { FC } from 'react';
import styles from './Checkbox.module.scss';
import { FieldRenderProps } from 'react-final-form';
import { WarningIcon } from '@grow-components/Icon/IconWithTooltip';
import commonStyles from '@grow-components/Form/CommonStyles.module.scss';
import { Tooltip } from '@grow-components/Tooltip/Tooltip';
import { Icon } from '@grow-components/Icon/Icon';
import { cn } from 'utils';

type TCheckboxProps = JSX.IntrinsicElements['input'] & {
  label?: string
  labelTooltip?: string
  error?: string
  className?: string
}

export const Checkbox: FC<TCheckboxProps> = (props) => {
  const { label, error, labelTooltip, className, ...intrinsicProps } = props;
  return (
    <div className={cn(styles.checkbox, className)}>
      <label>
        <input className={styles.input} type="checkbox" {...intrinsicProps} />
        <Icon icon={'square'} className={styles.empty} />
        <Icon icon={'square-check'} className={styles.full} />
        {labelTooltip ? (
          <Tooltip text={labelTooltip}>{label}</Tooltip>
        ) : (
          <span>{label}</span>
        )}
        {error && (<WarningIcon text={error} className={commonStyles.iconInLabel} />)}
      </label>
    </div>
  );
};

export const FFCheckbox: FC<FieldRenderProps<string> & TCheckboxProps> = (props) => {
  const { input, meta, forceError, ...rest } = props;
  return (
    <Checkbox
      {...input}
      error={forceError
        ? meta.error
        : (meta.touched && meta.error)
      }
      {...rest}
    />
  );
};

