import { ISettings } from 'domains/env/types';
import { EPartyHistoryEntity, PartyHistoryItem } from 'domains/history';
import { ENameType } from 'domains/party/types';
import { EIdentifierType } from 'modules/common/types/partyTypes';

export enum EField {
  partyType = 'partyType',
  partySubType = 'partySubType',
  countryOfOrigin = 'countryOfOrigin',
  dateOfBeginningDay = 'dateOfBeginningDay',
  dateOfBeginningMonth = 'dateOfBeginningMonth',
  dateOfBeginningYear = 'dateOfBeginningYear',
  dateOfBeginning = 'dateOfBeginning',
  dateOfEnd = 'dateOfEnd',
  languageOfOrigin = 'languageOfOrigin',
  majorGenre = 'majorGenre',
  minorGenre = 'minorGenre',
  privacyType = 'privacyType',
  isLegal = 'isLegal',
  nameValue = 'nameValue',
  nameType = 'nameType',
}

export type THistoryGroup = Record<EPartyHistoryEntity, ReadonlyArray<PartyHistoryItem>>

interface IBaseHistoryTooltip {
  data?: THistoryGroup;
}

export interface IProps extends IBaseHistoryTooltip {
  gkey: EPartyHistoryEntity;
  field?: EField;
  values?: {
    nameType?: ENameType;
    identifier?: EIdentifierType;
    nameValue?: string;
  };
  settings?: ISettings;
}
