import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, AnyObject } from 'react-final-form';
import { Dialog, EDialogType } from '@grow-components/Popup';
import { Party } from 'modules/common/types/partyTypes';

import { TInitialParty } from '../../types';

import { usePartyFormModified } from '../businessLogic';
import { usePartyLock } from 'domains/party/hooks/usePartyLock';

enum EHistoryStateStatus {
  cancel = 'cancel',
  submit = 'submit',
}

export type THistoryState = undefined | EHistoryStateStatus;

interface IProps {
  initialValues: Party | TInitialParty;
  onSubmit: () => Promise<AnyObject | undefined> | undefined
}

export const Prompt: FC<IProps> = ({ initialValues, onSubmit }) => {

  const [nextPath, setNextPath] = useState<string | undefined>();
  const formModified = usePartyFormModified(initialValues);
  const isLocked = useRef<boolean>(formModified);
  const linkCollection = useRef<HTMLAnchorElement[]>([]);
  const { t } = useTranslation();
  const { push, block } = useHistory<EHistoryStateStatus>();
  const { getState } = useForm();

  const  { lockParty, unlockParty } = usePartyLock(initialValues.id);

  const closeDialog = useCallback(
    () => { setNextPath(undefined); },
    [setNextPath],
  );

  const gotoUrl = useCallback((url: string) => {
    try {
      const { pathname, origin, search } = new URL(url);
      if (origin === window.location.origin) {
        push(pathname + search);
      } else {
        window.onbeforeunload = null;
        window.location.href = url;
      }
    } catch (err) {
      console.warn(err);
      window.onbeforeunload = null;
      window.location.href = url;
    }
  }, []);

  const handleSave = useCallback( async () => {
    closeDialog();
    await onSubmit();
    const hasErrors = getState().hasValidationErrors || getState().hasSubmitErrors;
    if (typeof nextPath === 'string' && !hasErrors) {
      isLocked.current = false;
      gotoUrl(nextPath);
    }
  }, [getState, closeDialog, gotoUrl, nextPath]);

  const handleDoNotSave = useCallback(
    async () => {
      isLocked.current = false;
      closeDialog();
      if (initialValues.id) await unlockParty();
      if (typeof nextPath === 'string') {
        gotoUrl(nextPath);
      }
    },
    [nextPath, gotoUrl, closeDialog],
  );

  useEffect(() => { isLocked.current = formModified; }, [formModified]);

  useEffect(() => {
    const unBlock = block(({ pathname, search, state }) => {
      try {
        const { href } = new URL(pathname + search, window.location.origin);
        setNextPath(href);
      } catch (err) {
        console.warn(err);
      }
      if (state === 'submit' || state === 'cancel') {
        isLocked.current = false;
        push({ pathname, search, state: undefined }); // TODO: Should check is it really PUSH
        return false;
      }
      return isLocked.current ? false : undefined;
    });
    return () => {
      unBlock();
    };
  }, [block, setNextPath]);

  const handleUnload = (event: any) => {
    if (initialValues.id) unlockParty();
    if (isLocked.current) {
      event.returnValue = t('modal:would_you_like_to_save_your_changes');
      // outer setTimeout is to provide partyLock on window close cancel
      setTimeout(function() {
        // inner setTimeout is for avoiding partyLock on window close confirm
        setTimeout(function() {
          if (initialValues.id) lockParty();
        }, 100);
      },100);
      return t('modal:would_you_like_to_save_your_changes');
    }
  };

  useEffect(
    () => {
      window.onbeforeunload = handleUnload;
      return () => {
        window.onbeforeunload = null;
      };
    },
    [isLocked.current],
  );

  const handleForeignClick = useCallback(function (this: HTMLAnchorElement, event: MouseEvent) {
    if (isLocked.current) {
      event.preventDefault();
      event.stopPropagation();
      setNextPath(this.href);
    }
  }, [isLocked.current]);

  useEffect(() => {
    const links = document.getElementsByTagName('a');
    for (const link of links) {
      if (link.origin !== window.location.origin) {
        link.addEventListener('click', handleForeignClick, false);
        linkCollection.current.push(link);
      }
    }
    return () => {
      linkCollection.current.forEach(l => l.removeEventListener('click', handleForeignClick));
    };
  }, []);

  return (
    <Dialog
      name={t('cancel')}
      isOpen={!!nextPath}
      type={EDialogType.warning}
      onClose={closeDialog}
      onAction={handleSave}
      onCancel={handleDoNotSave}
      actionText={t('save')}
      cancelText={t('modal:do_not_save')}
      closeText={t('cancel')}
    >{t('modal:would_you_like_to_save_your_changes')}</Dialog>
  );
};
