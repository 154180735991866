import { useCallback, useEffect, useMemo, useRef } from 'react';
import { EIsniPageCameFrom, EIsniSearchParam, TIsniSearchData } from 'modules/common/types/isniTypes';
import { EStorageKeys } from 'common/consts';
import { BROADCAST_CHANNELS, EBroadCastMessageType, useBroadcastChannel } from 'modules/common/hooks/useBroadcastChannel';
import { ENamePrivacyType } from 'modules/common/types/partyTypes';
import { useParams } from 'react-router-dom';

interface IName {
  nameValue: string;
  nameId?: number;
  partyId?: number;
  privacyType?: ENamePrivacyType;
  _formNameId?: string;
}

interface IParams<T extends IName = IName> {
  currentISNI?: string,
  page?: EIsniPageCameFrom;
  name: T;
  hideModal: () => void;
}

interface ISearch {
  type: EIsniSearchParam,
  term: string;
  nameId: string;
}

export const useIsni = ({ currentISNI, name, page, hideModal }: IParams) => {

  const { id } = useParams<{ id: any }>();

  const { subscribeOnMessage, sendTMessage } = useBroadcastChannel(BROADCAST_CHANNELS.ISNI);

  const subscriber = useRef<() => void>();

  const { partyId, privacyType } = useMemo(
    () => ({
      partyId: id || name.partyId,
      privacyType: name.privacyType,
    }),
    [name, id, name.nameValue],
  );

  const getIsniData = useCallback<(data: ISearch) => TIsniSearchData>(
    ({ type, term, nameId }) => ({
      name: name.nameValue,
      nameId,
      partyId,
      searchParam: type,
      searchValue: term,
      currentISNI,
    }),
  [name, currentISNI],
  );

  const handleReceiveData = useCallback(
    (data: ISearch) => (id: string) => {
      if (data.nameId === id) {
        if (privacyType) localStorage.setItem(EStorageKeys.namePrivacy, privacyType);
        sendTMessage.respISNIData(getIsniData(data));
        if (page === EIsniPageCameFrom.SEARCH) {
          const isniData = getIsniData(data);
          localStorage.setItem('isniDataFromSearch', JSON.stringify(isniData));
        }
        hideModal();
      }
    },
    [hideModal, sendTMessage],
  );

  const openWindow = useCallback((nameId) => {
    window.open(`/isni-search/${nameId}?cameFrom=${page}`);
  }, [page]);

  const nameId = useMemo<string>(() => {
    // for lookup from create/edit form
    if (name?._formNameId) return name._formNameId;

    // for lookup from search
    return name.nameId ? name.nameId.toString() : '';
  }, [name]);

  const unsabscribe = useCallback(
    () => {
      if (typeof subscriber.current === 'function') {
        subscriber.current();
        subscriber.current = undefined;
      }
    },
    [],
  );

  const activate = useCallback(
    ({ term, type }: ISearch) => {
      unsabscribe();
      const handler = handleReceiveData({ type, term, nameId });
      subscriber.current = subscribeOnMessage(EBroadCastMessageType.REQ_ISNI_DATA, handler);
    },
    [unsabscribe, handleReceiveData],
  );

  const makeSearch = useCallback((data: ISearch) => {
    activate(data);
    openWindow(nameId);
  }, [openWindow, activate]);

  const handleUnload = () => {
    if (page === EIsniPageCameFrom.SEARCH) {
      const searchUri = window.location.pathname + window.location.search;
      localStorage.setItem('searchUri', JSON.stringify(searchUri));
      sendTMessage.searchTabClosed({ searchUri });
    }
  };

  useEffect(
    () => {
      window.onbeforeunload = handleUnload;
      sendTMessage.nameIdIsReady(nameId)
        .then(msg => {
          activate({ term: msg.searchValue, type: msg.searchParam, nameId });
        })
        .catch((err) => { console.warn(err); });
      return () => {
        unsabscribe();
      };
    },
    [],
  );

  return {
    makeSearch,
  };
};
