import { useLazyQuery } from '@apollo/client';
import { TFileExportSearchResponse, TFileExportSearchVars } from 'domains/search/hooks/types';

import { FILE_EXPORT_SEARCH } from '../graphql/fileExportSearch';
import { autoDownload } from 'utils/autoDownload';

export const useFileExportSearch = () => {

  const [fileExportSearch] = useLazyQuery<TFileExportSearchResponse, TFileExportSearchVars>(
    FILE_EXPORT_SEARCH,
    {
      fetchPolicy: 'no-cache',
      onCompleted(data) { // init auto-download from signed url
        const { url: signedUrl, filename } = data?.fileExportSearch;
        autoDownload(signedUrl, filename);
      }
    });
  return { fileExportSearch };
};