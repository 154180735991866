import React, { FC, useEffect } from 'react';
import { Field, useField, useForm } from 'react-final-form';
import { FFTextarea, LegatoFFSelect } from '@grow-components/Form';
import { EButtonSizes, EButtonStyles } from 'modules/common/types/legatoTypes';
import { Button } from '@wmg-ae/legato';
import { useTranslation } from 'react-i18next';
import { TSelectOption } from 'common/types';
import { cn } from 'utils';
import { Tooltip } from '@grow-components/Tooltip/Tooltip';
import { ERemarkType } from 'modules/common/types/partyTypes';
import styles from '@grow-components/Remarks/Remarks.module.scss';

const INTERNAL_COMMENT_MAX_LENGTH = 30;

type TRemarkFormProps = {
  name: string
  remarkTypes: TSelectOption[] | undefined
  onCancel?: () => void
  onConfirm?: () => void
}
export const RemarkForm: FC<TRemarkFormProps> = (props) => {
  const { name, remarkTypes = [] } = props;
  const { t } = useTranslation();
  const { input: remarkTypeInput } = useField(`${name}.remarkType`);
  const { input: remarkText } = useField(`${name}.text`);
  const form = useForm();

  useEffect(() => {
    if (remarkTypeInput.value === ERemarkType.INTERNAL_COMMENT)
      form.getFieldState(`${name}.text`)?.change((remarkText.value as string).substring(0,INTERNAL_COMMENT_MAX_LENGTH));
  }, [remarkTypeInput]);
  
  const couldBeConfirmed = remarkTypeInput.value && remarkText.value;
  const isInternalComment = remarkTypeInput.value === ERemarkType.INTERNAL_COMMENT;
  return (
    <div className={cn(styles.container)}>
      <Tooltip text={t('tooltips:remark_type')}>
        <Field
          name={`${name}.remarkType`}
          component={LegatoFFSelect}
          placeholder={t('party_placeholders:select_remark_type')}
          options={remarkTypes}
        />
      </Tooltip>
      <Tooltip text={t('tooltips:remark_text')}>
        <Field
          placeholder={t('party_placeholders:enter_remark')}
          name={`${name}.text`}
          component={FFTextarea}
          className={styles.remarkTextarea}
          maxLength={isInternalComment ? INTERNAL_COMMENT_MAX_LENGTH : null }
        />
        <div 
          className={cn(styles.internalCommentCharacterCounter)}
          hidden={!isInternalComment} 
        >
          <span>
            {`${(remarkText.value as string).length}/${INTERNAL_COMMENT_MAX_LENGTH}`}
          </span>       
        </div>
      </Tooltip>
      <div className={styles.formButtons}>
        <Button size={EButtonSizes.mini} label={t('cancel')} containerStyle={EButtonStyles.link} onClick={props.onCancel} />
        <Button
          size={EButtonSizes.mini}
          label={t('confirm')}
          containerStyle={EButtonStyles.solid}
          onClick={props.onConfirm}
          disabled={!couldBeConfirmed}
        />
      </div>
    </div>
  );
};
