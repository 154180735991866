import { Remark } from 'modules/common/types/partyTypes';
import { EIsniPartyType } from 'common/consts';
import { TPartyDates, TPartyEntity } from './types';
import { months } from 'moment';
import { TIsniPersonalInfo } from 'domains/isni/types';
import { returnValueIfExists } from 'utils';

export const getPartyDates = (party: TPartyEntity | undefined) => {
  if (!party) return {};
  const {
    dateOfBeginningDay,
    dateOfBeginningMonth,
    dateOfBeginningYear,
    dateOfEndDay,
    dateOfEndMonth,
    dateOfEndYear
  } = party;

  const filterNotUndefined = (v: number | undefined) => !!v;
  const humanizeRawDate = (rawDateArray: number[]) => rawDateArray
    .map((dateItem, idx) => {
      return (idx === 1 && dateItem) ? months(dateItem - 1) : dateItem;
    })
    .reverse()
    .join(' ');

  const rawStartDate = [dateOfBeginningYear, dateOfBeginningMonth, dateOfBeginningDay].filter(filterNotUndefined) as number[];
  const rawEndDate = [dateOfEndYear, dateOfEndMonth, dateOfEndDay].filter(filterNotUndefined) as number[];
  const startDate: string = humanizeRawDate(rawStartDate);
  const endDate: string = humanizeRawDate(rawEndDate);

  return {
    startDateArray: rawStartDate,
    endDateArray: rawEndDate,
    startDate: startDate,
    endDate: endDate
  };
};

export const nameRemarksSortByDateFn = (aR: Remark, bR: Remark) => {
  if (!aR.lastModifiedAt && !bR.lastModifiedAt) return 0;
  if (!aR.lastModifiedAt) return 1;
  if (!bR.lastModifiedAt) return -1;
  if (aR.lastModifiedAt === bR.lastModifiedAt) return 0;
  return aR.lastModifiedAt > bR.lastModifiedAt ? 1 : -1;
};

export const isniTypeDependantLabels = (type?: string) => {
  const isPerson = type === EIsniPartyType.PERSON;

  return isPerson ? {
    dateStart: 'date_birth',
    dateEnd: 'date_death',
    startPlace: 'born_in'
  } : {
    dateStart: 'date_formed',
    dateEnd: 'date_disbanded',
    startPlace: 'formed_in'
  };
};

export const partyDatesFormatter = (partyEntryData: TPartyEntity , personalInfo: TIsniPersonalInfo | undefined): { formattedDates: TPartyDates, quansicRegister: string[] } => {

  let doby, dobm, dobd, dody, dodm, dodd;

  const dateDividerFormat = '-';
  const { dateOfBeginningYear, dateOfBeginningMonth, dateOfBeginningDay, dateOfEndYear, dateOfEndMonth, dateOfEndDay } = partyEntryData;
  const quansicRegister: string[] = [];
  const dates: TPartyDates = {};

  // the birthdate and deathdate comes in the format yyyy-m-d (at least for now)
  // so if there's some missing value the default is gonna be undefined
  if(personalInfo?.birthDate)
    [doby,dobm,dobd] = personalInfo.birthDate.split(dateDividerFormat).map(date => Number(date));

  if(personalInfo?.deathDate)
    [dody,dodm,dodd] = personalInfo.deathDate.split(dateDividerFormat).map(date => Number(date));

  //DOB
  if (!dateOfBeginningYear && personalInfo && personalInfo.birthDate) {
    dates.dateOfBeginningDay = dobd;
    dates.dateOfBeginningMonth = dobm;
    dates.dateOfBeginningYear = doby;
    quansicRegister.push(...[
      returnValueIfExists(dobd,'party.dateOfBeginningDay'),
      returnValueIfExists(dobm,'party.dateOfBeginningMonth'),
      'party.dateOfBeginningYear'
    ]);
  }

  if((dateOfBeginningYear === doby) && (!dateOfBeginningMonth || !dateOfBeginningDay) && (dobm || dobd)) {
    dates.dateOfBeginningDay = dobd;
    dates.dateOfBeginningMonth = dobm;
    quansicRegister.push(...[
      ...quansicRegister,
      returnValueIfExists(dobd,'party.dateOfBeginningDay'),
      returnValueIfExists(dobm,'party.dateOfBeginningMonth')
    ]);
  }
  //DOD
  if (!dateOfEndYear && personalInfo && personalInfo.deathDate) {
    dates.dateOfEndDay = dodd;
    dates.dateOfEndMonth = dodm;
    dates.dateOfEndYear = dody;
    quansicRegister.push(...[
      ...quansicRegister,
      returnValueIfExists(dodd, 'party.dateOfEndDay'),
      returnValueIfExists(dodm, 'party.dateOfEndMonth'),
      'party.dateOfEndYear',
    ]);
  }
  if ((dateOfEndYear === dody) && (!dateOfEndMonth || !dateOfEndDay) && (dodm || dodd)) {
    dates.dateOfEndDay = dodd;
    dates.dateOfEndMonth = dodm;
    quansicRegister.push(...[
      ...quansicRegister,
      returnValueIfExists(dodd, 'party.dateOfEndDay'),
      returnValueIfExists(dodm, 'party.dateOfEndMonth'),
    ]);
  }

  return {
    formattedDates: dates,
    quansicRegister: quansicRegister.filter(item => item)
  };
};

