import React, { FC, useMemo } from 'react';

import styles from './menu-item.module.scss';

interface ESubMenu {
  data: any;
  renderItem(data: any): JSX.Element;
}

interface IProps {
  label: string;
  subMenu?: ESubMenu;
}

export const MenuItem: FC<IProps> = ({ label, subMenu }) => {

  const header = useMemo(
    () => (
      <>
        {label}
      </>
    ),
    [label],
  );

  return (
    <li className={styles.container}>
      {header}
      <div className={styles.submenu}>
        {
          subMenu?.renderItem(subMenu.data)
        }
      </div>
    </li>
  );
};
