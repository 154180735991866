import tuple from '../helpers/tuple';
import {
  EPartySubType,
  TPartyName
} from 'domains/party/types';

export type Party = {
  id?: number;
  partyType: EPartyType;
  partySubType?: EPartySubType;
  countryOfOriginId?: number;
  languageOfOriginId?: number;
  dateOfBeginningYear?: number;
  dateOfBeginningMonth?: number;
  dateOfBeginningDay?: number;
  dateOfEndYear?: number;
  dateOfEndMonth?: number;
  dateOfEndDay?: number;
  names: TPartyName[];
  lock?: TPartyLock;

  signedByWMG?: boolean
  saveType?: boolean
}

export type PartyCompetency = {
  competencyId: number;
  nameCompetencyId?: number
};

export enum EPartyType {
  GROUP = 'GROUP',
  PERSON = 'PERSON',
  ORGANIZATION = 'ORGANIZATION',
}

export type TPartyLock = {
  partyId?: number;
  active: boolean;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
};

export const partyTypes = tuple(EPartyType.PERSON, EPartyType.GROUP);

export const partySubTypes = tuple(
  EPartySubType.NATURAL_PERSON,
  EPartySubType.CHARACTER,
  EPartySubType.BAND,
  EPartySubType.ORCHESTRA
);

export type PartyType = typeof partyTypes[number];

export type PartySubType = typeof partySubTypes[number];

export type Translation = {
  name: string;
  languageVariantId: number;
  nameTransId?: number;
};

export type Genre = { majorGenre: string; minorGenres: string[] };

export type Language = {
  languageVariantId: number;
  name: string;
  displayName: string;
  ietfCode?: string;
};
export type Country = { countryId: number; name: string; isoCode: string };

export type MajorGenre = {
  majorGenreId: number;
  name: string;
};
export type MinorGenre = {
  minorGenreId: number;
  name: string;
};

export type MajorMinorGenre = {
  majorGenreId: number;
  name: string;
  minorGenres: MinorGenre[];
};

export type CompetencyType = {
  partyType: EPartyType;
  competencies: Competency[];
};

export enum ECompetencyType {
  PERFORMING_ARTIST = 'PERFORMING_ARTIST',
  SONGWRITER = 'SONGWRITER',
}

export type Competency = {
  id: number;
  value: ECompetencyType;
};

export enum EIdentifierType {
  APPLE = 'APPLE',
  DISCOGS = 'DISCOGS',
  IPI = 'IPI',
  IPN = 'IPN',
  DPID = 'DPID',
  ISNI = 'ISNI',
  MBID = 'MBID',
  PARTICIPANT_ID = 'PARTICIPANT_ID',
  SPOTIFY = 'SPOTIFY',
  UAID = 'UAID',
  WIKIDATA = 'WIKIDATA',
  AMAZON = 'AMAZON',
  DEEZER = 'DEEZER',
  MERGED_ISNI = 'MERGED_ISNI'
}

export type Identifier = {
  id?: number;
  identifierType: EIdentifierType;
  createdBy?: number;
  updatedBy?: string;
  value: string;
};

export enum ERemarkType {
  ARTIST_CLEANUP = 'ARTIST_CLEANUP',
  ARTIST_VERIFIED = 'ARTIST_VERIFIED',
  REASON_FOR_REVISION = 'REASON_FOR_REVISION',
  OTHER = 'OTHER',
  INTERNAL_COMMENT = 'INTERNAL_COMMENT',
  WEBSITE_URL = 'WEBSITE_URL',
  ARTIST_CREATED_ON_THE_FLY = 'ARTIST_CREATED_ON_THE_FLY',
  BAND_GROUP_MEMBERS_INCLUDE = 'BAND_GROUP_MEMBERS_INCLUDE',
  COMPETENCY_CHANGE = 'COMPETENCY_CHANGE',
  ISNI_DISAMBIGUATION_COMMENT = 'ISNI_DISAMBIGUATION_COMMENT',
  ADDITIONAL_INFO = 'ADDITIONAL_INFO'
}

export type Remark = {
  remarkId?: number;
  text: string;
  remarkType: ERemarkType;
  lastModifiedBy?: string;
  lastModifiedAt?: string;
  added?: true
};

export type RemarkType = {
  type?: string;
  name?: string;
};

export enum ENamePrivacyType {
  PUBLIC = 'PUBLIC',
  INTERNAL_TO_WMG = 'INTERNAL_TO_WMG',
}

export enum EOrganizationType {
  WMG_COMPANY = 'WMG_COMPANY',
  THIRD_PARTY_LABEL = 'THIRD_PARTY_LABEL',
  PUBLISHER = 'PUBLISHER',
  DSP = 'DSP'
}

export enum EDataQualityType {
  VERIFIED = 'VERIFIED',
  UNVERIFIED = 'UNVERIFIED',
}

export type TDictionaries = {
  identifierTypes: any;
  translationLanguages: Language[];
  languages: Language[];
  countries: Country[];
  majorGenres: MajorMinorGenre[];
  competencies: CompetencyType[];
  groupTypes: string[];
  partyTypes: EPartyType[];
  personTypes: string[];
  remarkTypes: ERemarkType[];
}

export type TEditableIds = 'apple' | 'spotify';
