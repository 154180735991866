import { TFunction } from 'i18next';
import { TSelectOption } from 'common/types';
import { EAdvancedSearchOperator } from 'domains/search/types';

export const advancedSearchOperators = (t: TFunction): TSelectOption[] => ([
  {
    label: t('begins_with'),
    value: EAdvancedSearchOperator.BEGINS_WITH
  },
  {
    label: t('contains'),
    value: EAdvancedSearchOperator.CONTAINS
  }, {
    label: t('exact'),
    value: EAdvancedSearchOperator.EQUALS
  },
]);
