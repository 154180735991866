import { useQuery } from '@apollo/client';
import { ESearchType, NAMES_SEARCH } from 'domains/search/graphql';
import { TMainSearchResponse, TMainSearchVars } from 'domains/search/hooks/types';

export function useGetPartyByUAID(uaid: string) {
  const { loading, data }  = useQuery<TMainSearchResponse,
    TMainSearchVars>(NAMES_SEARCH, {
      variables: {
        searchTerm: uaid,
        searchType: ESearchType.FULL_SEARCH,
        size: 1,
        skip: 0,
      },
      fetchPolicy: 'no-cache'
    });

  return {
    party: data?.searchNames.names[0],
    loading
  };
}
