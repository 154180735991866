import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AlertState {
  saveSuccess: boolean;
  saveFailed: boolean;
  deleteSuccess: boolean;
  deleteFailed: boolean;
  procureSuccess: boolean;
  procureFailed: boolean;
  formIsModified: boolean;
}

const initialState: AlertState = {
  saveSuccess: false,
  saveFailed: false,
  deleteSuccess: false,
  deleteFailed: false,
  procureSuccess: false,
  procureFailed: false,
  formIsModified: false,
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    saveSuccess(state) {
      state.saveSuccess = true;
    },
    cancelSaveSuccess(state) {
      state.saveSuccess = false;
    },
    saveFailed(state) {
      state.saveFailed = true;
    },
    cancelSaveFailed(state) {
      state.saveFailed = false;
    },
    deleteSuccess(state) {
      state.deleteSuccess = true;
    },
    cancelDeleteSuccess(state) {
      state.deleteSuccess = false;
    },
    deleteFailed(state) {
      state.deleteFailed = true;
    },
    cancelDeleteFailed(state) {
      state.deleteFailed = false;
    },
    procureSuccess(state) {
      state.procureSuccess = true;
    },
    cancelProcureSuccess(state) {
      state.procureSuccess = false;
    },
    procureFailed(state) {
      state.procureFailed = true;
    },
    cancelProcureFailed(state) {
      state.procureFailed = false;
    },
    setFormModified(state, { payload }: PayloadAction<boolean>) {
      state.formIsModified = payload;
    }
  },
});

export const {
  saveSuccess,
  cancelSaveSuccess,
  saveFailed,
  cancelSaveFailed,
  deleteSuccess,
  cancelDeleteSuccess,
  deleteFailed,
  cancelDeleteFailed,
  procureSuccess,
  cancelProcureSuccess,
  procureFailed,
  cancelProcureFailed,
  setFormModified,
} = alertSlice.actions;
export default alertSlice.reducer;
