import { useLazyQuery } from '@apollo/client';
import { TFileExportAdvancedSearchResponse, TFileExportAdvancedSearchVars } from 'domains/search/hooks/types';
import {
  TAdvancedSearchValues
} from 'domains/search/types';
import { useAdvancedSearchOperations } from './useAdvancedSearchOperations';
import { FILE_EXPORT_ADVANCED_SEARCH } from '../graphql/fileExportAdvancedSearch';
import { autoDownload } from 'utils/autoDownload';

export const useFileExportAdvancedSearch = (vars: TAdvancedSearchValues) => {
  const operations = useAdvancedSearchOperations(vars);

  const [fileExportAdvancedSearch] = useLazyQuery<TFileExportAdvancedSearchResponse, TFileExportAdvancedSearchVars>(
    FILE_EXPORT_ADVANCED_SEARCH,
    {
      fetchPolicy: 'no-cache',
      onCompleted(data) { // init auto-download from signed url
        const { url: signedUrl, filename } = data?.fileExportAdvancedSearch;
        autoDownload(signedUrl, filename);
      }
    });
  return { fileExportAdvancedSearch, operations };
};

