import React, { FC, useMemo } from 'react';
import styles from './BuzzMill.module.scss';
import { cn } from 'utils';
import { WorkspacePreview } from '@grow-components/WorkspacePreview/WorkspacePreview';
import pwLogo from 'assets/images/workspace-logos/party-workspace-logo.svg';
import { useTranslation } from 'react-i18next';
import { dateChangeFormat } from 'modules/common/components/PartyHistory/helpers';

type TBuzzMillProps = {
  className?: string,
  dateFormat: string | undefined
}

export const BuzzMill: FC<TBuzzMillProps> = (props) => {
  const { t } = useTranslation();
  const date = useMemo(()=>dateChangeFormat('09/13/21', props.dateFormat || 'MM/DD/YYYY'), [props.dateFormat]);
  return (
    <div className={cn(styles.container, props.className)}>
      <div className={styles.header}>
        <WorkspacePreview
          title={t('liw')}
          subtitle="Party Workspace"
          icon={<img src={pwLogo} alt="pw-logo" />}
        />
      </div>
      <div className={styles.content}>
        LIW is an integrated suite of Workspaces used to create and manage data for Parties, Works, Assets and
        Products. More workspaces will continue to be added as they go online. This workspace is intended for artist
        metadata, names, ISNI and streaming service ID’s, and additional information. All records in LIW will provide
        data for downstream use in WMG applications. Keep an eye on this space for future updates and app news!
      </div>
      <div className={styles.footer}>
        <span className={styles.author}>LIW Admin</span>
        <span className={styles.date}>{date}</span>
      </div>
    </div>
  );
};
