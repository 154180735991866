import { useQueryParams } from 'modules/common/hooks/useQueryParams';
import { TQueryMeta } from 'domains/search/types';
import { toNumber } from 'utils';
import { useCallback } from 'react';
import { usePaginationSize } from 'modules/common/hooks/usePaginationSize';

type TQueryMetaURLParams = {
  size: string
  page: string
  sortBy: string
  sortDesc: string
}
type TSetQueryMetaURLParams = {
  size: string | number,
  page: string | number,
  sortBy: string
  sortDesc: boolean
}
export const useQueryMetaParams = () => {
  const { getSize } = usePaginationSize();
  const { params, updateUrlParams } = useQueryParams<TQueryMetaURLParams>();
  const { size: sizeStr, page: pageStr, sortBy, sortDesc } = params;
  const page = toNumber(pageStr, 1);
  const size = toNumber(sizeStr, getSize());
  const sort = {} as any;
  if (sortBy) {
    sort.type = sortBy;
  }
  if (sortDesc === 'true') {
    sort.desc = true;
  }
  const queryMetaParams = {
    page: page,
    skip: (page - 1) * size,
    size: size,
  } as TQueryMeta;
  if (sort.type) {
    queryMetaParams.sort = sort;
  }

  const set = useCallback((params: Partial<TSetQueryMetaURLParams>) => {
    const stringParams: Record<string, string> = {};
    for (const [key, val] of Object.entries(params)) {
      if (typeof val !== 'undefined') {
        stringParams[key] = val.toString();
      }
    }
    updateUrlParams(stringParams);
  }, [updateUrlParams]);
  return {
    queryMetaParams,
    set
  };
};
