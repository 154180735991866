import { TPartyEntity, ENameType, TPartyName } from 'domains/party/types';
import {
  ENamePrivacyType,
  EPartyType,
  Party,
  PartyCompetency,
  Remark,
  Translation,
} from 'modules/common/types/partyTypes';
import get from 'lodash/get';
import compose from 'lodash/fp/compose';
import update from 'lodash/fp/update';
import { IStoredParty } from 'redux/reducers/party';
import { TInitialParty } from '../types';
import { FormState } from 'final-form';
import { isModified } from '../components/businessLogic';

const createTranslation = (): Translation => ({
  languageVariantId: 0,
  name: '',
});

const createCompetency = (): PartyCompetency => ({ competencyId: 0 });

type TStoredData = { names:  TPartyName[], party: TPartyEntity };

export function storedPartyAdapter({ names, party }: TStoredData): IStoredParty {
  return {
    id: get(party, 'partyId', '').toString(),
    partyType: party.partyType,
    pkaName: get(names.find(f => f.nameType === ENameType.PKA), 'nameValue', ''),
  };
}

type TPartyData = { party: TPartyEntity; names: TPartyName[] };

type TInitForm = (partyData: TPartyData) => Party;

export function createDefaultName(nameType: ENameType = ENameType.PKA): TPartyName {
  return {
    nameValue: '',
    privacyType: ENamePrivacyType.PUBLIC,
    nameCreatedBy: '',
    nameCreatedAt: '',
    nameUpdatedAt: '',
    nameUpdatedBy: '',
    nameType,
    isLegal: false,
    majorGenreId: '',
    minorGenreId: '',
    translations: [createTranslation()],
    competencies: [createCompetency()],
    remarks: [],
  };
}

export function regidrateName<T extends TPartyName = TPartyName>(name: T): T {
  return compose(
    update('translations')((t: T['translations']) => t && t.length ? t : [createTranslation()]),
    update('competencies')((c: T['competencies']) => c && c.length ? c : [createCompetency()])
  )(name);
}

function getPropertyList<T extends TPartyEntity, K extends keyof T>(list: ReadonlyArray<K>, party: T) {
  return list.reduce((a, v) => ({ ...a, [v]: party[v] || undefined }), {});
}

export const createInitialValuesFromExistingParty: TInitForm = ({ party, names }): Party => {
  return {
    id: party.partyId,
    partyType: party.partyType,
    countryOfOriginId: party.countryOfOriginId || 0,
    languageOfOriginId: party.languageOfOriginId || 0,
    signedByWMG: party.signed,
    names: names.map(regidrateName),
    ...getPropertyList([
      'partySubType',
      'dateOfBeginningYear',
      'dateOfBeginningMonth',
      'dateOfBeginningDay',
      'dateOfEndYear',
      'dateOfEndMonth',
      'dateOfEndDay',
    ], party),
  };
};

export const createInitialValuesFromScratch: (PartyType: EPartyType) => TInitialParty = (
  partyType
) => {
  return {
    partyType,
    partySubType: undefined,
    countryOfOriginId: 0,
    languageOfOriginId: 0,
    dateOfBeginningYear: undefined,
    dateOfBeginningMonth: undefined,
    dateOfBeginningDay: undefined,
    dateOfEndYear: undefined,
    dateOfEndMonth: undefined,
    dateOfEndDay: undefined,
    names: [createDefaultName()],
  };
};

export const createRemark = (): Omit<Remark, 'remarkType'> => ({
  text: '',
});

const getRemarks = (names: TPartyName[]): Remark[] => names.flatMap((item: TPartyName) => item.remarks || []).filter((r: Remark) => r.remarkType && r.text);

export const checkIsModified = (state: FormState<any>): boolean | undefined => {
  //get previous remarks and current remarks to verify if they are the same
  const initialRemarks = getRemarks(state.initialValues.names);
  const currentRemarks = getRemarks(state.values.names);
  //the isModified function doesn't detect when you remove or add a remark correctly, so It was created this validation
  const hasTheSameLength = initialRemarks.length === currentRemarks.length;
  const areRemarksModified = isModified(currentRemarks, initialRemarks);
  return (state.modified && Object.entries(state.modified).filter(item => !item[0].includes('_')).some(item => item[1])) || !hasTheSameLength || areRemarksModified;
};

export const fieldSubscription = { touched: true, error: true, value: true };
