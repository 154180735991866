import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { usePermissions } from 'modules/common/hooks/usePersmissions';
import { RouteDictionary } from 'Router/routes';

export const usePolicy = (component: () => JSX.Element) => {

  const { canCreateParty } = usePermissions();

  const { path } = useRouteMatch();

  const allowedRoutes = useMemo(() => [
    RouteDictionary.LandingPage.props.path,
    RouteDictionary.SearchPage.props.path,
    RouteDictionary.ViewPartyPage.props.path,
    RouteDictionary.AdvancedSearchPage.props.path,
  ], []);

  if (canCreateParty && allowedRoutes.includes(path)) return component();

  return null;
};
