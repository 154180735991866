import React, { FC, useMemo } from 'react';
import { Button } from '@wmg-ae/legato';
import { EButtonStyles } from 'modules/common/types/legatoTypes';
import { Tooltip } from '@grow-components/Tooltip';
import { useTranslation } from 'react-i18next';
import styles from '../PartyHistory.module.scss';

interface ISortBtnProps {
  sortDesc: boolean;
  toggleSort: () => void;
}

export const SortBtn: FC<ISortBtnProps> = ({ sortDesc, toggleSort }) => {
  const btnText = useMemo(() => sortDesc ? 'Newest First' : 'Oldest First', [sortDesc]);
  const { t } = useTranslation();
  return (
    <Tooltip text={t('tooltips:sort_button')} place={'bottom'}>
      <Button
        className={styles.sortBtn}
        containerStyle={EButtonStyles.solid}
        label={btnText}
        onClick={toggleSort}
        secondaryIcon={sortDesc ? 'arrow-up-1-9' : 'arrow-down-9-1'}
      />
    </Tooltip>
  );
};
