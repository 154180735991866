import React, { FC, useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import get from 'lodash/get';
import set from 'lodash/set';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DateFilterForm } from './DateFilterForm';
import { TSetState, TState, EOperator } from './types';
import { validate } from './helpers';

interface IProps {
  min: number;
  max: number;
  state: TState;
  setState: TSetState;
}

export const DateFilter: FC<IProps> = ({ min, max, state, setState }) => {
  const onSubmit = () => { return {}; };

  const { t } = useTranslation();

  const formRender = useCallback(
    ({ handleSubmit }) => (
      <DateFilterForm
        handleSubmit={handleSubmit}
        min={min}
        max={max}
        state={state}
        setState={setState}
      />
    ),
    [min, max, state, set],
  );

  const isRange = useMemo(() => Array.isArray(state.dateTime), [state]);

  const isDateCreated = useMemo(
    () => {
      const { dateTime } = state;
      if (isRange && moment(get(dateTime, 0)).isSame(moment.unix(min), 'day')) return true;
      if (typeof dateTime === 'string' && moment.unix(min).isSame(dateTime, 'day')) return true;
      return undefined;
    },
    [isRange, state],
  );

  const isPresent = useMemo(
    () => {
      const { dateTime } = state;
      if (isRange && get(dateTime, 1) && moment().isSame(get(dateTime, 1), 'day')) return true;
      if (typeof dateTime === 'string' && moment().isSame(dateTime, 'day')) return true;
      return undefined;
    },
    [isRange, state],
  );

  const init = useMemo(
    () => {
      const { dateTime } = state;
      return {
        operator: typeof dateTime === 'undefined' || isRange ? EOperator.RANGE : EOperator.SINGL,
        from: isRange ? get(dateTime, 0) : undefined,
        to: isRange ? get(dateTime, 1) : undefined,
        date: typeof dateTime === 'string' ? dateTime : undefined,
        datecreated: isDateCreated,
        present: isPresent,
      };
    },
    [state, isRange, isDateCreated, isPresent],
  );

  return (
    <Form
      onSubmit={onSubmit}
      render={formRender}
      initialValues={init}
      validate={validate(min, t)}
    />
  );
};
