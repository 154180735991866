export const namesLabels = [
  {
    key: 'fullname',
    label: 'Name'
  },
  {
    key: 'language',
    label: 'Language'
  }
];
 
export const isrcLabels = [
  {
    key: 'title',
    label: 'Track Title'
  },
  {
    key: 'subtitle',
    label: 'Subtitle'
  },
  {
    key: 'year',
    label: 'Year'
  },
  {
    key: 'durationMs',
    label: 'Duration'
  },
  {
    key: 'value',
    label: 'ISRC'
  },
  {
    key: 'role',
    label: 'Role'
  },

];

export const releaseLabels = [
  {
    key: 'title',
    label: 'Title'
  },
  {
    key: 'type',
    label: 'Type'
  },
  {
    key: 'year',
    label: 'Release date'
  },
  {
    key: 'upc',
    label: 'UPC/EAN'
  }
];

export const iswcLabels = [
  {
    key: 'title',
    label: 'Track Title'
  },
  {
    key: 'value',
    label: 'ISWC'
  },
  {
    key: 'bowi',
    label: 'BOWI'
  },
  {
    key: 'role',
    label: 'Role'
  },
];

export const relationshipsLabels = [
  {
    key: 'name',
    label: 'Name'
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'isni',
    label: 'ISNI'
  }
];