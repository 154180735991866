import { tMessage } from 'modules/common/helpers/translation';
import { months } from 'modules/common/helpers/dateHelpers';
import { TSelectOption } from 'common/types';

export const monthsAsSelectOptions = months.map((month, idx) => (
  { label: month, value: idx + 1 }
));

export const enumsToSelectOptions = (type: string): TSelectOption => ({
  label: tMessage(type),
  value: type,
});
