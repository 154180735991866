import React from 'react';
import MaskedInput, { maskArray } from 'react-text-mask';
import { cn } from 'utils';
import styles from './text-input.module.scss';

interface Props {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  autofocus?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  className?: string;
  mask?: maskArray | ((value: string) => maskArray);
}
export const MaskedField: React.FC<Props> = ({
  value,
  placeholder,
  onChange,
  autofocus,
  onKeyDown,
  className,
  mask
}) => {
  return (
    <MaskedInput
      autoFocus={autofocus}
      mask={mask}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      keepCharPositions={false}
      guide={false}
      onKeyDown={onKeyDown}
      className={cn(styles.input, className)}
    />
  );
};
