const errors = {
  nonPKA: 'Legal Non-PKA name',
  nonCompetencyError: 'should not be a performing artist'
};

export const formatMessageModal = (message: string) => {
  if(message.includes(errors.nonCompetencyError)){
    return 'A legal name that is not a PKA should not be a Performing Artist Competency';
  }
  return message;
};