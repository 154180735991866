export const autoDownload = (signedUrl: string, filename: string ) => {
  fetch(signedUrl, {
    method: 'GET',
    headers: {
      'Content-Type': filename.endsWith('.csv') ? 'text/csv' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        filename,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    });
};
