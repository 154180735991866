import { useQuery } from '@apollo/client';
import { SEARCH_ISNI } from 'graphql/queries';
import { EIsniSearchParam, IIsniSearchFilter } from 'modules/common/types/isniTypes';
import { TIsniInfo, TIsniSearchResponse } from '../types';
import { keyIdentifiersByType } from '../utils';

const transformResponse = (isniResponse: TIsniSearchResponse | undefined): TIsniInfo | undefined => {
  const foundIsni = isniResponse?.isni[0];
  if (!foundIsni) return undefined;
  const { identifiers, ...rest } = foundIsni;

  return {
    identifiers: keyIdentifiersByType(identifiers),
    ...rest
  };
};

export const useGetISNI = (isni: string) => {
  const { loading, data } = useQuery<any, IIsniSearchFilter>(SEARCH_ISNI, {
    variables: {
      data: {
        identifier: {
          isFull: true,
          value: isni,
          type: EIsniSearchParam.ISNI,
        }
      }
    },
  });
  return { loading, data: transformResponse(data?.searchIsni) };
};
