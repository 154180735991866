import React, { FC, useCallback } from 'react';

interface IProps {
  config: ReadonlyArray<{
    name: string,
    renderCell?(d: any): JSX.Element | string,
  }>;
  cells: Record<string, any>;
}

export const Row: FC<IProps> = ({ cells, config }) => {

  const cellData = useCallback(
    (e) => typeof e.renderCal === 'function' ? e.renderCell() : cells[e.name],
    [cells],
  );

  return (
    <tr>
      {
        config.map(e => (
          <td key={e.name}>
            {
              cellData(e)
            }
          </td>
        ))
      }
    </tr>
  );
};
