import React, { FC, useMemo } from 'react';
import { Button } from '@wmg-ae/legato';
import { DropdownMenu, TCustomDropdownMenuItem, IDMenuItem } from '@grow-components/DropdownMenu';
import { Icon } from '@grow-components/Icon/Icon';
import { reverseName } from 'utils';
import { TFilters } from '../types';
import styles from './FilterBtn.module.scss';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@grow-components/Tooltip';
import size from 'lodash/size';

interface IFilterBtnProps {
  filters: TFilters;

  setFilters: React.Dispatch<React.SetStateAction<TFilters>>

  filterBy: IDMenuItem[];
}

const SelectedUser: FC<{ value: string | undefined, onClick: () => void }> = (props) => {
  return (
    <div className={styles.selectedUser}>
      <span className={styles.selectedUserIcon} onClick={props.onClick}>
        <Icon icon="xmark" className={styles.clearFilterBtn} />
      </span>
      <b>{props.value}</b>
    </div>
  );
};

export const FilterBtn: FC<IFilterBtnProps> = ({ filters, setFilters, filterBy }) => {
  const { t } = useTranslation();
  const btnText = useMemo(() => {
    const activeFiltersCount = size(filters) > 1 ? `(${size(filters)})` : '';
    const singleFilterName = filterBy.find(item => item.field === Object.keys(filters)[0])?.name;
    const singleFilter = size(filters) === 1 ? singleFilterName : '';

    return `Filter${!size(filters) ? '' : ': '}${singleFilter}${activeFiltersCount}`;
  }, [filters]);

  const activeFilterMenuItems = useMemo(() => Object.keys(filters).map((key) => {
    const { [key]: currentValue } = filters;
    const value = key === 'userName' ? reverseName(currentValue) : currentValue;
    return {
      label: currentValue,
      content: <SelectedUser value={value} onClick={() => setFilters(prev => {
        const newState = {
          ...prev
        };
        delete newState[key];
        return newState;
      })} />,
      className: styles.customRenderItem
    } as TCustomDropdownMenuItem;
  }), [filters]);

  return (
    <DropdownMenu
      renderDropdownToggle={ ({ onClick }) =>
        <Tooltip text={t('tooltips:filter_button')} place={'bottom'}>
          <Button
            onClick={onClick}
            label={btnText}
            colorType={'primary'}
            secondaryIcon="filter"
            size='sm'
            containerStyle='outline'
            className={styles.filterBtn}
          />
        </Tooltip>
      }
      items={filterBy}
      customRenderItems={activeFilterMenuItems}
    />
  );
};
