import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import App from './App';
import store from './redux/store';
import * as serviceWorker from './serviceWorker';

import { apolloClient, getTokenFromLStorage } from 'config/apiConfig';

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient(getTokenFromLStorage)}>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
