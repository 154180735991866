import { useCallback, useMemo } from 'react';
import { StorageFactory, IStorage } from './types';

type TGet<T> = <K extends keyof T, V extends T[K]>(key: K, defaultValue?: V) => Promise<V>;
type TSet<T> = <K extends keyof T, V extends T[K]>(key: K, value: V) => Promise<void>;

export function useStorage<T>(driver: StorageFactory<T>): IStorage<T> {

  const storage = useMemo(() => driver(), []);

  const get = useCallback<TGet<T>>(
    (key, def) => {
      return storage.get(key, def);
    },
    [],
  );

  const set = useCallback<TSet<T>>(
    (key, value) => {
      return storage.set(key, value);
    },
    [],
  );

  return {
    set,
    get,
  };

}
