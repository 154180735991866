import { gql } from '@apollo/client';

export const SAVE_SETTINGS = gql`
    mutation saveSettings($data: settingsInput!) {
        settingsUpdate(data: $data) {
            language
            dateFormat  
            timeFormat      
        }
    }
`;

