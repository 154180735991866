import { useState, useEffect, useCallback } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { formatGroupNames, parseToken } from 'modules/common/helpers/userFuncs';
import { TUser, TAuthUser } from '../types';
import get from 'lodash/get';

export const useUser = () => {
  const { authState } = useOktaAuth();

  const getUser = useCallback<() => TUser>(
    () => {
      if (authState.isAuthenticated && typeof authState.idToken?.value === 'string') {
        const { name, groups, exp, uid, firstName, lastName } = parseToken(authState.idToken?.value);
        return {
          isAuthenticated: true,
          userName: name,
          groups: formatGroupNames(groups),
          tokenExpiration: exp,
          firstName,
          lastName,
          uid,
        };
      } return {
        isAuthenticated: false,
      };
    },
  [authState],
  );

  const [user, setUser] = useState<TUser>(getUser);

  useEffect(
    () => {
      if (authState.isPending) return;
      const user = getUser();
      setUser(user);
    },
    [authState],
  );

  const getItem = <T extends TAuthUser, K extends keyof T>(key: K, def?: any): T[K] => {
    if (user.isAuthenticated) {
      return get(user, key, def);
    }
    return def;
  };

  return {
    ...user,
    getItem,
  };
};
