import React, { FC } from 'react';
import { cn } from 'utils';
import styles from './SearchParamRow.module.scss';

type TSearchParamRowProps = {
  label: string
  controls: JSX.Element
  labelClassName?: string
  controlsClassName?: string
  labelTestId?: string
}

export const SearchParamRow: FC<TSearchParamRowProps> = ({ label, controls, controlsClassName, labelClassName , labelTestId }) => {
  return (
    <div className={styles.formRow}>
      <div data-test-id={labelTestId} className={cn(styles.label, labelClassName)}>{label}</div>
      <div className={cn(styles.controls, controlsClassName)}>
        {controls}
      </div>
    </div>
  );
};
