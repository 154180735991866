import { EPartyType } from 'modules/common/types/partyTypes';
import { ENameType, EPartySubType } from 'domains/party/types';
import { ESortType } from 'modules/common/types/searchTypes';

export enum EAdvancedSearchOperator {
  BEGINS_WITH = 'BEGINS_WITH',
  CONTAINS = 'CONTAINS',
  EQUALS = 'EQUALS',
  GTE = 'GTE',
  LTE = 'LTE',
  BETWEEN = 'BETWEEN',  // Do not exist on the API
  IN = 'IN'
}

export enum EAdvancedSearchField {
  NAME_VALUE = 'NAME_VALUE',
  PARTY_TYPE = 'PARTY_TYPE',
  NAME_TYPE = 'NAME_TYPE',
  NAME_IS_LEGAL = 'NAME_IS_LEGAL',
  PARTY_SUB_TYPE = 'PARTY_SUB_TYPE',
  PARTY_COUNTRY_OF_ORIGIN = 'PARTY_COUNTRY_OF_ORIGIN',
  COMPETENCY = 'COMPETENCY',
  NAME_CREATED_AT = 'NAME_CREATED_AT',
  NAME_CREATED_BY = 'NAME_CREATED_BY',
  NAME_UPDATED_AT = 'NAME_UPDATED_AT',
  NAME_UPDATED_BY = 'NAME_UPDATED_BY',
}

export enum ELegalNameQueryTypes {
  LEGAL_ONLY = 'true',
  EXCLUDE_LEGAL = 'false',
}

export type TAdvancedSearchOperation = {
  field: EAdvancedSearchField
  operator: EAdvancedSearchOperator
  value: string
}

export type TMainSearchURLParams = {
  term: string
}

export type TAdvancedSearchURLParams = {
  nameOperator: string
  nameValue: string
  nameType: string
  legalName: string
  partyType: string
  partySubType: string
  partyCountryOfOriginId: string
  competency: string
  createdBy: string
  modifiedBy: string
  createdOnOperator: string
  createdOnA: string
  createdOnB: string
  modifiedOnOperator: string
  modifiedOnA: string
  modifiedOnB: string
}

export type TAdvancedSearchValues = {
  nameOperator?: EAdvancedSearchOperator
  nameValue?: string
  nameType?: ENameType
  legalName?: ELegalNameQueryTypes
  partyType?: EPartyType
  partySubType?: EPartySubType,
  partyCountryOfOriginId?: string | number,
  competency?: ECompetencyQueryType,
  createdBy?: string
  modifiedBy?: string
  createdOnOperator?: EAdvancedSearchOperator
  createdOnA?: string
  createdOnB?: string
  modifiedOnOperator?: EAdvancedSearchOperator
  modifiedOnA?: string
  modifiedOnB?: string
}

export type TQuickSearchValues = {
  nameOperator: EAdvancedSearchOperator
  nameValue: string
  partyType?: EPartyType
}


export enum ECompetencyQueryType {
  PERFORMING_ARTIST = 'PERFORMING_ARTIST',
  NO_COMPETENCY_ASSIGNED = 'NO_COMPETENCY_ASSIGNED'
}

export type TQueryMeta = {
  size: number,
  skip: number,
  sort?: {
    type: ESortType,
    desc?: boolean | undefined
  }
}
