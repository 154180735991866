import React, { FC, useMemo } from 'react';
import { IProps } from './types';
import styles from './container.module.scss';
import { cn } from 'utils';

export const SuggestionContainer: FC<IProps> = ({ containerProps, children, title, onReset }: any) => {

  const header = useMemo(
    () => title.length && children !== null ? (<p className={styles.title}>{title}</p>) : null,
    [title, children]
  );

  return (
    <div
      {...containerProps}
      onMouseLeave={onReset}
      className={cn(containerProps.className, styles.container)}
    >
      {
        header
      }
      {
        children
      }
    </div>
  );
};
