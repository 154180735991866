import { ERecentPartiesAction } from 'modules/pages/LandingPage/consts';
import { useQuery } from '@apollo/client';
import { GET_RECENT_PARTIES } from 'graphql/queries';
import { TGetRecentPartiesVars, TRecentPartiesResponse } from './types';

const transformResponse = (recentPartiesData: TRecentPartiesResponse | undefined) => {
  if (!recentPartiesData) return undefined;
  return recentPartiesData.partyManageHistoryGet.items;
};

export const useGetMyRecentParties = (size: number, type: ERecentPartiesAction) => {
  const { data, loading } = useQuery<TRecentPartiesResponse, TGetRecentPartiesVars>(GET_RECENT_PARTIES, {
    variables: {
      size,
      type
    }
  });

  return { recentPartiesData: transformResponse(data), recentPartiesLoading: loading };
};