import React, { FC, useCallback } from 'react';
import styles from './UserModal.module.scss';
import { EUserImageSizes, UserImage } from '../UserImage/';
import { UserForm } from '../UserForm/';
import { Form } from 'react-final-form';
import { useSettings } from 'domains/env';
import { useTranslation } from 'react-i18next';
import { BROADCAST_CHANNELS, useBroadcastChannel } from 'modules/common/hooks/useBroadcastChannel';
import { Icon, Modal } from '@wmg-ae/legato';

interface IUserModalProps {
  onClose: () => void;
  userData: any;
}

export const UserModal: FC<IUserModalProps> = ({ onClose, userData }) => {
  const { t } = useTranslation();
  const userName = userData('userName');
  const groups: string[] = userData('groups');
  const { settings } = useSettings();
  const { sendMessage: sendNewPreferences } = useBroadcastChannel(BROADCAST_CHANNELS.USER_UTILS);

  const initialValues = {
    dateFormat: settings?.dateFormat,
    language: settings?.language,
    timeFormat: settings?.timeFormat
  };

  const handleSubmit = useCallback(
    async (values) => {
      await sendNewPreferences(values);
      onClose();
    },
    [initialValues],
  );

  return (
    <Modal
      isOpen={true}
      shouldCloseOnOverlayClick={false}
      onClose={onClose}
      icon={<Icon name='dial' className={styles.modalIcon} />}
      title={t('User Preferences')}
      className={styles.UserModal}
    >
      <div className={styles.modalContent}>
        <UserImage 
          size={EUserImageSizes.large} 
        />
        <div className={styles.descriptionContent}>
          <span>{t('name')}</span>
          <p>
            {
              userName ? userName : 'Unknown'
            }
          </p>
          <span>{t('role')}</span>
          {
            groups.length > 0 && groups.map(item => 
              <p key={item}>
                {item}
              </p>)
          }
        </div>
        <Form
          onSubmit={handleSubmit}
          onCancel={onClose}
          initialValues={initialValues}
          component={UserForm}
        />
      </div>
    </Modal>
  );
};
