import React, { FC, memo, useMemo, useCallback } from 'react';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { LegatoFFSelect } from '@grow-components/Form';
import { MajorMinorGenre } from 'modules/common/types/partyTypes';
import { TPartyName, ENameType } from 'domains/party/types';
import { useCurrentName } from '../useCurrentName';

interface IProps {
  namePath: string;
  disabled?: boolean;
  dictionaries?: MajorMinorGenre[];
}

const Major: FC<IProps> = ({ namePath, disabled, dictionaries }) => {
  const { t } = useTranslation();
  const { change, batch } = useForm();

  const { values } = useCurrentName<TPartyName>(namePath);

  const handleChange = useCallback(
    (value: number) => {
      batch(() => {
        change(`${namePath}.majorGenreId`, value);
        change(`${namePath}.minorGenreId`, '');
      });
    },
    [batch, change],
  );

  const options = useMemo(
    () => (dictionaries || []).map(
      ({ name, majorGenreId }) => ({ label: name, value: majorGenreId })
    ),
    [dictionaries],
  );

  const isDisable = useMemo<boolean | undefined>(
    () => {
      if (disabled) return true;
      if (values.nameType !== ENameType.PKA) {
        return values.isLegal;
      }
    },
    [disabled, values],
  );

  return (
    <Field
      name={`${namePath}.majorGenreId`}
      component={LegatoFFSelect}
      isFilterable={true}
      label={t('party_labels:major_genre')}
      labelTooltip={t('tooltips:major_genre')}
      disabled={isDisable}
      placeholder={t('party_placeholders:select_major_genre')}
      options={options}
      onChange={handleChange}
    />
  );
};

export default memo(Major);
