import React, { FC, useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import compose from 'lodash/fp/compose';
import { LookupForm } from './Form';
import { TPartyName } from 'domains/party/types';
import { SearchName } from 'modules/common/types/searchNameTypes';
import { EIsniSearchParam } from 'modules/common/types/isniTypes';
import { IFData } from './types';

interface IProps {
  name: TPartyName | SearchName;
  makeSearch(data: IFData): void;
}

function cleanParams(data: IFData): IFData {
  if (data.type !== EIsniSearchParam.NAME) return {
    type: data.type,
    term: data.term.replace(/\s+/g, ''),
  };
  return data;
}

export const ISNILookup: FC<IProps> = ({ name, makeSearch }) => {

  const onSubmit = useCallback(compose(makeSearch, cleanParams), [makeSearch]);

  const initialValues = useMemo<IFData>(
    () => ({
      type: EIsniSearchParam.NAME,
      term: name.nameValue,
    }),
    [name],
  );

  return (
    <Form<IFData>
      initialValues={initialValues}
      onSubmit={onSubmit}
      component={({ handleSubmit }) => <LookupForm name={name.nameValue} handleSubmit={handleSubmit} />}
    />
  );
};
