import { IRoutes } from './types';
import LandingPage from 'modules/pages/LandingPage';
import { SearchPage } from 'modules/pages/SearchPage';
import NotFound from 'modules/common/components/errors/pages/NotFound';
import AccessDenied from 'modules/common/components/errors/pages/AccessDenied';
import { IsniSearchPage } from 'modules/pages/IsniSearchPage/IsniSearchPage';
import { IsniMoreInfoPage } from 'modules/pages/IsniMoreInfoPage/IsniMoreInfoPage';
import { GCDMMoreInfoPage } from 'modules/pages/GCDMMoreInfoPage/GCDMMoreInfoPage';
import { CreatePartyPage } from 'modules/pages/Party/CreatePartyPage';
import { EditPartyPage } from 'modules/pages/Party/EditPartyPage';
import { ViewPartyPage } from 'modules/pages/Party/ViewPartyPage';
import { EPermissionType } from 'modules/common/types/authTypes';
import { PartyUAIDPage } from 'modules/pages/Party/PartyUAIDPage';
import { PartyHistoryPage } from 'modules/pages/Party/PartyHistoryPage';
import { AdvancedSearchPage } from 'modules/pages/SearchPage/AdvancedSearchPage';
import { IsniDetailsPage } from 'modules/pages/IsniDetailsPage/IsniDetailsPage';

export const routes: ReadonlyArray<IRoutes> = [
  {
    id: 'LandingPage',
    component: () => LandingPage,
    restriction: true,
    props: {
      exact: true,
      path: '/',
    }
  },
  {
    id: 'SearchPage',
    component: () => SearchPage,
    restriction: true,
    props: {
      exact: true,
      path: '/search',
    }
  },
  {
    id: 'AdvancedSearchPage',
    component: () => AdvancedSearchPage,
    restriction: true,
    props: {
      exact: true,
      path: '/advanced-search',
    }
  },
  {
    id: 'IsniSearchPage',
    component: () => IsniSearchPage,
    restriction: (d) => d[EPermissionType.SEARCH_ISNI],
    props: {
      exact: true,
      path: '/isni-search/:nameId',
    }
  },
  {
    id: 'IsniMoreInfoPage',
    component: () => IsniMoreInfoPage,
    restriction: (d) => d[EPermissionType.SEARCH_ISNI],
    props: {
      exact: true,
      path: '/isni-more-info/:nameId/:isni',
    }
  },
  {
    id: 'IsniDetailsPage',
    component: () => IsniDetailsPage,
    restriction: (d) => d[EPermissionType.VIEW_PARTY],
    props: {
      exact: true,
      path: '/isni-details/:isni',
    }
  },
  {
    id: 'GCDMMoreInfoPage',
    component: () => GCDMMoreInfoPage,
    restriction: (d) => d[EPermissionType.VIEW_PARTY],
    props: {
      exact: true,
      path: '/gcdm-more-info/:uaid',
    }
  },
  {
    id: 'PartyUAIDPage',
    component: () => PartyUAIDPage,
    restriction: (d) => d[EPermissionType.VIEW_PARTY],
    props: {
      exact: true,
      path: '/party/uaid/:uaid',
    }
  },
  {
    id: 'CreatePartyPage',
    component: () => CreatePartyPage,
    restriction: (d) => d[EPermissionType.CREATE_PARTY],
    props: {
      exact: true,
      path: '/party/:partyType/create',
    }
  },
  {
    id: 'EditPartyPage',
    component: () => EditPartyPage,
    restriction: (d) => d[EPermissionType.EDIT_PARTY],
    props: {
      exact: true,
      path: '/party/:id/edit',
    }
  },
  {
    id: 'PartyHistoryPage',
    restriction: (d) => d[EPermissionType.VIEW_HISTORY],
    component: () => PartyHistoryPage,
    props: {
      exact: true,
      path: '/party/:id/history'
    }
  },
  {
    id: 'ViewPartyPage',
    component: () => ViewPartyPage,
    restriction: (d) => d[EPermissionType.VIEW_PARTY],
    props: {
      exact: true,
      path: '/party/:id',
    }
  },
  {
    id: 'NotFound',
    component: () => NotFound,
    restriction: true,
    props: {
      exact: true,
      path: '/not-found',
    }
  },
  {
    id: 'AccessDenied',
    component: () => AccessDenied,
    restriction: true,
    props: {
      exact: true,
      path: '/access-denied',
    },
  }
];

export type TRoutesDictionary = Record<string, IRoutes>;

export const RouteDictionary: TRoutesDictionary = routes.reduce((a, v) => ({ ...a, [v.id]: v }), {});
