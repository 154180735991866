import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from 'domains/env';
import { ERemarkType, Remark } from 'modules/common/types/partyTypes';
import { formatLastModifiedAt, formatLastModifiedBy } from 'modules/common/helpers/formatLastModified';
import { tMessage } from 'modules/common/helpers/translation';
import { RemarkCard } from '@grow-components/Remarks/RemarkСard';
import { RemarkForm } from '@grow-components/Remarks/RemarkForm/RemarkForm';
import { useConfirmation } from '@grow-components/Popup';
import { useForm } from 'react-final-form';

interface Props {
  nameIdx: number;
  remarkIdx: number;
  remarkData: Remark;
  dataLoading: boolean;
  remarkTypes: ERemarkType[];
  existingRemarkTypes: string[] | undefined;
  handleCancelEdit: (idx: number, remark: Remark) => void;
  handleUpdateRemark: (remarkIdx: number) => void;
  handleDeleteRemark: (remarkIdx: number) => void;
}

const RemarkModule: React.FC<Props> = ({
  nameIdx,
  remarkIdx,
  remarkData,
  remarkTypes = [],
  existingRemarkTypes = [],
  handleUpdateRemark,
  handleCancelEdit,
  handleDeleteRemark,
}) => {
  const [localEditMode, setLocalEditMode] = useState(!remarkData?.remarkId);
  const user = useUser();
  const [initialData, setInitialData] = useState(remarkData);
  const { t } = useTranslation();
  const form = useForm();
  const remarkAuthor = useMemo(() => {
    //this is validated to show an author when the remark is recently created or modified and there's not in the backend values
    if (!remarkData?.lastModifiedBy) return remarkData?.remarkType === ERemarkType.ADDITIONAL_INFO ? 'Quansic Import' : formatLastModifiedBy(user.getItem('userName'));
    return formatLastModifiedBy(remarkData?.lastModifiedBy);
  }, [remarkData?.lastModifiedBy, remarkData?.remarkType]);
  useEffect(() => {
    if (!localEditMode) setInitialData(remarkData);
  }, [localEditMode, remarkData]);

  useEffect(() => {
    if(remarkData?.text === '' || initialData?.remarkType !== remarkData?.remarkType || initialData?.text !== remarkData?.text) {
      setLocalEditMode(true);
    } else {
      setLocalEditMode(false);
    }
    if(remarkData?.remarkType === ERemarkType.ADDITIONAL_INFO) setLocalEditMode(false);
  }, [remarkData, initialData]);




  const handleCancel = () => {
    if(!initialData) handleDeleteRemark(remarkIdx);
    else if (!initialData.text && !initialData.remarkType) handleDeleteRemark(remarkIdx);
    else handleCancelEdit(remarkIdx, initialData);
    setLocalEditMode(false);
  };
  const handleSaveRemark = () => {
    setLocalEditMode(false);

    if (!initialData.remarkId)
      form.change(`names[${nameIdx}].remarks[${remarkIdx}].added`, true);

    if (initialData?.remarkType && (remarkData.text !== initialData.text || remarkData.remarkType !== initialData.remarkType)) 
      handleUpdateRemark(remarkIdx);
  };

  const handleEdit = () => {
    setInitialData(remarkData);
    setLocalEditMode(true);
  };

  const [onDelete, dialog] = useConfirmation(
    handleDeleteRemark,
    (idx) => {
      if (typeof remarkData.remarkId !== 'undefined') return true;
      handleDeleteRemark(idx);
      return false;
    }, {
      name: t('modal:delete_remark_confirmation'),
      message: t('modal:delete_remark_confirmation_message'),
      actionText: t('delete'),
    });

  const isAlreadySelectedRemarkType = useCallback((remarkType: string) =>
    existingRemarkTypes.includes(remarkType) && remarkData?.remarkType !== remarkType,
  [remarkData, existingRemarkTypes]
  );

  const allowedNewRemarkTypes = useMemo(() => remarkTypes.filter(rType => ![
    ERemarkType.ARTIST_CREATED_ON_THE_FLY,
    ERemarkType.BAND_GROUP_MEMBERS_INCLUDE,
    ERemarkType.COMPETENCY_CHANGE,
    ERemarkType.ISNI_DISAMBIGUATION_COMMENT,
    ERemarkType.ADDITIONAL_INFO
  ].includes(rType)), [remarkTypes]);

  const remarkOptions = useMemo(() =>
    [...allowedNewRemarkTypes].sort().map(remarkType => ({
      label: `${isAlreadySelectedRemarkType(remarkType) ? '* ' : ''}${tMessage(remarkType)}`,
      value: remarkType,
      isDisabled: isAlreadySelectedRemarkType(remarkType)
    })), [allowedNewRemarkTypes, existingRemarkTypes]);

  return (
    <>
      {
        dialog
      }
      {
        localEditMode ? (
          <RemarkForm
            name={`names[${nameIdx}].remarks[${remarkIdx}]`}
            remarkTypes={remarkOptions}
            onCancel={handleCancel}
            onConfirm={handleSaveRemark}
          />
        ) : (
          <RemarkCard
            title={remarkData?.remarkType && tMessage(remarkData.remarkType)}
            text={remarkData?.text}
            author={remarkAuthor}
            date={remarkData?.lastModifiedAt ? formatLastModifiedAt(remarkData?.lastModifiedAt) : ''}
            onEdit={handleEdit}
            onDelete={() => onDelete(remarkIdx)}
            readonly={remarkData?.remarkType === ERemarkType.ADDITIONAL_INFO}
          />
        )
      }
    </>
  );
};

export default RemarkModule;
