import React, { FC } from 'react';
import { Tooltip } from '../Tooltip';
import { Icon } from '../Icon/Icon';
import { Place } from 'react-tooltip';
import styles from './source-credit.module.scss';

interface ISourceCreditProps {
    source: string;
    tooltipContent?: string | JSX.Element;
    tooltipPlace?: Place
}

export const SourceCredit: FC<ISourceCreditProps> = ({ source, tooltipContent, tooltipPlace }) => <>
  {tooltipContent ?
    <div>
      <Tooltip text={tooltipContent} place={tooltipPlace || 'bottom'}>
        <div className={styles.content}><Icon icon="circle-info" className={styles.icon} />
                Info: {source}
        </div>
      </Tooltip>
    </div>
    : <div className={styles.content}>
      <Icon icon="circle-info" className={styles.icon} />
            Info: {source}
    </div>}
</>;