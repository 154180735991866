export const formatLastModifiedBy = (lastModifiedBy: string|undefined) => {
  const arr = lastModifiedBy?.split(', ');
  if (arr?.length === 2) return arr[1]+' '+ arr[0];
  else return lastModifiedBy;
};

export const formatLastModifiedAt = (lastModifiedAt: string|undefined) => {
  if (!lastModifiedAt) lastModifiedAt = '';
  const dateObj =new Date(lastModifiedAt);
  const date = dateObj.toLocaleDateString().replace(/\./g, '/');
  let hours = dateObj.getHours();
  let minutes: number|string = dateObj.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  const  time = hours + ':' + minutes + ' ' + ampm;
  return date+ ' - '+ time;
};