/**
 * Copies any string entered as a parameter to the clipboard
 * @param str
 */
export const copyToClipboard = (str: string) => {
  try {
    navigator.clipboard.writeText(str);
  } catch (error) {
    throw new Error();
  }
};
