import React from 'react';
import styled from 'styled-components/macro';
import ReactTooltip from 'react-tooltip';
import { Offset, Place } from 'react-tooltip/dist/react-tooltip';
import { PopupSpeed } from 'modules/common/types/popupTypes';

// Wrapper for additional tooltip styling
const TooltipWrapper = styled.div`
  * {
    padding: 0;

    p {
      padding: 0;
      margin: 0;
    }
  }

  .react-tooltip {
    padding: 8px 21px;
  }

  .artist-results-tooltip {
    padding: ${({ theme }) => theme.customSpacing.artistResultPadding};
  }
`;

interface Props {
  id?: string;
  place?: Place;
  delayShow?: number | undefined;
  offset?: Offset;
  positionLeft?: boolean;
  customClass?: string;
  event?: string;
  refProp?: React.MutableRefObject<any>;
  disable?: boolean;
}

const Tooltip: React.FC<Props> = ({
  id,
  place,
  offset,
  delayShow,
  children,
  positionLeft,
  customClass,
  event,
  refProp,
  disable,
}) => {
  return (
    <TooltipWrapper>
      <ReactTooltip
        className={`${
          customClass
            ? [customClass, 'react-tooltip'].join(' ')
            : 'react-tooltip'
        }`}
        id={id}
        ref={refProp}
        place={place}
        effect={'solid'}
        event={event}
        offset={offset}
        disable={disable}
        multiline
        delayShow={delayShow || delayShow === 0 ? delayShow : PopupSpeed.FAST}
        overridePosition={({ left, top }) => {
          if (positionLeft && offset && offset.right) {
            left = 0 + offset.right;
          } else if (positionLeft) {
            left = 0;
          }
          return { top, left };
        }}
      >
        {children}
      </ReactTooltip>
    </TooltipWrapper>
  );
};

export default Tooltip;
