import React, { useContext, useMemo, useCallback, useReducer, useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, EDialogType } from '@grow-components/Popup';
import { BROADCAST_CHANNELS, useBroadcastChannel, EBroadCastMessageType } from 'modules/common/hooks/useBroadcastChannel';
import { ToastService } from 'utils/ToastService';
import { reducer, initialArg, EActionType, TReducer } from './reducer';
import { ISNILookupContext } from './context';
import { useQueryParams } from 'modules/common/hooks/useQueryParams';
import { EIsniPageCameFrom, EIsniPageHashFlag, TIsniQueryParams } from 'modules/common/types/isniTypes';

export function useConfirmation() {
  const { data, activate } = useContext(ISNILookupContext);
  const { sendTMessage, subscribeOnMessage, receiveMessage: handleSearchTabClose } = useBroadcastChannel(BROADCAST_CHANNELS.ISNI);
  const [state, dispatch] = useReducer<TReducer>(reducer, initialArg);
  const { t } = useTranslation();
  const [search, setSearch] = useState(JSON.parse(localStorage.getItem('searchUri') as string));

  const unsub = useRef<() => void>();

  const { params } = useQueryParams<TIsniQueryParams>();
  const cameFrom = params.cameFrom;

  const searchTabCloseHandler = useCallback((msg) => {
    if (cameFrom === EIsniPageCameFrom.SEARCH && msg.type && msg.type === EBroadCastMessageType.CLOSE_SEARCH_TAB) {
      setSearch(msg.payload.searchUri);
      window.location.hash = EIsniPageHashFlag.SEARCH_TAB_CLOSED;
    }
  }, []);

  useEffect(() => handleSearchTabClose(searchTabCloseHandler), []);

  const action = useCallback((type: EActionType) => dispatch({ type }), [dispatch]);

  const subHandler = useCallback(
    (id: string) => {
      if (id === data.nameId) {
        action(EActionType.OPEN_SOURCE_SUCCESS);
        activate();
        ToastService.info(t('toasts:select_isni_again'));
        if (typeof unsub.current === 'function') {
          unsub.current();
        }
      }
    },
    [data.nameId],
  );

  const handleAction = useCallback(
    () => {
      action(EActionType.OPEN_SOURCE_REQUEST);
      window.open(['/party', data.partyId, 'edit'].join('/'), '_blank');
      unsub.current = subscribeOnMessage(EBroadCastMessageType.NAME_ID_READY, subHandler);
    },
    [data.partyId],
  );
  
  const handleCancel = useCallback(
    () => {
      if (data.nameId) {
        sendTMessage.assigneingInterrupted(data.nameId);
      }
    },
    [data.nameId],
  );

  const handleClose = useCallback(
    () => action(EActionType.HIDE_MODAL),
    [dispatch]
  );

  const onInitAssignIsni = useCallback(
    () => {
      if (data.nameId) {
        action(EActionType.CHECK_AVAILABLE);
        sendTMessage.reqISNIData(data.nameId)
          .then(() => { action(EActionType.CHECK_SUCCESS ); })
          .catch(() => { 
            if ( cameFrom !== EIsniPageCameFrom.SEARCH ) action(EActionType.CHECK_FAILURE ); 
            else {
              action(EActionType.CHECK_SUCCESS );
            }
          });
      }
      else {
        action(EActionType.CHECK_SUCCESS );
      }
    },
    [sendTMessage, data.nameId, dispatch],
  );

  const element = useMemo(
    () => (
      <Dialog
        isOpen={state.interruptModal}
        name={t('confirm_isni')}
        loading={state.loading}
        type={EDialogType.destructive}
        onAction={handleAction}
        onCancel={handleCancel}
        onClose={handleClose}
        cancelText={t('cancel')}
        actionText={t('ok')}
      >{t('modal:ISNI_select_has_been_interrupted')}</Dialog>
    ),
    [state],
  );
  return {
    search,
    data,
    dialog: element,
    onInitAssignIsni,
    state,
    hideConfirmModal: handleClose,
  };
}
