import React, { FC } from 'react';
import styles from './Widget.module.scss';
import { cn } from 'utils';

type TWidgetProps = {
  title?: string
  className?: string
}

export const Widget: FC<TWidgetProps> = (props) => {
  return (
    <div className={cn(styles.container, props.className)}>
      {props.title && <h4 className={styles.title}>{props.title}</h4>}
      {props.children}
    </div>
  );
};
