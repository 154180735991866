import { Dispatch, SetStateAction } from 'react';
import { TFilters } from '../types';

export type TState = TFilters & { dateTime?: [string, string] | string };
export type TSetState<T = TFilters> = Dispatch<SetStateAction<T>>;

export enum EOperator {
  RANGE = 'range',
  SINGL = 'singl',
}

export interface IFormFilter {
  operator: EOperator,
  date?: string;
  from?: string;
  to?: string;
  datecreated?: boolean;
  present?: boolean;
}
