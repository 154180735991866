import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatDateTime, createViewPartyPageLink } from 'utils';
import { TRecentPartiesEntry } from 'domains/party/hooks/types';
import { EDateTimeFormat } from 'common/consts';
import { ERecentPartiesAction } from 'modules/pages/LandingPage/consts';
import { ArtistImage } from 'modules/common/components/ArtistImage';

import styles from './partyPreview.module.scss';
import { usePartyImage } from 'domains/party/hooks/usePartyImage';

interface IPartyPreviewProps {
    partyEntry: TRecentPartiesEntry;
    userAction: ERecentPartiesAction;
    dateFormat: string | undefined
}

export const PartyPreview: FC<IPartyPreviewProps> = ({ partyEntry, userAction, dateFormat }) => {
  const { t: tLanding } = useTranslation('landing');
  const { dataImages } = usePartyImage({ partyId: partyEntry.partyId });
  const action = userAction === ERecentPartiesAction.CREATED ? tLanding('created') : tLanding('modified');
  const date = useMemo(() => formatDateTime(partyEntry.managedAt, dateFormat?.toUpperCase() || EDateTimeFormat.MM_DD_YYYY_Slash), [partyEntry, dateFormat]);

  return <div className={styles.partyPreview}>
    <ArtistImage className={styles.partyImageSmall} images={dataImages} artistName={partyEntry.pkaName} isRound isCentred />
    <div className={styles.partyInfo}>
      <Link to={createViewPartyPageLink(partyEntry.partyId)} className={styles.partyLink}>{partyEntry.pkaName}</Link>
      <span className={styles.date}>{action} { date }</span>
    </div>
  </div>;
};