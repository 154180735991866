import React, { useCallback, useEffect, useMemo, useState } from 'react';
import get from 'lodash/get';
import { useForm } from 'react-final-form';
import { Dialog, EDialogType } from '@grow-components/Popup';
import { useTranslation, Trans } from 'react-i18next';

export const useConfirmation = (name: string, disabled: boolean) => {
  const { t } = useTranslation();
  const [isOpen, setDialogStatus] = useState<boolean>(false);
  const [closed, setClosed] = useState(false);
  const { change, getState } = useForm();
  const { initialValues, values } = getState();
  const path = useMemo(() => `${name}.competencyId`, [name]);
  const currentValue = useMemo<number>(() => get(values, path), [values, path]);
  const initialValue = useMemo<number>(() => get(initialValues, path), [initialValues, path]);

  const nameValuePath = useMemo(() => `${name.split('.')[0]}.nameValue`, [name]);
  const nameValue = useMemo<string>(() => get(values, nameValuePath), [values, path]);

  const handleClose = useCallback(() => { setDialogStatus(false);}, [setDialogStatus]);

  const handleCancel = useCallback(
    () => {
      change(path, initialValue);
      handleClose();
    },
    [change, handleClose, path, initialValue],
  );

  const element = useMemo(() => (
    <Dialog
      name={t('delete')}
      isOpen={isOpen}
      type={EDialogType.destructive}
      onClose={handleClose}
      onCancel={handleCancel}
      actionText={t('continue')}
      cancelText={t('cancel')}
      onAction={()=>{handleClose();  setClosed(true); }}
    >
      <Trans i18nKey="modal:delete_competencies" values={{ name: nameValue }} />
    </Dialog>
  ), [handleClose, isOpen, handleCancel]);

  const isExistInitialValue = useMemo(
    () => typeof get(initialValues, `${name}.nameCompetencyId`) !== 'undefined',
    [initialValues],
  );

  useEffect(() => {
    if (!disabled && isExistInitialValue && typeof currentValue === 'undefined' && closed === false) {
      setDialogStatus(true);
    }
  }, [disabled, initialValue, currentValue, setDialogStatus]);

  return {
    element,
  };
};
