export const formatPartyType = (type: string) => {
  if (!type) return '';
  const lowercaseAndParsed = type.toLowerCase().split('_');
  for (let i = 0; i < lowercaseAndParsed.length; i++) {
    lowercaseAndParsed[i] =
      lowercaseAndParsed[i].slice(0, 1).toUpperCase() +
      lowercaseAndParsed[i].slice(1);
  }
  return lowercaseAndParsed.join(' ');
};
