import React, { FC, useMemo } from 'react';
import { LayoutDefault } from './default';

interface IProps<T = Record<string, any>> {
  renderRow(data: T, index?: number): JSX.Element;
  renderLayout?(): FC;
  getRowKey?(d: T, i: number): string | number,
  rows: ReadonlyArray<T>;
}

export const Body: FC<IProps> = ({
  renderLayout = () => LayoutDefault,
  renderRow,
  rows,
}) => {

  const Layout = useMemo(() => renderLayout(), [renderLayout]);

  return (
    <Layout>
      {
        rows.map((e, index) => renderRow(e, index))
      }
    </Layout>
  );
};
