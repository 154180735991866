export interface IPosition {
  x: number;
  y: number;
  height: number;
  width: number;
  innerHeight: number;
  innerWidth: number;
}

export enum EPosition {
  top = 'top',
  left = 'left',
  bottom = 'bottom',
  right = 'right',
  rightBottom = 'right-bottom',
  rightTop = 'right-top',
}

export interface ICalculate extends IPosition {
  top: number;
  left: number;
  bottom: number;
  xPosition: number;
  yPosition: number;
  middleX: number;
  middleY: number;
  yShift: number;
}

export type TEffect = [EPosition, string];
