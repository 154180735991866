import { TSpinnerSpecs } from './types';

export const SPINNER_COLOR = '#0032a0';

export enum ESpinnerSize {
  SMALL= 'sm',
  MEDIUM = 'md',
  LARGE = 'lg'
}

export const SPINNER_SPECS_LARGE: TSpinnerSpecs = {
  height: 100,
  width: 4,
  radius: 8,
  margin: 8
};

export const SPINNER_SPECS_MEDIUM: TSpinnerSpecs = {
  height: 40,
  width: 3,
  radius: 2,
  margin: 3
};

export const SPINNER_SPECS_SMALL: TSpinnerSpecs = {
  height: 9,
  width: 2,
  radius: 1,
  margin: 1
};