import { gql } from '@apollo/client';
import { LOCK_FRAGMENTS, PARTY_FRAGMENTS } from './fragments';

export const GET_PARTY = gql`
  ${PARTY_FRAGMENTS}
  ${LOCK_FRAGMENTS}
  query getParty($partyId: Int!) {
    partyGet(data: {partyId: $partyId}) {
      party {
        partyId
        partyType
        partySubType
        countryOfOriginId
        languageOfOriginId
        dateOfBeginningYear
        dateOfBeginningMonth
        dateOfBeginningDay
        dateOfEndYear
        dateOfEndMonth
        dateOfEndDay
        createdById
        signed
        lock {
          ...PartyLockFields
        }
      }
      names {
        ...PartyName
      }
    }
  }
`;
