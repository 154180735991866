import { IStorage, IGROWSession } from './types';

export function localStorage<T extends Record<string, any>>(storage: Storage): IStorage<T> {

  const get = async <K extends keyof T, V extends T[K]>(key: K, def: V): Promise<V> => {
    const res = storage.getItem(key as string);
    if (typeof res === 'undefined' || res === null) {
      return Promise.resolve(res || def);
    }
    try {
      const item: V = JSON.parse(res);
      return Promise.resolve(item);
    } catch (err) {
      return Promise.resolve(def);
    }
  };

  const set = async <K extends keyof T, V extends T[K]>(key: K, value: V): Promise<void> => {
    const keyString = key as string;
    if (typeof value === 'string') {
      storage.setItem(keyString, value);
    } else {
      storage.setItem(keyString, JSON.stringify(value));
    }
    return Promise.resolve();
  };

  return {
    get,
    set,
  };

}

export const storageFactory = (storage: Storage) => localStorage<IGROWSession>(storage);
