import React, { useMemo } from 'react';
import { FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { fieldSubscription, createRemark } from 'modules/common/components/forms/CreateEditPartyForm/utils/formValues';
import { ERemarkType, Remark } from 'modules/common/types/partyTypes';
import RemarkModule from './RemarkModule';
import { Button, EButtonSizes, EButtonVariants } from '@grow-components/Button';
import styles from '@grow-components/Remarks/Remarks.module.scss';
import { SectionHeader } from '@grow-components/Form/InputsArray/SectionHeader';
import { cn } from 'utils';
import { Tooltip } from '@grow-components/Tooltip/Tooltip';
import { useCurrentName } from './useCurrentName';
import createEditPartyFormStyles from 'modules/pages/Party/CreateEditPartyForm.module.scss';

interface Props {
  isAddButtonDisabled?: boolean;
  nameIdx: number;
  remarkTypes: ERemarkType[];
  dataLoading: boolean;
}

const RemarksContainer: React.FC<Props> = ({
  nameIdx,
  remarkTypes = [],
  dataLoading,
  isAddButtonDisabled
}) => {
  const { t } = useTranslation();

  const { values } = useCurrentName(`names[${nameIdx}]`);

  const isDisable = useMemo<boolean>(
    () => {
      if (isAddButtonDisabled) return true;
      return false;
    },
    [isAddButtonDisabled, values],
  );

  return (
    <FieldArray
      name={`names[${nameIdx}].remarks`}
      subscription={fieldSubscription}
    >
      {({ fields }) => {
        const handleAddRemark = () => {
          fields.push(createRemark());
        };

        return (
          <div className={styles.remarksContainer}>
            <SectionHeader
              title={t('party_labels:remarks')}
              button={
                <Tooltip text={t('tooltips:new_remark_button')}>
                  <Button
                    className={createEditPartyFormStyles.addButton}
                    text={t('party_labels:new_remark')}
                    variant={EButtonVariants.link}
                    icon="plus"
                    size={EButtonSizes.mini}
                    onClick={handleAddRemark}
                    disabled={isDisable}
                  />
                </Tooltip>
              }
            />
            <div className={cn(styles.remarksList, 'columns-4')}>
              <FormSpy subscription={{ values: true }}>
                {({ values, form }) => {
                  const existingRemarkTypes = form.getState().values.names[nameIdx].remarks
                    ?.map((remark: { remarkType: string }) => remark.remarkType);
                  const handleUpdateRemark = (remarkIdx: number) => {
                    form.change(`names[${nameIdx}].remarks[${remarkIdx}].lastModifiedBy`, '');
                    form.change(`names[${nameIdx}].remarks[${remarkIdx}].lastModifiedAt`, '');
                  };
                  const handleCancelEdit = (remarkIdx: number, originalRemark: Remark) => {
                    form.change(`names[${nameIdx}].remarks[${remarkIdx}]`, originalRemark);
                  };
                  const handleDeleteRemark = (remarkIdx: number) => {
                    fields.remove(remarkIdx);
                  };
                  return (
                    fields.map((name, i) => {
                      return  <RemarkModule
                        key={name}
                        nameIdx={nameIdx}
                        remarkIdx={i}
                        remarkData={values.names[nameIdx]?.remarks[i]}
                        remarkTypes={remarkTypes}
                        existingRemarkTypes={existingRemarkTypes}
                        dataLoading={dataLoading}
                        handleCancelEdit={handleCancelEdit}
                        handleDeleteRemark={handleDeleteRemark}
                        handleUpdateRemark={handleUpdateRemark}
                      />;
                    }
                    )
                  );
                }}
              </FormSpy>
            </div>
          </div>
        );
      }}
    </FieldArray>
  );
};
export default RemarksContainer;
