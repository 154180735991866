import React, { Fragment } from 'react';
import { useTranslation, Trans } from 'react-i18next';

export const SearchHeader = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className="container search-header">
        <div className="row">
          <div className="col-sm-8 col-sm-offset-2">
            <div className="">
              <h1>{t('search:welcome_heading')}</h1>
              <h4>{t('search:welcome_text_1')}</h4>
              <h4>
                <Trans i18nKey='search:welcome_text_2'>
                In this version our focus is on creating a cross-reference for
                GCDM Artists based on UAID to ISNI (International Standard Name
                Identifier). See
                  <a
                    href="https://isni.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                  https://isni.org
                  </a>
                  for more info.</Trans>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
