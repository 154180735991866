import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useIdleTimer } from 'react-idle-timer';
import { TimeoutValues } from './types';
import IdleAlert from './IdleAlert';

export function useIdle() {
  const timeout = TimeoutValues.SESSION_TIME;
  const warningTime = TimeoutValues.DISPLAY_ALERT_TIME;
  const offset = TimeoutValues.OFFSET;
  const changeColorTime = TimeoutValues.CHANGE_COLOR_TIME;

  const [remaining, setRemaining] = useState(timeout);
  const [displayIdleAlert, setDisplayIdleAlert] = useState(false);
  const [showAlertColor, setShowAlertColor] = useState(false);

  const { authState, oktaAuth } = useOktaAuth();

  const handleOnActive = useCallback(() => {
    setDisplayIdleAlert(false);
    setShowAlertColor(false);
  }, []);

  const handleOnIdle = useCallback(async () => {
    try {
      await oktaAuth.signOut({
        postLogoutRedirectUri: window.location.origin + '/',
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }, [oktaAuth]);

  const handleToggleAlertOff = useCallback(() => {
    setDisplayIdleAlert(false);
  }, [setDisplayIdleAlert]);

  const { getRemainingTime, pause, reset } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
    events: ['mousedown', 'keydown'],
  });

  useEffect(() => {
    let interval: any;

    if (
      authState.isAuthenticated &&
      !authState.isPending &&
      !displayIdleAlert
    ) {
      interval = setInterval(() => {
        setRemaining(getRemainingTime());
      }, 60000);
    } else {
      interval = setInterval(() => {
        setRemaining(getRemainingTime());
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [
    authState.isAuthenticated,
    authState.isPending,
    getRemainingTime,
    displayIdleAlert,
  ]);

  useEffect(() => {
    if (!authState.isAuthenticated) {
      pause();
      reset();
      handleToggleAlertOff();
    }
  }, [authState, pause, reset]);

  // trigger idle alert when only is 5 minutes are remaining
  useEffect(() => {
    if (remaining <= warningTime + offset) {
      setDisplayIdleAlert(true);
    } else {
      setDisplayIdleAlert(false);
    }
    if (remaining - offset <= changeColorTime) {
      setShowAlertColor(true);
    } else {
      setShowAlertColor(false);
    }
  }, [remaining, changeColorTime, offset, warningTime]);

  const alert = useMemo<JSX.Element | null>(
    () => displayIdleAlert ? (
      <IdleAlert
        getRemainingTime={getRemainingTime}
        onClick={handleToggleAlertOff}
        showAlertColor={showAlertColor}
        offset={offset}
      />
    ) : null,
    [displayIdleAlert, handleToggleAlertOff, showAlertColor, offset],
  );

  return {
    element: alert,
  };

}
