import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { match } from 'utils';
import { Party } from 'modules/common/types/partyTypes';

import { initialValuesFactoryAdapter } from './adapters';

import { TDictionaries } from './types';

interface IProps {
  dictionaries?: TDictionaries,
}

export const BusinessLogic: FC<IProps> = ({ dictionaries }) => {

  const prevValues = useRef<Party>();

  const { initialize } = useForm<Party>();

  const { values } = useFormState<Party>();

  const initialValuesAdapter = useMemo(
    () => initialValuesFactoryAdapter(dictionaries),
    [dictionaries],
  );

  const handleChange = useCallback<(prev: Party | undefined, next: Party) => void>(
    (prev, next) => {
      match({ prev, next })
        .on(
          (state) => state.prev?.partyType !== state.next.partyType,
          () => {
            initialize(initialValuesAdapter);
          }
        )
        .otherwise(() => null);
    },
  [initialize, initialValuesAdapter],
  );

  const getValues = useCallback<(formValues?: Party) => Party | undefined>(
    (formValues) => {
      if (typeof formValues === 'undefined') return undefined;
      return { ...formValues };
    },
  [],
  );

  useEffect(() => {
    const prev = getValues(prevValues.current);
    const next = values;
    handleChange(prev, next);
    if ('current' in prevValues) {
      prevValues.current = next;
    }
  }, [values, prevValues]);

  return null;
};
