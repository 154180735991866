import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@grow-components/Icon/Icon';
import { Button, Icon as IconLegato } from '@wmg-ae/legato';
import useDetectClickOutside from 'modules/common/hooks/useDetectClickOutside';

import styles from './WorkspaceSelect.module.scss';
import { Tooltip } from '@grow-components/Tooltip';

const partyWorkspaceUrl = window.location.origin;

export const WorkspaceSelect: FC = () => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);
  const toggleSelect = useCallback(() => setOpen(!isOpen), [isOpen]);
  const handleClose = useCallback(() => setOpen(false), []);

  const ref = useDetectClickOutside(handleClose);

  const workspaceOptions = useMemo(() => [{
    value: partyWorkspaceUrl,
    label: <div className={styles.linkContent}>
      <Icon icon="users" className={styles.linkIcon} />
      {t('party_workspace')}
    </div>
  }], [t]);

  const renderWorkspaceOptions = useMemo(
    () => (
      <div className={styles.list}>
        {workspaceOptions.map((option, index) => (
          <a
            key={`workspaceSelectOption_${index}`}
            onClick={() => setOpen(false)}
            href={option.value}
            className={styles.link}
          >{option.label}</a>
        ))}
      </div>
    ),
    [workspaceOptions]);

  const currentWorkspace = useMemo(() => t('party_workspace'), [t]);

  return <div className={styles.container} ref={ref}>
    <Tooltip text={t('tooltips:workspace_selector')} block>
      <Button className={styles.dropBtn} onClick={toggleSelect} containerStyle='outline' colorType='info' icon={<IconLegato name="chevron-down" />} label={currentWorkspace} />
    </Tooltip>
    {isOpen && renderWorkspaceOptions}
  </div>;
};
