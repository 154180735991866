import React, { FC, useEffect, useMemo } from 'react';
import { cn } from 'utils';
import Layout from 'modules/common/components/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useAdvancedNamesSearch } from 'domains/search/hooks/useAdvancedNamesSearch';
import { SearchResults } from 'modules/pages/SearchPage/components/ResultsBox/SearchResults';
import { useQueryParams } from 'modules/common/hooks/useQueryParams';
import { useQueryMetaParams } from 'modules/common/hooks/useQueryMetaParams';
import { toggleAdvancedSearchForm, updateAdvancedSearchParams, } from 'redux/reducers/advancedSearchReducer';
import { EAdvancedSearchOperator, ECompetencyQueryType, ELegalNameQueryTypes, TAdvancedSearchURLParams } from 'domains/search/types';
import { ENameType, EPartySubType } from 'domains/party/types';
import { EPartyType } from 'modules/common/types/partyTypes';
import { useTranslation } from 'react-i18next';
import { Subheader } from '@grow-components/Subheader/Subheader';
import { EmptySearchResult } from '@grow-components/EmptySearchResult/EmptySearchResult';
import { ExportBtn } from 'modules/common/components/utils/ExportBtn';
import { useFileExportAdvancedSearch } from 'domains/search/hooks/useFileExportAdvancedSearch';
import { EFileExportType } from 'domains/search/graphql';
import { Icon } from '@grow-components/Icon/Icon';
import { PillsList } from './components/PillsList';
import calculateResultNums from 'modules/common/helpers/calculateResultNums';
import NotFound from 'modules/common/components/errors/pages/NotFound';

import styles from './AdvancedSearchPage.module.scss';
import { ToastService } from 'utils/ToastService';

export const AdvancedSearchPage: FC = () => {
  const { t } = useTranslation();
  const { t: tEmptySearch } = useTranslation('empty_search');
  const dispatch = useDispatch();
  const advancedSearchQueryParams = useSelector((state: RootState) => state.advancedSearch.queryParams);
  const { params } = useQueryParams<TAdvancedSearchURLParams>();
  const { queryMetaParams: { size, skip } } = useQueryMetaParams();
  const searchTerm = params.nameValue;
  const readURLQueryParams = () => {
    dispatch(updateAdvancedSearchParams({
      nameOperator: params.nameOperator as EAdvancedSearchOperator,
      nameValue: params.nameValue,
      nameType: params.nameType as ENameType,
      legalName: params.legalName as ELegalNameQueryTypes,
      partyType: params.partyType as EPartyType,
      partySubType: params.partySubType as EPartySubType,
      partyCountryOfOriginId: Number(params.partyCountryOfOriginId) || undefined,
      competency: params.competency as ECompetencyQueryType,
      createdBy: params.createdBy as string,
      modifiedBy: params.modifiedBy as string,
      createdOnOperator: params.createdOnOperator as EAdvancedSearchOperator,
      createdOnA: params.createdOnA as string,
      createdOnB: params.createdOnB as string,
      modifiedOnOperator: params.modifiedOnOperator as EAdvancedSearchOperator,
      modifiedOnA: params.modifiedOnA as string,
      modifiedOnB: params.modifiedOnB as string
    }));
  };
  useEffect(readURLQueryParams, [params]);
  const { loading, data, error, } = useAdvancedNamesSearch(advancedSearchQueryParams);
  const initialLoading = !data && loading;
  const totalCount = data?.totalCount ?? 0;
  const page = skip / size + 1;

  const { startNum, endNum } = useMemo(() => {
    return calculateResultNums(
      page,
      totalCount,
      size,
    );
  }, [totalCount, size, page]);

  const formattedParams = useMemo(
    () => {
      if (params.nameValue) return params.nameValue;
      return '';
    },
    [params]
  );

  const areParamsExist = Object.keys(params).length > 0;
  useEffect(() => {
    if (!areParamsExist) dispatch(toggleAdvancedSearchForm(true));
  }, [areParamsExist]);

  const { fileExportAdvancedSearch, operations } = useFileExportAdvancedSearch(advancedSearchQueryParams);

  const advancedSearchExportHandler = (filetype: EFileExportType) => {
    ToastService.info(t('toasts:export_search'));
    fileExportAdvancedSearch({
      variables: {
        operations,
        filetype
      }
    });
  };

  if (error) return (
    <NotFound />
  );

  return (
    <Layout loading={initialLoading}>
      <div className="container">
        <div className="results-box">
          <Subheader
            location={t('search:search_results')}
            mainInfo={formattedParams}
            mainInfoClassName={styles.mainInfo}
            noShrink
            additionalInfo={areParamsExist ? `Showing ${searchTerm || ''} ${startNum} - ${endNum} of ${totalCount} ${totalCount === 1 ? 'result' : 'results'}` : ''}
            rightSideBlock={
              <div className={styles.exportBtn}>
                <ExportBtn
                  disabled={!areParamsExist || totalCount === 0}
                  exportHandler={advancedSearchExportHandler}
                  buttonContent={<>Export <Icon icon="file-arrow-down" /></>}
                />
              </div>
            }
            sticky
            className={styles.subheader}
            bottom={<PillsList />}
          />
          {(areParamsExist && data && totalCount)
            ? (
              <SearchResults
                results={data.names}
                searchTerm={searchTerm}
                loading={loading}
                totalCount={totalCount}
                className={cn(styles.resultsTable, { [styles.emptyNameValueTable]: !params.nameValue })}
              />
            ) : (
              <EmptySearchResult
                headline={tEmptySearch('bummer')}
                message={tEmptySearch('adv_search_msg')}
              />
            )
          }
        </div>
      </div>
    </Layout>
  );
};

